import {ReactElement, useMemo} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import {ReactComponent as FilePresentOutlined} from '../../../../../../assets/images/FilePresentOutlined.svg'

import {ClientProfileDoc} from '../../types'
import {styles} from './styles'

type FilePreviewProps = {
  file?: File | ClientProfileDoc
} & WithStyles

function FilePreviewBase(props: FilePreviewProps): ReactElement | null {
  const {classes, file} = props

  const fileName = useMemo(() => {
    if (!file) return null
    if (file instanceof File) return file?.name

    return file?.fileName
  }, [file])

  if (!file) return null

  return (
    <div className={classes.container}>
      <FilePresentOutlined className={classes.icon}/>
      <span className={classes.fileName}>{fileName}</span>
    </div>
  )
}

const FilePreview = withStyles(styles)(FilePreviewBase)
export {FilePreview}
