export const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  documentTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  documentFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  }
}) as const
