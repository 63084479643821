export const styles = (theme) => ({
  row: {
    maxWidth: '50%',
    paddingRight: '20px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingRight: 0,
    },
  }
}) as const
