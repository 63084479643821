export const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }
}) as const
