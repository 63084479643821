export const styles = theme => ({
  formControl: {
    fontSize: '0.9rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  textLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  firstItemHide: {
    display: 'none',
  },
  button:{
    marginTop: 0
  },
  usButton: {
    padding: '8px 0',
    '&:hover':{
      backgroundColor: 'transparent',
    },
    '&:active':{
      backgroundColor: 'transparent',
    }
  },
  subtitle: {
    paddingBottom: 20
  },
  error: {
    color: theme.palette.error.main,
  },
  textRight: {
    textAlign: 'right'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginBottom: '40px',
  }
}) as const
