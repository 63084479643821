import {economicProfileQuestions} from '@bdswiss/common-enums'

const DOCUMENT_FIELDS = [
  economicProfileQuestions.identityOfTheThirdParty.value,
  economicProfileQuestions.proofsOfIdentityFiamlRegulations.value,
  economicProfileQuestions.relationshipThirdParty.value,
]


const FORM_FIELDS = Object.freeze({
  transactionPurpose: 'transactionPurpose',
  transactionPurposeClarify: 'transactionPurposeClarify',
  approxYearlyIncome: 'approxYearlyIncome',
  approxNetWorth: 'approxNetWorth',
  sourceOfFunds: 'sourceOfFunds',
  sourceOfFundsClarify: 'sourceOfFundsClarify',
  jobTitle: 'jobTitle',
  approxExpectedDeposit: 'approxExpectedDeposit',
  originOfFunds: 'originOfFunds',
  natureOfTransactions: 'natureOfTransactions',
  natureOfTransactionsClarify: 'natureOfTransactionsClarify',
  politicallyExposed: 'politicallyExposed',
  usCitizen: 'usCitizen',
  taxJurisdictionCountry: 'taxJurisdictionCountry',
  tinReason: 'tinReason',
  tin: 'tin',
  tinClarify: 'tinClarify',
  approxAccountTurnover: 'approxAccountTurnover',
  nomineeStatus: 'nomineeStatus',
  commercialRationaleForBusinessRelationship: 'commercialRationaleForBusinessRelationship',
  riskToleranceForCfd: 'riskToleranceForCfd',
  employmentStatus: 'employmentStatus',
  initialIntendedContribution: 'initialIntendedContribution',
  relevantTradingEducationExperience: 'relevantTradingEducationExperience',
  riskAwarenessOfCfdTrading: 'riskAwarenessOfCfdTrading',
  ...DOCUMENT_FIELDS,
})

export {FORM_FIELDS, DOCUMENT_FIELDS}
