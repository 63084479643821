import {ReactElement, ReactNode, PropsWithChildren} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import compose from 'lodash/flowRight'
import Typography from '@material-ui/core/Typography'

import {styles} from './styles'

type FieldsSectionProps = PropsWithChildren<{
  title: ReactNode
} & WithStyles>

function FieldsSectionBase(props: FieldsSectionProps): ReactElement {
  const {classes, title, children} = props

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

const FieldsSection = compose(withStyles(styles))(FieldsSectionBase)
export {FieldsSection}
