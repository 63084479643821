import {backendGetRequest} from '../../../../../common/utils/requests'
import {Account} from '../../../../../graphql/types'

export const toKartwheelIBPortal = async (account: Account) => {
  const newTab = window.open('about:blank', '_blank')
  if (!newTab) {
    throw new Error('Can\'t open popup')
  }
  newTab.document.write('<h4>Please wait</h4>')
  try {
    const response =  await backendGetRequest('api/v2/dashboard/ib/get-auto-login-url',true,{accountId: account.id})
    newTab.location = await response.json()
  }
  catch (e) {
    newTab.close()
  }
}
