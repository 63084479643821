import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {Redirect, Link} from 'react-router-dom'
import {
  get,
  some,
  isEmpty,
  find,
  map,
  includes,
  omit,
  filter,
  has,
  capitalize,
  keys,
  difference,
  intersection,
  uniq,
  endsWith,
  flowRight as compose,
  trim,
  size,
  toString,
  toLower,
  isUndefined,
} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import ReCAPTCHA from 'react-google-recaptcha'

import {companies, countries, accountTypes, signupSteps, whiteLabels, currencies, languages, regulators, nationalities} from '@bdswiss/common-enums'

import messages from '../../../assets/messages'
import {config} from '../../../config'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import i18nApp from '../../../i18n'
import {InnerAppContext} from '../../../common/types'
import {
  validateEmail,
  validatePhone,
  validateLength,
  validateDate,
  validateDOB,
  validateCharacters,
  validatePartnerCode,
} from '../../../common/utils/validations'
import queryString from 'querystring'
import {europeCountries, referAndEarnDetails, localesUpperNameFields, fnsLocales} from '../../../common/utils/uioptions'
import RegulationBar from '../../Common/RegulationBar'
import CountriesSelect from '../../Common/CountriesSelect'
import Header from '../../App/Header'

import {getItem, storeItem, storeSessInfoInLocalStorage, findCompany, isWhiteLabel, checkPassword, isMobile, getCookie, setCookie} from '../../../common/utils'
import {register, checkEmail, getIPCountry, checkReferralCode, getConfigVars, getCampaignCookie, getCountries, setCampaignCookie} from '../../../common/utils/requests'
import {isMt5ForexAccount, getAccountSubtype} from '../../../common/utils/accounts'
import {checkPasswordMauritius, validCountries} from '../../../common/utils/general'
import {fireRegistrationEvent, fireOnBoardingEvent} from '../../../common/utils/tagsManager'
import AppContext from '../../Common/contexts/AppContext'
import LoadingButton from '../../Common/LoadingButton'
import NotificationBar from '../../Common/NotificationBar'
import PageTitle from '../../Common/PageTitle'
import UiNotification from '../../Common/UiNotification'
import {AlertDialog, CustomDialog} from '../../Common/Dialog'
import {Password} from '../../Common/Password'

// MUI
import Alert from '@material-ui/lab/Alert'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import InputLabel from '@material-ui/core/InputLabel'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {LinearProgress} from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {MfaSettings} from './components/MfaSettings'
import {MfaSettingsContainer} from './components/MfaSettingsContainer'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative' as const,
  },
  groupTitle:{
    fontWeight:400,
    backgroundColor: theme.palette.background.default,
  },
  form: {
    padding: '40px 0px 40px 0px',
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px 15px 10px',
      marginBottom: 0,
    },
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.red.color
  },
  errorMessage: {
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  errorRoot: {
    lineHeight: 1.5
  },
  riskTitle: {
    fontWeight: 500
  },
  riskText:{
    fontWeight:300
  },
  risk: {
    paddingTop: 30,
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 15px',
    },
  },
  showGBFooter: {
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 15px',
    },
  },
  selectPrefix: {
    height: '1.43em',
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 18,
    },
  },
  selectIcon: {
    marginTop: 7
  },
  div: {
    maxWidth:1100,
  },
  partnerDiv: {
    maxWidth:1100,
    background: '#fff',
    'flex-basis': '50%',
    borderRadius: 12,
    padding: 60,
    paddingTop: 0,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      'flex-basis': '100%',
      padding: 0,
      margin: 10,
    },
  },
  steps:{
    [theme.breakpoints.down('sm')]: {
      padding:0
    }
  },
  regDiv:{
    position: 'relative' as const,
    [theme.breakpoints.between('md','lg')]: {
      padding: '0 15px'
    }
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  selectMenu:{
    maxWidth: 500,
    height: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300
    }
  },
  highlightedText:{
    fontWeight:400
  },
  highlightAffiliateText:{
    fontWeight:400,
    color: theme.palette.red.color
  },
  regLinks: {
    display: 'inline-block',
  },
  inline: {
    display: 'inline-flex',
  },
  grey: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  lightGrey: {
    color: theme.palette.lightgrey.color
  },
  helpText:{
    color: theme.palette.grey[500],
  },
  ol: {
    listStylePosition: 'inside' as const,
    [theme.breakpoints.down('sm')]: {
      listStylePosition: 'inherit' as const,
      textAlign: 'left',
    }
  },
  nameField:{
    display:'none'
  },
  li: {
    paddingBottom: 10,
  },
  notificationTitle: {
    maxWidth: 430,
    margin: '0 auto'
  },
  underline: {
    textDecoration: 'underline'
  },
  customBackground: {
    marginTop: 50
  },
  dateInput:{
    width: '100%'
  },
  serverError: {
    marginBottom: 50
  }
})

let companyObject = findCompany()
const isWhiteLabelCompany = isWhiteLabel()
const chainModals = {
  solicitationModal: 'solicitationModal',
  nonEUModal: 'nonEUModal',
  mfaModal: 'mfaModal'
}

const recaptchaRef = React.createRef<ReCAPTCHA>()
const FULL_NAME_MAX_LENGTH = 32
const OPTIONAL_FIELDS = ['promoCode']

class Register extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    const product = this.findProduct()

    // Extract campaign & partner from URL
    const urlParams = new URLSearchParams(window.location.search)
    const campaign = urlParams.get('campaign')
    const partner = urlParams.get('p')

    this.state = {
      form: {
        firstName:'',
        lastName: '',
        prefix: '',
        phone:'',
        email:'',
        password: '',
        country: '',
        acceptTerms: '',
        emailExists: '',
        emailNotValidDomain: '',
        affiliateEmailExists: '',
        affiliateCountries: [],
        isCorporate: '',
        currency: '',
        birthday: '',
        checkField: '',
        closedAccount: '',
        partnerReferenceNumber: '',
        promoCode: ''
      },
      recaptcha:'',
      showPassword: false,
      errors: {},
      loading: false,
      status: '',
      submitMessageError: '',
      passwordError: '',
      activeStep: 0,
      prefixChanged: false,
      countryChanged: false,
      company:null,
      selectAllAffCountries:false,
      showCountries: false,
      prefixFlag:'',
      countryFlag:'',
      showCountriesPrefix: false,
      visibleSidebar: true,
      selectedProduct: product,
      companyReg: '',
      showEmbeddedUi: false,
      embedded: window.sessionStorage && sessionStorage.getItem('embedded'),
      errorsKeys: {},
      fields: (has(config.registrationFields, product.category) || has(config.registrationFields, product.subCategory))
        ? ((config.registrationFields[product.category]) ? config.registrationFields[product.category] : config.registrationFields[product.subCategory])
        : config.registrationFields['default'],
      showReferral: false,
      referralDetails: {},
      showRedirectionModal: false,
      showForbiddenCountryPopup: false,
      showForbiddenCountryPopupStrict: false,
      listCountries: [],
      licenseAcknowledgement: '',
      openModalsInQueue: undefined,
      activeModal: '',
      showServerError: false,
      isUSACampaign: campaign === '3169' && partner === '36195_367200'
    }
  }

  async componentDidMount() {
    const {location} = this.props
    const {gTagContainerId, excludedTranslations} = config
    const urlParams = queryString.parse(location.search.replace('?', ''))

    getConfigVars()
      .then((res) => {
        this.setState({isRecaptchaEnabled: res.recaptchaEnabled})
      })
      .catch((err) => {
        this.setState({isRecaptchaEnabled: true})
      })

    if (gTagContainerId) {
      //tagmanager body noscript
      const gtmExternal = document.createElement('noscript')
      const gtmIframe = document.createElement('iframe')
      gtmIframe.src=`https://www.googletagmanager.com/ns.html?id=${gTagContainerId}`
      gtmIframe.height='0'
      gtmIframe.width='0'
      gtmIframe.setAttribute('style','display:none;visibility:hidden')
      gtmExternal.appendChild(gtmIframe)

      //tagmanager head script
      const gtmElement = document.createElement('script')
      gtmElement.id = 'gtm-script'
      gtmElement.type = 'text/javascript'
      gtmElement.text = `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${gTagContainerId}');`
      //@ts-ignore
      document.getElementsByTagName('head').item(0).appendChild(gtmElement)
      //@ts-ignore
      document.getElementsByTagName('body').item(0).appendChild(gtmExternal)
    }

    if (get(urlParams, 'embedded'))
      window.sessionStorage && sessionStorage.setItem('embedded', 'true')

    if (get(urlParams, 'webinarid'))
      window.sessionStorage && sessionStorage.setItem('webinarid', get(urlParams, 'webinarid') as string)

    const urlLang : any = (urlParams && urlParams.lang) || window.navigator.language.split(/-|_/)[0]
    const supportedLanguages = map(filter(languages, (a) => !!a.client && !a.disabled), 'value')
    if (urlLang && includes(supportedLanguages,urlLang) && !includes(excludedTranslations, urlLang)) {
      const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
      if (!storageLang || storageLang!==urlLang) {
        window.sessionStorage && sessionStorage.setItem('lang', urlLang)
        i18nApp.init(urlLang)
        storeItem('locale', urlLang)
      }
    }
    const paramKeys = keys(urlParams)
    const allowedPrefilled =['firstName', 'lastName', 'phone', 'email']
    const prefilledFields = intersection(paramKeys, allowedPrefilled)
    const extraFields = difference(paramKeys, allowedPrefilled)
    !isEmpty(prefilledFields) && this.setState(state => ({
      form: {
        ...state.form,
        ...omit(urlParams, extraFields),
      }
    }))

    const country = get(urlParams, 'country') as string
    this.getCountry(country && country.toUpperCase())

    if (get(urlParams, 'referralCode') && get(urlParams, 'referral')) {
      checkReferralCode( urlParams.referralCode, urlParams.referral)
        .then((res) => {
          this.setState({showReferral: res.result.valid,
            referralDetails: {awards: res.result.awards, conditions: res.result.conditions}})
        })
        .catch((err) => {
          this.setState({showReferral: false})
        })
    }
    config.supportedCompanies ? this.getCountries() :
      this.getCountries(this.state.selectedProduct.productName, companyObject.value)

    if (urlParams.partnerManagerId) {
      setCookie('partnerManagerId',urlParams.partnerManagerId,1)
    }
    if (urlParams.refid) {
      setCookie('nullpointIbId', urlParams.refid, 1)
    }
    if (urlParams.cmp) {
      setCookie('nullpointIbCampaign', urlParams.cmp, 1)
    }
    if (get(urlParams, 'campaign')) {
      setCampaignCookie(JSON.stringify(urlParams))
        .then(() => {
          this.campaignCookiePartner()
        })
        .catch(() => {})

    } else {
      await this.campaignCookiePartner()
    }

    this.setState((state) => ({...state, openModalsInQueue: this.getModals()}))
  }

  componentDidUpdate(prevProps, prevState) {
    const {registrationCountriesRedirect} = config
    const {countryFromIp, activeModal} = this.state
    if (includes(get(registrationCountriesRedirect, 'from'), countryFromIp) && isEmpty(get(prevState, 'listCountries'))
      && !isEmpty(get(this.state, 'listCountries'))) {
      this.handleChangeCountry(countryFromIp)
    }

    if (isUndefined(activeModal)) {
      this.handleProcessModal()
    }
  }

  async campaignCookiePartner() {
    await getCampaignCookie()
      .then((res) => {
        this.setState({campaignCookie: get(res, 'result'), partner: get(res, 'partner')})
        if (get(res, 'partner')) {
          config.supportedCompanies ? this.getCountries() : this.getCountries(this.state.selectedProduct.productName, companyObject.value)
        }
      })
      .catch()
  }

  async getCountries(product?, company?) {
    await getCountries(product, company)
      .then((res) => {
        if (isEmpty(res.countries)) {
          this.setState({showServerError: true})

          return
        }
        const allowedCountries = map(res.countries, 'value')

        const country = get(this.state, 'form.country', '') ||  get(this.state, 'countryFromIp', '')
        const countryCode =
          size(res.countries) === 1
            ? get(res.countries, '[0].value')
            : !isEmpty(res.countries) && includes(allowedCountries, country)
              ? country
              : ''

        const selectedCountryCode = !isEmpty(countryCode)
          ? find(countries, (country) => country.value === countryCode)
          : ''

        const prefixCode = !isEmpty(countryCode) ? `+${countries[toLower(countryCode)].callingCode}` : ''

        this.setState((state) => ({
          form: {...state.form, country: countryCode, prefix: prefixCode},
          countryFlag: selectedCountryCode,
          prefixFlag: countryCode,
          listCountries: res.countries,
        }))

        const regulator = this.findDefaultRegulator(countryCode)
        const companyReg = this.getRegulatorCompany(regulator)
        if (companyReg) this.setState({selectedRegulator: companyReg.key})
      })
      .catch((err) => err)
  }

  findRegulatorFromParams(regulator) {
    return get(find(regulators, (r) => toLower(r.label) === regulator), 'value')
  }

  findAllowedRegulators(country) {
    const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
    return  get(selectedCountry,'regulators')
  }

  findDefaultRegulator(selectedCountry? : string ) {
    const country : string = selectedCountry ?? get(this.state, 'country', '') ??  get(this.state, 'countryFromIp', '')
    const urlParams = queryString.parse(this.props.location.search.replace('?', ''))
    if (urlParams.regulator && selectedCountry) {
      const regulatorFromParams = this.findRegulatorFromParams(urlParams.regulator)
      const companyFromParams = this.getRegulatorCompany(regulatorFromParams)
      if (!includes(europeCountries, selectedCountry) &&
        includes(this.findAllowedRegulators(selectedCountry), companyFromParams?.value)) {
        return this.findRegulatorFromParams(urlParams.regulator)
      }
    }

    const forbiddenCountries = map(filter(countries, country => !!country.forbidden), 'value')
    const forbiddenCountry = country && includes(forbiddenCountries, country)
    let regulator = '', defaultSelectedCompany
    const allowedRegulators = !forbiddenCountry && this.findAllowedRegulators(country)

    if (country && allowedRegulators && !isEmpty(allowedRegulators)) {
      if (allowedRegulators.length === 1) {
        defaultSelectedCompany = allowedRegulators[0]
      } else {
        const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
        defaultSelectedCompany = get(selectedCountry,'defaultRegulator')
      }
      regulator = defaultSelectedCompany && companies[defaultSelectedCompany].regulator
    }

    return regulator
  }

  findRegulator(company) {
    const {location} = this.props
    const {supportedCompanies} = config
    const urlParams = queryString.parse(location.search.replace('?', ''))
    let companyReg
    if (urlParams.regulator)
      companyReg = this.getRegulatorCompany(this.findRegulatorFromParams(urlParams.regulator))

    if (!urlParams.regulator && supportedCompanies) {
      let regulator = this.findDefaultRegulator()

      if (!regulator)
        regulator =  companies[company].regulator

      companyReg = this.getRegulatorCompany(regulator)
    }

    return companyReg
  }

  getCurrentLocale() {
    return getItem('locale','en')
  }

  getRegulatorCompany(regulator) {
    const brand = isWhiteLabel() ? (get(config, 'companyBrand') || config.brand)  : config.brand
    return find(companies, (company) => company.regulator === regulator && company.brand === brand)
  }

  handleChange(prop, value) {
    if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && !this.state.selectAllAffCountries)
      this.setState(state => ({
        form: {...state.form, affiliateCountries: map(validCountries(), country => country.value)},
        errors:{...state.errors, 'affiliateCountries': !value,},
        selectAllAffCountries:true
      }))
    else if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && this.state.selectAllAffCountries)
      this.setState(state => ({
        form: {...state.form, affiliateCountries: []},
        errors:{...state.errors, 'affiliateCountries': !value,},
        selectAllAffCountries:false
      }))
    else {
      const isFieldValid = OPTIONAL_FIELDS.includes(prop) || Boolean(value)

      this.setState(state => ({
        form: {...state.form,[prop]: value,},
        errors: {...state.errors,[prop]: !isFieldValid, websiteLength: false}
      }))
    }
  }

  handleChangeCountry(value) {
    const {prefixChanged, countryChanged, selectedRegulator} = this.state
    const {registrationCountriesRedirect} = config
    const selectedCountry = find(countries,(country) => country.value === value)
    if (registrationCountriesRedirect && includes(get(registrationCountriesRedirect, 'from'), value))
      this.setState({showRedirectionModal: true})
    if (prefixChanged && !countryChanged)
      this.setState({prefixChanged:false})
    if (!prefixChanged || !countryChanged) {
      this.handleChangePrefix(value)
      this.handleChange ('prefix', `+${get(selectedCountry,'callingCode','')}`)
    }
    this.setState({showCountries:false,countryFlag:selectedCountry})

    //check regulator based on country selection
    const allowedRegulators = this.findAllowedRegulators(value)

    if (!selectedRegulator || (europeCountries.indexOf(value) !== -1)
     || (selectedRegulator && !includes(allowedRegulators, selectedRegulator))) {
      const regulator = this.findDefaultRegulator(value)
      const companyReg = this.getRegulatorCompany(regulator)
      if (companyReg) {
        this.setState({selectedRegulator:companyReg.key})
      } else {
        this.setState({selectedRegulator:null})
      }
    }
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  validateForm(companyReg) {
    const {form, form:{email,firstName,lastName}, passwordError, selectedProduct, referredByPartner, licenseAcknowledgement, countryFlag, selectedRegulator} = this.state
    const {registrationFields, supportedCompanies, licenseAcknowledgementCountries} = config
    const {t, match} = this.props
    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType
    const errors: Record<string, boolean> = {}
    const exceptions = ['emailExists', 'emailNotValidDomain', 'affiliateEmailExists', 'closedAccount']

    let selectedCompany, currentProduct = selectedProduct
    if (supportedCompanies && companyReg) {
      selectedCompany = companyReg
      currentProduct = this.findAccountType(paramType, paramSubType, selectedCompany)
    } else {
      selectedCompany=companyObject.key
    }
    const isIb = this.isIb()
    const isAffiliate = this.isAffiliate()

    if (!isAffiliate)
      exceptions.push(...difference(registrationFields['affiliate'], (isIb) ? registrationFields['ib'] : registrationFields['default']))

    if (!isIb)
      exceptions.push(...difference(registrationFields['ib'], (isAffiliate) ? registrationFields['affiliate'] : registrationFields['default']))

    if (!referredByPartner)
      exceptions.push('partnerReferenceNumber')

    for (const field of Object.keys(form)) {
      if (field === 'phone') {
        errors[field] = this.state.isUSACampaign
          ? false // Allow empty phone field for USA campaign users
          : isEmpty(form[field]) || !validatePhone(form[field])
      } else if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'website') {
        errors[field] = isEmpty(form[field])
        errors['websiteLength'] = !validateLength(form[field], 150)
      } else if (field === 'birthday') {
        errors[field] = !validateDOB(form[field])
      } else if ((field === 'firstName') || (field === 'lastName')) {
        const nameError = validateCharacters(trim(form[field]))
        this.setState(state => ({errorsKeys: {...state.errorsKeys, [field]: nameError}}))
        errors[field] = !!nameError
      } else if (field === 'partnerReferenceNumber') {
        errors[field] = validatePartnerCode(trim(form[field]))
      } else {
        if (field !== 'checkField') {
          const isFieldValid = OPTIONAL_FIELDS.includes(field) || !isEmpty(form[field])

          errors[field] = !isFieldValid
        } else {
          errors[field] = !isEmpty(form[field])
        }
      }
    }

    if ([firstName, lastName].join(' ').length > FULL_NAME_MAX_LENGTH) {
      errors.fullName = true
    }

    if (supportedCompanies) {
      if (!selectedRegulator) {
        errors['selectedRegulator'] = true
        this.setState(state => ({errors: {...state.errors}}))
      }
      else {
        errors['selectedRegulator'] = false
      }
    }

    if (includes(licenseAcknowledgementCountries, get(countryFlag, 'value')) && !licenseAcknowledgement) {
      errors['licenseAcknowledgement'] = true
    }

    const errorsWithoutExceptions = omit(errors, uniq(exceptions))
    if (some(errorsWithoutExceptions)) {
      this.setState({errors})
      return
    }

    if (!passwordError) {
      this.setState({status: '', loading: true})
      checkEmail(email,selectedCompany, get(currentProduct, 'productName', ''))
        .then((res) => {
          this.setState(state => ({
            form: {
              ...state.form,
              emailExists: res === 'emailIsUsed',
              emailNotValidDomain: res === 'invalidEmail',
              affiliateEmailExists: res === 'affiliateEmailIsUsed',
              closedAccount: res === 'emailIsUsedAccountClosed'
            }
          }))
          if (!res) {
            this.handleProcessModal()
          } else {
            this.setState({loading: false})
          }
        })
        .catch((err) => {
          this.setState({status: 'failure', loading: false, submitMessageError: t(messages.connectionError.i18nKey,messages.connectionError.defaults)})
          this.setState(state => ({form: {...state.form}}))
        })
    }
  }

  handleProcessModal(modalName?) {
    const {openModalsInQueue} = this.state

    if (!isEmpty(openModalsInQueue)) {
      if (modalName) {
        const filteredModalQueue = filter(openModalsInQueue, (modal) => modal !== modalName)
        this.setState((state) => ({
          ...state,
          openModalsInQueue: filteredModalQueue,
          activeModal: filteredModalQueue[0],
        }))
      } else {
        this.setState((state) => ({
          ...state,
          activeModal: openModalsInQueue[0],
        }))
      }
    } else {
      this.setState({
        openModalsInQueue: this.getModals(),
        activeModal: '',
      })
      this.handleSubmit()
    }
  }

  getModals() {
    const {solicitationAcceptanceDisabled, nonEURegulationCountries} = config
    const {countryFromIp} = this.state

    const modalsInQueue : any[] = []
    for (const modal of (Object.keys(chainModals))) {
      const solicitationModalEnabled = modal === chainModals.solicitationModal && !solicitationAcceptanceDisabled
      const nonEUModalEnabled = modal === chainModals.nonEUModal && (!isEmpty(nonEURegulationCountries) && nonEURegulationCountries?.includes(countryFromIp))

      if (solicitationModalEnabled || nonEUModalEnabled) {
        modalsInQueue.push(modal)
      }
    }

    return modalsInQueue
  }

  findAccountSubType(type, subType, company, accountTypeObj?) {

    const selectedSubType = subType ? `${type}_${subType}` : type
    const selectedSubTypeDetails : any = getAccountSubtype(selectedSubType)
    const validSubType = selectedSubTypeDetails && !selectedSubTypeDetails.hideFromClients

    const checkSubtype = type &&
    find(accountTypes, (accountType) => accountType.company === company && accountType.clientOpenPermitted && validSubType &&
       (includes(accountType.supportedSubtypes ?? [],(subType && `${type}_${subType}`.toUpperCase()) || type.toUpperCase()) ||
        includes(accountType.supportedSubtypes, selectedSubTypeDetails.value))
    )

    return checkSubtype ?
      (accountTypeObj ? checkSubtype : selectedSubType) : false
  }

  findAccountType(type, subType, company) {
    const {key} = config
    const paramType = type || 'forex'
    const selectedAccountType = paramType &&
      find(accountTypes, (accountType) => accountType.company === company && accountType.clientOpenPermitted && (!subType && !accountType.subscription) &&
      ((!subType && includes(accountType.productName,paramType)) || accountType.category === paramType || accountType.subCategory === paramType) &&
      (isWhiteLabelCompany && whiteLabels[key].baseProducts ? includes(whiteLabels[key].baseProducts, accountType.key) : true))
    return selectedAccountType || this.findAccountSubType(type, subType, company, true)
  }

  findProduct() {
    const {supportedCompanies} = config
    const {match, history} = this.props
    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType

    let company = companyObject.key
    const companyReg = this.findRegulator(company)
    if (companyReg && supportedCompanies) {
      company = companyReg.value
    }

    const accountType =  this.findAccountType(paramType, paramSubType,company)
    const accountSubType = this.findAccountSubType(paramType, paramSubType,company)

    if (paramType && !accountType && !accountSubType)
      history.push('/register')

    return accountType
  }

  checkPassword(password) {
    const passwordValidationFn = this.context.companyObject.value === 'tauroMarketsMauritius' ? checkPasswordMauritius : checkPassword
    const passwordError = passwordValidationFn(password)
    if (isEmpty(passwordError)) {
      this.setState({passwordError: ''})
    } else {
      this.setState({passwordError: <Trans {...messages[passwordError]} />})
    }
  }

  handleFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
    this.handleChange('firstName', event.target.value)
    this.validateFirstOrLastName('firstName')
    this.validateFullName()
  }

  handleLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
    this.handleChange('lastName', event.target.value)
    this.validateFirstOrLastName('lastName')
    this.validateFullName()
  }

  validateFirstOrLastName = (fieldName: 'firstName' | 'lastName'): void => {
    this.setState(prevState => {
      const {form} = prevState
      const value = form[fieldName]
      const errorKey = validateCharacters(value)

      return {
        errors: {...prevState.errors, [fieldName]: Boolean(errorKey)},
        errorsKeys: {...prevState.errorsKeys, [fieldName]: errorKey},
      }
    })
  }


  validateFullName = (): void => {
    this.setState(prevState => {
      const {form: {firstName, lastName}} = prevState
      const errorKey = [firstName, lastName].join(' ').length <= FULL_NAME_MAX_LENGTH ? '' : 'maxFullNameLengthError'

      return {
        errors: {...prevState.errors, fullName: Boolean(errorKey)},
        errorsKeys: {...prevState.errorsKeys, fullName: errorKey},
      }
    })
  }

  findCurrencyByCountry(country, disallowedCurrencies) {
    if ((['PL'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'PLN'))) {
      return 'PLN'
    }
    else if ((['CH'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'CHF'))) {
      return 'CHF'
    }
    else if ((['GB', 'UK'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'GBP'))) {
      return 'GBP'
    }
    else if (europeCountries.indexOf(country) !== -1) {
      return 'EUR'
    }
    else {
      return 'USD'
    }
  }

  isAffiliate() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(match.params && match.params.type ==='affiliate' && includes(product.category,'affiliate'))
  }

  isIb() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(match.params && match.params.type ==='ib' && includes(product.subCategory,'ib'))
  }

  getSteps() {
    const locale = getItem('locale','en')
    const steps : string[] = []

    for (const step of companyObject.registrationSteps)
      steps.push(signupSteps[step].localization.t(locale))

    return steps
  }

  steps() {
    const {activeStep} = this.state
    const {classes} = this.props
    const steps = this.getSteps()
    return (
      <div>
        <Stepper activeStep={activeStep} alternativeLabel classes={{root:classes.steps}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }

  errorHandling(res) {
    const {t, classes} = this.props
    const {form:{country}} = this.state
    const key = get(res, 'key')
    const message = get(res, 'message')
    const countryObj = find(countries, {value: country})
    this.setState({
      loading: false,
      status: 'failure',
      submitMessageError: messages[key] ?
        <Trans {...messages[key]} values={{entity: companyObject.trademark, country: get(countryObj, 'label')}} components={[<Link to={'/login'} className={classes.link}> logging </Link>]} />
        : (message && key) ? message.toString() : t(messages.registrationError.i18nKey),
      openModalsInQueue: this.getModals(),
      submitMessageErrorKey: key,
      activeModal: '',
    })
  }


  async handleSubmit() {
    let recaptcha
    if (this.state.isRecaptchaEnabled) {
      if (!endsWith(this.state.form.email, get(config, 'common.autoTestEmail'))) {
        if (config.common.reCaptcha.isInvisible) {
          recaptcha = await recaptchaRef.current.executeAsync()
        } else {
          recaptcha = recaptchaRef.current.getValue()
        }
      }
    }
    const {form:{firstName, lastName, phone, email, promoCode, password, country, prefix, acceptTerms,
      affiliateCountries, isCorporate, currency, birthday, checkField, partnerReferenceNumber}, embedded, referredByPartner, licenseAcknowledgement} = this.state
    const {history, match : {params}, client: apolloClient, location} = this.props
    const {key, gTagContainerId, supportedCompanies} = config
    let whiteLabel = filter(whiteLabels, (whitelabel) => whitelabel.value === key)
    const locale = this.getCurrentLocale()
    const companyReg = get(companies,this.state.selectedRegulator)
    let companyRegSelected = ''
    if (supportedCompanies) {
      companyRegSelected = companyReg.value
      const whiteLabelKey = get(supportedCompanies[companyRegSelected], 'whiteLabelKey')
      whiteLabel = filter(whiteLabels, (whitelabel) => whitelabel.value === whiteLabelKey)
    }

    const whiteLabelValue = whiteLabel[0] ? whiteLabel[0].value : undefined
    const company = !isEmpty(companyRegSelected) ? companyRegSelected : companyObject.key

    const paramType = params && params.type
    const paramSubType = params && params.subType
    const type = this.findAccountType(paramType, paramSubType,company)
    const isAffiliate = this.isAffiliate()
    const curr = (currency) ? currency :
      isAffiliate ? 'USD' : this.findCurrencyByCountry(country.toUpperCase(), type.disallowedCurrencies)
    const risk = !!(acceptTerms)
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const bta = (isAffiliate && get(queryParams,'bta')) || ''
    const am = (isAffiliate && get(queryParams,'am')) || ''
    const webinarid = get(queryParams,'webinarid') || sessionStorage.getItem('webinarid') || ''
    const regulationAcceptance = getCookie('RegulationAccepted')
    const countriesAffiliate = (affiliateCountries.length)?affiliateCountries:''

    const subType = this.findAccountSubType(paramType, paramSubType, company)
    const accountSubtype = get(getAccountSubtype(subType), 'value')
    const defaultProduct = find(accountTypes, (accountType) => accountType.company === company && accountType.clientOpenPermitted && accountType.category.includes('forex'))?.productName

    const products:string[] = []
    products.push((type && type.productName) ? type.productName : defaultProduct )

    const referralCode = get(queryParams, 'referralCode') || ''
    const referral = get(queryParams, 'referral') || ''

    const customDomain = get(config, 'customDomain')
    //@ts-ignore
    const dob = birthday && moment(birthday, validateDate(birthday, true, true)).format('YYYY-MM-DD')
    const otpSecret = this.state.otpSecret

    register(trim(firstName),trim(lastName),curr,prefix,phone,email,promoCode,password,country,true,risk,true,true,products,accountSubtype,true,
      locale,countriesAffiliate,bta,am,companyRegSelected, whiteLabelValue, isCorporate, customDomain,
      dob, checkField, recaptcha,  referralCode, referral, regulationAcceptance, referredByPartner && partnerReferenceNumber, licenseAcknowledgement, '', true, otpSecret)
      .then((res) => {
        if (res.result === 'success') {
          if (gTagContainerId) {
            fireRegistrationEvent(res.id,products[0])
            fireOnBoardingEvent(res.id,country)
          }
          apolloClient.query({query: CLIENT_DATA_QUERY, fetchPolicy:'network-only'})
            .then((res) => {
              if (embedded) {
                this.setState({showEmbeddedUi: true})
              } else {
                if (companyObject.registrationSteps && companyObject.registrationSteps.length<2) {
                  storeSessInfoInLocalStorage(res['data']['viewer'].company)
                  if (!isEmpty(webinarid)) {
                    history.push({pathname:'/webinar', state: {webinarid: webinarid, locationTrack: 'signup'}})
                  } else {
                    supportedCompanies && res['data']['viewer'].company
                      ? window.location.href=supportedCompanies[res['data']['viewer'].company].frontendUrl
                      : history.push({pathname:'/', state: {queryParams: queryParams}})
                  }
                }
                else {
                  (includes(companyObject.registrationSteps,'step2')) ? history.push('/register/step2') : history.push('/register/step3')
                }
              }
            }).catch((err) => {
              if (supportedCompanies) {
                if (get(err, 'networkError.result.data.redirectUrl')) {
                  const url = new URL(get(err, 'networkError.result.data.redirectUrl'))
                  if (!isEmpty(webinarid)) {
                    window.location.href = url.origin + '/webinar' + url.search + '&webinarid='+ webinarid +'&locationTrack=signup'
                    return null
                  } else {
                    history.push({pathname:'/', state: {queryParams: queryParams}})
                  }
                } else {
                  history.push({pathname:'/', state: {queryParams: queryParams}})
                }
              } else {
                history.push({pathname:'/', state: {queryParams: queryParams}})
              }
            })
        }
        else {
          this.errorHandling(res)
        }
      })
      .catch((err) => {
        this.errorHandling(err)
      })
  }

  getCountry(paramCountry) {
    getIPCountry()
      .then((res) => {
        this.setState({countryFromIp : res['country_code']})
        const {featuresConfig:{countriesSpecificLocale}} = config
        const {listCountries} = this.state
        const countryList = listCountries
        const singleCheck = paramCountry ? paramCountry : countryList.length === 1 ? get(countryList[0], 'value') : ''
        const countryCode = singleCheck && Boolean(find(countryList, {value: singleCheck})) ? singleCheck : res['country_code']
        if (Boolean(find(countryList, {value: countryCode}))) {
          this.handleChange ('prefix', `+${countries[toLower(res['country_code'])].callingCode}`)
          this.handleChangeCountry (countryCode)
          this.handleChange('country', countryCode)
          this.handleChangePrefix (countryCode)
          const countriesLocale = JSON.parse(countriesSpecificLocale)
          if (get(countriesLocale, countryCode)) {
            const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
            if (!storageLang || storageLang!==countriesLocale[countryCode]) {
              window.sessionStorage && sessionStorage.setItem('lang', countriesLocale[countryCode])
              i18nApp.init(countriesLocale[countryCode])
              storeItem('locale', countriesLocale[countryCode])
            }
          }
        }
        const forbiddenCountries = map(filter(countries, country => !!country.forbidden), 'value')
        const forbiddenCountry = includes(forbiddenCountries, countryCode) || includes(forbiddenCountries, res['country_code'])
        const showForbiddenCountryPopup = forbiddenCountry && (includes(config.common.forbiddenCountryPopupSpecific, countryCode) || includes(config.common.forbiddenCountryPopupSpecific, res['country_code']))
        const showForbiddenCountryPopupStrict = forbiddenCountry && (includes(config.common.forbiddenCountryPopupSpecificStrict, countryCode) || includes(config.common.forbiddenCountryPopupSpecificStrict, res['country_code']))
        this.setState({showForbiddenCountryPopup, showForbiddenCountryPopupStrict})
      })
      .catch((err) => err)
  }

  handleChangeTab = (event, value) => {
    this.setState({value})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  handleChangePrefix(value) {
    this.setState({showCountriesPrefix:false,prefixFlag:value})
  }

  closeEmbeddedIframe() {
    sessionStorage.removeItem('embedded')
    const {weblinks:{websiteUrl}} = config
    window.parent.postMessage('success', websiteUrl)
  }

  referralNotificationContent(type) {
    const {referralDetails: {awards, conditions}} = this.state
    const {classes} = this.props
    const {days, referralInstructions, currency} = referAndEarnDetails

    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.notificationTitle}>
          <Trans {...messages.congratulationsSubtitle} values={{amount: `${currency}${get(awards, 'referralAward')}`, days}}/>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ol className={classes.ol}>
          {map(referralInstructions, (key) => <li key={key} className={classes.li}>
            <Typography variant='body1' className={classes.inline}><Trans {...messages[key]}
              values={{deposit: conditions.deposit, trades: conditions.trades, days: conditions.days}}/></Typography>
          </li>)
          }
        </ol>
      </Grid>
    </Grid>
  }

  handleModalClose(modalName) {
    if (modalName === chainModals.nonEUModal) {
      const {weblinks: {websiteUrl}} = config
      window.location.href = websiteUrl
    } else {
      this.setState((prevState) => ({
        ...prevState,
        openModalsInQueue: this.getModals(),
        activeModal: '',
        loading: false,
      }))
    }
  }

  getFormTitle() {
    const {classes, match} = this.props

    const isIbForm = this.isIb()
    const isAffiliateForm = this.isAffiliate()

    const partnerFormTitle = isIbForm ? messages.signUpIB : messages.signUpAffiliate
    if (isIbForm || isAffiliateForm) {
      return (
        <span className={classes.highlightedText}>
          <Trans
            {...partnerFormTitle}
            components={[
              <span className={classes.highlightAffiliateText}>Title</span>,
            ]}
          />
        </span>
      )
    }

    const accountType = this.state.selectedProduct
    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType
    const accountSubType = this.findAccountSubType(paramType, paramSubType, companyObject.key)

    const mt4mt5Signup = accountType && get(messages, `signUp${accountType.shortLabel}`) ?  `signUp${accountType.shortLabel}` :
      isMt5ForexAccount(accountType.baseProduct) ? 'signUpMt5' : 'signUp2min'

    const selectedSubType = getAccountSubtype(accountSubType)
    const signupTextType = ((get(accountType, 'subCategory') && !selectedSubType) || (get(accountType, 'subCategory') === get(selectedSubType,'key')))  ? capitalize(accountType.subCategory)
      : `${capitalize(accountType.subCategory)} ${get(selectedSubType,'label')}`
    return accountSubType ?
      <Trans {...messages.signUpSubTypes} values={{accountSubType: signupTextType}}/>
      : <Trans {...messages[mt4mt5Signup]} />
  }

  handleMFAChange = (event) => {
    const enabled = event.target.checked
    this.setState({mfaEnabled: enabled})

    const modals = this.state.openModalsInQueue
    if (enabled) {
      this.setState({
        openModalsInQueue: [chainModals.mfaModal, ...modals],
      })
    } else {
      this.setState({
        openModalsInQueue: modals.filter((modal) => modal!== chainModals.mfaModal),
      })
    }
  }

  handleOtpSetup = (secret: string) => {
    this.setState({
      otpSecret: secret,
    })
    this.handleProcessModal(chainModals.mfaModal)
  }

  render() {
    const {classes, t, loadingProp, viewer, location, match} = this.props
    const {errors, showPassword, form:{firstName, lastName, phone, email, promoCode, password, emailExists, acceptTerms,
      affiliateCountries, isCorporate, currency, emailNotValidDomain, affiliateEmailExists,
      birthday, prefix, checkField, closedAccount, partnerReferenceNumber}, loading, status, submitMessageError, submitMessageErrorKey, passwordError,
    countryFromIp, selectAllAffCountries, prefixFlag, countryFlag, showEmbeddedUi, errorsKeys,
    fields, showReferral, showRedirectionModal, isRecaptchaEnabled, showForbiddenCountryPopup, referredByPartner, campaignCookie, listCountries,
    licenseAcknowledgement, showForbiddenCountryPopupStrict, forbiddenCountryPopupStrictShown, forbiddenCountryPopupShown, partner, selectedRegulator, activeModal, showServerError} = this.state
    const {weblinks: {termsAndConditions, complaints, legalDocuments, privacyPolicy, affiliateTermsAndConditions, affiliateGuidelines, affiliateCodeConduct,
      ibGuidelines, ibCodeConduct, ibAppendix, ibMasterTermsAndConditions, websiteUrl}, complaintsEmail, riskWarning, entityWebsite,
    supportedCompanies, euRegulation: {redirectTo, excludeProducts, cancelationRedirect, showEuRedirectionModal}, riskWarningPercentage: percentage, translatedPartnerGuidelines,
    common:{reCaptcha:{siteKey, isInvisible}, autoTestEmail}, countriesTPRFooter, supportEmail, registrationCountriesRedirect, partnerCodeCountries,
    licenseAcknowledgementCountries, registrationDisabled, registrationBackground} = config
    const {themePreference} = this.context
    const stepNumber = companyObject.registrationSteps.length
    const accountType = this.state.selectedProduct

    let riskWarningKey = riskWarning
    const locale = this.getCurrentLocale()
    let showGBFooter = countriesTPRFooter
    let partnerCodeRegCountries = partnerCodeCountries

    let weblinks = {
      complaints: complaints.replace('{lang}', locale),
      privacyPolicy: privacyPolicy,
      termsAndConditions: termsAndConditions,
      complaintsEmail: complaintsEmail,
      entityWebsite: entityWebsite,
      legalDocuments: legalDocuments,
      websiteUrl,
    }

    const urlParamsString = location.search.replace('?', '')
    const urlParams = queryString.parse(urlParamsString)

    const checkExcludedProducts = (accountType && excludeProducts && (includes(excludeProducts, accountType.category) || includes(excludeProducts, accountType.subCategory)))
    const forbiddenCountries = map(filter(countries, country => !!country.forbidden), 'value')
    const forbiddenCountry = countryFromIp && includes(forbiddenCountries, countryFromIp)
    // TODO get rid of this outdated redirect process completely
    // here the code is commented because after the predicate we trying to get the field of redirectToObject regardless
    // of whether it's empty or not, which may lead to the "accessing property of undefined" error
    // const reverseRedirect = !forbiddenCountry && countryFromIp && (redirectTo && redirectTo.from && get(redirectTo, 'reverse', false) ? redirectTo.from.indexOf(countryFromIp) === -1 : redirectTo.from.indexOf(countryFromIp) !== -1)
    const reverseRedirect = false
    const checkRegulationRedirect =  (!checkExcludedProducts && redirectTo && redirectTo.from && reverseRedirect && !isEmpty(redirectTo.to))
    checkRegulationRedirect && !urlParams.noredirect && (window.location.href = (redirectTo.to + window.location.pathname +  window.location.search))
    const companyReg = (typeof selectedRegulator !== 'undefined') ? get(companies,selectedRegulator) : this.findRegulator(companyObject.key)
    const fallbackWarning =  (typeof selectedRegulator === 'undefined') && forbiddenCountry
    if (supportedCompanies && companyReg) {
      companyObject =  isWhiteLabelCompany ? findCompany(companyReg.value) : companyObject
      weblinks = !fallbackWarning ? supportedCompanies[companyReg.value] : weblinks
      riskWarningKey = (!fallbackWarning && supportedCompanies[companyReg.value].riskWarning) ? supportedCompanies[companyReg.value].riskWarning : riskWarning
      showGBFooter = supportedCompanies[companyReg.value].countriesTPRFooter
      partnerCodeRegCountries = supportedCompanies[companyReg.value].partnerCodeCountries
    }

    const regSelected = (companyReg && companyReg.value) || ''

    /*Add noredirect flag on group registration page => access correct regulator docs from anywhere*/
    if (supportedCompanies || this.isIb()) {
      const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ?    //@ts-ignore
        window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
      setCookie('noredirect_flag', true, (5/(24*60)), '.'+cookieDomain)
    }

    if (loadingProp) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    const notAcceptedCountries = ['GF', 'PF', 'TF', 'RE', 'GP', 'MQ', 'YT', 'PM', 'WF', 'MF']
    const countryList = this.isAffiliate() && listCountries ? listCountries.filter(country => !notAcceptedCountries.includes(country.value)) : listCountries || []
    const viewerType = get(viewer, '__typename')
    if (viewer && viewerType === 'Client') {
      return <Redirect to='/' />
    }

    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType

    const trackingEventsUrl = queryString.parse(this.props.location.search.replace('?', ''))
    const eventUrlKey = get(trackingEventsUrl,'subcampid','')
    const eventID = !eventUrlKey.includes('LTCOMP') && eventUrlKey //exclude Live Trading Competition Events
    //@ts-ignore
    const eventCountry = eventID && get(get(countries, toLower(eventID.substring(0,2))),'label')
    //@ts-ignore
    const eventDate = eventID && moment(eventID.substring(2), 'YYYYMMDD').isValid() && moment(eventID.substring(2), 'YYYYMMDD').format('DD/MM/YYYY')
    const showEventNotification = eventID && eventCountry && eventDate
    const maxDate = moment().subtract(18,'year').format('YYYY-MM-DD')
    const minDate = moment().subtract(120,'year').format('YYYY-MM-DD')
    const birthdayFormat =  birthday && validateDate(birthday, true, true)
    const birthdayValidFormat = birthdayFormat && moment(birthday, birthdayFormat).format('YYYY-MM-DD')

    const automationEmail = endsWith(this.state.form.email, autoTestEmail)

    const countryObj = find(countries, {value: countryFromIp})
    const forbiddenCountryMessage = showForbiddenCountryPopup &&
      messages.forbiddenCountryPopupSpecific
    const referredByPartnerShow = !campaignCookie && includes(partnerCodeRegCountries, prefixFlag)
    const countryValue = get(countryFlag, 'value')
    const objectCompany = (supportedCompanies && companyReg) ? findCompany(companyReg.value) : companyObject
    const showLicenseAcknowledgement = includes(licenseAcknowledgementCountries, countryValue)
    const authority = find(nationalities, {alpha3Code: countryFlag.alpha3Code})
    const regulatorObj = regulators[objectCompany.regulator]
    const forbiddenCountryMessageStrict = showForbiddenCountryPopupStrict &&
    messages.forbiddenCountryPopupSpecificStrict
    const notForbiddenSpecific = !forbiddenCountryMessage && !forbiddenCountryMessageStrict

    const showRedirectionEuPopup = includes([], countryValue) && showEuRedirectionModal && partner && !get(accountType, 'walletProduct')
    if (showRedirectionEuPopup) {
      window.location.href = `${cancelationRedirect}${urlParamsString ? `?${urlParamsString}`: ''}`
    }
    const isPartnerRegistration = this.isAffiliate()

    return (
      <div className={classes.mainBody} id={'scroll-div'}>
        <Header linkTo={urlParamsString ? `/login?${urlParamsString}` : '/'}
          customBackground={get(registrationBackground, paramType)} outer/>
        <div>
          <Grid container>
            {!isMobile() && <Hidden xsDown>
              <Grid item sm={3} />
            </Hidden>}
            <Grid item sm={!isMobile() ? 9 : 12} className={isPartnerRegistration ? classes.partnerDiv : classes.div}>
              <Grid container className={classes.form}>

                <Grid item sm={12} xs={12}>
                  {stepNumber <= 1 &&
                    <PageTitle modal>
                      {this.getFormTitle()}
                    </PageTitle>}
                  {showServerError && <Grid item md={6} xl={6} xs={12} className={classes.serverError}><Alert severity="error">{t(messages.registerServerError.i18nKey, messages.registerServerError.defaults)}</Alert></Grid>}
                  {stepNumber > 1 && <Grid item md={6} xl={6} xs={12}>
                    {this.steps()}</Grid>
                  }
                </Grid>
                {showEventNotification && <Grid container spacing={1}>
                  <Grid item md={6} xl={6} xs={12}>
                    <NotificationBar status={'info'}>
                      <Trans {...messages.registerForEventRegistration} values={{eventCountry: eventCountry, eventDate:eventDate}}
                        components={[<span className={classes.highlightedText}>registration</span>]}/>
                    </NotificationBar>
                  </Grid>
                </Grid>}
                {(listCountries === undefined || registrationDisabled) && <Grid container spacing={1}>
                  <Grid item md={6} xl={6} xs={12}>
                    <NotificationBar status={'warning'}>
                      <Trans {...messages[`${(listCountries === undefined) ? 'registrationServer' : 'registrationDisabled'}Error`]} />
                    </NotificationBar>
                  </Grid>
                </Grid>
                }
                <Grid item md={isPartnerRegistration ? 12 : 6} xl={isPartnerRegistration ? 12 : 6} xs={12}>
                  <Grid container spacing={3}>
                    {includes(fields,'firstName') && <Grid item xs={12}>
                      <TextField
                        autoFocus
                        fullWidth
                        required
                        id="firstName"
                        name="firstName"
                        label={t(messages.firstName.i18nKey, messages.firstName.defaults)}
                        error={errors.firstName}
                        onChange={this.handleFirstNameChange}
                        value={firstName}
                        autoComplete="given-name"
                      />
                      {firstName && errors.firstName && messages[errorsKeys.firstName] ? <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.firstName]} /></FormHelperText>
                        : <FormHelperText className={classes.helpText}><i>
                          <Trans {...messages.explanationField} values={{field: includes(localesUpperNameFields, locale) ? t(messages.firstName.i18nKey, messages.firstName.defaults) : toLower(t(messages.firstName.i18nKey, messages.firstName.defaults))}}/>
                        </i></FormHelperText>
                      }
                    </Grid>}
                    {includes(fields,'lastName') && <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        id="lastName"
                        name="lastName"
                        label={t(messages.lastName.i18nKey, messages.lastName.defaults)}
                        error={errors.lastName}
                        onChange={this.handleLastNameChange}
                        value={lastName}
                        autoComplete="family-name"
                      />
                      {lastName && errors.lastName && messages[errorsKeys.lastName] ? <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.lastName]} /></FormHelperText>
                        : <FormHelperText className={classes.helpText}><i>
                          <Trans {...messages.explanationField} values={{field: includes(localesUpperNameFields, locale) ? t(messages.lastName.i18nKey, messages.lastName.defaults) : toLower(t(messages.lastName.i18nKey, messages.lastName.defaults))}} />
                        </i></FormHelperText>
                      }
                      {errors.fullName ? (
                        <FormHelperText className={classes.errorMessage}>
                          <Trans {...messages[errorsKeys.fullName]} />
                        </FormHelperText>
                      ) : null}
                    </Grid>}
                    {includes(fields,'country') && <Grid item xs={12} id="countryGrid">
                      <CountriesSelect
                        countryList={countryList}
                        handleChangeField={(e) => {
                          this.handleChangeCountry(e)
                          referredByPartner && this.setStateOuter('referredByPartner', includes(partnerCodeRegCountries, e))
                        }}
                        handleChange={this.handleChange.bind(this)}
                        setStateOuter={this.setStateOuter.bind(this)}
                        errors={errors}
                        value={countryFlag && countryFlag['label']}
                        name="country"
                        label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
                        showRequired
                      />
                    </Grid>}
                    {includes(fields,'prefix') && <Grid item sm={4} xs={5} id="prefixGrid">
                      <CountriesSelect
                        countryList={countryList}
                        handleChangeField={this.handleChangePrefix.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                        setStateOuter={this.setStateOuter.bind(this)}
                        errors={errors}
                        value={prefixFlag}
                        name="prefix"
                        label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
                        phonePrefix
                        prefixValue={prefix}
                        showRequired
                      />
                    </Grid>}
                    {includes(fields,'phone') && <Grid item sm={8} xs={7} className={classes.phoneInp}>
                      <TextField
                        fullWidth
                        required
                        id="phone"
                        name="phone"
                        label={t(messages.phone.i18nKey, messages.phone.defaults)}
                        error={errors.phone}
                        onChange={(e) => this.handleChange('phone', e.target.value)}
                        value={phone}
                        inputProps={{pattern: '[0-9]*', inputMode:'numeric'}}
                      />
                      {get(errors, 'phone') && <FormHelperText className={classes.errorMessage}><Trans {...messages.phoneValidation} /></FormHelperText>}
                    </Grid>}
                    {includes(fields, 'birthday') && <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="birthday"
                          label={t(messages.dateOfBirth.i18nKey, messages.dateOfBirth.defaults)}
                          format="dd/MM/yyyy"
                          value={birthday ? new Date(birthdayValidFormat) : null}
                          onChange={(selectedDate) => this.handleChange('birthday', moment(selectedDate).format('DD/MM/YYYY'))}
                          KeyboardButtonProps={{'aria-label': 'change date'}}
                          className={classes.dateInput}
                          maxDate={maxDate}
                          minDate={minDate}
                          error={this.state.errors.birthday}
                          helperText={null}
                          required={true}
                          okLabel={t(messages.submit.i18nKey, messages.submit.defaults)}
                          cancelLabel={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>}
                    {includes(fields,'email') && <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        id="email"
                        label={t(messages.email.i18nKey, messages.email.defaults)}
                        onChange={(e) => this.handleChange('email', e.target.value)}
                        error={get(errors, 'email', false)}
                        value={email}
                      />
                      {emailExists &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages.emailExistsLogin}
                        components={[<Link to={`/login?email=${encodeURIComponent(email)}`} className={classes.link}><Trans {...messages.login} /></Link>]}/>
                      </FormHelperText>}
                      {closedAccount &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages.emailExistsClosed}
                        components={[<a href={`mailto:${supportEmail}`} className={classes.link}>support</a>]}/></FormHelperText>}
                      {emailNotValidDomain &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages.emailNotValidDomain} /></FormHelperText>}
                      {affiliateEmailExists &&
                      <FormHelperText className={classes.errorMessage}><Trans {...messages.affiliateEmailExists} /></FormHelperText>}
                      {get(errors, 'email') && <FormHelperText className={classes.errorMessage}><Trans {...messages.emailValidation} /></FormHelperText>}
                    </Grid>}
                    {!urlParams.refid && !urlParams.campaign && !this.isAffiliate() && <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="promoCode"
                        label={t(messages.promoCode.i18nKey, messages.promoCode.defaults)}
                        onChange={(e) => this.handleChange('promoCode', e.target.value)}
                        error={get(errors, 'promoCode', false) || (submitMessageErrorKey === 'wrongPromoCode')}
                        value={promoCode}
                      />
                      {submitMessageErrorKey === 'wrongPromoCode' &&
                        <FormHelperText className={classes.errorMessage}><Trans {...messages.wrongPromoCode} /></FormHelperText>
                      }
                    </Grid>}
                    {includes(fields,'password') && <Grid item xs={12}>
                      <Password
                        classes={classes}
                        showPassword={showPassword}
                        onChange={(e)=>{
                          this.handleChange('password',e.target.value)
                          this.checkPassword(e.target.value)
                        }}
                        error={errors.password}
                        onClickShow={()=> this.handleClickShowPassword()}
                        fullWidth
                        errorText={passwordError}
                        value={password}
                        showErrorText={true}
                      />
                    </Grid>}
                    {includes(fields,'currency') && <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel error={errors.currency}>
                          <Trans {...messages.currency} />
                        </InputLabel>
                        <Select
                          value={currency}
                          onChange={(e) => this.handleChange('currency', e.target.value)}
                          error={errors.currency}
                          required
                        >
                          {map(filter(currencies, (currency)=> !includes(accountType.disallowedCurrencies, currency.key)), (option) =>
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>}
                    {includes(fields,'affiliateCountries') && <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="affiliateCountries" error={errors.affiliateCountries} required>
                          {t(messages.affiliateCountries.i18nKey, messages.affiliateCountries.defaults)}
                        </InputLabel>
                        <Select
                          multiple
                          required
                          id="affiliateCountries"
                          placeholder="affiliateCountries"
                          name="affiliateCountries"
                          onChange={(e) => this.handleChange('affiliateCountries', e.target.value)}
                          error={errors.affiliateCountries}
                          value={affiliateCountries}
                          renderValue={(selected: any) => selected.join(', ')}
                          classes={{selectMenu:classes.selectMenu}}
                        >
                          <MenuItem value={'selectAll'}>
                            <Checkbox checked={selectAllAffCountries} />
                            <ListItemText primary={t(messages.selectAll.i18nKey, messages.selectAll.defaults)} />
                          </MenuItem>
                          {map(validCountries(), (option) => (
                            <MenuItem key={option.value} value={option.value}>
                              <Checkbox checked={affiliateCountries.indexOf(option.value) > -1} />
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>}
                    {includes(fields,'isCorporate') && <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel error={errors.isCorporate} required>
                          <Trans {...messages.company} /> / <Trans {...messages.individual} />
                        </InputLabel>
                        <Select
                          value={isCorporate}
                          onChange={(e) => this.handleChange('isCorporate', e.target.value)}
                          error={errors.isCorporate}
                          required
                        >
                          <MenuItem key={'individual'} value={'false'}><Trans {...messages.individual} /></MenuItem>
                          <MenuItem key={'company'} value={'true'}><Trans {...messages.company} /></MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>}
                    {(isCorporate === 'true') && <Grid item xs={12}>
                      <NotificationBar status={'warning'} variant={'caption'} classes={{notificationMessage: classes.riskText}}>
                        <Trans {...messages.corporateNote} components={[<span className={classes.underline}>corporateVerification</span>]}/>
                      </NotificationBar>
                    </Grid>}
                    <Grid item xs={12} className={classes.nameField}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          id="name"
                          label={t(messages.name.i18nKey, messages.name.defaults)}
                          onChange={(e) => this.handleChange('checkField', e.target.value)}
                          value={checkField}
                        />
                      </FormControl>
                    </Grid>
                    {supportedCompanies && <RegulationBar location={location}
                      regSelected ={(regulator)=> this.setState({selectedRegulator: regulator})}
                      error={!selectedRegulator && errors.selectedRegulator}
                      country={countryFlag.value || countryFromIp}
                      selectedRegulator ={selectedRegulator}
                      regulator={countryFlag.value && companyReg && companyReg.regulator}
                    />}
                    {referredByPartnerShow && <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          classes={{label:classes.checkboxLabel}}
                          control={
                            <Checkbox
                              checked={!!referredByPartner}
                              onChange={(e) => this.setStateOuter('referredByPartner', e.target.checked)}
                              color="primary"
                              value={toString(referredByPartner)}
                            />
                          }
                          label={<Trans {...messages.referredByPartner} />}
                        />
                      </FormControl>
                    </Grid>}
                    {referredByPartnerShow && referredByPartner && <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required={referredByPartner}
                        id="referenceCode"
                        name="referenceCode"
                        label={t(messages.partnerReferenceNumber.i18nKey, messages.partnerReferenceNumber.defaults)}
                        error={errors.partnerReferenceNumber}
                        onChange={(e) => this.handleChange('partnerReferenceNumber', e.target.value)}
                        value={partnerReferenceNumber}
                      />
                    </Grid>}
                    <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          classes={{label:classes.checkboxLabel}}
                          control={
                            <Checkbox
                              required
                              checked={this.state.acceptTerms}
                              onChange={(e) => this.handleChange('acceptTerms', e.target.checked? 'accept' : '')}
                              color="primary"
                              value={acceptTerms}
                              className={errors.acceptTerms ? classes.checkBoxError : ''}
                            />
                          }
                          label={this.isAffiliate() ?
                            <Trans {...messages.signUpAffiliateCheckbox} components={[
                              <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                              <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                              <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                              <a href={affiliateTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                              <a href={affiliateCodeConduct} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                              <a href={includes(translatedPartnerGuidelines, locale) ? affiliateGuidelines.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : affiliateGuidelines}
                                target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                            ]} />
                            : this.isIb() ?
                              <Trans {...messages.signUpIBCheckbox} values={{company:companyObject.brandLabel}} components={[
                                <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                                <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                                <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                                <a href={ibCodeConduct} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                                <a href={includes(translatedPartnerGuidelines, locale)  && ibGuidelines ? ibGuidelines.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : ibGuidelines}
                                  target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                                <a href={affiliateTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>ibTerms</a>,
                                <a href={ibMasterTermsAndConditions} target='_blank' rel='noreferrer noopener' className={classes.link}>ibMasterTerms</a>,
                                <a href={ibAppendix} target='_blank' rel='noreferrer noopener' className={classes.link}>appendix</a>,
                              ]} />
                              : <Trans {...messages.signUpCheckbox} values={{company:companyObject.brandLabel}} components={[
                                <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                                <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                                <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                              ]} />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          classes={{label:classes.checkboxLabel}}
                          control={
                            <Checkbox
                              required
                              checked={this.state.enableMFA}
                              onChange={this.handleMFAChange}
                              color="primary"
                              value={acceptTerms}
                              className={errors.acceptTerms ? classes.checkBoxError : ''}
                            />
                          }
                          label={<Trans {...messages.enableMFA} />}
                        />
                      </FormControl>
                    </Grid>
                    {showLicenseAcknowledgement && (!supportedCompanies || (supportedCompanies && selectedRegulator)) && <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          classes={{label:classes.checkboxLabel}}
                          control={
                            <Checkbox
                              required
                              checked={!!licenseAcknowledgement}
                              onChange={(e) => this.setState({licenseAcknowledgement: e.target.checked ? moment().format() : ''})}
                              color="primary"
                              value={licenseAcknowledgement}
                              className={errors.licenseAcknowledgement ? classes.checkBoxError : ''}
                            />
                          }
                          label={<Trans {...messages.licenseAcknowledgementCountriesText2} values={{company: objectCompany.trademark,
                            authority: get(authority, 'label'), license: get(regulatorObj, 'license'), regulation: get(regulatorObj, 'name').localization.t(locale),
                            regulationLabel: get(regulatorObj, 'label'), regulationCountry: get(regulatorObj, 'country')}}/>}
                        />
                      </FormControl>
                    </Grid>}
                    {isRecaptchaEnabled && !automationEmail && <Grid item xs={12}>
                      <ReCAPTCHA
                        sitekey={siteKey}
                        ref={recaptchaRef}
                        theme={themePreference}
                        size={isInvisible ? 'invisible' : 'normal'}
                      />
                    </Grid>}
                    <Grid item xs={12}>
                      <LoadingButton
                        id='loadingButton'
                        onClick={() => this.validateForm(regSelected)}
                        disabled={loading}
                        status={status}
                        fullWidth={isMobile()}
                      >
                        <Trans {...messages.submit} />
                      </LoadingButton>
                      {status==='failure' &&
                      <FormHelperText classes={{root: classes.errorRoot}}className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
                    </Grid>
                  </Grid>
                </Grid>

                {!isPartnerRegistration && <Grid item md={2} xl={2} className={classes.middleLine}></Grid>}
              </Grid>
              {!config.visual?.registration?.hideRiskWarning && <Grid item xs={12} className={classes.risk}>
                <Typography variant='caption' className={classes.riskText}>
                  <span className={classes.riskTitle}>*<Trans {...messages.riskWarningTitle} /*classes={classes.riskTitle}*//></span>&nbsp;
                  <Trans {...messages[riskWarningKey]} values={{complaintsEmail: weblinks.complaintsEmail, percentage}}
                    components={[
                      <a href={weblinks.legalDocuments} target='_blank' rel='noreferrer noopener' className={classes.link}>here</a>,
                      <a href={`mailto:${ weblinks.complaintsEmail}`} className={classes.link}>complaintsEmail</a>,
                      <a href={weblinks.entityWebsite} target='_blank' rel='noreferrer noopener' className={classes.link}>entityWebsite</a>,
                      <p key={'p'}/>,
                      <div key={'div'}/>,
                      <p key={'p2'}/>,
                      <p key={'p3'}/>,
                      <a href={weblinks.websiteUrl} target='_blank' rel='noreferrer noopener' className={classes.link}>bdstrading.com</a>,
                      <i key={'i'}>itallic</i>,
                    ]} />
                </Typography>
              </Grid>}
              {showGBFooter && <React.Fragment>
                <Grid item xs={12} className={classes.showGBFooter}>
                  <Typography variant="caption" className={classes.riskText}>
                    <Trans {...messages.showGBFooter} />
                  </Typography>
                  <Typography variant="caption" className={classes.riskText}>
                    <Trans {...messages.showGBFooter2} components={[<span className={classes.highlightedText}>title</span>]}/>
                  </Typography>
                </Grid></React.Fragment>}
            </Grid>
          </Grid>
        </div>
        {showEmbeddedUi && <UiNotification
          open={true}
          status={'success'}
          type="document-upload"
          buttonMessage={t(messages.close.i18nKey)}
          onClose={() => this.closeEmbeddedIframe()}
        />}
        {showReferral && <UiNotification
          open={showReferral}
          title={'congratulationsReferred'}
          titleComponents={[<br key={'br'} />]}
          status={'referral'}
          children={this.referralNotificationContent(get(urlParams, 'referral'))}
          type="register"
          buttonMessage={t(messages.register.i18nKey)}
          onClose={() => this.setState({showReferral: false})}
          buttonOnClick={() => this.setState({showReferral: false})}
        />}
        {notForbiddenSpecific && !showRedirectionEuPopup && <AlertDialog
          open={showRedirectionModal || false}
          title={t(messages.entityRedirectionHeader.i18nKey, messages.entityRedirectionHeader.defaults)}
          children={<Typography variant="body1"> <Trans {...messages[get(get(config, 'registrationCountriesRedirect'),'redirectMessage')]} values={{company: companyObject.trademark}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onAgree={()=> window.location.href = `${get(registrationCountriesRedirect, 'to')}${paramType ? `/${paramType}` : ''}${paramSubType ? `/${paramSubType}` : ''}${urlParamsString ? `?${urlParamsString}`: ''}`}
          onDisagree={()=> this.setState(state => ({form: {...state.form,
            country: '', prefix: ''}, showRedirectionModal: false, countryFlag: '', prefixFlag: ''}))}
        />}
        {forbiddenCountryMessage && <CustomDialog open={showForbiddenCountryPopup && !forbiddenCountryPopupShown} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({forbiddenCountryPopupShown: true})}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <Trans {...forbiddenCountryMessage} values={{country:countryObj!.localization.t(locale)}} components={[<br key={'br'} />, <br key={'br2'} />]} />
              </Typography>
            </Grid>
          </Grid>
        </CustomDialog>}
        {forbiddenCountryMessageStrict && <CustomDialog open={showForbiddenCountryPopupStrict && !forbiddenCountryPopupStrictShown} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({forbiddenCountryPopupStrictShown: true})}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <Trans {...forbiddenCountryMessageStrict} values={{country:countryObj!.localization.t(locale)}} />
              </Typography>
            </Grid>
          </Grid>
        </CustomDialog>}
        {activeModal === chainModals.solicitationModal && <AlertDialog
          open
          onAgree={() => this.handleProcessModal(chainModals.solicitationModal)}
          onDisagree={() => this.handleModalClose(chainModals.solicitationModal)}
          agreeText={t(messages.submit.i18nKey, messages.submit.defaults)}
          disagreeText={t(messages.decline.i18nKey, messages.decline.defaults)}
          title={t(messages.solicitationAcceptanceTitle.i18nKey, messages.solicitationAcceptanceTitle.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.solicitationAcceptancePart1} />
              </Typography>
              <br />
              <Typography variant='body1'>
                <Trans {...messages.solicitationAcceptancePart2} />
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
        {activeModal === chainModals.nonEUModal && <AlertDialog
          open
          onAgree={() => this.handleProcessModal(chainModals.nonEUModal)}
          onDisagree={() => this.handleModalClose(chainModals.nonEUModal)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          disagreeText={t(messages.leaveWebsite.i18nKey, messages.leaveWebsite.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.acceptNonEURegulation} />
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
        {activeModal === chainModals.mfaModal && <MfaSettingsContainer><MfaSettings
          email={this.state.form.email}
          onAgree={this.handleOtpSetup}
          onDisagree={() => this.handleModalClose(chainModals.mfaModal)}
        /></MfaSettingsContainer>}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withApollo,
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}: any) => ({
      loadingProp:loading,
      redirectUrl: get(error, 'networkError.result.data.redirectUrl'),
      viewer: get(data, 'viewer')
    })
  }),
)(Register)
