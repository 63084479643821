import {ReactElement, ReactNode} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

import {FileUploadButton} from './FileUploadButton'
import {StatusIcon} from './StatusIcon'
import {FilePreview} from './FilePreview'
import {ClientProfileDoc} from '../types'
import {styles} from './styles'

type UploadDocumentFieldProps = {
  label: ReactNode,
  name: string,
  file?: File | ClientProfileDoc,
  error?: boolean,
  onUpload: (file: File) => void,
} & WithStyles

function UploadDocumentFieldBase(props: UploadDocumentFieldProps): ReactElement {
  const {classes, file, label, name, onUpload, error} = props

  const status = (() => {
    if (file instanceof File) return null

    return file?.status
  })()

  return (
    <div className={cn(classes.container, {[classes.error]: error})}>
      <StatusIcon status={status} />
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.button}>
        <FilePreview file={file} />
        <FileUploadButton file={file} name={name} onUpload={onUpload} />
      </div>
    </div>
  )
}

const UploadDocumentField = withStyles(styles)(UploadDocumentFieldBase)
export {UploadDocumentField}
