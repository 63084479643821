export const styles = () => ({
  container: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: '140px',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 'auto',
  }
}) as const
