import React from 'react'
import {Link} from 'react-router-dom'
import {graphql} from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import {red} from '@material-ui/core/colors'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Icon from '@material-ui/core/Icon'
import {withNamespaces, Trans} from 'react-i18next'
import {get, map, filter, keys, includes, flowRight as compose, isNil, size, isEmpty} from 'lodash'
import messages from '../../../assets/messages'
import {FOREX_ACCOUNT_SETTINGS, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {
  UPDATE_LEVERAGE,
  UPDATE_ACCOUNT_NAME_MUTATION,
  UPDATE_OWN_PERFORMANCE_FEE_MUTATION,
  PROVIDE_COPY_TRADING_MUTATION,
  TOGGLE_PASSWORD_STRATEGY_MUTATION,
  DELETE_ACCOUNT_MUTATION
} from '../../../graphql/mutations'
import Typography from '@material-ui/core/Typography'
import {config} from '../../../config'
import Loading from '../../Common/Loading'
import LoadingButton from '../../Common/LoadingButton'
import {AlertDialog} from '../../Common/Dialog'
import FormHelperText from '@material-ui/core/FormHelperText'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import {accountTypes, closedTradingStatusReasons} from '@bdswiss/common-enums'
import {validateLength, validateAccountName} from '../../../common/utils/validations'
import {performanceFeeOptions} from '../../../common/utils/uioptions'
import SwitchButton from '../../Common/SwitchButton'
import {checkAccountCopyTrading, isIBClient, isForexAccount, isDemoAccount, isVipOrRaw} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import Password from '../../Common/Password'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import AppContext from '../../Common/contexts/AppContext'
import {InnerAppContext} from '../../../common/types'
import {getMetadataFromAccount} from '../AccountCard/utils'

const styles = theme => ({
  spinner: {
    textAlign: 'center' as const,
  },
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px',
    width: 'unset'
  },
  link: {
    color: theme.palette.primary.main,
  },
  highlightText:{
    fontWeight:400
  },
  dialogTitle:{
    textAlign: 'center' as const,
  },
  title: {
    marginTop: 0
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    paddingTop: 10,
  },
  switchLabel:{
    display:'inline-block',
  },
  switch:{
    float:'right' as const,
  },
  formControlLabel:{
    float:'left' as const,
    color: theme.palette.grey.color,
  },
  labelPlacementStart:{
    marginLeft: 0,
    marginRight: 0
  },
  div:{
    width: '100%'
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  alertTitle: {
    fontSize: 24
  },
  rootLoading:{
    color: theme.palette.primary.main,
    '&.success': {
      backgroundColor: 'transparent',
      color: theme.palette.green.color,
    },
    '&.failure': {
      backgroundColor: 'transparent',
      color: theme.palette.error.main,
    },
  },
  noticeDiv: {
    marginBottom: 15,
    padding: 20,
    borderRadius: 15,
  },
  notice: {
    backgroundColor: theme.palette.lightyellow.color,
  },
  info: {
    backgroundColor: theme.palette.lightblue.color,
  },
  noticeTitle: {
    paddingBottom: 15
  },
  list: {
    padding: '0px 0px 0px 20px',
    margin: 0,
  },
  notificationBox: {
    marginTop: 20,
    marginBottom: 0
  },
  grid: {
    paddingBottom: 40
  },
  infoIcon: {
    marginBottom: -6
  },
  deleteTradingAccountDisclaimer:{
    margin: '10px 0px',
    fontStyle: 'italic'
  },
  deleteTradingAccountPopupDisclaimer:{
    color: 'red',
    fontStyle: 'italic'
  },
  errorButton: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[100],
    },
  },
  mt0: {
    marginTop: 0
  }
})

class ForexAccountSettings extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      leverageUpdating: false,
      showPopup: false,
      leverageChange: 0,
      form: {password: ''},
      errors: {},
      showFeePopup: false,
      showStopProvidingPopup: false,
      stopProvidingLoading: false,
      showPassword: false,
      showDeleteTradingAccountPopup: false,
      accountDeleteSuccessMessage: null
    }
  }

  async onLeverageChange() {
    const {leverageChange} = this.state
    this.setState({leverageUpdating: true})
    await this.props.updateLeverage({variables: {leverage: leverageChange, accountId: this.props.accountId}})
      .then((res) => {
        this.setState({leverageUpdating: false, showPopup: false, changeStatus: 'success', updateOwnLeverage: get(res, 'data.updateOwnLeverage')})
      })
      .catch(() => {
        this.setState({leverageUpdating: false, changeStatus: 'failure'})
      })
  }

  async onFeeChange() {
    const {feeChange} = this.state
    this.setState({feeUpdating: true})
    await this.props.updateOwnPerformanceFee({variables: {accountId: this.props.accountId, performanceFee: feeChange}})
      .then(() => {
        this.setState({feeUpdating: false, showFeePopup: false, changeFeeStatus: 'success'})
      })
      .catch(() => {
        this.setState({feeUpdating: false, changeFeeStatus: 'failure'})
      })
  }

  handleChange(name, value) {
    this.setState(state => ({
      nameStatus: includes(name, 'name') ? '' : state.nameStatus,
      form: {...state.form, [name]: value},
      errors: {...state.errors, [name]: !value,},
    }))
  }

  updateName() {
    const {form: {name}} = this.state
    const {accountId} = this.props
    let nameError = !name || validateAccountName(name)
    if (!nameError) nameError = !validateLength(name, 20) && 'maxLengthError'
    if (nameError) {
      this.setState(state => ({errors: {...state.errors, name: nameError}}))
      return
    }
    this.setState({nameLoading: true})
    this.props.updateAccountName({variables: {accountId, name}})
      .then((succ) => {
        this.setState({nameLoading: false, nameStatus: 'success'})
      })
      .catch((e) => {
        this.setState({nameLoading: false, nameStatus: 'failure'})
      })
  }

  toggleProvideCopyTrading(provideCopyTrading) {
    const {showStopProvidingPopup} = this.state
    if (!provideCopyTrading && !showStopProvidingPopup)
      this.setState({showStopProvidingPopup: true})
    else {
      const {accountId} = this.props
      this.setState({stopProvidingLoading: true})
      this.props.toggleProvideCopyTrading({variables: {accountId, provideCopyTrading}})
        .then((succ) => {
          this.setState({
            showStopProvidingPopup: false,
            toggleStatus: 'success',
            stopProvidingLoading: false,
            providingErrorMessage: '',
          })
        })
        .catch((e) => {
          this.setState({
            showStopProvidingPopup: false,
            toggleStatus: 'failure',
            stopProvidingLoading: false,
            providingErrorMessage: includes(e.message, 'GraphQL error: ')
              ? e.message.replace('GraphQL error: ', '')
              : this.props.t(messages.generalError.i18nKey, messages.generalError.defaults)
          })
        })
    }
  }

  toggleProtectPassword() {
    const {form: {password}} = this.state
    const {account: {id, passwordProtectedStrategies}} = this.props
    this.setState({protectPasswordLoading: true, protectPasswordToggle: !passwordProtectedStrategies})
    const variables = {accountId: id, password: (!passwordProtectedStrategies) ? password : null}
    this.props.togglePasswordStrategy({variables}).then(() => {
      this.setState(state => ({protectPasswordStatus: 'success', protectPasswordLoading: false, form: {...state.form, password: ''}}))
    }).catch(() => {
      this.setState(state => ({protectPasswordStatus: 'failure', protectPasswordLoading: false, errors: {...state.errors, password: true}}))
    })
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword,
      protectPasswordToggle: false,
    }))
  }

  deleteAccount(id) {
    const {deleteAccount, t} = this.props

    deleteAccount({
      variables: {
        accountId: id,
        reason: 'Client side deletion',
        rejectionReasonCode: closedTradingStatusReasons.clientRequest.key
      }})
      .then(() => {
        this.setState({
          errors: {},
          accountDeleteSuccessMessage: t(messages.deleteTradingAccountSuccess.i18nKey, messages.deleteTradingAccountSuccess.defaults)
        })

        setTimeout(() => {
          window.location.href = '/accounts'
        }, 300)
      })
      .catch(err => {
        this.setState({errors: {deleteAccount: err.message.replace('GraphQL error: ', '')}})
      })
  }

  get isForbidToDeleteAccount() {
    const {account} = this.props
    const metadata = getMetadataFromAccount(account)

    return metadata.pammFeesAccount || metadata.pammTradesAccount
  }

  render() {
    const {classes, allowedLeverages, leverage, canEditLeverage, loading, t , viewer: {clientAllowCopyTrading, showPasswordProtectedStrategies}, typename,
      accountName, performanceFee, provideCopyTrading, remoteId, account} = this.props
    const {leverageUpdating, showPopup, leverageChange, changeStatus, errors, form: {name, fee, password}, showFeePopup,
      feeChange, changeFeeStatus, feeUpdating, showStopProvidingPopup, stopProvidingLoading ,stopProvidingStatus, nameLoading, nameStatus,
      providingErrorMessage, protectedErrorMessage, showPassword, protectPasswordToggle, updateOwnLeverage,
    } = this.state
    const {clientType} = this.context
    const exceptions = ['changeLeverageLimitation5']
    const leverageMessages = filter(keys(messages), (m) => !includes(exceptions, m) && m.indexOf('changeLeverageLimitation') === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})

    if (loading) return <Loading />

    const feeValue = performanceFee === -1 ? performanceFeeOptions.default : performanceFee
    const accountType = accountTypes[typename]
    const label = (isNil(name) && accountName) || (!accountName && isNil(name) && `${remoteId} - ${get(accountType, 'label')}`)

    const copyTradingEnabled = clientAllowCopyTrading && checkAccountCopyTrading(account)
    const passwordProtectedStrategies = get(account, 'passwordProtectedStrategies')
    const passwordProtectedStrategyAvailable = config.featuresConfig.passwordProtectedStrategy && isIBClient(clientType) && showPasswordProtectedStrategies
      && isForexAccount(account) && !isDemoAccount(account) && !isVipOrRaw(account)

    return (
      <Grid container spacing={3} className={classes.grid}>
        {(!isEmpty(this.state.errors) || this.state.accountDeleteSuccessMessage) &&
          <Grid item xs={12} md={6}>
            {
              this.state.errors.deleteAccount &&
                <NotificationBar onClose={() => this.setState({errors: {}})} status="error">
                  {this.state.errors.deleteAccount}
                </NotificationBar>
            }
            {
              this.state.accountDeleteSuccessMessage &&
                <NotificationBar status="success">
                  {this.state.accountDeleteSuccessMessage}
                </NotificationBar>
            }
          </Grid>
        }
        <Grid item xs={12} sm={10} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" color="secondary" className={classes.mt0}> <Trans {...messages.accountSettings} /> : </Typography>
            </Grid>
            <Grid item sm={10} xs={12} >
              <FormControl fullWidth classes={{root: classes.formControlRoot}}>
                <InputLabel htmlFor="leverage">
                  <Trans {...messages.leverage} />
                </InputLabel>
                <Select
                  value={updateOwnLeverage || leverage}
                  inputProps={{id: 'leverage'}}
                  onChange={(e) => this.setState({changeStatus: '', showPopup: true, leverageChange: e.target.value})}
                  disabled={(!canEditLeverage && !get(account, 'isDemo')) || (size(allowedLeverages) < 2)}
                >
                  {map(allowedLeverages, lvrg =>
                    <MenuItem key={lvrg} value={lvrg} disabled={lvrg === leverage}>{`1:${lvrg}`}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={10} xs={12}>
              <FormControl className={classes.div}>
                <InputLabel htmlFor="name">
                  <Trans {...messages.name} />
                </InputLabel>
                <Input
                  fullWidth
                  id="name"
                  value={name || label || ''}
                  onChange={(e) => this.handleChange('name', e.target.value)}
                  inputProps={{maxLength: 20}}
                  endAdornment={
                    <InputAdornment position='end'>
                      <LoadingButton
                        disabled={nameLoading || !name || name === accountName}
                        status={nameStatus}
                        onClick={() => this.updateName()}
                        hideProgressBar={!nameLoading}
                        size="small"
                        noMargin={true}
                        variant={'text'}
                        className={classes.rootLoading}
                      > <Trans {...messages.update} />
                      </LoadingButton>
                    </InputAdornment>
                  }
                  error={!!errors.name}
                />
                {errors.name && <FormHelperText className={classes.errorMessage}> {name && messages[errors.name] ?
                  <Trans {...messages[errors.name]} values={{length: 20}}/> : <Trans {...messages.requiredField} />} </FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          {copyTradingEnabled && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3"><Trans {...messages.copyTradingSettings} /></Typography>
            </Grid>
            <Grid item sm={10} xs={12} >
              <FormControl fullWidth classes={{root: classes.formControlRoot}} disabled={provideCopyTrading}>
                <InputLabel htmlFor="performanceFee">
                  <Trans {...messages.performanceFee} />
                </InputLabel>
                <Select
                  value={feeValue}
                  inputProps={{id: 'fee'}}
                  onChange={(e) => this.setState({changeFeeStatus: '', showFeePopup: true, feeChange: e.target.value})}
                >
                  {map(performanceFeeOptions.options, fee =>
                    <MenuItem key={fee} value={fee} disabled={fee === feeValue}>{`${fee}%`}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={10} xs={12}>
              <SwitchButton iOSSwitch={true} id={'provideCopyTrading'} value={provideCopyTrading} checked={provideCopyTrading}
                onChange={(e) => this.toggleProvideCopyTrading(e.target.checked)}
                label={t(messages.provideCopyTrading.i18nKey, messages.provideCopyTrading.defaults)}/>
              {providingErrorMessage && <FormHelperText className={classes.errorMessage}>{providingErrorMessage}</FormHelperText>}
            </Grid>
          </Grid>}
          {passwordProtectedStrategyAvailable && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3"><Trans {...messages.strategySecuritySettings} /></Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <SwitchButton iOSSwitch={true} id={'passwordProtected'} value={!!passwordProtectedStrategies} checked={!!passwordProtectedStrategies}
                onChange={(e) => this.toggleProtectPassword()}
                label={t(messages.passwordProtected.i18nKey, messages.passwordProtected.defaults)}
                disabled={!password && !passwordProtectedStrategies}/>
              {protectedErrorMessage && <FormHelperText className={classes.errorMessage}>{protectedErrorMessage}</FormHelperText>}
            </Grid>
            <Grid item sm={10} xs={12} >
              <Password
                classes={classes}
                showPassword={showPassword}
                onChange={(e) => this.handleChange('password', e.target.value)}
                onClickShow={() => this.handleClickShowPassword()}
                value={password || passwordProtectedStrategies || ''}
                disabled={passwordProtectedStrategies}
              />
              {!password && !passwordProtectedStrategies && !protectPasswordToggle && <FormHelperText>
                <Trans {...messages.enterPasswordProtectStrategy} />
              </FormHelperText>}
            </Grid>
          </Grid>}
          {this.isForbidToDeleteAccount ? null : (
            <Grid item xs={12} sm={10} lg={10}>
              <Typography variant="h3" color="secondary"> <Trans {...messages.deleteTradingAccount} /> : </Typography>
              <Grid container className={classes.marginTop}>
                <Grid item xs={12}>
                  <Typography variant="caption" color="secondary" className={classes.deleteTradingAccountDisclaimer}>
                    *<Trans {...messages.deleteTradingAccountDisclaimer} />
                  </Typography>
                  {/* The first following version of MUI core that supports button color error is 5.0, which i cant quickly update, so i will just use custom style here for now */}
                  {/* Maybe i can make this error button version in custom theme edits, so it can be used globally */}
                  {/* Technically its possible to use red button in our LoadingButton component, but i would need to use status failure which is not the correct approach */}
                  <Button
                    id='deleteTradingAccountBtn'
                    onClick={() => this.setState({showDeleteTradingAccountPopup: true})}
                    variant={'outlined'}
                    size="large"
                    className={classes.errorButton}>
                    <Trans {...messages.deleteTradingAccountBtn} />
                  </Button>
                </Grid>
                <AlertDialog
                  open={this.state.showDeleteTradingAccountPopup}
                  title={t(messages.deleteTradingAccount.i18nKey, messages.deleteTradingAccount.defaults)}
                  disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                  agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
                  onAgree={() => {
                    this.setState({showDeleteTradingAccountPopup: false})
                    this.deleteAccount(account.id)
                  }}
                  onDisagree={() => this.setState({showDeleteTradingAccountPopup: false})}
                  onClose={() => this.setState({showDeleteTradingAccountPopup: false})}
                  buttonLoading={loading}
                >
                  <Trans {...messages.deleteTradingAccountPopupContent} />
                  <br/>
                  <br/>
                  <Typography className={classes.deleteTradingAccountPopupDisclaimer}>
                    <Trans {...messages.deleteTradingAccountPopupDisclaimer} />
                  </Typography>
                </AlertDialog>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={10} lg={4}>
          <Grid container>
            {config.featuresConfig.leverageNotice === 'euNotice' && <Grid item xs={10} lg={12} className={classNames(classes.noticeDiv, classes.notice)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.leverageNotice} /></Typography>
              <Typography variant="body1"><Trans {...messages.changeLeverageNoticeEu} /></Typography>
              <Typography variant="body1"><Trans {...messages.leverageLimitations} /></Typography>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} sm={11}>
                  <ul key="information" className={classes.list}>
                    {map((leverageMessages), (m: any) =>
                      <li key={m.i18nKey} className={classes.listItem}>
                        <Typography variant='body1'><Trans {...m} /></Typography>
                      </li>
                    )}
                  </ul>
                </Grid>
              </Grid>
              <Typography variant="body1">
                <Trans {...messages.contactSupportEu}
                  components={[
                    config.weblinks.professionalAccount && <a href={config.weblinks.professionalAccount} className={classes.link} target='_blank' rel='noopener noreferrer'> </a>,
                    <Link to={'/support'} className={classes.link}> </Link>
                  ]}
                />
              </Typography>
            </Grid>}
            {config.featuresConfig.leverageNotice === 'globalNotice' && <Grid item xs={10} lg={12} className={classNames(classes.noticeDiv, classes.notice)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.leverageNotice} /></Typography>
              <Typography variant="body1"> <Icon className={classes.warningIcon}> warning </Icon> <Trans {...messages.changeLeverageNotice} /> </Typography>
              <Typography variant="body1"> <Trans {...messages.changeLeverageNoticeExample} /> </Typography>
              <Link to="/support">
                <Typography variant="body1" className={classes.link}> <Trans {...messages.contactSupportForMore} /> </Typography>
              </Link>
            </Grid>}
            {passwordProtectedStrategyAvailable && <Grid item xs={10} lg={12} className={classNames(classes.noticeDiv, classes.info)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.passwordProtection} /></Typography>
              <Typography variant="body1"> <InfoIcon className={classNames(classes.link, classes.infoIcon)} /> <Trans {...messages.passwordProtectionText} /> </Typography>
            </Grid>}
          </Grid>
        </Grid>
        <AlertDialog
          open={showPopup || showFeePopup}
          onClose={() => this.setState({showPopup: false, showFeePopup: false})}
          onAgree={() => (showPopup) ? this.onLeverageChange() : this.onFeeChange()}
          onDisagree={() => this.setState({showPopup: false, showFeePopup: false})}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
          title = { <Typography variant='body2' className={classes.dialogTitle}>
            <Trans {...messages.submitWithdrawalTitle} />
          </Typography>}
          buttonLoading={leverageUpdating || feeUpdating}
          buttonStatus={changeStatus || changeFeeStatus}
          errorMessage={(changeStatus === 'failure' || changeFeeStatus === 'failure') ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
        >
          {(showPopup) ? <React.Fragment>
            <Typography variant="body1">
              <Trans {...messages.changeLeveragePopupText1} values={{leverage}} components={[<span className={classes.highlightText}>oldleverage</span>]} />
            </Typography>
            <Typography variant="body1">
              <Trans {...messages.changeLeveragePopupText2} values={{leverageChange}} components={[<span className={classes.highlightText}>newleverage</span>]}/>
            </Typography>
            {/* Open trades won't be affected */}
            {/*<NotificationBar status="warning" classes={{notificationBox: classes.notificationBox}}>*/}
            {/*  <Trans {...messages.openTradesAffected} />*/}
            {/*</NotificationBar>*/}
          </React.Fragment>
            : <React.Fragment>
              <Typography variant="body1">
                <Trans {...messages.changeFeePopupText1} values={{fee: fee || feeValue}} components={[<span className={classes.highlightText}>oldFee</span>]} />
              </Typography>
              <Typography variant="body1">
                <Trans {...messages.changeFeePopupText2} values={{feeChange}} components={[<span className={classes.highlightText}>newFee</span>]}/>
              </Typography></React.Fragment>
          }
        </AlertDialog>
        <AlertDialog
          open={showStopProvidingPopup}
          onClose={() => this.setState({showStopProvidingPopup: false})}
          onAgree={() => this.toggleProvideCopyTrading(false)}
          onDisagree={() => this.setState({showStopProvidingPopup: false})}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.stopProviding.i18nKey, messages.stopProviding.defaults)}
          title = { <Typography variant='body1' className={classes.alertTitle}>
            <Trans {...messages.stopProvidingTitle} components={[<span className={classNames(classes.stopProviding, classes.errorMessage)}>stopProviding</span>]}/>
          </Typography>}
          buttonLoading={stopProvidingLoading}
          buttonStatus={stopProvidingStatus}
          errorMessage={stopProvidingStatus === 'failure' ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
        >
          <Typography variant="body1">
            <Trans {...messages.stopProvidingText1} />
          </Typography><br />
          <Typography variant="body1">
            <Trans {...messages.stopProvidingText2}/>
          </Typography>
        </AlertDialog>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(FOREX_ACCOUNT_SETTINGS, {
    options: (props:any) => ({variables: {id: props.match.params.accountId}}),
    props: ({data: {error, loading}, data, ownProps}:any) => {
      const leverage = get(data, 'viewer.accounts.0.leverage', 0)
      const allowedLeverages = get(data, 'viewer.accounts.0.allowedLeverages')
      const canEditLeverage = get(data, 'viewer.canEditLeverage')
      const company = get(data, 'viewer.company')
      const typename = get(data, 'viewer.accounts.0.__typename')
      const accountName = get(data, 'viewer.accounts.0.accountName')
      const performanceFee = get(data, 'viewer.accounts.0.performanceFee')
      const provideCopyTrading = get(data, 'viewer.accounts.0.provideCopyTrading')
      const remoteId = get(data, 'viewer.accounts.0.remoteId')
      const account = get(data, 'viewer.accounts.0')
      return {
        error,
        loading,
        allowedLeverages,
        leverage,
        accountId: ownProps.match.params.accountId,
        canEditLeverage,
        company,
        typename,
        accountName,
        performanceFee,
        provideCopyTrading,
        remoteId,
        account
      }
    }
  }),
  graphql(UPDATE_LEVERAGE, {
    name: 'updateLeverage',
    options: (props:any) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error: viewerError, loading: viewerLoading}, data}:any) => ({
      viewerError,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(UPDATE_ACCOUNT_NAME_MUTATION, {
    name: 'updateAccountName',
    options: (props:any) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(UPDATE_OWN_PERFORMANCE_FEE_MUTATION, {
    name: 'updateOwnPerformanceFee',
    options: (props:any) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(PROVIDE_COPY_TRADING_MUTATION, {
    name: 'toggleProvideCopyTrading',
    options: (props:any) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(TOGGLE_PASSWORD_STRATEGY_MUTATION, {
    name: 'togglePasswordStrategy',
    options: (props:any) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(DELETE_ACCOUNT_MUTATION, {
    name: 'deleteAccount',
  }),
)(ForexAccountSettings)
