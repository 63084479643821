import React, {Component} from 'react'
import {
  map,
  get,
  isEmpty,
  some,
  omitBy,
  findKey,
  filter,
  includes,
  debounce,
  flowRight as compose,
  pickBy,
  pick,
} from 'lodash'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import {AlertDialog} from '../../../Common/Dialog/index'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import {UPDATE_ECONOMIC_PROFILE_MUTATION, SIGN_UPLOAD_URL_MUTATION} from '../../../../graphql/mutations'
import {
  appropTestNomineeStatus,
  appropTestIsUsReportable,
  appropTestPoliticallyExposed,
  economicProfileQuestions,
  appropTestPoliticallyExposedReason,
  appropTestTinReason,
  countries,
  whiteLabels,
} from '@bdswiss/common-enums'
import withWidth from '@material-ui/core/withWidth'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'
import AppContext from '../../../Common/contexts/AppContext'
import {Link} from 'react-router-dom'
import {scrollElementIntoView, putFile} from '../../../../common/utils'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {validateLength} from '../../../../common/utils/validations'
import {config} from '../../../../config'
import {InnerAppContext} from '../../../../common/types'
import {TaxInformation} from './TaxInformation'
import {PoliticalExposedPersonStatus} from './PoliticalExposedPersonStatus'
import {FinancialBackgroundAndResources} from './FinancialBackgroundAndResources'
import {UserProfileAndInvestmentObjectives} from './UserProfileAndInvestmentObjectives'
import {TradingKnowledgeAndExperience} from './TradingKnowledgeAndExperience'
import {UploadDocumentField} from './UploadDocumentField'
import {applyPendingChanges} from './utils'
import {DOCUMENT_FIELDS} from './constants'
import {EconomicProfileState} from './types'
import {styles} from './styles'

const gridScroll = 'scroll-grid'

export class EconomicProfile extends Component<any, EconomicProfileState> {
  static contextType = AppContext
  context!: InnerAppContext

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      form: props.initialFormValues,
      errors: {
        tinUsError: false,
      },
      isPoliticallyExposedAlertShow: false,
      usCitizen: false,
      taxJurisdictionCountry: false,
      loading: false,
      status: '',
      submitMessageError: '',
      countryReadOnly: false,
      formChanged: {},
      usCitizenNotification: '',
      showMessage: false,
    }
  }

  componentDidMount() {
    const {clientData} = this.props
    const {euRegulation: {showEpTestMessage}} = config
    this.setState({showMessage: !!showEpTestMessage && get(clientData, 'fromCompany') && get(clientData, 'verificationActions.forcedVerification') === 'economicProfile'})
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialFormValues !== prevProps.initialFormValues) {
      this.setState({form: this.props.initialFormValues})
    }
  }

  handleChange = (name, value, alert?) => {
    const {form} = this.state
    const tinDisabledCitizen = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.yes.value)
    const tinDisabledCountry = (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value === countries.us.key)
    const resetUsCitizen = (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value) && (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value !== countries.us.key)
    const resetCountry = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.no.value)
    const pepRemoveReason = get(form, 'politicallyExposedReason') && (name === economicProfileQuestions.politicallyExposed.value) && value === appropTestIsUsReportable.no.value

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
        tinReason: tinDisabledCitizen ||  tinDisabledCountry ? appropTestTinReason.iHaveTin.key : (name === economicProfileQuestions.tinReason.value) ? value : form.tinReason,
        usCitizen: tinDisabledCountry ? appropTestIsUsReportable.yes.value : (name === economicProfileQuestions.usCitizen.value) ? value : (resetUsCitizen) ? '' : form.usCitizen,
        taxJurisdictionCountry: tinDisabledCitizen ? countries.us.key : (name === economicProfileQuestions.taxJurisdictionCountry.value) ? value : (resetCountry) ? '' : form.taxJurisdictionCountry,
        politicallyExposedReason: (pepRemoveReason) ?  '' : (name === 'politicallyExposedReason') ? value : form.politicallyExposedReason
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: value !== this.props.initialFormValues[name],
      },
      status: '',
      pepCheckboxError: false
    }))
    if (alert && alert(value) && name === economicProfileQuestions.politicallyExposed.value) {
      this.setState({isPoliticallyExposedAlertShow: true})
    }
  }

  onContinue = () => {
    const {form: {politicallyExposedReason}} = this.state
    if (!politicallyExposedReason)
      this.setState({pepCheckboxError: true})
    else
      this.setState(state => ({
        form: {
          ...state.form,
          politicallyExposed: (politicallyExposedReason === appropTestPoliticallyExposedReason.none.value) ? appropTestPoliticallyExposed.no.value : appropTestPoliticallyExposed.yes.value,
        },
        isPoliticallyExposedAlertShow: false,
        pepCheckboxError: false
      }))
  }


  scrollUp = (errors?: any ) => {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : `${findKey(errors)}-simple`, 250))
  }

  uploadDocuments = async () => {
    const {clientData, signUploadUrl} = this.props
    const {formChanged, form} = this.state

    const res = {}
    const updatedFileFields = pickBy(formChanged, (value, key) => {
      const isDocumentFieldKey = DOCUMENT_FIELDS.includes(key)
      const isDocumentFieldChanged = value
      const isFile = form[key] instanceof File

      return isDocumentFieldKey && isDocumentFieldChanged && isFile
    })

    if (isEmpty(updatedFileFields)) return res

    const filesToUpload = pick(form, Object.keys(updatedFileFields))
    for (const fileFieldName of Object.keys(filesToUpload)) {
      const {data: {signedDetails: {key, signedUrl}}} = await signUploadUrl({variables: {clientId: clientData.id}})
      const fileToUpload = filesToUpload[fileFieldName]

      await putFile(fileToUpload, signedUrl)
      res[fileFieldName] = {
        key,
        fileName: fileToUpload.name
      }
    }

    return res
  }

  handleSubmit = async () => {
    const {form} = this.state
    const {initialFormValues, t} = this.props
    const errors : any = {}
    const {companyObject} = this.context

    this.setState({status: '', loading: true})

    map(economicProfileQuestions, (q) => {
      const fieldName = q.value
      const fieldValue = form[fieldName]
      const acceptableCompanies = filter(q.company, (o) => o.value === companyObject.value)
      const isFieldShouldBeRendered = q.relevant({answers: form, company: companyObject})
        && !q.disable
        && !isEmpty(acceptableCompanies)

      const usCountry = (fieldName === economicProfileQuestions.tin.value && (get(form, 'taxJurisdictionCountry') === countries.us.key))
      const usCitizen = (fieldName === economicProfileQuestions.usCitizen.value && (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value))
      const usRelevant = [economicProfileQuestions.taxJurisdictionCountry.value, economicProfileQuestions.tinReason.value]
      const tinCheck = (usCountry || usCitizen) && fieldName === economicProfileQuestions.tin.key


      if (isFieldShouldBeRendered) {
        if (tinCheck) {
          const validation = !(/^9/i.test(fieldValue)) || !validateLength(fieldValue, null, null, 9)
          errors[fieldName] = validation
          errors.tinUsError =  validation
        } else if (DOCUMENT_FIELDS.includes(fieldName)) {
          errors[fieldName] = form.nomineeStatus === appropTestNomineeStatus.yes.value && !fieldValue
        } else {
          errors[fieldName] = isEmpty(fieldValue)
        }
      } else {
        errors[fieldName] = false
        if (!(get(form, 'taxJurisdictionCountry') === countries.us.key && includes(usRelevant, fieldName)))
          form[fieldName] = ''
      }
    })

    if (some(errors)) {
      this.setState({errors, status: '', loading: false}, () => {this.scrollUp(errors)})
      return
    }

    const formParams = omitBy(form, (currentFieldValue, currentFieldKey) => {
      const initialValue = initialFormValues[currentFieldKey]
      const isFieldNotUpdated = initialValue === currentFieldValue
      const isFieldStayEmpty = isEmpty(currentFieldValue) && isEmpty(initialValue)
      const isDocumentField = DOCUMENT_FIELDS.includes(currentFieldKey)

      return isFieldNotUpdated || isFieldStayEmpty || isDocumentField
    })

    const filesParams = await this.uploadDocuments()
    const variables = {...formParams, ...filesParams}

    this.props.updateDetails({variables}).then((res) => {
      const usCitizen = get(res, 'data.updateOwnDetails.answers.globalQuestionnaire.usCitizen') === appropTestIsUsReportable.yes.value
      this.props.removeForced()
      if (usCitizen) {
        this.props.profileSettings.refetch()
          .then((res) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotification: 'hasOpenPositions'}))
          .catch((err) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotification: 'noOpenPositions'},
            //@ts-ignore
            debounce(() => {
              this.context.logout()}, 20000)()))
      } else {
        this.setState({loading: false, formChanged: false, status: 'success', submitMessageError: '', usCitizenNotification: ''}, ()=>{this.scrollUp()})
      }
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({
            loading: false, status: 'failure', usCitizenNotification: '',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({
            loading: false, status: 'failure', usCitizenNotification: '',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  renderQuestion = (question) => {
    const {classes, initialFormValues} = this.props
    const {form, countryReadOnly, errors} = this.state
    const {locale, companyObject} = this.context
    const fieldValue = get(form, question.value, '')
    const checkCompanies = filter(question.company, (o) => o.value === companyObject.value)

    const isRenderField = !question.disable
      && question.relevant({answers: form, company: companyObject})
      && !isEmpty(checkCompanies)

    if (!isRenderField) return null

    return <React.Fragment key={question.value}>
      {question.dropdown && <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`${question.value}-simple`} className={classes.formControl}>
            {question.localization.t(locale)}
          </InputLabel>
          <Select
            native
            error={errors[question.value]}
            id={`${question.value}-simple`}
            name={question.value}
            value={fieldValue}
            onChange={(e) => this.handleChange(question.value, e.target.value, question.alert)}
            disabled={(question.value === economicProfileQuestions.taxJurisdictionCountry.value && countryReadOnly ? true : false)}
          >
            <option value='' key='' className={classes.firstItemHide}></option>
            {map(question.getOptions({company: companyObject}), (option) =>
              !option.disable && !option.hidden ? (
                <option key={option.key} value={option.key}>
                  {option.localization.t(locale)}
                </option>
              ) : null
            )}
          </Select>
          {question.value === economicProfileQuestions.politicallyExposed.value &&
          (initialFormValues.politicallyExposedReason || form.politicallyExposedReason) &&
          <Typography variant="caption" className={classNames(classes.textLink, classes.textRight)} onClick={() => this.setState({isPoliticallyExposedAlertShow: true})}>
            <Trans {...messages.viewDetails} /></Typography>}
        </FormControl></Grid>}
      {question.textfield && <Grid item xs={12} key={question.value}>
        <TextField
          required
          placeholder={question.placeholderTranslation ? question.placeholderTranslation(locale) : null}
          id={question.value}
          name={question.value}
          label={question.localization.t(locale)}
          fullWidth
          value={fieldValue}
          error={errors[question.value]}
          onChange={(e) => this.handleChange(question.value, e.target.value, question.alert)}
        />
        {question.value === economicProfileQuestions.tin.value && errors.tinUsError &&
          <FormHelperText className={classes.errorMessage}><Trans {...messages.tinUsError} /></FormHelperText>}
      </Grid>}
      {question.isFileField ? (
        <UploadDocumentField
          label={question.localization.t(locale)}
          name={question.value}
          file={fieldValue}
          error={errors[question.value]}
          onUpload={(file) => this.handleChange(question.value, file)}
        />
      ) : null}
    </React.Fragment>
  }

  get isTauromarketsMauritiusCompany() {
    const {companyObject} = this.context
    return companyObject.key === whiteLabels.tauroMarketsMauritius.key
  }

  render() {
    const {classes, t} = this.props
    const {locale, companyObject} = this.context
    const {
      isPoliticallyExposedAlertShow,
      status,
      form,
      loading,
      submitMessageError,
      formChanged,
      usCitizenNotification,
      pepCheckboxError,
      showMessage,
    } = this.state
    const isFormChanged = includes(formChanged, true)

    return (
      <>
        <AlertDialog
          open={isPoliticallyExposedAlertShow}
          onAgree={this.onContinue}
          title={t(messages.pepTitle.i18nKey, messages.pepTitle.defaults)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.subtitle}>
                <Trans {...messages.pepSubtitle} />
              </Typography>
            </Grid>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                value={get(form, 'politicallyExposedReason')}
                onChange={(event) => this.handleChange('politicallyExposedReason', event.target.value)}
              >
                {map(appropTestPoliticallyExposedReason, (option) =>
                  <FormControlLabel key={option.key} value={option.value} control={<Radio color="primary" className={pepCheckboxError ? classes.error: ''}/>}
                    label={<Typography variant="subtitle2" className={pepCheckboxError ? classes.error: ''}>
                      {/*@ts-ignore*/}
                      {option.localization.t(locale)}
                    </Typography>} />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </AlertDialog>
        <Grid item id={gridScroll}></Grid>
        {(status === 'success'  || usCitizenNotification) &&
          <Grid item xs={12} sm={12}>
            <NotificationBar status={(usCitizenNotification) ? (usCitizenNotification === 'hasOpenPositions') ? 'warning': 'error' : 'success'}>
              {(!usCitizenNotification) ? <Trans {...messages.economicProfileChanged}
                components={[
                  <Link to={'/'} className={classes.textLink}>Dashboard</Link>,
                ]} />
                : <>
                  <Trans {...messages.openPositionsUsCitizen} values={{company: companyObject['brandLabel']}}
                    components={[(usCitizenNotification === 'hasOpenPositions') && <Link to={'/support'} className={classes.textLink}>contanctSupport</Link>]} />
                  {(usCitizenNotification !== 'hasOpenPositions') && <Trans {...messages.registrationRejectedUsCitizen}
                    components={[
                      <Button onClick={() => this.context.logout()} className={classNames(classes.usButton, classes.textLink)}>
                        <Trans {...messages.logout} />
                      </Button>,
                    ]} />}
                </>
              }
            </NotificationBar>
          </Grid>
        }

        <TaxInformation renderQuestion={this.renderQuestion} />
        <PoliticalExposedPersonStatus renderQuestion={this.renderQuestion} />
        {this.isTauromarketsMauritiusCompany ? (
          <UserProfileAndInvestmentObjectives
            renderQuestion={this.renderQuestion}
            isShowDocumentsUploadSection={form.nomineeStatus === appropTestNomineeStatus.yes.value}
          />
        ) : null}
        <FinancialBackgroundAndResources renderQuestion={this.renderQuestion} />
        {this.isTauromarketsMauritiusCompany ? (
          <TradingKnowledgeAndExperience renderQuestion={this.renderQuestion} />
        ) : null}

        <div className={classes.buttonContainer}>
          <LoadingButton
            id='loadingButton'
            disabled={!isFormChanged || loading}
            hideProgressBar={!isFormChanged}
            status={status}
            className={classes.button}
            onClick={this.handleSubmit}
          >
            <Trans {...messages.saveButtonSettings} />
          </LoadingButton>
          {status === 'failure' ? (
            <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>
          ) : null}
        </div>

        <AlertDialog
          open={showMessage}
          onClose={() => this.setState({showMessage: false})}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        >
          <Typography variant="subtitle1">
            {/*@ts-ignore*/}
            <Trans {...messages.migrateEpTestMessage} components={{company: get(companyObject, 'trademark')}}/>
          </Typography>
        </AlertDialog>
      </>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      const profileChanges = get(viewer, 'profileChanges[0].answers', {})
      return {
        error: props.data?.error,
        loading:props.data?.loading || true,
        profileChanges,
        viewer,
        initialFormValues: applyPendingChanges(viewer, profileChanges)
      }
    }
  }),
  graphql(UPDATE_ECONOMIC_PROFILE_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}, {query: PROFILE_SETTINGS_QUERY}],
    }, //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    name: 'profileSettings',
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      clientData: get(props.data, 'viewer'),
    })
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {name: 'signUploadUrl'}),
)(EconomicProfile)
