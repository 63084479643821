import {ReactElement, useRef} from 'react'
import Button from '@material-ui/core/Button'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import {Trans} from 'react-i18next'

import messages from '../../../../../../assets/messages'
import {ClientProfileDoc} from '../../types'
import {styles} from './styles'

type FileUploadButtonProps = {
  name: string,
  onUpload: (file: File) => void,
  className?: string,
  file?: File | ClientProfileDoc,
} & WithStyles

function FileUploadButtonBase(props: FileUploadButtonProps): ReactElement {
  const {classes, name, onUpload, className, file} = props
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => inputRef.current?.click()

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    onUpload(fileUploaded)
  }

  return (
    <>
      <label htmlFor={name} className={className}>
        <Button size='small' className={classes.button} onClick={handleUploadClick}>
          {file ? <Trans {...messages.replace} /> : <Trans {...messages.upload} />}
        </Button>
      </label>
      <input
        accept="image/jpeg,image/png,application/pdf"
        className={classes.input}
        id={name}
        type="file"
        multiple={false}
        ref={inputRef}
        onChange={handleChange}
      />
    </>
  )
}

const FileUploadButton = withStyles(styles)(FileUploadButtonBase)
export {FileUploadButton}
