export const styles = theme => ({
  statusIcon: {
    verticalAlign:'middle',
    fontSize: '20px',
  },
  doneIcon: {
    color: theme.palette.green.color
  },
  pendingIcon: {
    color: theme.palette.yellow.color
  },
})
