import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {graphql} from 'react-apollo'
import {isString, isEmpty, some, get, map, flowRight as compose, trim, find, omit, filter, includes, keys} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {currencies, depositVendors, frontends} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {findCompany, putFile} from '../../../../common/utils'
import {checkIbanMatchSwiftCountry} from '../../../../common/utils/general'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import {BEGIN_DEPOSIT_MUTATION,
  CREATE_OWN_DOCUMENT_MUTATION,
  SIGN_UPLOAD_URL_MUTATION
} from '../../../../graphql/mutations'
import NotificationBar from '../../../Common/NotificationBar'
import {JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY,
  PROFILE_SETTINGS_QUERY
} from '../../../../graphql/queries'
import {isValidIBAN, isValidBIC, electronicFormatIBAN} from 'ibantools'
import {PDFDownloadLink} from '@react-pdf/renderer'
import {BankTransferDetailsPDF} from './BankTransferDetailsPDF'
import Images from '../../../Common/Images'
import Icon from '@material-ui/core/Icon'
import {Button} from '@material-ui/core'
import {eSignDocument, eSignDocumentConfirmation} from '../../../../common/utils/requests'
import {FullScreenDialog} from '../../../Common/Dialog'
import PageTitle from '../../../Common/PageTitle'
import moment from 'moment'
import {wireTransferNotAvailableBanks} from '../../../../common/utils/uioptions'
import SelectDocument from '../../../Settings/Documents/SelectDocument'
import {acceptedFormats, maxFileSize} from '../../../../common/utils/variables'
import {checkFileMimeType} from '../../../../common/utils/validations'

const style = theme => ({
  greyText:{
    color: theme.palette.secondary.main
  },
  boldText:{
    fontWeight: 500
  },
  title: {
    marginTop: 0,
    marginBottom: 8
  },
  errorMessage:{
    color:  theme.palette.error.main,
  },
  noteText:{
    fontWeight: 300,
  },
  error: {
    color: theme.palette.error.main,
  },
  helperText: {
    paddingTop: 5
  },
  pdfImg: {
    height: 20,
    paddingRight: 10,
    marginBottom: -4,
  },
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px',
    fontSize: 20
  },
  bold: {
    fontWeight: 400,
  },
  signNowImg: {
    height: 20,
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: -4,
  },
  signNowButton: {
    marginTop: 10,
  },
  iframe:{
    overflow: 'scroll',
    minHeight: '75vh',
    width: '100%'
  },
})

const transferInfoOrderedKeyMessageMap = {
  recipient: messages.recipient,
  iban: 'IBAN',
  creditingAccount: messages.creditingAccount,
  swift: 'BIC-SWIFT-SEPA',
  bank: messages.bankName,
  address: messages.address,
  city: messages.cityName,
  country: messages.countryName,
  currency: messages.currency
}

class BankWireTransferProvider extends Component<any,any> {
  static propTypes = {
    viewer: PropTypes.object.isRequired,
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    providerProperties: PropTypes.shape({
      name: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      paymentKey: PropTypes.string.isRequired,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      form: {
        bankName:'',
        bankAccountHolderName: '',
        iban:'',
        swiftCode:'',
      },
      errors: {},
      loading: false,
      showPopup: false,
      documentSigned: false,
      documentExecutionError: false,
      uploadedFile: '',
      errorFiles: {},
      uploadLoading: false,
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
        ibanSwiftNotCoherent: false
      },
      status: ''
    }))
  }

  handleSubmit() {
    const {form} = this.state
    const {account, amount, bonusAmount, providerProperties:{provider, paymentKey}, bonusTerms} = this.props
    const errors : any = {}
    form.iban = form.iban && electronicFormatIBAN(form.iban)?.toUpperCase()
    form.swiftCode = form.swiftCode && trim(form.swiftCode).toUpperCase()
    const isIBANValid = form.iban && isValidIBAN(form.iban)
    const isBICValid = form.swiftCode && isValidBIC(form.swiftCode)
    for (const field of Object.keys(form)) {
      errors[field] = isEmpty(form[field])
      if (field === 'iban' && !isEmpty(form[field])) {
        errors[field] = !isIBANValid && 'validation'
        if (errors[field] && (!isNaN( form[field]) && form[field].length >= 6 && form[field].length <= 20)) {
          errors[field] = false
        }
      }
      if (field === 'swiftCode' && (form.iban && isIBANValid) && !isEmpty(form[field])) {
        errors[field] = !isBICValid  && 'validation'
      }
    }
    errors.ibanSwiftNotCoherent = !errors.iban && !errors.swiftCode && isIBANValid && checkIbanMatchSwiftCountry(form.iban, form.swiftCode)
    if (some(errors)) {
      this.setState({errors})
      return
    }

    this.setState({status: '', loading: true})
    const variables = {
      accountId: account.id,
      amount: amount,
      currency: account.currency,
      args: JSON.stringify({paymentFields: form, bonusAmount, paymentKey, bonusTerms}),
      vendor: paymentKey === 'wt' ? depositVendors.bankWire.value : depositVendors[provider].value,
      frontend: frontends.web2.value,
    }

    this.props.beginDeposit({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: ''})
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  eSignDocumentAction(paymentProvider) {
    const {amount} = this.props
    const depositAmount = String(amount)
    eSignDocument(paymentProvider, depositAmount).then((res) => {
      this.setState({showPopup: true})
      if (res.result === 'success') {
        this.setState({
          iFrameUrl: get(res.embeddedSigningSessions[0], 'embeddedSessionURL'),
          folderId: res && get(res.folder, 'folderId'),
          folderName: res && get(res.folder, 'folderName')
        })
      }
    }).catch((e) => {
      console.log(e) //eslint-disable-line no-console
    })
  }

  eSignDocumentConfirmed(paymentProvider) {
    const {folderId, folderName} = this.state
    eSignDocumentConfirmation(paymentProvider, folderId, folderName).then((res) => {
      const documentExecuted = get(res, 'folder.envelopeStatus') === 'EXECUTED'
      this.setState({showPopup: true})
      if (res.result === 'success' && documentExecuted) {
        this.setState({showPopup: false, documentSigned: true, showExecutionError: false})
      } else {
        this.setState({showPopup: false, showExecutionError: true})
      }
    })
  }

  handleFileUpload(prop, e) {
    this.setState({[prop]: e.target.files[0], uploadStatus: ''})

    const {errorFiles} = this.state
    map(e.target.files,(file) => {
      try {
        checkFileMimeType(file).then( (res: any) => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState({errorFiles:errors})
          }
        })
        e.target.value = null //reset input value to force reupload action in failure
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  uploadDocument(docType, file, internalTag) {
    const {paymentMethodId} = this.state
    const {signUploadUrl, createOwnDocument, viewer: {id}} = this.props
    if (!file) return Promise.resolve()
    return signUploadUrl({variables: {clientId: id}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails
      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, paymentMethodId}})
      )
    })
  }

  onUploadDocument() {
    const {uploadedFile: file} = this.state
    const {viewer: {id}} = this.props
    this.setState({uploadLoading: true})
    const internalTag = `${id}-copy-of-transfer-${Date.now()}`

    this.uploadDocument('other', file,  internalTag).then((res) => {
      this.setState({uploadLoading: false, uploadStatus: 'success'})
    }).catch(() => this.setState({uploadLoading: false, uploadStatus: 'failure', uploadedFile: '', secUploadedFile: ''}))
  }

  renderUploadPrompt() {
    const {classes} = this.props
    const {status, uploadedFile, errorFiles, uploadLoading, uploadStatus} = this.state
    const showUploadButton = uploadedFile ? true : false

    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              <Trans {...messages.uploadCheckPrompt} />
            </Typography>
            <Typography variant="body1">
              <Trans {...messages.uploadCheckPromptText} />
            </Typography>
            <Typography variant="caption"  color='error'>
              *<Trans {...messages.acceptedFormats} values={{acceptedFormats: acceptedFormats.replace(/(.*),.*/, '$1')}}/>&nbsp;
              <Trans {...messages.maxFileSizeText} values={{maxSize: maxFileSize}}/>
            </Typography>
            <Grid item xs={12}>
              <Grid container className={classes.uploadDiv} spacing={3}>
                <Grid item xs={12} md={6} >
                  <SelectDocument
                    uploadedFile={uploadedFile}
                    onChange={(e) => this.handleFileUpload('uploadedFile', e)}
                    status={uploadedFile && (includes(keys(errorFiles), 'uploadedFile') ? 'error': 'success')}
                    src={Images['upload-bankStatement.png']}
                  />
                </Grid>
              </Grid>
            </Grid>
            <LoadingButton
              id='uploadLoadingButton'
              onClick={() => this.onUploadDocument()}
              disabled={!showUploadButton || !isEmpty(errorFiles) || (uploadLoading && !uploadStatus) || (uploadStatus==='failure')}
              status={status}
              hideProgressBar={!uploadLoading}
            ><Trans {...messages.submitDocument} />
            </LoadingButton>
            {uploadStatus && ((uploadStatus === 'success')
              ?
              (<Typography color='primary'>
                <Trans {...messages.documentUploaded} />
              </Typography>)
              :
              (<Typography color='error'>
                <Trans {...messages.documentFailedUploadMessage} />
              </Typography>)
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {account: {id: accountId, currency}, viewer: {id: clientId}, classes, t, providerProperties, getJMFinancialBankTransferDetails} = this.props
    const {form: {bankName, bankAccountHolderName, iban, swiftCode}, loading, status, submitMessageError, errors, showPopup, iFrameUrl, documentSigned, showExecutionError} = this.state
    const companyObject = findCompany()
    const companyBrandLabel = companyObject.brandLabel
    const bankDetails = find(get(providerProperties, 'bankDetails'), (details) => details.clientAccountCurrency === currency) ||
      find(get(providerProperties, 'bankDetails'), (details) => details.clientAccountCurrency === currencies.EUR.value)
    const currencyCheck = currency !== get(bankDetails, 'clientAccountCurrency')
    const paymentProvider = get(providerProperties, 'provider')
    const bankTranferInfo = paymentProvider === 'bankWire' ? bankDetails : getJMFinancialBankTransferDetails
    const showBankDetails = documentSigned || (paymentProvider === 'bankWire')

    const today = moment().format()
    const notAvailableBanks = filter(wireTransferNotAvailableBanks, (bank) => {
      const endDate = moment(bank.startDate).add(bank.activeDays, 'd')
      return includes(get(bank, 'availablePayments'), paymentProvider)  &&
        moment(today).isSameOrBefore(moment(endDate).format(), 'day')
    })
    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
        {(status !== 'success') ? <Grid container spacing={3}>
          {showBankDetails && providerProperties.paymentKey === 'offlineBankingIndia' ? this.renderUploadPrompt() : ''}
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              <Trans {...messages.yourBankDetails} />
            </Typography>
            {!isEmpty(notAvailableBanks) && map(notAvailableBanks, (bank) => (<NotificationBar status="warning" key={bank.key}>
              <Trans {...messages.wireTransferNotificationBankNotAvailable}
                values={{bankName: bank.label, date: moment(bank.startDate).format('LL')}}
                components={[<span className={classes.bold} key={'b'}>text</span>]}/>
            </NotificationBar>)
            )}
            <Typography variant="body1">
              <Trans {...messages.yourBankDetailsText} />
            </Typography>
            <Typography variant="caption" color='error'>
              *<Trans {...messages.yourBankDetailsRequired} values={{company: companyBrandLabel}}/>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="bankName"
              name="bankName"
              label={t(messages.bankName.i18nKey, messages.bankName.defaults)}
              fullWidth
              autoComplete="bankName"
              error={errors.bankName}
              value={bankName}
              onChange={(e) => this.handleChange('bankName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="bankAccountHolderName"
              name="bankAccountHolderName"
              label={t(messages.yourBankAccountHolderName.i18nKey, messages.yourBankAccountHolderName.defaults)}
              fullWidth
              autoComplete="bankAccountHolderName"
              error={errors.bankAccountHolderName}
              value={bankAccountHolderName}
              onChange={(e) => this.handleChange('bankAccountHolderName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="iban"
              name="iban"
              label={t(messages.yourIbanAccountNumber.i18nKey, messages.yourIbanAccountNumber.defaults)}
              fullWidth
              autoComplete="iban"
              error={!!errors.iban}
              value={iban}
              onChange={(e) => this.handleChange('iban', e.target.value)}
              inputProps={{style: {textTransform: 'uppercase'}}}
            />
            {get(errors, 'iban') === 'validation' &&
              <FormHelperText className={classes.error}><Trans {...messages.ibanValidation} /></FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="swiftCode"
              name="swiftCode"
              label={t(messages.yourSwiftBic.i18nKey, messages.yourSwiftBic.defaults)}
              fullWidth
              autoComplete="swiftCode"
              error={!!errors.swiftCode}
              value={swiftCode}
              onChange={(e) => this.handleChange('swiftCode', e.target.value)}
            />
            {(get(errors, 'swiftCode') === 'validation' || errors.ibanSwiftNotCoherent) &&
              <FormHelperText className={classes.error}>
                <Trans {...messages[errors.ibanSwiftNotCoherent ? 'ibanSwiftNotCoherent' : 'swiftCodeValidation']} /></FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              id='loadingButton'
              onClick={() => this.handleSubmit()}
              disabled={loading}
              status={status}
            ><Trans {...messages.submit} />
            </LoadingButton>
            {status === 'failure' && <FormHelperText className={classnames(classes.helperText, classes.error)}>
              {submitMessageError}</FormHelperText>}
          </Grid>
        </Grid> : <Grid container spacing={3}>
          {depositVendors[paymentProvider].signatureRequired ?  <Grid container spacing={3}>
            <Grid item xs={12}>
              {documentSigned && <NotificationBar status="success">
                <Trans {...messages.bankTransferJMFinancialAcknowledgmentText}/>
              </NotificationBar>}
              {!documentSigned && <NotificationBar status="success">
                <Trans {...messages.bankTransferJMFinancialText}/>
              </NotificationBar>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.title}>
                <Trans {...messages.bankTransfer} />
              </Typography>
              {!showBankDetails && <Typography variant="body1">
                <Trans {...messages.bankTransferSignText} />
              </Typography>}
              {!showBankDetails && <Button
                disableFocusRipple
                disableRipple
                id='signNowButton'
                onClick={() => this.eSignDocumentAction(paymentProvider)}
                variant="text"
                color="primary"
                className={classes.signNowButton}
              >
                <span>
                  <img src={Images['sign-up.png']} alt={'signNow'} className={classes.signNowImg}/>
                  <Trans {...messages.signNow} />
                </span>

              </Button>}
              {showExecutionError && <FormHelperText className={classnames(classes.helperText, classes.error)}>
                <Trans {...messages.documentExecutionError} />
              </FormHelperText>}
            </Grid>
          </Grid> : <Grid item xs={12}>
            <NotificationBar status="success">
              <Trans {...messages.submittedWireDetails}
                components={[<span className={classes.bold}>access your personal bank\'s payment facility directly (either via online banking or in person)</span>]} />
            </NotificationBar>
            <NotificationBar status="warning" noMargin>
              <Typography variant="body1">
                <Icon className={classes.warningIcon}> warning </Icon>
                <Trans {...messages[get(bankDetails, 'reference') ? 'submittedWireDetailsWarningAdditionalReference' : 'submittedWireDetailsWarningAdditional']} />
              </Typography>
            </NotificationBar>
          </Grid>}
          {!depositVendors[paymentProvider].signatureRequired && <Grid item xs={12}>
            <Typography variant="h3" className={classnames(classes.title, (currencyCheck) ? classes.boldText : '')}>
              {(currencyCheck)
                ?<Trans
                  {...messages.bankTransferTitle2}
                />
                :<Trans
                  {...messages.bankTransferTitle}
                  values={{company: companyBrandLabel}}
                />}
            </Typography>
            <Typography variant="body1" className={classnames(classes.greyText, classes.noteText)}>
              <Trans {...messages.wireTransferNote} />
            </Typography>
          </Grid>}
          {showBankDetails && <Grid item xs={12}>
            <PDFDownloadLink document={<BankTransferDetailsPDF transferInfo={bankTranferInfo || {}} clientId={clientId} accountId={accountId}/>} fileName={`${companyBrandLabel}-banktransferdetails.pdf`}>
              {({blob, url, loading, error}) => (loading ? 'Loading document...' : <Typography variant={'body2'} color="primary">
                <span>
                  <img src={Images['downloadPdf.png']} alt={'downloadPdf'} className={classes.pdfImg}/>
                  <Trans {...messages.downloadPdf} />
                </span>
              </Typography>
              )}
            </PDFDownloadLink>
          </Grid>}
          {showBankDetails && <Grid item xs={12}>
            <Typography variant="body1" className={classes.greyText}><Trans {...messages.referenceNumber} /></Typography>
            {get(bankDetails, 'reference') && <Typography variant="body1">{bankDetails.reference}</Typography>}
            <Typography variant="body1">{`${clientId}-${accountId}`}</Typography>
          </Grid>}
          {showBankDetails && map(omit(bankTranferInfo, ['__typename', 'clientAccountCurrency', 'reference']), (details, key) => {
            const title = transferInfoOrderedKeyMessageMap[key]
            const translatedTitle = isString(title) ? title : <Trans {...title} />
            return <Grid item xs={12} key={key}>
              <Typography variant="body1" className={classes.greyText}>{translatedTitle}</Typography>
              <Typography variant="body1">{details}</Typography>
            </Grid>
          })}
        </Grid>}
        {showPopup && <FullScreenDialog
          desktopOnly
          fullScreen
          open={true}
          onClose={() => this.eSignDocumentConfirmed(paymentProvider)}
        >
          <PageTitle modal onBack={() => this.eSignDocumentConfirmed(paymentProvider)}><Trans {...messages.signNow} /></PageTitle>
          <React.Fragment>
            {iFrameUrl && <iframe
              src={iFrameUrl}
              frameBorder="0"
              id="tradeCompanion"
              title="tradeCompanion"
              className={classes.iframe}
            />}
          </React.Fragment>
        </FullScreenDialog>
        }
      </Grid>
    )
  }
}

export default compose(
  withStyles(style, {withTheme: true}),
  withNamespaces(),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(BEGIN_DEPOSIT_MUTATION, {
    name: 'beginDeposit',
  }),
  graphql(JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY, {
    options: (props:any) => ({variables: {accountId: get(props.account, 'id')}}),
    props: ({data: {error: errorBankDetails, loading: loadingBankDetails}, data}:any) => {
      const getJMFinancialBankTransferDetails = get(data, 'getJMFinancialBankTransferDetails')
      return {
        errorBankDetails,
        loadingBankDetails,
        getJMFinancialBankTransferDetails : omit(getJMFinancialBankTransferDetails,'__typename'),
      }
    }
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error: documentsError, loading: documentsLoading}, data}:any) => ({
      documentsError,
      documentsLoading,
      documents: get(data, 'viewer.documents', []),
    })
  }),
)(BankWireTransferProvider)
