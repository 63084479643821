export const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.light,
  },
  label: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  button: {
    display: 'flex',
    gap: '10px',
    marginLeft: 'auto',
  },
})
