export const styles = () => ({
  input: {
    display: 'none'
  },
  button: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    backgroundColor: '#F5FBFF',
    color: '#4388FF',
    borderRadius: 0,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none'
    }
  },
}) as const
