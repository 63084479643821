import {ReactElement, useContext} from 'react'
import {WithStyles, withStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

import Amount from '../../../../../Common/Amount'
import {Account} from '../../../../../../graphql/types'
import messages from '../../../../../../assets/messages'
import {Trans} from 'react-i18next'
import AppContext from '../../../../../Common/contexts/AppContext'
import {getAccountType} from '../../../../AccountCard/utils'
import {isApprovedAccount, getAccountLabel, getAccountProperty} from '../../../../../../common/utils/accounts'
import {styles} from './styles'

type PammManagerAccountCardBaseProps = WithStyles & {
  account: Account,
  pammFeesAccount: Account | undefined,
  pammTradesAccount: Account  | undefined,
  onViewOffersClick: (event: React.MouseEvent<HTMLDivElement>) => void,
  onViewTradesClick: (event: React.MouseEvent<HTMLDivElement>) => void,
  onViewFeesClick: (event: React.MouseEvent<HTMLDivElement>) => void,
}

function PammManagerAccountCardBase(props: PammManagerAccountCardBaseProps): ReactElement {
  const {classes, account, onViewOffersClick, pammFeesAccount, pammTradesAccount, onViewTradesClick, onViewFeesClick} = props
  const {locale} = useContext(AppContext)
  const {balance, remoteId} = account
  const accountUnconnected = getAccountProperty(account, 'unconnected') ?? undefined

  const pammFeesAccountBalance = (pammFeesAccount && getAccountProperty(pammFeesAccount, 'balance')) ?? 0
  const pammFeesAccountCurrency = (pammFeesAccount && getAccountProperty(pammFeesAccount, 'currency')) ?? undefined

  const pammTradesAccountBalance = (pammTradesAccount && getAccountProperty(pammTradesAccount, 'balance')) ?? 0
  const pammTradesAccountCurrency = (pammTradesAccount && getAccountProperty(pammTradesAccount, 'currency')) ?? undefined

  const accountType = getAccountType(account)
  const shortLabel = accountType.shortLabel
  const label = getAccountLabel(account, locale)
  const isUnapproved = !isApprovedAccount(account)
  const isDepositNeeded = accountUnconnected && balance === 0
  const isDisabled = isUnapproved || accountUnconnected || isDepositNeeded

  return (
    <Card classes={{root: cn(classes.cardRoot, {[classes.pendingCard]: isDisabled})}} onClick={onViewOffersClick}>
      <Grid container>
        <Grid item>
          <div className={cn(classes.typeBadge)}>
            {shortLabel}
          </div>
        </Grid>
        <Grid item className={classes.cardContent}>
          <Typography variant='subtitle1'>{label}</Typography>
          <Typography variant='caption'>{remoteId}</Typography>
        </Grid>
        <Grid item className={classes.accountInfo}>
          {isUnapproved ? (
            <Grid item className={cn(classes.pending, classes.badge)}>
              <Typography variant='caption' className={classes.pendingText}><Trans {...messages.pendingApproval} /></Typography>
            </Grid>
          ) : null}
          <div className={classes.link} data-testid='pamm-manager-account-view-offers'>
            <Trans {...messages.viewOffers} />
          </div>
        </Grid>
      </Grid>
      <div className={classes.accountsInfo}>
        <Grid container>
          <Grid item className={classes.underlineContainer}>
            <div className={classes.underline}/>
          </Grid>
        </Grid>
        <Grid container className={classes.relatedAccountInfo}>
          <Grid item xs={8}>
            <Trans {...messages.pammTradingAccount} />
          </Grid>
          <Grid item xs={3}>
            <Amount value={pammTradesAccountBalance} currency={pammTradesAccountCurrency} locale='en-US' />
          </Grid>
          <Grid item xs={1}>
            <div className={classes.link} onClick={onViewTradesClick}><Trans {...messages.view} /></div>
          </Grid>
        </Grid>
        <Grid container className={classes.relatedAccountInfo}>
          <Grid item xs={8}>
            <Trans {...messages.pammFeesAccount} />
          </Grid>
          <Grid item xs={3}>
            <Amount value={pammFeesAccountBalance} currency={pammFeesAccountCurrency} locale='en-US' />
          </Grid>
          <Grid item xs={1}>
            <div className={classes.link} onClick={onViewFeesClick}><Trans {...messages.view} /></div>
          </Grid>
        </Grid>
      </div>
    </Card>
  )
}

const PammManagerAccountCard = withStyles(styles)(PammManagerAccountCardBase)
export {PammManagerAccountCard}
