import {ReactElement, ReactNode} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import compose from 'lodash/flowRight'
import {withNamespaces, Trans} from 'react-i18next'
import {economicProfileQuestions} from '@bdswiss/common-enums'

import messages from '../../../../../assets/messages'
import {styles} from './styles'
import {FieldsSection} from '../FieldsSection'

type FinancialBackgroundAndResourcesProps = {
  renderQuestion: (question) => ReactNode
} & WithStyles

function FinancialBackgroundAndResourcesBase(props: FinancialBackgroundAndResourcesProps): ReactElement {
  const {classes, renderQuestion} = props

  return (
    <FieldsSection title={<Trans {...messages.financialBackgroundAndResources} />}>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.employmentStatus)}
        {renderQuestion(economicProfileQuestions.jobTitle)}
      </div>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.approxNetWorth)}
        {renderQuestion(economicProfileQuestions.sourceOfFunds)}
      </div>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.initialIntendedContribution)}
        {renderQuestion(economicProfileQuestions.sourceOfFundsClarify)}
      </div>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.approxYearlyIncome)}
      </div>
    </FieldsSection>
  )
}

const FinancialBackgroundAndResources = compose(withStyles(styles), withNamespaces())(FinancialBackgroundAndResourcesBase)
export {FinancialBackgroundAndResources}
