import {Theme} from '@material-ui/core'

export const styles = (theme: Theme) => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative' as const,
    height: '100%'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
    backgroundColor:  theme.palette.lightred.color,
    color: theme.palette.red.color,
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 35,
      lineHeight: '37px',
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 14,
    },
  },
  badge:{
    borderRadius: '5px',
    padding: '0px 7px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']:4
  },
  badgesBox: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '25px',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      top: 5,
    },
  },
  viewOnly:{
    backgroundColor: theme.palette.secondary.main,
  },
  viewOnlyText:{
    color: theme.palette.secondary.light,
    opacity: 1,
  },
  pendingText: {
    color: '#73738C',
  },
  pending: {
    backgroundColor: '#F1F5F9',
    marginBottom: '10px',
  },
  pendingCard: {
    backgroundColor: 'transparent',
    border: '1px solid #f1f5f9',
    boxShadow: 'unset',
  },
  linkContainer: {
    marginLeft: 'auto',
  },
  link: {
    color: '#007AFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 'auto',
    textAlign: 'end',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  accountInfo: {
    marginLeft: 'auto'
  },
  relatedAccountInfo: {
    marginBottom: '2px',
  },
  accountsInfo: {
    marginLeft: '60px',
  },
  underlineContainer: {
    width: '100%',
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    margin: '10px 0 10px 0',
  },
}) as const
