import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import PropTypes from 'prop-types'
import {map, isEmpty, get, filter, head, size} from 'lodash'
import {allocationMethodTypes, copyByPercentageValues} from '@bdswiss/common-enums'
import {FormControl, InputLabel, ListItemText, MenuItem, Select, Typography} from '@material-ui/core'
import AppContext from '../../../Common/contexts/AppContext'
import messages from '../../../../assets/messages'
import {AlertDialog} from '../../../Common/Dialog'
import Password from '../../../Common/Password'
import {InnerAppContext} from '../../../../common/types'

const styles = theme => ({
  alertTitle: {
    fontSize: 24
  },
  highlight: {
    color: theme.palette.green.color,
  },
  standardHighlight: {
    fontWeight: 400
  },
  emptySelect:{
    marginTop:7
  }
})

class StrategyAlertDialog extends Component<any,any> {
  static propTypes = {
    strategy: PropTypes.object,
    onClick: PropTypes.func,
  }
  static contextType = AppContext
  context!: InnerAppContext

  constructor(props) {
    super(props)
    const {strategy: {copyTradingProvider}} = props
    const validAllocationTypes = filter(allocationMethodTypes, (t) => t.providerTypes.includes(copyTradingProvider))
    this.state = {
      method: head(validAllocationTypes)!.key,
      percentage: '',
      errorPercentage: false,
      validAllocationTypes,
    }
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  submitRequest() {
    const {onClick} = this.props
    const {method, percentage} = this.state
    const validValues = ((method === allocationMethodTypes.copyByPercentage.key) && !isEmpty(percentage)) ||
    (method === allocationMethodTypes.proportionCopy.key)

    const percentageValue = method === allocationMethodTypes.copyByPercentage.key && percentage
       && copyByPercentageValues[percentage].label
    if (validValues) {
      onClick && onClick(allocationMethodTypes[method].label, percentageValue ? percentageValue: '')
    } else {
      this.setState({errorPercentage: true})
    }
  }


  renderProtectedStrategy() {
    const {classes, password, showPassword} = this.props

    return  <Grid container>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <Trans {...messages.passwordProtectedStrategy} />
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <Password
          classes={classes}
          showPassword={showPassword}
          onChange={(e) => this.props.handleChangeForm('password', e.target.value)}
          onClickShow={() => this.props.onClickShow()}
          errorText={<Trans {...messages.requiredField} />}
          value={password}
        />
      </Grid>
    </Grid>
  }


  render() {
    const {classes, open, close, t, status, errorMessage, loading, strategy, passwordApproved, onClick, password} = this.props
    const {method, percentage, validAllocationTypes} = this.state
    const showError = !isEmpty(errorMessage) ? errorMessage :
      t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults)
    const fee = get(strategy, 'performanceFee')
    const showAllocationMethods = size(filter(allocationMethodTypes, (a) => !a.disabled)) > 1
    const passwordProtected = get(strategy, 'protectedPassword') && !passwordApproved

    return <Grid container>
      <AlertDialog
        open={open}
        onAgree={() => (passwordProtected) ? onClick && onClick(password) : this.submitRequest()}
        onDisagree={close}
        disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
        agreeText={t(messages[(passwordProtected) ? 'submit' : 'startCopying'].i18nKey, messages[(passwordProtected) ? 'submit' : 'startCopying'].defaults)}
        title = { <Typography variant='body1' className={classes.alertTitle}>
          <Trans
            {...messages.requestCopyTradingTitle}
            components={[
              <span  className={classes.highlight}>start copying</span>,
            ]}
          />
        </Typography>}
        buttonLoading={loading || status === 'success'}
        buttonStatus={status}
        errorText={status === 'failure' ? showError : ''}
        buttonDisabled={passwordProtected && !password}
        hideProgressBar={passwordProtected && !password}
      >
        {(passwordProtected) ? this.renderProtectedStrategy()
          : <Grid container direction="row" alignContent="center" justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.requestCopyTradingText} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.requestCopyTradingBonusLossNotificationText} />
              </Typography>
            </Grid>
            {fee > 0 && <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.requestCopyTradingText2} values={{fee}} components ={[<span className={classes.standardHighlight}>fee</span>]} />
              </Typography>
            </Grid>}
            {showAllocationMethods && <Grid item xs={12}>
              <Grid container direction="row" alignContent="center" justifyContent="center" spacing={4}>
                <Grid item xs={method === allocationMethodTypes.copyByPercentage.key ? 6 : 12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-simple"><Trans {...messages.allocationMethodLabel} /></InputLabel>
                    <Select
                      value={method}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'method',
                        id: 'method-simple',
                      }}
                    >
                      {map(validAllocationTypes, (option) => (
                        <MenuItem key={option.key} value={option.key}>
                          <ListItemText primary={option.localization.t(this.context.locale)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {method === allocationMethodTypes.copyByPercentage.key && <Grid item xs={12} md={6}>
                  <FormControl fullWidth className={isEmpty(percentage) ? classes.emptySelect : ''}>
                    <InputLabel htmlFor="percentage-simple" required><Trans {...messages.percentageLabel} /></InputLabel>
                    <Select
                      value={percentage}
                      required
                      error={this.state.errorPercentage}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'percentage',
                        id: 'percentage-simple',
                      }}
                    >
                      {map(copyByPercentageValues, (option) => (
                        <MenuItem key={option.key} value={option.key}>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>}
              </Grid>
            </Grid>}
            {fee === 0 && <Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.requestCopyTradingNoPerformanceFeeText} />
              </Typography>
            </Grid>}
          </Grid>}
      </AlertDialog>
    </Grid>
  }
}


export default withStyles(styles, {withTheme: true})(withNamespaces()(StrategyAlertDialog))
