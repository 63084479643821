import {ReactElement} from 'react'
import cn from 'classnames'

import {DOCUMENT_STATUSES} from '../constants'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import TimerOutlined from '@material-ui/icons/TimerOutlined'
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined'
import {withStyles, WithStyles} from '@material-ui/core/styles'

import {styles} from './styles'

type StatusIconProps = {
  status: typeof DOCUMENT_STATUSES[keyof typeof DOCUMENT_STATUSES] | null | undefined
} & WithStyles

function StatusIconBase(props: StatusIconProps): ReactElement {
  const {status, classes} = props

  switch (status) {
    case DOCUMENT_STATUSES.rejected:
      return <InfoOutlined color='error' className={classes.statusIcon} />
    case DOCUMENT_STATUSES.pending:
      return <TimerOutlined className={cn([classes.statusIcon, classes.pendingIcon])} />
    case DOCUMENT_STATUSES.verified:
      return <CheckCircleOutlined className={cn([classes.statusIcon, classes.doneIcon])} />
    default:
      return <InfoOutlined color='error' />
  }
}

const StatusIcon = withStyles(styles)(StatusIconBase)

export {StatusIcon}
