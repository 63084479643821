import {ReactElement, ReactNode} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import compose from 'lodash/flowRight'
import {withNamespaces, Trans} from 'react-i18next'
import {economicProfileQuestions} from '@bdswiss/common-enums'

import messages from '../../../../../assets/messages'
import {styles} from './styles'
import {FieldsSection} from '../FieldsSection'

type TaxInformationProps = {
  renderQuestion: (question) => ReactNode
} & WithStyles

function TaxInformationBase(props: TaxInformationProps): ReactElement {
  const {classes, renderQuestion} = props

  return (
    <FieldsSection title={<Trans {...messages.taxInformation} />}>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.usCitizen)}
        {renderQuestion(economicProfileQuestions.taxJurisdictionCountry)}
        {renderQuestion(economicProfileQuestions.tinReason)}
      </div>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.tin)}
        {renderQuestion(economicProfileQuestions.tinClarify)}
      </div>
    </FieldsSection>
  )
}

const TaxInformation = compose(withStyles(styles), withNamespaces())(TaxInformationBase)
export {TaxInformation}
