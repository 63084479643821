import {ReactElement, ReactNode} from 'react'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import {Trans} from 'react-i18next'
import {economicProfileQuestions} from '@bdswiss/common-enums'

import messages from '../../../../../assets/messages'
import {FieldsSection} from '../FieldsSection'
import {styles} from './styles'

type PoliticalExposedPersonStatusProps = {
  renderQuestion: (question) => ReactNode
} & WithStyles

function PoliticalExposedPersonStatusBase(props: PoliticalExposedPersonStatusProps): ReactElement {
  const {classes, renderQuestion} = props

  return (
    <FieldsSection title={<Trans {...messages.politicallyExposedPersonStatus} />}>
      <div className={classes.row}>
        {renderQuestion(economicProfileQuestions.politicallyExposed)}
      </div>
    </FieldsSection>
  )
}

const PoliticalExposedPersonStatus = withStyles(styles)(PoliticalExposedPersonStatusBase)
export {PoliticalExposedPersonStatus}
