import {ICompanyEnum} from '@bdswiss/common-enums'
import {useReducer} from 'react'
import {AccountCardProps} from '../types'
import {PammManagerUnapprovedPopup} from './components/Popups/PammManagerUnapprovedPopup'
import {reducer} from './reducer'
import {ActionType} from './actions'
import {initialState} from './initialState'
import {PammManagerAccountCard} from './PammManagerAccountCard'
import {useHistory} from 'react-router-dom'
import {isApprovedAccount} from '../../../../../common/utils/accounts'
import {Account} from '../../../../../graphql/types'
import {getMetadataFromAccount} from '../../../AccountCard/utils'

type PammTauroMarketsManagerAccountCardProps = AccountCardProps & {
  company: ICompanyEnum,
  restAccounts: Account[]
}

export const PammTauroMarketsManagerAccountCard: React.FC<PammTauroMarketsManagerAccountCardProps> = ({account, restAccounts, company}) => {
  const [{showPammManagerUnapprovedPopup}, dispatch] = useReducer(reducer, {...initialState, account})
  const history = useHistory()

  const handleViewOffersClick = () => {
    if (account != null && isApprovedAccount(account)) {
      history.push(`/accounts/pammManagerSettings/${account.id}`)
    } else {
      dispatch({type: ActionType.Click})
    }
  }

  const closeModals = () => {
    dispatch({type: ActionType.CloseModal})
  }

  const pammFeesAccount = restAccounts.find((restAccount) => {
    const metadata = getMetadataFromAccount(restAccount)
    return metadata.pammFeesAccount === account.id
  })

  const pammTradesAccount = restAccounts.find((restAccount) => {
    const metadata = getMetadataFromAccount(restAccount)
    return metadata.pammTradesAccount === account.id
  })

  const hanldeTradesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (!pammTradesAccount) return

    history.push(`/accounts/${pammTradesAccount.id}`)
  }

  const hanldeFeesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (!pammFeesAccount) return

    history.push(`/accounts/${pammFeesAccount.id}`)
  }

  return (
    <div data-testid='pamm-tauro-markets-manager-account-card'>
      <PammManagerAccountCard
        account={account}
        pammFeesAccount={pammFeesAccount}
        pammTradesAccount={pammTradesAccount}
        onViewOffersClick={handleViewOffersClick}
        onViewTradesClick={hanldeTradesClick}
        onViewFeesClick={hanldeFeesClick}
      />
      <PammManagerUnapprovedPopup
        company={company}
        onAgree={closeModals}
        onClose={closeModals}
        open={showPammManagerUnapprovedPopup}
      />
    </div>
  )

}
