import get from 'lodash/get'

import {FormFields} from './types'

function applyPendingChanges(client, changes): FormFields {
  return {
    transactionPurpose: get(changes, 'globalQuestionnaire.transactionPurpose') ||
      get(client, 'globalQuestionnaire.transactionPurpose') || '',
    jobTitle: get(changes, 'globalQuestionnaire.jobTitle') ||
      get(client, 'globalQuestionnaire.jobTitle') || '',
    approxYearlyIncome: get(changes, 'globalQuestionnaire.approxYearlyIncome') ||
      get(client, 'globalQuestionnaire.approxYearlyIncome') || '',
    approxNetWorth: get(changes, 'globalQuestionnaire.approxNetWorth') ||
      get(client, 'globalQuestionnaire.approxNetWorth') || '',
    approxExpectedDeposit: get(changes, 'globalQuestionnaire.approxExpectedDeposit') ||
      get(client, 'globalQuestionnaire.approxExpectedDeposit') || '',
    taxJurisdictionCountry: get(changes, 'globalQuestionnaire.taxJurisdictionCountry') ||
      get(client, 'globalQuestionnaire.taxJurisdictionCountry') || '',
    sourceOfFunds: get(changes, 'globalQuestionnaire.sourceOfFunds') ||
      get(client, 'globalQuestionnaire.sourceOfFunds') || '',
    natureOfTransactions: get(changes, 'globalQuestionnaire.natureOfTransactions') ||
      get(client, 'globalQuestionnaire.natureOfTransactions') || '',
    originOfFunds: get(changes, 'globalQuestionnaire.originOfFunds') ||
      get(client, 'globalQuestionnaire.originOfFunds') || '',
    politicallyExposed: get(changes, 'globalQuestionnaire.politicallyExposed') ||
      get(client, 'globalQuestionnaire.politicallyExposed') || '',
    usCitizen: get(changes, 'globalQuestionnaire.usCitizen') ||
      get(client, 'globalQuestionnaire.usCitizen') || '',
    tinReason: get(changes, 'globalQuestionnaire.tinReason') ||
      get(client, 'globalQuestionnaire.tinReason') || '',
    tin: get(changes, 'globalQuestionnaire.tin') ||
    get(client, 'globalQuestionnaire.tin') || '',
    tinClarify: get(changes, 'globalQuestionnaire.tinClarify') ||
    get(client, 'globalQuestionnaire.tinClarify') || '',
    transactionPurposeClarify: get(changes, 'globalQuestionnaire.transactionPurposeClarify') ||
    get(client, 'globalQuestionnaire.transactionPurposeClarify') || '',
    natureOfTransactionsClarify: get(changes, 'globalQuestionnaire.natureOfTransactionsClarify') ||
    get(client, 'globalQuestionnaire.natureOfTransactionsClarify') || '',
    sourceOfFundsClarify: get(changes, 'globalQuestionnaire.sourceOfFundsClarify') ||
    get(client, 'globalQuestionnaire.sourceOfFundsClarify') || '',
    politicallyExposedReason: get(changes, 'globalQuestionnaire.politicallyExposedReason') ||
      get(client, 'globalQuestionnaire.politicallyExposedReason') || '',
    nomineeStatus: get(changes, 'globalQuestionnaire.nomineeStatus') ||
      get(client, 'globalQuestionnaire.nomineeStatus') || '',
    commercialRationaleForBusinessRelationship: get(changes, 'globalQuestionnaire.commercialRationaleForBusinessRelationship') ||
      get(client, 'globalQuestionnaire.commercialRationaleForBusinessRelationship') || '',
    riskToleranceForCfd: get(changes, 'globalQuestionnaire.riskToleranceForCfd') ||
      get(client, 'globalQuestionnaire.riskToleranceForCfd') || '',
    employmentStatus: get(changes, 'globalQuestionnaire.employmentStatus') ||
      get(client, 'globalQuestionnaire.employmentStatus') || '',
    initialIntendedContribution: get(changes, 'globalQuestionnaire.initialIntendedContribution') ||
      get(client, 'globalQuestionnaire.initialIntendedContribution') || '',
    relevantTradingEducationExperience: get(changes, 'globalQuestionnaire.relevantTradingEducationExperience') ||
      get(client, 'globalQuestionnaire.relevantTradingEducationExperience') || '',
    riskAwarenessOfCfdTrading: get(changes, 'globalQuestionnaire.riskAwarenessOfCfdTrading') ||
      get(client, 'globalQuestionnaire.riskAwarenessOfCfdTrading') || '',
    identityOfTheThirdParty: get(client, 'economicProfileDocs.identityOfTheThirdParty'),
    proofsOfIdentityFiamlRegulations: get(client, 'economicProfileDocs.proofsOfIdentityFiamlRegulations'),
    relationshipThirdParty: get(client, 'economicProfileDocs.relationshipThirdParty'),
  }
}

export {applyPendingChanges}
