export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    margin: '30px 30px 50px 0',

    [theme.breakpoints.down('sm')]: {
      margin: '15px 0 15px 0',
      padding: '0 30px 0 30px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}) as const
