const {nationalities} = require('@bdswiss/common-enums')

/* eslint-disable quotes */
module.exports = {
  email: {
    i18nKey: 'email',
    defaults: 'Email'
  },
  password: {
    i18nKey: 'password',
    defaults: 'Password'
  },
  login: {
    i18nKey: 'login',
    defaults: 'Login'
  },
  loginButton: {
    i18nKey: 'loginButton',
    defaults: 'Login'
  },
  signUp: {
    i18nKey: 'signUp',
    defaults: 'No account? Signup'
  },
  forgotPassword: {
    i18nKey: 'forgotPassword',
    defaults: 'Forgot Password?'
  },
  facebook: {
    i18nKey: 'facebook',
    defaults: 'Facebook'
  },
  recipient: {
    i18nKey: 'recipient',
    defaults: 'Recipient / Crediting / Beneficiary Account name'
  },
  bankName: {
    i18nKey: 'bankName',
    defaults: 'Bank Name'
  },
  bank: {
    i18nKey: 'bank',
    defaults: 'Bank'
  },
  cardType: {
    i18nKey: 'cardType',
    defaults: 'Payment Card Type'
  },
  personalIdentificationNumber: {
    i18nKey: 'personalIdentificationNumber',
    defaults: 'Personal ID Number'
  },
  personalInformation: {
    i18nKey: 'personalInformation',
    defaults: 'Personal Information'
  },
  enterPaymentDetails: {
    i18nKey: 'enterPaymentDetails',
    defaults: 'Enter payment Details'
  },
  providerUnavailable: {
    i18nKey: 'providerUnavailable',
    defaults: 'Provider is temporarily unavailable, please choose another'
  },
  address: {
    i18nKey: 'address',
    defaults: 'Address'
  },
  cityName: {
    i18nKey: 'cityName',
    defaults: 'City Name'
  },
  countryName: {
    i18nKey: 'countryName',
    defaults: 'Country Name'
  },
  referenceNumber: {
    i18nKey: 'referenceNumber',
    defaults: 'Reference Number'
  },
  cardNumber: {
    i18nKey: 'cardNumber',
    defaults: 'Card Number'
  },
  expiryMonth: {
    i18nKey: 'expiryMonth',
    defaults: 'Expiry Month'
  },
  expiryYear: {
    i18nKey: 'expiryYear',
    defaults: 'Expiry Year'
  },
  invalidCardNumber: {
    i18nKey: 'invalidCardNumber',
    defaults: 'Card number is invalid'
  },
  invalidValue: {
    i18nKey: 'invalidValue',
    defaults: 'Value supplied is invalid'
  },
  cvv: {
    i18nKey: 'cvv',
    defaults: 'CVV'
  },
  enterCardDetails: {
    i18nKey: 'enterCardDetails',
    defaults: 'Enter Card Details'
  },
  walletResponsibilityConfirmation: {
    i18nKey: 'walletResponsibilityConfirmation',
    defaults: 'I acknowledge that providing an incorrect wallet address may result in the permanent loss of my funds, and that {{ company }} will not be held liable for any such loss'
  },
  wireTransferNote: {
    i18nKey: 'wireTransferNote',
    defaults: 'Please fill in all the transfer information exactly as shown below to avoid funds being returned.'
  },
  cryptoDepositNote1: {
    i18nKey: 'cryptoDepositNote1',
    defaults: 'Please only fund your {{company}} account using your private wallet from providers such as Blockchain.info, jaxx, electrum, myetherwallet etc.'
  },
  cryptoDepositNote2: {
    i18nKey: 'cryptoDepositNote2',
    defaults: 'Never use an exchange wallet as we will not be able to process your withdrawals back to the exchange wallet.'
  },
  cryptoDepositNote3: {
    i18nKey: 'cryptoDepositNote3',
    defaults: 'Please ensure to provide us with the sending BTC/ETH/BCH address, to avoid delays upon making a withdrawal, by sending the wallet address to funding@{{emailDomain}}.'
  },
  cryptoDepositNote4: {
    i18nKey: 'cryptoDepositNote4',
    defaults: 'When making a withdrawal you will need to provide us with the same wallet address that your deposit/s came from, otherwise your withdrawal will be rejected!'
  },
  cryptoDepositNote5: {
    i18nKey: 'cryptoDepositNote5',
    defaults: "Make sure you've secured the private keys of your wallet. Should you lose access to the wallet (phone breaks, gets stolen, gets lost), you will always be able to restore the wallet back on other device and your funds only by having the private key."
  },
  copyCryptoWalletAddress: {
    i18nKey: 'copyCryptoWalletAddress',
    defaults: 'Please copy the one time use {{walletType}} address below before proceeding to any futher action, as the address would be lost upon your next action'
  },
  cryptoWalletAddressCopied: {
    i18nKey: 'cryptoWalletAddressCopied',
    defaults: 'Address copied, please proceed with a transfer from your {{walletType}} wallet account to the {{company}} wallet account',
  },
  belowWalletAddressMessage: {
    i18nKey: 'belowWalletAddressMessage',
    defaults: 'The {{walletType}} address is only valid for one time use; a new address should be used for each deposit.',
  },
  depositResultSuccess: {
    i18nKey: 'depositSuccess',
    defaults: 'Your deposit is successful and account has been credited!'
  },
  depositResultSuccessWithAmount: {
    i18nKey: 'depositResultSuccessWithAmount',
    defaults: 'Your deposit of <0>{{amount}}</0> is successful and account has been credited!'
  },
  depositResultError: {
    i18nKey: 'depositResultError',
    defaults: 'Your deposit attempt failed, please try again!'
  },
  depositResultCancelled: {
    i18nKey: 'depositResultCancelled',
    defaults: 'Your deposit attempt has been cancelled!'
  },
  depositResultErrorWithAmount: {
    i18nKey: 'depositResultErrorWithAmount',
    defaults: 'Your deposit attempt of <0>{{amount}}</0> failed, please try again!'
  },
  depositResultPending: {
    i18nKey: 'depositResultPending',
    defaults: "Your deposit is pending, as further action is required from your side. For more information, please check your Dashboard and email"
  },
  depositResultPendingWithAmount: {
    i18nKey: 'depositResultPendingWithAmount',
    defaults: "Your deposit of <0>{{amount}}</0> is pending, as further action is required from your side. For more information, please check your Dashboard and email"
  },
  depositErrorRetry: {
    i18nKey: 'depositErrorRetry',
    defaults: "Something went wrong while processing your deposit, please try again using another method"
  },
  paymentMethodKycRequired: {
    i18nKey: 'paymentMethodKycRequired',
    defaults: "The current payment method is available only to fully KYC verified clients. Please proceed with another payment option or with the verification of your account to be able to continue. "
  },
  getCryptoWalletAddress: {
    i18nKey: 'getCryptoWalletAddress',
    defaults: 'Get Wallet Address to Deposit'
  },
  importantNotes: {
    i18nKey: 'importantNotes',
    defaults: 'Important Notes'
  },
  paymentMethodUnavailable: {
    i18nKey: 'paymentMethodUnavailable',
    defaults: 'This payment method is temporarily unavailable, please select another'
  },
  google: {
    i18nKey: 'google',
    defaults: 'Google'
  },
  publishedOn: {
    i18nKey: 'publishedOn',
    defaults: 'Published on <0>{{date}}</0>'
  },
  noNotification: {
    i18nKey: 'noNotification',
    defaults: 'You do not have any notifications available.'
  },
  emailOrPassword: {
    i18nKey: 'emailOrPassword',
    defaults: 'Or use your email and password'
  },
  selectAccount: {
    i18nKey: 'selectAccount',
    defaults: 'Select Account'
  },
  selectAccountToTopUp: {
    i18nKey: 'selectAccountToTopUp',
    defaults: 'Select account to top-up'
  },
  selectDepositAmount: {
    i18nKey: 'selectDepositAmount',
    defaults: 'Select amount to deposit'
  },
  chooseAccount: {
    i18nKey: 'chooseAccount',
    defaults: 'Choose account'
  },
  pageError: {
    i18nKey: 'pageError',
    defaults: 'An Error Occured'
  },
  pageErrorInfo: {
    i18nKey: 'pageErrorInfo',
    defaults: 'An error occured while processing your previous action. Our team has been notified and would be working to resolve this issue. Please try again later and if issue persists, contact support.'
  },
  termsChangeDisclaimer: {
    i18nKey: 'termsChangeDisclaimer',
    defaults: `If you continue using our services and do not take any action to decline the new terms and conditions within {{noticePeriod}} days of the publication of this notice, you are considered to have agreed to them.
      If you disagree with any of the new terms and conditions as presented above, please send an email to support@{{emailDomain}} listing the terms you do not accept, so that we can proceed with your account's closure.`
  },
  contactSupport: {
    i18nKey: 'contactSupport',
    defaults: 'Contact support'
  },
  backToDashboard: {
    i18nKey: 'backToDashboard',
    defaults: 'Back to dashboard'
  },
  other: {
    i18nKey: 'other',
    defaults: 'Other'
  },
  new: {
    i18nKey: 'new',
    defaults: 'New'
  },
  actionSeen: {
    i18nKey: 'actionSeen',
    defaults: 'Seen'
  },
  actionAccepted: {
    i18nKey: 'actionAccepted',
    defaults: 'Accepted'
  },
  actionDismissed: {
    i18nKey: 'actionDismissed',
    defaults: 'Dismissed'
  },
  actionRemindLater: {
    i18nKey: 'actionRemindLater',
    defaults: 'Remind me Later'
  },
  accept: {
    i18nKey: 'accept',
    defaults: 'Accept'
  },
  dismiss: {
    i18nKey: 'dismiss',
    defaults: 'Dismiss'
  },
  requiredField: {
    i18nKey: 'requiredField',
    defaults: 'Required Field'
  },
  noPhone: {
    i18nKey: 'noPhone',
    defaults: 'No phone was provided. Please add a phone to your profile first.',
  },
  optInMarketing: {
    i18nKey: 'optInMarketing',
    defaults: 'I want to receive marketing and promotional material.',
  },
  optInSms: {
    i18nKey: 'optInSms',
    defaults: 'I want to receive SMS messages for deposits, withdrawals and account activity.',
  },
  gdprExportData: {
    i18nKey: 'gdprExportData',
    defaults: 'Export my personal data',
  },
  gdprExportDataSuccess: {
    i18nKey: 'gdprExportDataSuccess',
    defaults: 'We are preparing an archive with your information. You will receive an email with a download link shortly.',
  },
  gdprDeleteData: {
    i18nKey: 'gdprDeleteData',
    defaults: 'Delete my account',
  },
  gdprDeleteDataConfirmTitle: {
    i18nKey: 'gdprDeleteDataConfirmTitle',
    defaults: 'Confirm account deletion',
  },
  delete: {
    i18nKey: 'delete',
    defaults: 'Delete',
  },
  cancel: {
    i18nKey: 'cancel',
    defaults: 'Cancel',
  },
  enterPassword: {
    i18nKey: 'enterPassword',
    defaults: 'Enter your password',
  },
  allAccounts: {
    i18nKey: 'allAccounts',
    defaults: 'All Accounts'
  },
  payments: {
    i18nKey: 'payments',
    defaults: 'Transactions'
  },
  deposit: {
    i18nKey: 'deposit',
    defaults: 'Deposit'
  },
  sendWithdraw: {
    i18nKey: 'sendWithdraw',
    defaults: 'Send / Withdraw'
  },
  transfer: {
    i18nKey: 'transfer',
    defaults: 'Transfer'
  },
  send: {
    i18nKey: 'send',
    defaults: 'Send'
  },
  history: {
    i18nKey: 'history',
    defaults: 'History'
  },
  appropriatenessTest: {
    i18nKey: 'appropriatenessTest',
    defaults: 'Appropriateness Test'
  },
  dueDiligence: {
    i18nKey: 'dueDiligence',
    defaults: 'Due Diligence'
  },
  transferFrom: {
    i18nKey: 'transferFrom',
    defaults: 'Transfer from'
  },
  transferTo: {
    i18nKey: 'transferTo',
    defaults: 'Transfer to'
  },
  transferFromAccount: {
    i18nKey: 'transferFromAccount',
    defaults: 'Transfer from account'
  },
  transferToAccount: {
    i18nKey: 'transferToAccount',
    defaults: 'Transfer to account'
  },
  selectAmount: {
    i18nKey: 'selectAmount',
    defaults: 'Select amount'
  },
  withdrawAccounts: {
    i18nKey: 'withdrawAccounts',
    defaults: 'Select account to withdraw'
  },
  back: {
    i18nKey: 'back',
    defaults: 'Back'
  },
  continue: {
    i18nKey: 'continue',
    defaults: 'Continue'
  },
  none: {
    i18nKey: 'none',
    defaults: 'None'
  },
  noSameTransferAccounts: {
    i18nKey: 'noSameTransferAccounts',
    defaults: 'Transfer From/To accounts cannot be the same!'
  },
  noAccountsSelected: {
    i18nKey: 'noAccountsSelected',
    defaults: 'No accounts selected!'
  },
  noZeroTransferAmount: {
    i18nKey: 'noZeroTransferAmount',
    defaults: 'Transfer amount cannot be negative or 0'
  },
  attention: {
    i18nKey: 'attention',
    defaults: 'Attention'
  },
  uploadDocuments: {
    i18nKey: 'uploadDocuments',
    defaults: 'Upload documents'
  },
  uploadDocument: {
    i18nKey: 'uploadDocument',
    defaults: 'Upload document'
  },
  pendingKYCDocs: {
    i18nKey: 'pendingKYCDocs',
    defaults: 'Withdrawals limited due to pending account verification.'
  },
  reviewingKYCDocs: {
    i18nKey: 'reviewingKYCDocs',
    defaults: 'Your documents are being reviewed'
  },
  withdrawalMethod: {
    i18nKey: 'withdrawalMethod',
    defaults: 'Withdrawal Method'
  },
  withdrawalMessage: {
    i18nKey: 'withdrawalMessage',
    defaults: 'Select method for withdrawal <0>{{amount}}</0> from <1>{{accountLabel}}</1>'
  },
  bankTransferTitle: {
    i18nKey: 'bankTransferTitle',
    defaults: 'Direct bank transfer to Tauro Markets'
  },
  bankTransferTitle2: {
    i18nKey: 'bankTransferTitle2',
    defaults: 'Direct bank transfer ONLY in EURO currency'
  },
  selectDepositMethodForAmount: {
    i18nKey: 'selectDepositMethodForAmount',
    defaults: 'Select payment method for <0>{{amount}}</0>'
  },
  minimumDepositAmount: {
    i18nKey: 'minimumDepositAmount',
    defaults: 'Minimum allowed deposit amount is {{amount}}'
  },
  proceedToPay: {
    i18nKey: 'proceedToPay',
    defaults: 'Proceed to Pay'
  },
  withdraw: {
    i18nKey: 'withdraw',
    defaults: 'Withdraw'
  },
  withdrawalTermsAndConditions: {
    i18nKey: 'withdrawalTermsAndConditions',
    defaults: 'Please review and accept our <0>Terms and Conditions</0>',
  },
  insufficientFunds: {
    i18nKey: 'insufficientFunds',
    defaults: 'Insufficient Funds',
  },
  close: {
    i18nKey: 'close',
    defaults: 'Close',
  },
  tryAgain: {
    i18nKey: 'tryAgain',
    defaults: 'Try Again',
  },
  success: {
    i18nKey: 'success',
    defaults: 'Success!',
  },
  failure: {
    i18nKey: 'failure',
    defaults: 'Failure!',
  },
  successWithdrawal: {
    i18nKey: 'successWithdrawal',
    defaults: 'Your withdrawal has been successfully submitted and is pending review by our Back Office team. You will be notified via email {{email}} /SMS once your withdrawal is approved. <1>If your withdrawal has not been processed within 24 hours of your transaction submission, please check for any email messages from us.</1> <2>Your withdrawal should be processed within 48 hours for wire transfers and 24 hours for all other withdrawal methods. If your withdrawal is not approved by then, please check for any email messages from us.</2>',
  },
  failureWithdrawal: {
    i18nKey: 'failureWithdrawal',
    defaults: 'There was an error when you tried to withdraw <0>{{amount}}</0>.',
  },
  support: {
    i18nKey: 'support',
    defaults: 'Support (24/5)',
  },
  supportMessageSuccess: {
    i18nKey: 'supportMessageSuccess',
    defaults: 'Thank you for your message. We will get back to you shortly.',
  },
  supportMethodsSubtitle: {
    i18nKey: 'supportMethodsSubtitle',
    defaults: 'How would you like to contact us?',
  },
  whatsapp: {
    i18nKey: 'whatsapp',
    defaults: 'WhatsApp',
  },
  telegram: {
    i18nKey: 'telegram',
    defaults: 'Telegram',
  },
  supportEmail: {
    i18nKey: 'supportEmail',
    defaults: 'Email',
  },
  sendMessage: {
    i18nKey: 'sendMessage',
    defaults: 'Send Message',
  },
  supportTopic1: {
    i18nKey: 'supportTopic1',
    defaults: 'I have a question about my account'
  },
  supportTopic2: {
    i18nKey: 'supportTopic2',
    defaults: 'I have a question about my deposit'
  },
  supportTopic3: {
    i18nKey: 'supportTopic3',
    defaults: 'I have a technical problem'
  },
  supportTopic4: {
    i18nKey: 'supportTopic4',
    defaults: 'I have a question for my Account Manager/Dealing Room'
  },
  supportTopic5: {
    i18nKey: 'supportTopic5',
    defaults: 'I’d like to make a complaint'
  },
  supportTopic6: {
    i18nKey: 'supportTopic6',
    defaults: 'I have a question regarding the Partner Program'
  },
  supportTopic7: {
    i18nKey: 'supportTopic7',
    defaults: 'Other'
  },
  callback: {
    i18nKey: 'callback',
    defaults: 'Support Call back',
  },
  oneOnOneSession: {
    i18nKey: 'oneOnOneSession',
    defaults: 'One on one session',
  },
  liveChat: {
    i18nKey: 'liveChat',
    defaults: 'Live chat',
  },
  whatsappInstructionsTitle: {
    i18nKey: 'whatsappInstructionsTitle',
    defaults: 'How to contact us on WhatsApp',
  },
  whatsappInstructionsStep1: {
    i18nKey: 'whatsappInstructionsStep1',
    defaults: 'Add the following {{company}} number to your contact list: {{companyWhatsapp}}',
  },
  whatsappInstructionsStep2: {
    i18nKey: 'whatsappInstructionsStep2',
    defaults: 'Open Whatsapp',
  },
  whatsappInstructionsStep3: {
    i18nKey: 'whatsappInstructionsStep3',
    defaults: 'Click the “New Chat” icon in the top right corner',
  },
  whatsappInstructionsStep4: {
    i18nKey: 'whatsappInstructionsStep4',
    defaults: 'Select the {{company}} number you added in step 1',
  },
  whatsappInstructionsStep5: {
    i18nKey: 'whatsappInstructionsStep5',
    defaults: 'Start chatting!',
  },
  telegramInstructionsTitle: {
    i18nKey: 'telegramInstructionsTitle',
    defaults: 'How to contact us on Telegram',
  },
  telegramInstructionsStep1: {
    i18nKey: 'telegramInstructionsStep1',
    defaults: 'Add the following {{company}} number to your contact list: {{companyTelegram}}',
  },
  telegramInstructionsStep2: {
    i18nKey: 'telegramInstructionsStep2',
    defaults: 'Open Telegram',
  },
  telegramInstructionsStep3: {
    i18nKey: 'telegramInstructionsStep3',
    defaults: 'Click on “Contacts”',
  },
  telegramInstructionsStep4: {
    i18nKey: 'telegramInstructionsStep4',
    defaults: 'Select the {{company}} number you added in step 1',
  },
  telegramInstructionsStep5: {
    i18nKey: 'telegramInstructionsStep5',
    defaults: 'Start chatting!',
  },
  bdSwisstelegramInstructionsStep1: {
    i18nKey: 'bdSwisstelegramInstructionsStep1',
    defaults: 'Open Telegram',
  },
  bdSwisstelegramInstructionsStep2: {
    i18nKey: 'bdSwisstelegramInstructionsStep2',
    defaults: 'Search for {{company}} Support ({{companyTelegram}})',
  },
  bdSwisstelegramInstructionsStep3: {
    i18nKey: 'bdSwisstelegramInstructionsStep3',
    defaults: 'Open the chat and click “Start”',
  },
  contactForm: {
    i18nKey: 'contactForm',
    defaults: 'Contact form',
  },
  supportYourMessage: {
    i18nKey: 'supportYourMessage',
    defaults: 'Your message',
  },
  supportYourQuestion: {
    i18nKey: 'supportYourQuestion',
    defaults: 'Your question',
  },
  profile: {
    i18nKey: 'profile',
    defaults: 'Profile'
  },
  profileMissing: {
    i18nKey: 'profileMissing',
    defaults: 'Profile details are missing'
  },
  profileSubmitted: {
    i18nKey: 'profileSubmitted',
    defaults: 'Profile submitted'
  },
  economicProfileSubmitted: {
    i18nKey: 'economicProfileSubmitted',
    defaults: 'Economic Profile submitted'
  },
  economicProfileMissing: {
    i18nKey: 'economicProfileMissing',
    defaults: 'Economic Profile is missing'
  },
  appropriatenessTestSubmitted: {
    i18nKey: 'appropriatenessTestSubmitted',
    defaults: 'Appropriateness Test submitted'
  },
  appropriatenessTestRejected: {
    i18nKey: 'appropriatenessTestRejected',
    defaults: 'Appropriateness Test rejected'
  },
  appropriatenessTestMissing: {
    i18nKey: 'appropriatenessTestMissing',
    defaults: 'Appropriateness Test is missing'
  },
  kycStatusApproved: {
    i18nKey: 'kycStatusApproved',
    defaults: 'KYC Status Approved'
  },
  kycStatusRejected: {
    i18nKey: 'kycStatusRejected',
    defaults: 'KYC Status Rejected'
  },
  kycStatusPending: {
    i18nKey: 'kycStatusPending',
    defaults: 'KYC Status Pending'
  },
  kycAutoRejectedExplanation: {
    i18nKey: 'kycAutoRejectedExplanation',
    defaults: 'Unfortunately we could not verify your identity and address electronically. Please submit required documents manually.'
  },
  kycCannotContactServiceExplanation: {
    i18nKey: 'kycCannotContactServiceExplanation',
    defaults: 'Could not contact verification service. Please submit required documents manually.'
  },
  kycAutoDescription:{
    i18nKey: 'kycAutoDescription',
    defaults: 'Please verify your identity and residential address.'
  },
  kycStatusApprovedDescription:{
    i18nKey: 'kycStatusApprovedDescription',
    defaults: 'We have successfully verified your account! No additional documents are required at this stage.*'
  },
  accountFunded: {
    i18nKey: 'accountFunded',
    defaults: 'Account Funded'
  },
  depositFailed: {
    i18nKey: 'depositFailed',
    defaults: 'Deposit Failed'
  },
  depositPending: {
    i18nKey: 'depositPending',
    defaults: 'Start trading by depositing to your account.'
  },
  accountPending: {
    i18nKey: 'accountPending',
    defaults: 'Your account is pending'
  },
  accountVerification: {
    i18nKey: 'accountVerification',
    defaults: 'Account Verification'
  },
  accountID: {
    i18nKey: 'accountID',
    defaults: 'Account ID: {{accountId}}'
  },
  getVerified: {
    i18nKey: 'getVerified',
    defaults: 'Get Verified'
  },
  submit: {
    i18nKey: 'submit',
    defaults: 'Submit'
  },
  completed: {
    i18nKey: 'completed',
    defaults: 'Completed'
  },
  missing: {
    i18nKey: 'missing',
    defaults: 'Missing'
  },
  appropTestHeaderMessage: {
    i18nKey: 'appropTestHeaderMessage',
    defaults: '{{company}} is a financially regulated company and is obliged by law to collect certain information about our clients. The following questions are part of this process to protect clients by ensuring that our services are appropriate for them.'
  },
  dueDiligenceHeaderMessage: {
    i18nKey: 'dueDiligenceHeaderMessage',
    defaults: '{{company}} lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.'
  },
  whyIsThisNeeded: {
    i18nKey: 'whyIsThisNeeded',
    defaults: 'Why is this needed?',
  },
  netWorthDesc: {
    i18nKey: 'appropTestNetWorthDesc',
    defaults: 'This is the total of all your assets (including any savings accounts and property) minus any personal liabilities you have (money you owe to others, such as credit card debt or bank loans)', // eslint-disable-line max-len
  },
  initialInvestmentDesc: {
    i18nKey: 'appropTestiIitialInvestmentDesc',
    defaults: 'This is the range of money in Euros you expect to deposit before start trading',
  },
  leverage: {
    i18nKey: 'leverage',
    defaults: 'Leverage',
  },
  downloadMT4Subtitle:{
    i18nKey: 'downloadMT4Subtitle',
    defaults:'{{product}} trading software download'
  },
  windows:{
    i18nKey: 'windows',
    defaults:'{{product}} for Windows'
  },
  iosAndroid:{
    i18nKey: 'iosAndroid',
    defaults:'{{product}} for iOS / Android'
  },
  mac:{
    i18nKey: 'mac',
    defaults:'{{product}} for Mac'
  },
  web: {
    i18nKey: 'web',
    defaults:'*suitable for mobile trading'
  },
  mt4MoreInfo:{
    i18nKey: 'mt4MoreInfo',
    defaults:'More information on {{product}} Trading Software'
  },
  loginDetails:{
    i18nKey: 'loginDetails',
    defaults:'Login Details'
  },
  server:{
    i18nKey: 'server',
    defaults:'Server'
  },
  clickToCopy:{
    i18nKey: 'clickToCopy',
    defaults:'Click to copy'
  },
  mt4Example:{
    i18nKey: 'mt4Example',
    defaults:'{{product}} login example ( Screenshot)'
  },
  yourPasswordText: {
    i18nKey: 'yourPasswordText',
    defaults:'Your password'
  },
  addAccount: {
    i18nKey: 'addAccount',
    defaults: 'Add account',
  },
  addNewAccount: {
    i18nKey: 'addNewAccount',
    defaults: 'Add new account',
  },
  setPassword: {
    i18nKey: 'setPassword',
    defaults: 'Set Password',
  },
  selectAccountType: {
    i18nKey: 'selectAccountType',
    defaults: 'Select account type',
  },
  selectCurrency: {
    i18nKey: 'selectCurrency',
    defaults: 'Select currency',
  },
  newPassword: {
    i18nKey: 'newPassword',
    defaults: 'New password'
  },
  passwordConfirmation: {
    i18nKey: 'passwordConfirmation',
    defaults: 'Password Confirmation'
  },
  currentPassword: {
    i18nKey: 'currentPassword',
    defaults: 'Current Password'
  },
  passwordChanged: {
    i18nKey: 'passwordChanged',
    defaults: 'Password changed successfully!'
  },
  transferSuccess: {
    i18nKey: 'transferSuccess',
    defaults: 'Your transfer was completed successfully!',
  },
  upload: {
    i18nKey: 'upload',
    defaults: 'Upload',
  },
  verificationDocuments: {
    i18nKey: 'verificationDocuments',
    defaults: 'Verification documents',
  },
  documentsList: {
    i18nKey: 'documentsList',
    defaults: 'Documents list',
  },
  optionalDocumentsList: {
    i18nKey: 'optionalDocumentsList',
    defaults: 'Optional Documents list',
  },
  daysToVerifyAccount: {
    i18nKey: 'daysToVerifyAccount',
    defaults: 'Please <0>verify your account</0> as soon as possible. Meanwhile, you can start <1>trading now</1>',
  },
  whyNecessary: {
    i18nKey: 'whyNecessary',
    defaults: 'Why is it necessary?',
  },
  poiTitle: {
    i18nKey: 'poiTitle',
    defaults: 'Proof of Identity',
  },
  porTitle: {
    i18nKey: 'porTitle',
    defaults: 'Proof of Residence',
  },
  poiTitlenotUploaded: {
    i18nKey: 'poiTitlenotUploaded',
    defaults: 'Proof of Identity is required',
  },
  poiTitleapproved: {
    i18nKey: 'poiTitleapproved',
    defaults: 'Proof of Identity approved',
  },
  poiTitlepending: {
    i18nKey: 'poiTitlepending',
    defaults: 'Proof of Identity is pending review',
  },
  poiTitlerejected: {
    i18nKey: 'poiTitlerejected',
    defaults: 'Proof of Identity rejected',
  },
  porTitlenotUploaded: {
    i18nKey: 'porTitlenotUploaded',
    defaults: 'Proof of Residence is required',
  },
  porTitleapproved: {
    i18nKey: 'porTitleapproved',
    defaults: 'Proof of Residence approved',
  },
  porTitlepending: {
    i18nKey: 'porTitlepending',
    defaults: 'Proof of Residence is pending review',
  },
  porTitlerejected: {
    i18nKey: 'porTitlerejected',
    defaults: 'Proof of Residence rejected',
  },
  skipWaitingList: {
    i18nKey: 'skipWaitingList',
    defaults: 'Skip Waiting List',
  },
  documentType: {
    i18nKey: 'documentType',
    defaults: 'Document type',
  },
  date: {
    i18nKey: 'date',
    defaults: 'Date',
  },
  poiUpload: {
    i18nKey: 'poiUpload',
    defaults: 'Upload proof of identity',
  },
  porUpload: {
    i18nKey: 'porUpload',
    defaults: 'Upload proof of residence',
  },
  pngOrJpeg: {
    i18nKey: 'pngOrJpeg',
    defaults: '(png or jpeg image)',
  },
  id: {
    i18nKey: 'id',
    defaults: 'ID',
  },
  drivingLicense: {
    i18nKey: 'drivingLicense',
    defaults: 'Driving Licence',
  },
  card: {
    i18nKey: 'card',
    defaults: 'Credit/Debit Card Copy',
  },
  documentUploaded: {
    i18nKey: 'documentUploaded',
    defaults: 'Document has been successfully uploaded.',
  },
  documentUploadedMessage: {
    i18nKey: 'documentUploadedMessage',
    defaults: 'We\'re checking the uploaded documents as fast as we can, but at the moment there\'s a short waiting list ' +
      'for our basic accounts. It may take us some time to check everything',
  },
  placeAtWaitingList: {
    i18nKey: 'placeAtWaitingList',
    defaults: 'Your place on the waiting list is:',
  },
  pofTitle: {
    i18nKey: 'pofTitle',
    defaults: 'Deposit Verification',
  },
  personalTitle: {
    i18nKey: 'personalTitle',
    defaults: 'Personal Documents',
  },
  personalUpload: {
    i18nKey: 'personalUpload',
    defaults: 'Personal Documents upload',
  },
  pofUpload: {
    i18nKey: 'pofUpload',
    defaults: 'Verify your Payment method',
  },
  warning: {
    i18nKey: 'warning',
    defaults: 'Warning'
  },
  level1AppropTestMessage: {
    i18nKey: 'leverageLevel1Message',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading
    involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company
    regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot
    accept your account’s funding at this time.<0/> The Appropriateness test can be retaken after {{failedAppropTestTrainingPeriod}} days; however,
    we highly recommend visiting our <1>Trading Academy</1>, where you can learn more about online trading and practice your skills on our free Demo Account.`,
  },
  level2AppropTestMessage: {
    i18nKey: 'leverageLevel2Message',
    defaults: `Congratulations, you have successfully completed the Appropriateness test. You seem to possess
    enough knowledge and experience to trade with real money. Though {{company}} is warning you that this carries a
    high degree of risk, you can proceed further.`,
  },
  level15AppropTestMessage: {
    i18nKey: 'leverageLevel15Message',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you only have a moderate understanding of CFD trading.<1/>
    Although you are eligible to start trading, we would highly recommend you first gain a better understanding
    of what speculative trading involves, by visiting our <2>Trading Academy.</2><3/>
    By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level15AppropTestMessageMaubdSwiss: {
    i18nKey: 'level15AppropTestMessageMaubdSwiss',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you seem to have a moderate understanding of CFD trading.<1/>
    You can gain a better understanding of what speculative trading involves, by visiting the <2>BDSwiss Trading Academy.</2><3/>
    Your account shall be set to a maximum <4>1:{{leverage}}</4> default leverage. However, you can choose to increase your leverage at your own risk.
    For more information, please contact our <5>Support Team</5>.<6/> By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level1AppropTestMessageMaubdSwiss: {
    i18nKey: 'level1AppropTestMessageMaubdSwiss',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot accept your account’s funding at this time.<1/>
    You may re-submit your Appropriateness test immediately. Alternatively, you may choose to improve your skills by visiting our <0>Trading Academy.</0><2/>
    If you have already deposited funds into your account but don’t wish to proceed with trading on our platform, you can withdraw them at any time by contacting our <3>Support Team</3>.`,
  },
  level15AppropTestMessageMauswissMarkets: {
    i18nKey: 'level15AppropTestMessageMauswissMarkets',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you seem to have a moderate understanding of CFD trading.<1/>
    Your account shall be set to a maximum <4>1:{{leverage}}</4> default leverage. However, you can choose to increase your leverage at your own risk.
    For more information, please contact our <5>Support Team</5>.<6/>
    By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level1AppropTestMessageMauswissMarkets: {
    i18nKey: 'level1AppropTestMessageMauswissMarkets',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot accept your account’s funding at this time.<1/>
    You may re-submit your Appropriateness test immediately.<2/> If you have already deposited funds into your account but don’t wish to proceed with trading on our platform, you can withdraw them at any time by contacting our <3>Support Team</3>.`,
  },
  tradeNow: {
    i18nKey: 'tradeNode',
    defaults: 'Trade Now',
  },
  balance: {
    i18nKey: 'balance',
    defaults: 'Balance',
  },
  equity: {
    i18nKey: 'equity',
    defaults: 'Equity',
  },
  margin: {
    i18nKey: 'margin',
    defaults: 'Margin',
  },
  freeMargin: {
    i18nKey: 'freeMargin',
    defaults: 'Free Margin',
  },
  marginLevel: {
    i18nKey: 'marginLevel',
    defaults: 'Margin Level',
  },
  openPositions: {
    i18nKey: 'openPositions',
    defaults: 'Open Positions',
  },
  overview: {
    i18nKey: 'overview',
    defaults: 'Overview',
  },
  tools: {
    i18nKey: 'tools',
    defaults: 'Tools',
  },
  settings: {
    i18nKey: 'settings',
    defaults: 'Settings',
  },
  tradingTools: {
    i18nKey: 'tradingTools',
    defaults: 'Trading Tools',
  },
  forexCalculator: {
    i18nKey: 'forexCalculator',
    defaults: 'Forex Calculator',
  },
  tradingAcademy: {
    i18nKey: 'tradingAcademy',
    defaults: 'Trading Academy',
  },
  tradingKnowledgeAndExperience: {
    i18nKey: 'tradingKnowledgeAndExperience',
    defaults: 'Trading Knowledge and Experience',
  },
  tradingSignals: {
    i18nKey: 'tradingSignals',
    defaults: 'Trading Signals',
  },
  getLiveSignals: {
    i18nKey: 'getLiveSignals',
    defaults: 'How to get live signals?',
  },
  timeClosed: {
    i18nKey: 'timeClosed',
    defaults: 'Time Closed',
  },
  pair: {
    i18nKey: 'pair',
    defaults: 'Pair',
  },
  action: {
    i18nKey: 'action',
    defaults: 'Action',
  },
  entryPrice: {
    i18nKey: 'entryPrice',
    defaults: 'Entry Price',
  },
  stopLossPrice: {
    i18nKey: 'stopLossPrice',
    defaults: 'Stop Loss Price',
  },
  takeProfitPrice: {
    i18nKey: 'takeProfitPrice',
    defaults: 'Take Profit Price',
  },
  successfulTrade: {
    i18nKey: 'successfulTrade',
    defaults: 'Successful Trade',
  },
  status: {
    i18nKey: 'status',
    defaults: 'Status',
  },
  comments: {
    i18nKey: 'comments',
    defaults: 'Comments',
  },
  documentFailedUploadMessage: {
    i18nKey: 'documentFailedUploadMessage',
    defaults: 'Document upload failed. Please try again.',
  },
  account:{
    i18nKey: 'account',
    defaults: 'Account',
  },
  transactionStatus:{
    i18nKey: 'transactionStatus',
    defaults: 'Transaction Status',
  },
  transactionTypes:{
    i18nKey: 'transactionTypes',
    defaults: 'Transaction Types',
  },
  pending:{
    i18nKey: 'pending',
    defaults: 'Pending',
  },
  rejected:{
    i18nKey: 'rejected',
    defaults: 'Rejected',
  },
  approved:{
    i18nKey: 'approved',
    defaults: 'Approved',
  },
  withdrawal:{
    i18nKey: 'withdrawal',
    defaults: 'Withdrawal',
  },
  time:{
    i18nKey: 'time',
    defaults: 'Time',
  },
  type:{
    i18nKey: 'type',
    defaults: 'Type',
  },
  amount:{
    i18nKey: 'amount',
    defaults: 'Amount',
  },
  filters:{
    i18nKey: 'filters',
    defaults: 'Filters',
  },
  generalWithdrawalDescription: {
    i18nKey: 'generalWithdrawalDescription',
    defaults: '{{company}} reserves the right to charge a fee of 10 EUR (or trading account currency equivalent) where the withdrawal amount is below or equal to 20 EUR (or trading account currency equivalent).',
  },
  generalWithdrawalDescriptionPartner: {
    i18nKey: 'generalWithdrawalDescriptionPartner',
    defaults: 'Any payment method used may generate withdrawal fees, the amount of which varies depending on the method chosen and/or the value of the transaction.',
  },
  creditCardWithdrawalDescription1: {
    i18nKey: 'creditCardWithdrawalDescription1',
    defaults: 'All deposits must be withdrawn back to the same credit/debit card before other methods may be offered for withdrawal.',
  },
  creditCardWithdrawalDescription2: {
    i18nKey: 'creditCardWithdrawalDescription2',
    defaults: 'Please note that you can withdraw the total sum of the deposits you placed via credit card back to the same card with no additional charge. For Credit Card withdrawals exceeding the sum of your deposits, an additional €4 fee will apply.',
  },
  eWalletWithdrawalDescription1: {
    i18nKey: 'eWalletWithdrawalDescription1',
    defaults: 'Ensure to submit your withdrawal request back to the same e-wallet / e-mail address you used for deposit/s.',
  },
  bankTransferWithdrawalDescription1: {
    i18nKey: 'bankTransferWithdrawalDescription1',
    defaults: 'Please ensure to submit your withdrawal to the same bank account that you deposited with. In case when you didn’t previously deposit using bank wire, please email us your bank statement showing your name and the bank details you wish to withdraw your funds to.',
  },
  bankTransferWithdrawalDescription2: {
    i18nKey: 'bankTransferWithdrawalDescription2',
    defaults: 'Withdrawals by Bank Wire are processed within {{hours}} hours on working days upon receipt of your withdrawal request. However, the funds are credited to your bank account within 2-5 working days<1> for SEPA transfers and 7-10 working days for International transfers</1>.',
  },
  bankTransferWithdrawalDescription3: {
    i18nKey: 'bankTransferWithdrawalDescription3',
    defaults: 'The minimum withdrawable net amount (i.e. amount after the deduction of fees) for international transfers is 50 EUR and 5 EUR for SEPA transfers. We are unable to process withdrawals below these limits, thus in such cases, an alternative withdrawal method may be used, if available.',
  },
  bankTransferWithdrawalDescription4: {
    i18nKey: 'bankTransferWithdrawalDescription4',
    defaults: '{{company}} reserves the right to charge a fixed fee of 10 EUR or trading account currency equivalent on bank wire withdrawals below or equal to 100 EUR or trading account currency equivalent.',
  },
  onlineBankingWithdrawalDescription1: {
    i18nKey: 'onlineBankingWithdrawalDescription1',
    defaults: 'Please ensure to submit your withdrawal to the same bank account that you deposited with. In case you didn’t previously deposit using bank wire, please provide us with bank statement showing your name and the bank details you wish to withdraw your funds to.',
  },
  onlineBankingWithdrawalDescription2: {
    i18nKey: 'onlineBankingWithdrawalDescription2',
    defaults: 'You may be required to provide us with a screenshot of the transactions made to BDSwiss upon deposit/withdrawal, for confirmation of your bank details.',
  },
  coinbaseWithdrawalDescription2: {
    i18nKey: 'coinbaseWithdrawalDescription2',
    defaults: '<0>Please note that your withdrawal amount will be converted to your selected cryptocurrency before reaching your crypto wallet.</0>'
  },
  coinbaseWithdrawalDescription1: {
    i18nKey: 'coinbaseWithdrawalDescription1',
    defaults: 'Please ensure to provide us with the same wallet address that your deposit/s came from, otherwise your withdrawal will be rejected.',
  },
  cryptocurrencyWithdrawalDescription1: {
    i18nKey: 'cryptocurrencyWithdrawalDescription1',
    defaults: 'Please double-check the wallet address you provide to ensure it is accurate. <2>{{ company }} will not be held responsible for any funds lost due to an incorrect wallet address provided by the client.</2>',
  },
  cryptocurrencyWithdrawalDescription2: {
    i18nKey: 'cryptocurrencyWithdrawalDescription2',
    defaults: 'Make sure the wallet is compatible with the selected cryptocurrency and network to avoid transaction issues. Always verify the details carefully before submitting your request.',
  },
  gigadatWithdrawalFootnote: {
    i18nKey: 'gigadatWithdrawalFootnote',
    defaults: 'Trade-mark of Interac Corp. Used Under Licence.',
  },
  submitWithdrawalTitle: {
    i18nKey: 'submitWithdrawalTitle',
    defaults: 'Are you sure you want to proceed?',
  },
  edit: {
    i18nKey: 'edit',
    defaults: 'Edit',
  },
  correctDetails: {
    i18nKey: 'correctDetails',
    defaults: 'Please ensure you have provided us with the correct details',
  },
  creditCardWithdrawalSuccess: {
    i18nKey: 'creditCardWithdrawalSuccess',
    defaults: 'Please note that credit card withdrawals are processed automatically but it takes 2-7 working days for the funds to appear in your bank account.',
  },
  eWalletWithdrawalSuccess: {
    i18nKey: 'eWalletWithdrawalSuccess',
    defaults: 'Please note that it takes up to 1 hour for the funds to reflect on your e-wallet account, once your withdrawal has been approved by our Back Office Team.',
  },
  bankTransferWithdrawalSuccess: {
    i18nKey: 'bankTransferWithdrawalSuccess',
    defaults: 'Please note that it takes (on average) up to {{days}} working days for the funds to reflect on your bank account, once your withdrawal has been approved by our Back Office Team.',
  },
  onlineBankingWithdrawalSuccess: {
    i18nKey: 'onlineBankingWithdrawalSuccess',
    defaults: 'Please note that it takes up to 3 days for the funds to reflect on your bank account, once your withdrawal has been approved by our Back Office Team.',
  },
  coinbaseWithdrawalSuccess: {
    i18nKey: 'coinbaseWithdrawalSuccess',
    defaults: 'Please note that it takes 1-2 hours for the funds to reflect on your wallet account, once your withdrawal has been approved by our Back Office Team.',
  },
  createAccountSuccess: {
    i18nKey: 'createAccountSuccess',
    defaults: 'New account has been created. <0>Dismiss</0>'
  },
  openTime: {
    i18nKey: 'openTime',
    defaults: 'Open time'
  },
  symbol: {
    i18nKey: 'symbol',
    defaults: 'Symbol'
  },
  direction: {
    i18nKey: 'direction',
    defaults: 'Direction'
  },
  multiplier: {
    i18nKey: 'multiplier',
    defaults: 'Multiplier'
  },
  value: {
    i18nKey: 'value',
    defaults: 'Value'
  },
  profit: {
    i18nKey: 'profit',
    defaults: 'Profit'
  },
  totalPerformanceFee: {
    i18nKey: 'totalPerformanceFee',
    defaults: 'Total Performance Fee'
  },
  monthlyPerformanceFee: {
    i18nKey: 'monthlyPerformanceFee',
    defaults: 'Performance Fee In Month'
  },
  tradesHeader_open: {
    i18nKey: 'tradesHeaderOpen',
    defaults: 'My open trades',
  },
  tradesHeader_closed: {
    i18nKey: 'tradesHeaderClosed',
    defaults: 'My closed trades',
  },
  tradesHeader_pending: {
    i18nKey: 'tradesHeaderPending',
    defaults: 'My pending trades',
  },
  tradesEmpty_open: {
    i18nKey: 'tradesEmptyOpen',
    defaults: 'You do not have any open trades',
  },
  tradesEmpty_closed: {
    i18nKey: 'tradesEmptyClosed',
    defaults: 'You do not have any closed trades',
  },
  tradesEmpty_pending: {
    i18nKey: 'tradesEmptyPending',
    defaults: 'You do not have any pending trades',
  },
  personalProfileCard: {
    i18nKey: 'personalProfileCard',
    defaults: 'Personal Profile'
  },
  firstName: {
    i18nKey: 'firstName',
    defaults: 'First Name'
  },
  passportNumber: {
    i18nKey: 'passportNumber',
    defaults: 'Passport Number'
  },
  previousNamesOptional: {
    i18nKey: 'previousNamesOptional',
    defaults: 'Previous Names (Optional)'
  },
  lastName: {
    i18nKey: 'lastName',
    defaults: 'Last Name'
  },
  dateOfBirth: {
    i18nKey: 'dateOfBirth',
    defaults: 'Date of birth'
  },
  secondaryEmailText: {
    i18nKey: 'secondaryEmailText',
    defaults: 'Add Secondary email'
  },
  secondaryEmailLabel: {
    i18nKey: 'secondaryEmailLabel',
    defaults: 'Secondary Email'
  },
  phone: {
    i18nKey: 'phone',
    defaults: 'Phone'
  },
  secondaryPhoneText: {
    i18nKey: 'secondaryPhoneText',
    defaults: 'Add Secondary phone'
  },
  secondaryPhoneLabel: {
    i18nKey: 'secondaryPhoneLabel',
    defaults: 'Secondary Phone'
  },
  streetLabel: {
    i18nKey: 'streetLabel',
    defaults: 'Street Name'
  },
  cityLabel: {
    i18nKey: 'cityLabel',
    defaults: 'City'
  },
  houseNumberLabel: {
    i18nKey: 'houseNumberLabel',
    defaults: 'House Number'
  },
  zipLabel: {
    i18nKey: 'zipLabel',
    defaults: 'Postal Code'
  },
  regionLabel: {
    i18nKey: 'regionLabel',
    defaults: 'Region'
  },
  countryLabel: {
    i18nKey: 'countryLabel',
    defaults: 'Country of residence'
  },
  correspondenceAddress: {
    i18nKey: 'correspondenceAddress',
    defaults: 'Correspondence Address'
  },
  residentialAddress: {
    i18nKey: 'residentialAddress',
    defaults: 'Residential Address'
  },
  nationalityLabel: {
    i18nKey: 'nationalityLabel',
    defaults: 'Nationality'
  },
  saveButtonSettings: {
    i18nKey: 'saveButtonSettings',
    defaults: 'Save'
  },
  kycSettingsCard: {
    i18nKey: 'kycSettingsCard',
    defaults: 'KYC documentation'
  },
  profileSettingsCard: {
    i18nKey: 'profileSettingsCard',
    defaults: 'Personal information and economic profile'
  },
  apTestSettingsCard: {
    i18nKey: 'apTestSettingsCard',
    defaults: 'For forex and other trading accounts only'
  },
  dueDiligenceSettingsCard: {
    i18nKey: 'dueDiligenceSettingsCard',
    defaults: 'Due Diligence questionnaire'
  },
  kycSettingsCardTitle: {
    i18nKey: 'kycSettingsCardTitle',
    defaults: 'Verification Documents'
  },
  kycSettingsCardMessage: {
    i18nKey: 'kycSettingsCardMessage',
    defaults: 'Please fill in Personal Profile Details first.'
  },
  profileSettingsCardTitle: {
    i18nKey: 'profileSettingsCardTitle',
    defaults: 'Personal Profile'
  },
  profileSettingsTooltip1: {
    i18nKey: 'profileSettingsTooltip1',
    defaults: 'Personal profile is filled'
  },
  profileSettingsTooltip1Pending: {
    i18nKey: 'profileSettingsTooltip1Pending',
    defaults: 'Personal profile is pending'
  },
  profileSettingsTooltip2: {
    i18nKey: 'profileSettingsTooltip2',
    defaults: 'Economic profile is filled'
  },
  profileSettingsTooltip2Pending: {
    i18nKey: 'profileSettingsTooltip2Pending',
    defaults: 'Economic profile is pending'
  },
  documentSettingsStatus: {
    i18nKey: 'documentSettingsStatus',
    defaults: 'uploaded'
  },
  settingsProfileTab: {
    i18nKey: 'settingsProfileTab',
    defaults: 'Profile'
  },
  privacy: {
    i18nKey: 'privacy',
    defaults: 'Privacy'
  },
  changePassword: {
    i18nKey: 'changePassword',
    defaults: 'Change Password'
  },
  closeMemberAccount: {
    i18nKey: 'closeMemberAccount',
    defaults: 'Close Down Your Account'
  },
  closeMemberAccountDisclaimer: {
    i18nKey: 'closeMemberAccountDisclaimer',
    defaults: 'Can\'t close account if KYC is approved, while copy trading or when you have open positions.'
  },
  closeMemberAccountPopupContent: {
    i18nKey: 'closeMemberAccountPopupContent',
    defaults: 'Are you sure you want to close down your account?'
  },
  closeMemberAccountPopupDisclaimer: {
    i18nKey: 'closeMemberAccountPopupDisclaimer',
    defaults: 'You wont be able to login, and to reactivate the account you will have to contact support.'
  },
  closeMemberAccountSuccess: {
    i18nKey: 'closeMemberAccountSuccess',
    defaults: 'You have successfully closed your account.'
  },
  closeMemberAccountBtn: {
    i18nKey: 'closeMemberAccountBtn',
    defaults: 'Close Account'
  },
  deleteTradingAccount: {
    i18nKey: 'deleteTradingAccount',
    defaults: 'Delete Trading Account'
  },
  deleteTradingAccountDisclaimer: {
    i18nKey: 'deleteTradingAccountDisclaimer',
    defaults: 'Can\'t delete affiliate accounts or if you have balance, open or pending positions.'
  },
  deleteTradingAccountPopupContent: {
    i18nKey: 'deleteTradingAccountPopupContent',
    defaults: 'Are you sure you want to delete your trading account?'
  },
  deleteTradingAccountPopupDisclaimer: {
    i18nKey: 'deleteTradingAccountPopupDisclaimer',
    defaults: 'For any further actions regarding this trading account after deleting, you will have to contact support.'
  },
  deleteTradingAccountSuccess: {
    i18nKey: 'deleteTradingAccountSuccess',
    defaults: 'Trading account successfully deleted.'
  },
  deleteTradingAccountBtn: {
    i18nKey: 'deleteTradingAccountBtn',
    defaults: 'Delete Account'
  },
  personalDetails: {
    i18nKey: 'personalDetails',
    defaults: 'Personal Details'
  },
  economicProfile: {
    i18nKey: 'economicProfile',
    defaults: 'Economic Profile'
  },
  userProfileAndInvestmentObjectives: {
    i18nKey: 'userProfileAndInvestmentObjectives',
    defaults: 'User Profile and Investment Objectives'
  },
  webtraderWelcomeTitle: {
    i18nKey: 'webtraderWelcomeTitle',
    defaults: 'You have successfully registered',
  },
  webtraderWelcomePracticeCredit: {
    i18nKey: 'webtraderWelcomePracticeCredit',
    defaults: 'Credited to your practice account:',
  },
  webtraderWelcomeRealAccountCreated: {
    i18nKey: 'webtraderWelcomeRealAccountCreated',
    defaults: 'We have also created a real account for you:',
  },
  webtraderWelcomeNoWithdrawals: {
    i18nKey: 'webtraderWelcomeNoWithdrawals',
    defaults: 'No withdrawals',
  },
  webtraderWelcomeEasyWithdrawals: {
    i18nKey: 'webtraderWelcomeEasyWithdrawals',
    defaults: 'Easy withdrawals',
  },
  webtraderWelcomeFreeTopUp: {
    i18nKey: 'webtraderWelcomeFreeTopUp',
    defaults: 'Free top-up',
  },
  webtraderWelcomeMinAmount: {
    i18nKey: 'webtraderWelcomeMinAmount',
    defaults: 'Minimum amount {amount}',
  },
  webtraderWelcomeStartTradingTitle: {
    i18nKey: 'webtraderWelcomeStartTradingTitle',
    defaults: 'START TRADING',
  },
  webtraderWelcomeStartTradingDemoSubtitle: {
    i18nKey: 'webtraderWelcomeStartTradingDemoSubtitle',
    defaults: 'on a practice account',
  },
  webtraderWelcomeStartTradingRealSubtitle: {
    i18nKey: 'webtraderWelcomeStartTradingRealSubtitle',
    defaults: 'on a real account',
  },
  authKeyError: {
    i18nKey: 'authKeyError',
    defaults: 'Could not authenticate, please try later.',
  },
  positiveNumber: {
    i18nKey: 'positiveNumber',
    defaults: 'Amount has to be a positive number',
  },
  resetPassword: {
    i18nKey: 'resetPassword',
    defaults: 'Reset Password',
  },
  backToLogin: {
    i18nKey: 'backToLogin',
    defaults: 'Back to login page'
  },
  forgotPasswordNotification: {
    i18nKey: 'forgotPasswordNotification',
    defaults: 'Password reset request submitted. You will receive an email shortly to confirm your password reset request '
  },
  passwordMismatch: {
    i18nKey: 'passwordMismatch',
    defaults: 'Passwords do not match'
  },
  resetPasswordNotification: {
    i18nKey: 'resetPasswordNotification',
    defaults: 'Password reset successful. You can now login with your new password'
  },
  changeLeverageNotice: {
    i18nKey: 'changeLeverageNotice',
    defaults: 'Please note that the current leverage only affects when trading currencies (forex)',
  },
  changeLeverageNoticeExample: {
    i18nKey: 'changeLeverageNoticeExample',
    defaults: 'For example, if your leverage is 1:400, then this will only impact trading on currencies such as EUR/USD or EUR/GBP. Commodities and indices stay at fixed leverage', // eslint-disable-line max-len
  },
  contactSupportForMore: {
    i18nKey: 'contactSupportForMore',
    defaults: 'For more information please contact our support team.',
  },
  successfulEmailConfirmation: {
    i18nKey: 'successfulEmailConfirmation',
    defaults: 'Email was successfully confirmed.',
  },
  failedEmailConfirmation: {
    i18nKey: 'failedEmailConfirmation',
    defaults: 'Email confirmation failed.',
  },
  language: {
    i18nKey: 'language',
    defaults: 'Language'
  },
  startTrading: {
    i18nKey: 'startTrading',
    defaults: 'To starting trading',
  },
  step: {
    i18nKey: 'step',
    defaults: 'Step {{number}}',
  },
  fundYourAccount: {
    i18nKey: 'campaignPortalFundYourAccount',
    defaults: '<0>Fund your account</0> to starting trading',
  },
  downloadMt4App: {
    i18nKey: 'downloadMt4App',
    defaults: '<0>Download</0> the {{product}} App',
  },
  openMt4App: {
    i18nKey: 'openMt4App',
    defaults: 'Open the {{product}} mobile App',
  },
  addAccountClickLogin: {
    i18nKey: 'addAccountClickLogin',
    defaults: 'Add your account by clicking to "<0>Login to an existing account</0>"',
  },
  searchBdswiss: {
    i18nKey: 'searchBdswiss',
    defaults: 'Search for BDSwiss and select <0>{{server}}</0>',
  },
  writeOrScreenshot: {
    i18nKey: 'writeOrScreenshot',
    defaults: 'Write these details down or take a screenshot!',
  },
  name: {
    i18nKey: 'name',
    defaults: 'Name',
  },
  notifications: {
    i18nKey: 'notifications',
    defaults: 'Notifications'
  },
  more: {
    i18nKey: 'more',
    defaults: 'More'
  },
  accounts: {
    i18nKey: 'accounts',
    defaults: 'Accounts'
  },
  logout: {
    i18nKey: 'logout',
    defaults: 'Logout'
  },
  settingsSpoaTitle: {
    i18nKey: 'settingsSpoaTitle',
    defaults: 'SPOA Authorisation',
  },
  settingsSpoaCaption: {
    i18nKey: 'settingsSpoaCaption',
    defaults: 'Grant special power of attorney to your IB',
  },
  spoaAuthorization: {
    i18nKey: 'spoaAuthorization',
    defaults: 'You have granted SPOA Authorisation to {{firstName}} {{lastName}} ({{id}}).'
  },
  spoaAuthorizationMissing: {
    i18nKey: 'spoaAuthorizationMissing',
    defaults: 'Click to grant SPOA Authorisation.'
  },
  spoaAcceptedTerms: {
    i18nKey: 'spoaAcceptedTerms',
    defaults: 'I have read and accepted the <0>Terms and Conditions</0>'
  },
  granted: {
    i18nKey: 'granted',
    defaults: 'Granted'
  },
  ibClientId: {
    i18nKey: 'ibClientId',
    defaults: 'IB Client ID'
  },
  grantAuthorization: {
    i18nKey: 'grantAuthorization',
    defaults: 'Grant Authorisation'
  },
  revokeAuthorization: {
    i18nKey: 'revokeAuthorization',
    defaults: 'Revoke Authorisation'
  },
  requiredClientId: {
    i18nKey: 'requiredClientId',
    defaults: 'You have to supply a Client ID'
  },
  requiredAgreeToTerms: {
    i18nKey: 'requiredAgreeToTerms',
    defaults: 'You have to agree to the terms and conditions'
  },
  transferDeposit: {
    i18nKey: 'transferDeposit',
    defaults: 'Transfer Deposit'
  },
  transferWithdrawal: {
    i18nKey: 'transferWithdrawal',
    defaults: 'Transfer Withdrawal'
  },
  vpsFee: {
    i18nKey: 'vpsFee',
    defaults: 'VPS Fee'
  },
  inactivityFee: {
    i18nKey: 'inactivityFee',
    defaults: 'Inactivity Fee'
  },
  archived: {
    i18nKey: 'archived',
    defaults: 'Archived'
  },
  archivedMessage: {
    i18nKey: 'archivedMessage',
    defaults: 'This account was archived'
  },
  archivedTooltip: {
    i18nKey: 'archivedTooltip',
    defaults: 'Please contact support'
  },
  managed: {
    i18nKey: 'managed',
    defaults: 'Managed'
  },
  demo: {
    i18nKey: 'demo',
    defaults: 'Demo'
  },
  mifir: {
    i18nKey: 'mifir',
    defaults: 'MiFIR',
  },
  mifirPopupMessage: {
    i18nKey: 'mifirPopupMessage',
    defaults: 'To comply with the Markets in Financial Instruments Regulation ("MiFIR") we must report all client transactions. Hereof, it is compulsory to request and verify you National Client Identifier ("NCI") which is based on your citizenship and must be assigned in accordance with the highest priority identifier available.',
  },
  mifirPopupNationalitySelectLabel: {
    i18nKey: 'mifirIdNationalitySelectLabel',
    defaults: 'Please enter your nationality: ',
  },
  mifirPopupSelectLabel: {
    i18nKey: 'mifirIdSelectLabel',
    defaults: 'Please choose your NCI document type on the field below: ',
  },
  mifirPopupInputLabel: {
    i18nKey: 'mifirPopupInputLabel',
    defaults: 'Please enter your NCI number on the field below: ',
  },
  mifirPopuplFirstOptionsPortugal: {
    i18nKey: 'mifirPopupFirstOptionPortugal',
    defaults: 'First Priority Identifier: Your Citizen Card or Taxpayer card which indicates your Tax Idntification Number ("TIN").',
    countriesAvailable: [nationalities.portuguese.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionsPortugal: {
    i18nKey: 'mifirPopupSecondOptionPortugal',
    defaults: 'Second Priority Identifier: Your Passport in case it was not already submitted and the first priority is not available.',
    countriesAvailable: [nationalities.portuguese.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionUK: {
    i18nKey: 'mifirPopupFirstOptionUK',
    defaults: 'Any document which indicates your UK National Insurance Number ("NINO").',
    countriesAvailable: [nationalities.british.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionGreece: {
    i18nKey: 'mifirPopupFirstOptionGreece',
    defaults: 'A document which indicates your 10 Dematerialised Securities System ("DSS") digit investor share.',
    countriesAvailable: [nationalities.greek.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionPoland: {
    i18nKey: 'mifirPopupFirstOptionPoland',
    defaults: 'First Priority Identifier: Your National ID or Driving Licence which indicates your National Identification Number (PESEL).',
    countriesAvailable: [nationalities.polish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionPoland: {
    i18nKey: 'mifirPopupSecondOptionPoland',
    defaults: 'Second Priority Identifier: Your Tax Number (identyfikacji podatkowej).',
    countriesAvailable: [nationalities.polish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionSpain: {
    i18nKey: 'mifirPopupFirstOptionSpain',
    defaults: 'Your Tax Identification Number (Código de identificación fiscal) existing on your National Identification Card, your driving licence or your social secutiry card.',
    countriesAvailable: [nationalities.spanish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionItaly: {
    i18nKey: 'mifirPopupSecondOptionItaly',
    defaults: 'Your Tax Identification Number (Codice Fiscale) existing on your Fiscal Code Card, or your Health Card.',
    countriesAvailable: [nationalities.italian.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionBulgaria: {
    i18nKey: 'mifirPopupFirstOptionBulgaria',
    defaults: 'Your Personal Identification Code/Number existing on your National ID Card.',
    countriesAvailable: [nationalities.bulgarian.key, nationalities.danish.key, nationalities.finnish.key, nationalities.croatian.key, nationalities.latvian.key, nationalities.norwegian.key, nationalities.swedish.key, nationalities.slovene.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionLithuania: {
    i18nKey: 'mifirPopupFirstOptionLithuania',
    defaults: 'First Priority Identifier: Your Personal Identification Code/Number existing on your National ID Card.',
    countriesAvailable: [nationalities.czech.key, nationalities.lithuanian.key, nationalities.romanian.key, nationalities.slovak.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionLithuania: {
    i18nKey: 'mifirPopupSecondOptionLithuania',
    defaults: 'Second Priority Identifier: Your Passport in case your ID card is not available.',
    countriesAvailable: [nationalities.czech.key, nationalities.lithuanian.key, nationalities.romanian.key, nationalities.slovak.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionNetherlands: {
    i18nKey: 'mifirPopupFirstOptionNetherlands',
    defaults: 'First Priority Identifier: Your Passport ID Number.',
    countriesAvailable: [nationalities.liechtensteiner.key, nationalities.dutch.key],
    defaultMifirType: 'pid',
  },
  mifirPopupSecondOptionNetherlands: {
    i18nKey: 'mifirPopupSecondOptionNetherlands',
    defaults: 'Second Priority Identifier: Your National ID Card in case the Passport is not available.',
    countriesAvailable: [nationalities.liechtensteiner.key, nationalities.dutch.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionMalta: {
    i18nKey: 'mifirPopupFirstOptionMalta',
    defaults: 'First Priority Identifier: Your National ID Card.',
    countriesAvailable: [nationalities.maltese.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionMalta: {
    i18nKey: 'mifirPopupSecondOptionMalta',
    defaults: 'Second Priority Identifier: Your Passport in case the ID card is not available.',
    countriesAvailable: [nationalities.maltese.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionEstonia: {
    i18nKey: 'mifirPopupFirstOptionEstonia',
    defaults: 'Your Personal Identification Code (Isikukood) existing on the National ID.',
    countriesAvailable: [nationalities.estonian.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionIceland: {
    i18nKey: 'mifirPopupFirstOptionIceland',
    defaults: 'Your Personal Identification Code (Kennitala) existing on the Passport.',
    countriesAvailable: [nationalities.icelander.key],
    defaultMifirType: 'nid',
  },
  mifirPopupGeneral: {
    i18nKey: 'mifirPopupGeneral',
    defaults: 'Your Passport in case it was not already submitted.',
    defaultMifirType: 'pid',
  },
  mifirSuccessfulUpdate: {
    i18nKey: 'mifirSuccessfulUpdate',
    defaults: 'Your MiFIR details have been successfully updated.',
  },
  mifirFailedUpdate: {
    i18nKey: 'mifirFailedUpdate',
    defaults: 'Your MifIR details have failed to be updated.',
  },
  requestCallback: {
    i18nKey: 'requestCallback',
    defaults: 'Request Callback'
  },
  spreadsDepending: {
    i18nKey: 'spreadsDepending',
    defaults: '1. Trading Alerts are provided by BDSwiss’ professional traders in real time via our VIP Telegram Channel. All Black Account holders are given automatic access to the channel.'
  },
  noCommisions: {
    i18nKey: 'noCommisions',
    defaults: '2. No Commissions apply on Index products.'
  },
  subjectAppropriate: {
    i18nKey: 'subjectAppropriate',
    defaults: '3. Spreads can vary depending on the asset and type of account you choose to trade with and may increase the costs of your trades. View our full list of assets and average spreads.'
  },
  averageSuccess: {
    i18nKey: 'averageSuccess',
    defaults: '4. Priority of Transactions i.e. faster withdrawal processing.'
  },
  joinVipTelegram: {
    i18nKey: 'joinVipTelegram',
    defaults: 'Join our VIP Telegram channel to receive live trading alerts'
  },
  alreadyBlack: {
    i18nKey: 'alreadyBlack',
    defaults: 'Already a Black Account Holder?'
  },
  askManager: {
    i18nKey: 'askManager',
    defaults: 'Ask your Account Manager to add you to the channel today!'
  },
  riskWarningTitle: {
    i18nKey: 'riskWarningTitle',
    defaults: 'Risk Warning:'
  },
  riskWarning: {
    i18nKey: 'riskWarning',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <3 />Registered address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. For complaints please email us at <1>{{complaintsEmail}}</1>. <4 />German address (for postal letters): Mainzer Landstrasse 41, 60329-Frankfurt, Germany'
  },
  riskWarningMau: {
    i18nKey: 'riskWarningMau',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 71% of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.'
  },
  riskWarningEf: {
    i18nKey: 'riskWarningEf',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}}% of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.'
  },
  riskWarningVa: {
    i18nKey: 'riskWarningVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation <0>here</0>.'
  },
  riskWarningSMEu: {
    i18nKey: 'riskWarningSMEu',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <3 />Registered address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. For complaints please email us at <1>{{complaintsEmail}}</1>. <4 />German address (for postal letters): Mainzer Landstrasse 41, 60329-Frankfurt, Germany'
  },
  riskWarningSMMau: {
    i18nKey: 'riskWarningSMMau',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (<2>FSC</2>) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the company policies regarding the regulation <0>here</0>. <4 />Swissmarkets.com is operated by BDS Swiss Markets Global Services Ltd which is the primary payment provider and website operator. BDS Swiss Markets Global Services Ltd is acting on behalf of its related company, BDS Markets. <3 />For complaints please email us at: <1>{{complaintsEmail}}</1>'
  },
  riskWarningSMVa: {
    i18nKey: 'riskWarningSMVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation <0>here</0>.'
  },
  riskWarningTM: {
    i18nKey: 'riskWarningTM',
    defaults: 'custom TauroMarkets risk warning message in english (default)'
  },
  legal: {
    i18nKey: 'legal',
    defaults: 'Legal'
  },
  privacyPolicy: {
    i18nKey: 'privacyPolicy',
    defaults: 'Privacy Policy'
  },
  disclaimer: {
    i18nKey: 'disclaimer',
    defaults: 'Disclaimer'
  },
  termsAndConditions: {
    i18nKey: 'termsAndConditions',
    defaults: 'Terms and conditions'
  },
  regulation: {
    i18nKey: 'regulation',
    defaults: 'Regulation'
  },
  complaints: {
    i18nKey: 'complaints',
    defaults: 'Complaints'
  },
  getAppstore: {
    i18nKey: 'getAppstore',
    defaults: 'Get it on App Store'
  },
  getGoogleplay: {
    i18nKey: 'getGoogleplay',
    defaults: 'Get it on Google Play'
  },
  likeTwitter: {
    i18nKey: 'likeTwitter',
    defaults: 'Like us on Twitter'
  },
  likeFacebook: {
    i18nKey: 'likeFacebook',
    defaults: 'Like us on Facebook'
  },
  followTelegram: {
    i18nKey: 'followTelegram',
    defaults: 'Follow us on Telegram'
  },
  lowerSpreads: {
    i18nKey: 'lowerSpreads',
    defaults: 'Lower Spreads'
  },
  feesDeposits: {
    i18nKey: 'feesDeposits',
    defaults: 'Fees on Deposits & No Commissions'
  },
  priorityTransactions: {
    i18nKey: 'priorityTransactions',
    defaults: 'Priority of Transactions'
  },
  supportPersonalManager: {
    i18nKey: 'supportPersonalManager',
    defaults: 'Dedicated Multilingual Support & Personal Account Manager'
  },
  complimentarySessions: {
    i18nKey: 'complimentarySessions',
    defaults: 'Complimentary 1:1 Trading Session'
  },
  liveAlerts: {
    i18nKey: 'liveAlerts',
    defaults: 'Live Trading Alerts'
  },
  blackTitleCallback: {
    i18nKey: 'blackTitleCallback',
    defaults: 'Learn how you can upgrade to BDSwiss Black Account'
  },
  supportTitleCallback: {
    i18nKey: 'supportTitleCallback',
    defaults: 'Schedule support call-back'
  },
  oneOnOneTitleCallback: {
    i18nKey: 'oneOnOneTitleCallback',
    defaults: 'Book a free one-on-one session'
  },
  fullName: {
    i18nKey: 'fullName',
    defaults: 'Full Name'
  },
  phoneNumber: {
    i18nKey:'phoneNumber',
    defaults: 'Phone Number'
  },
  bdswissEmail: {
    i18nKey:'bdswissEmail',
    defaults: 'BDSwiss email account'
  },
  timeHours: {
    i18nKey:'timeHours',
    defaults: 'Time, hours'
  },
  minutes: {
    i18nKey:'minutes',
    defaults: 'Minutes'
  },
  timeZone: {
    i18nKey:'timezone',
    defaults: 'Timezone'
  },
  preferableLanguage: {
    i18nKey:'preferableLanguage',
    defaults: 'Preferable Language'
  },
  blackAccountNote: {
    i18nKey:'blackAccountNote',
    defaults: '* Please note that our Account Manager will not be providing investment advice of any kind. You are solely responsible for any investment you choose to make using your own funds.'
  },
  tradingAlerts: {
    i18nKey:'tradingAlerts',
    defaults: 'Get real time trading alerts'
  },
  tradingAlertsp1: {
    i18nKey:'tradingAlertsp1',
    defaults: 'Exclusively for '
  },
  tradingAlertsp1Strong: {
    i18nKey:'tradingAlertsp1Strong',
    defaults: 'Exclusively for <0>Black Account Holders</0>'
  },
  tradingAlertsp2: {
    i18nKey:'tradingAlertsp2',
    defaults: 'BDSwiss analysts study the market constantly, taking into consideration all the different parameters that may affect key assets, to provide our VIP clients with live trading alerts via our offcial Telegram channel.'
  },
  tradingAlertsp3: {
    i18nKey:'tradingAlertsp3',
    defaults: 'Get started in two easy steps'
  },
  tradingAlertsStep1: {
    i18nKey:'tradingAlertsStep1Text',
    defaults: '<0>Step1:</0> Download Telegram app on your phone and set up your account'
  },
  tradingAlertsbtn1: {
    i18nKey:'tradingAlertsbtn1',
    defaults: 'Telegram for Android'
  },
  tradingAlertsbtn2: {
    i18nKey:'tradingAlertsbtn2',
    defaults: 'Telegram for iPhone and iPad'
  },
  tradingAlertsbtn3: {
    i18nKey:'tradingAlertsbtn3',
    defaults: 'Telegram for web'
  },
  tradingAlertsStep2: {
    i18nKey:'tradingAlertsStep2',
    defaults: '<0>Step 2:</0> Request to join our VIP Telegram channel'
  },
  accessVipAlertsTextAbove: {
    i18nKey:'accessVipAlertsTextAbove',
    defaults: 'Already <0>Black Account Holder?</0>'
  },
  accessVipAlerts: {
    i18nKey:'accessVipAlerts',
    defaults: 'Access VIP Alerts channel'
  },
  joinBdswissTextAbove: {
    i18nKey:'joinBdswissTextAbove',
    defaults: 'Get a taste. Discover benefits.'
  },
  joinBdswiss: {
    i18nKey:'joinBdswiss',
    defaults: 'Join BDSwiss channel'
  },
  viaTelegram: {
    i18nKey:'viaTelegram',
    defaults: 'Via <0>Telegram</0>'
  },
  skipWaitingListMessage: {
    i18nKey:'skipWaitingListMessage',
    defaults: '<0>BDSwiss Black Accounts</0> get priority for all KYC document processing and fund withdrawals among many other benefits such as tighter spreads.'
  },
  useMyResidentialAddressAsTheCorrespondenceAddress: {
    i18nKey: 'useMyResidentialAddressAsTheCorrespondenceAddress',
    defaults: 'Use my residential address as the correspondence address',
  },
  upgradeNow: {
    i18nKey:'upgradeNow',
    defaults: 'Upgrade now'
  },
  skip: {
    i18nKey:'skip',
    defaults: 'How to skip the waiting list'
  },
  isReadonlyAccount: {
    i18nKey: 'isReadonlyAccount',
    defaults: 'Your account is currently readonly.',
  },
  accountReadonlyVerificationNeeded: {
    i18nKey: 'accountReadonlyVerificationNeeded',
    defaults: 'Your account is currently readonly, as it is not fully verified yet. <0> Click to verify now. </0>',
  },
  isReadonlyAccountWithReason: {
    i18nKey: 'isReadonlyAccountWithReason',
    defaults: 'Your account is currently readonly. Reason:',
  },
  financialInfo: {
    i18nKey: 'financialInfo',
    defaults: 'Financial info',
  },
  financialBackgroundAndResources: {
    i18nKey: 'financialBackgroundAndResources',
    defaults: 'Financial Background And Resources',
  },
  managedAccount: {
    i18nKey: 'managedAccount',
    defaults: 'Managed Account',
  },
  agreeContinue: {
    i18nKey: 'agreeContinue',
    defaults: 'Agree & Continue',
  },
  dearClient: {
    i18nKey: 'dearClient',
    defaults: 'Dear Client,',
  },
  pammDisclaimer1: {
    i18nKey: 'pammDisclaimer1',
    defaults: 'Managed Accounts are not offered to clients of BDSwiss Holding Ltd (CYSEC).'
  },
  pammTradingAccount: {
    i18nKey: 'pammTradingAccount',
    defaults: 'PAMM Trading Account',
  },
  pammDisclaimer2: {
    i18nKey: 'pammDisclaimer2',
    defaults: 'Clients wishing to operate a Managed Account must do so with BDS Markets, a regulated entity with the FSC.'
  },
  pammDisclaimer3: {
    i18nKey: 'pammDisclaimer3',
    defaults: 'BDS Markets is a BDSwiss Group Company licensed with the FSC.'
  },
  pammDisclaimer4: {
    i18nKey: 'pammDisclaimer4',
    defaults: 'By continuing and accepting, you acknowledge and accept the following:'
  },
  pammDisclaimerMessage1: {
    i18nKey: 'pammDisclaimerMessage1',
    defaults: 'You allow BDSwiss Holding Ltd to forward your personal information to BDS Markets for account opening and operations.',
  },
  pammDisclaimerMessage2: {
    i18nKey: 'pammDisclaimerMessage2',
    defaults: 'You accept BDSwiss Holding Ltd to act only as a payment intermediary for the Managed Account on behalf of BDS Markets.',
  },
  pammDisclaimerMessage3: {
    i18nKey: 'pammDisclaimerMessage3',
    defaults: 'You are bound by the <0>Terms and Conditions</0> of BDS Markets',
  },
  commission: {
    i18nKey: 'commission',
    defaults: 'Commission',
  },
  partnershipPlatform: {
    i18nKey: 'partnershipPlatform',
    defaults: 'Partnership platform',
  },
  inProgress: {
    i18nKey:'inProgress',
    defaults: 'In progress'
  },
  inQueue: {
    i18nKey:'inQueue',
    defaults: 'Position {{position}} in queue'
  },
  downloadMT4:{
    i18nKey:'downloadMT4',
    defaults: 'Download {{product}}'
  },
  webtraderMT4:{
    i18nKey: 'webtraderMT4',
    defaults: 'MT4 WebTrader'
  },
  footer: {
    i18nKey:'footer',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure  that you are aware of all our <2>legal documents</2>, <3>T&Cs</3> and <4>Privacy Policy</4>.<5> </5>'
  },
  footerBinary: {
    i18nKey:'footerBinary',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure  that you are aware of all our <2>legal documents</2>, <3>T&Cs</3>, <4>Binary Options T&Cs</4> and <5>Privacy Policy</5>.<6> </6>'
  },
  openTrades: {
    i18nKey: 'openTrades',
    defaults: 'Open trades',
  },
  closedTrades: {
    i18nKey: 'closedTrades',
    defaults: 'Closed trades',
  },
  pendingTrades: {
    i18nKey: 'pendingTrades',
    defaults: 'Pending trades',
  },
  sendComplaint: {
    i18nKey: 'sendComplaint',
    defaults: '<0>Send Complaint</0>',
  },
  depositNow: {
    i18nKey: 'depositNow',
    defaults: 'Deposit now',
  },
  outstandingActivationDeposit: {
    i18nKey: 'outstandingActivationDeposit',
    defaults: 'Your account is currently readonly and needs to be funded before activation'
  },
  personalDetailsChanged: {
    i18nKey: 'personalDetailsChanged',
    defaults: 'Personal details submitted successfully! Please allow up to 24 hours for our team to review this'
  },
  economicProfileChanged: {
    i18nKey: 'economicProfileChanged',
    defaults: 'Economic profile submitted successfully! Go to <0>Dashboard</0>'
  },
  oneOnOne: {
    i18nKey: 'oneOnOne',
    defaults: 'One on One'
  },
  oneOnOnep1: {
    i18nKey: 'oneOnOnep1',
    defaults: 'Book your 5-minute slot with one of our expert trading coaches and access the training you need to redefine your trading, they can help you get started and discuss any personal challenges you may be facing. You get to choose the topic!'
  },
  bookCall: {
    i18nKey: 'bookCall',
    defaults: 'Book a call'
  },
  bookCallp1: {
    i18nKey: 'bookCallp1',
    defaults: 'Have any questions about your account settings, dashboard, verification process or trading platform?'
  },
  bookCallp2: {
    i18nKey: 'bookCallp2',
    defaults: 'Have a chat with a support representative at your preferred time and date by requesting a free call-back.'
  },
  bookCallp3: {
    i18nKey: 'bookCallp3',
    defaults: 'They’ll walk you through the platform and help you discover the tools only the pros know about.'
  },
  transferFunds: {
    i18nKey: 'transferFunds',
    defaults: 'Transfer funds'
  },
  isSuspendedTradingStatusWithReason: {
    i18nKey: 'isSuspendedTradingStatusWithReason',
    defaults: 'Your trading status is currently suspended.',
  },
  withdrawalAccountWithoutBalance: {
    i18nKey: 'withdrawalAccountWithoutBalance',
    defaults: 'You do not have any available funds for withdrawal. Fund your account first.'
  },
  withdrawalByCryptoNotice: {
    i18nKey: 'withdrawalByCryptoNotice',
    defaults:
      'If you would like to withdraw profits via crypto, please contact live chat here in order to verify wallet details.',
  },
  apply: {
    i18nKey: 'apply',
    defaults: 'Apply'
  },
  noTransactions: {
    i18nKey: 'noTransactions',
    defaults: 'There are no transactions.'
  },
  noticeMauritiusMigrationTerms: {
    i18nKey: 'notice_mauritius_migration_terms',
    defaults: `Dear Client,{lineBreak}{lineBreak}
    Due to recent changes in the regulatory environment, we were mandated to transfer your account to another BDSwiss entity within the BDSwiss Group.{lineBreak}
    Please note that we have taken various provisions to make this transition as seamless as possible for our clients. {lineBreak}
    And in that effort we want to reassure you that this transfer will not impact the business relationship between you and the Company. {lineBreak}
    The transfer has no impact on your account balance, trading activity or your history in any way, meaning that you will be able to continue trading as normal, on a different BDSwiss site.{lineBreak}
    You are advised to read the <a href="https://global.bdswiss.com/general-terms-and-conditions/">Terms and Conditions</a> of BDS Markets prior to proceeding.{lineBreak}{lineBreak}
    Should you have any questions regarding the transfer please do not hesitate to contact us at <b>xfer@bdswiss.com</b>.{lineBreak}{lineBreak}
    You were automatically redirected to a different BDSwiss site. We kindly ask you to acknowledge this transition.{lineBreak}
    By clicking on the link below you agree to your account's transfer. {lineBreak}
    And again, we are available for any question you might have, just reach out to us at <b>xfer@bdswiss.com</b>.{lineBreak}{lineBreak}`,
  },
  frenchDisclaimer: {
    i18nKey: 'french_disclaimer',
    defaults: `<div>
      <p>Dear Customer,</p>
      <p> Due to recent new regulations in France for Investor Protection we must take extra precautions for French customers to
        understand the risks involved in trading highly speculative products which {company} offers. </p>
      <p> You hereby acknowledge:
        <ul>
          <li>That you fully understand the risks involved in trading binary and or CFDs contracts</li>
          <li>You understand that your losses may exceed your winnings </li>
          <li>Although the account balance may shall never be negative it may be reduced to 0 if your speculative trades are unsuccessful</li>
          <li>You were not incentivised by {company} or its agents to trade</li>
          <li>You were not solicited by {company} to open an account with {company}</li>
          <li>You deem yourself suitable for trading the products which {company} offers and can demonstrate it upon request. </li>
        </ul>
      </p>
    </div>`,
  },
  clientAgreementChanges20180420: {
    i18nKey: 'client_agreement_changes_20180420',
    defaults: `<div style="text-align: justify">
      <h3>Client Agreement Changes</h3>
      <p> Dear Trader,</p>
      <p> This is a formal announcement in relation to amendments we have made in our Client Agreement.</p>
      <p> We invite you to review the new version of the Client Agreement governing the relationship between {trademark}. and its clients.</p>
      <p>The new version of the Client Agreement will come into force 7 calendar days after the date of the publication of this present notice 20th April 2017.</p>
      <p>
        <b> Important: </b>
          If you do not agree with the conditions of the new version of the Client Agreement, within 7 calendar days of the publication of the present notice,
          you must send a request to terminate your account to support@{emailDomain}.
          If such a request is not received within 7 calendar days from the date of publication of the present notice, the updated Client Agreement will automatically be deemed to have your approval and come into effect.
          If you have any questions, feel free to contact our multilingual, friendly Customer Support who are ready to assist you Monday - Friday, from 09:00 to 18:00 (GMT+3).
      </p>
    </div>`,
  },
  gdprMessage: {
    i18nKey: 'gdpr_message',
    defaults: `<div style="text-align: justify">
    <h3>Client Agreement Changes</h3>
    <p> Dear Client,</p>
    <p> Please note that starting May 25th, we will update our Privacy Policy and Terms and Conditions in accordance with the new EU Data Protection Law which safeguards your personal information from corruption, compromise or loss. </p>
  </div>`,
  },
  genericTermAndConditionUpdate: {
    i18nKey: 'generic_term_and_condition_update',
    defaults: `<div style="text-align: justify">
      <h3> Important Notice: Update to Terms and Conditions </h3>
      <p> Dear Client, </p> <p>  Please see our updated <a target="_blank" href={termsAndConditions}> Terms and Conditions </a>.</p>
      <p>We would like to draw your attention to the changes in the withdrawal terms, please read carefully. </p>
    </div>`,
  },
  blackAccountDateNote: {
    i18nKey: 'blackAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted between Monday to Friday 09:30 and 19:30 EEST time.',
  },
  supportAccountDateNote: {
    i18nKey: 'supportAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted from our support team Monday to Friday (24/5)',
  },
  oneOnOneAccountDateNote: {
    i18nKey: 'oneOnOneAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted between Monday to Friday 09:30 and 19:30 EEST time.',
  },
  thankYou:{
    i18nKey: 'thankYou',
    defaults: 'Thank you!',
  },
  messageBlack:{
    i18nKey: 'messageBlack',
    defaults: 'Your message has been successfully submitted.',
  },
  blackContact:{
    i18nKey: 'blackContact',
    defaults: '{{company}} representative will contact you on the selected day & time.',
  },
  iban: {
    i18nKey: 'iban',
    defaults: 'IBAN'
  },
  accountholdername: {
    i18nKey: 'accountholdername',
    defaults: 'Account holder name'
  },
  bic: {
    i18nKey: 'bic',
    defaults: 'BIC'
  },
  front: {
    i18nKey: 'front',
    defaults: 'Front',
  },
  frontSide: {
    i18nKey: 'frontSide',
    defaults: 'Front side',
  },
  backSide: {
    i18nKey: 'backSide',
    defaults: 'Back side',
  },
  uploadDocumentsText: {
    i18nKey: 'uploadDocumentsText',
    defaults: '{{company}}, being a regulated company, is obliged by law to verify your identity and ensure compliance with Anti-Money Laundering rules and regulations. These measures are put in place to protect you and ensure that no unauthorized person can register an account in your name. Accordingly, please provide us with the following documents:',
  },
  poiText: {
    i18nKey: 'poiText',
    defaults: 'Passport and/or national identity card. The attached file must be valid (i.e. not expired) and clearly readable.',
  },
  porText: {
    i18nKey: 'porText',
    defaults: 'A utility bill such as: electricity, water, gas or landline phone bill or a bank statement, showing your name and address as listed on your application and issued within the last 3 months. Please note that we do not accept mobile phone bills and bank receipts as proof of residence. In case you do not have any of the above documents you may provide us with a certificate of residence from the local authority of your country of residence.',
  },
  updateEconomicProfile: {
    i18nKey: 'updateEconomicProfile',
    defaults: 'Next please complete your <0>Economic Profile</0>.',
  },
  mt4LoginDetails: {
    i18nKey: 'mt4LoginDetails',
    defaults: '{{product}} App login details',
  },
  yourBalance: {
    i18nKey: 'yourBalance',
    defaults: 'Your balance is {{balance}}',
  },
  pepQuestion: {
    i18nKey: 'pepQuestion',
    defaults: 'Are you Politically Exposed?',
  },
  pepText: {
    i18nKey: 'pepText',
    defaults: 'A politically exposed person (PEP) is any natural person who is authorised to perform prominent public functions in a country. This includes governors of state, members of Parliament, high ranking military officers, and heads of local bodies among others. You could also qualify as a PEP if you are a family member, a partner, or a close relative of such an individual.',
  },
  pepText2: {
    i18nKey: 'pepText2',
    defaults: 'Please note that Politically Exposed Persons require additional documentation in order to qualify for an Account Opening.',
  },
  pepSuccessButton: {
    i18nKey: 'pepSuccessButton',
    defaults: 'Yes, I am Politically Exposed',
  },
  pepCancelButton: {
    i18nKey: 'pepCancelButton',
    defaults: 'No, I am not Politically Exposed',
  },
  politicallyExposedPersonStatus: {
    i18nKey: 'politicallyExposedPersonStatus',
    defaults: 'Politically Exposed Person Status',
  },
  loginExistingAccount: {
    i18nKey: 'loginExistingAccount',
    defaults: 'Select "Login to existing account"',
  },
  usDeclarationTitle: {
    i18nKey: 'usDeclarationTitle',
    defaults: 'U.S. Reportable Citizen Declaration',
  },
  usSuccessButton: {
    i18nKey: 'usSuccessButton',
    defaults: 'Yes, I am a U.S. reportable person',
  },
  usModalTextIntro: {
    i18nKey: 'usModalTextIntro',
    defaults: 'A US Reportable Citizen generally includes the following:',
  },
  usModalText1: {
    i18nKey: 'usModalText1',
    defaults: 'A US citizen or resident individual, including US citizens living outside the US or US citizens and residents operating a business outside the US (This includes green card holders.)',
  },
  usModalText2: {
    i18nKey: 'usModalText2',
    defaults: 'Anyone maintaining a US address (including a P.O. Box address)',
  },
  usModalText3: {
    i18nKey: 'usModalText3',
    defaults: 'A corporation or partnership organized in the US or under the laws of the US or any state thereof, including where the entity is operating a business outside the US',
  },
  usModalText4: {
    i18nKey: 'usModalText4',
    defaults: 'A trust if (i) a court within the US would have authority under applicable law to render orders or judgments concerning substantially all issues regarding administration of the trust, and (ii) one or more US persons have the authority to control all substantial decisions of the trust, or an estate of a decedent that is a citizen or resident of the US',
  },
  usModalText5: {
    i18nKey: 'usModalText5',
    defaults: 'Power of attorney or signatory authority granted to someone with a US address and/or',
  },
  usModalText6: {
    i18nKey: 'usModalText6',
    defaults: 'US telephone number',
  },
  networkError: {
    i18nKey: 'networkError',
    defaults: 'Network Error',
  },
  usCancelButton: {
    i18nKey: 'usCancelButton',
    defaults: 'No, I am not U.S. reportable',
  },
  welcome:{
    i18nKey: 'welcome',
    defaults: 'Welcome',
  },
  affiliateWelcomeTitle:{
    i18nKey: 'affiliateWelcomeTitle',
    defaults: 'Thank you and welcome',
  },
  affiliateWelcomeNotificationText1:{
    i18nKey: 'affiliateWelcomeNotificationText1',
    defaults: 'Your account is pending approval. You will receive an email as soon as we have verified your marketing activities and activated your account.',
  },
  affiliateWelcomeNotificationText2:{
    i18nKey: 'affiliateWelcomeNotificationText2',
    defaults: '(Approval usually happens within 1 working day).',
  },
  affiliateWelcomeNotificationText3:{
    i18nKey: 'affiliateWelcomeNotificationText3',
    defaults: 'For further questions, do not hesitate to contact us via affiliates@{{company}}',
  },
  accountcysecSummary:{
    i18nKey: 'accountcysecSummary',
    defaults: 'EU Account with leverage restrictions',
  },
  accountfscSummary:{
    i18nKey: 'accountfscSummary',
    defaults: 'Offshore account without restrictions',
  },
  accountvfscSummary:{
    i18nKey: 'accountvfscSummary',
    defaults: 'Offshore account without restrictions',
  },
  successSignal: {
    i18nKey: 'successSignal',
    defaults: 'Success',
  },
  failureSignal: {
    i18nKey: 'failureSignal',
    defaults: 'Failure',
  },
  activeSignal:{
    i18nKey: 'activeSignal',
    defaults: 'Active',
  },
  getReadySignal:{
    i18nKey: 'getReadySignal',
    defaults: 'Get Ready',
  },
  closedSignal:{
    i18nKey: 'closedSignal',
    defaults: 'Closed',
  },
  profileSettingsStatus:{
    i18nKey: 'profileSettingsStatus',
    defaults: 'filled'
  },
  pendingApproval:{
    i18nKey: 'pendingApproval',
    defaults: 'Pending Approval',
  },
  verified:{
    i18nKey: 'verified',
    defaults: 'Verified',
  },
  notVerified:{
    i18nKey: 'notVerified',
    defaults: 'Not Verified',
  },
  resetBalanceSubTitle: {
    i18nKey: 'resetBalanceSubTitle',
    defaults: 'Reset Demo Account Balance'
  },
  resetBalance: {
    i18nKey: 'resetBalance',
    defaults: 'Reset Balance'
  },
  resetBalanceSuccess: {
    i18nKey: 'resetBalanceSuccess',
    defaults: 'Your demo balance has been reset! Go to <0>Dashboard</0>'
  },
  noAccounts: {
    i18nKey: 'noAccounts',
    defaults: 'You do not have any live account.'
  },
  withdrawalsOtherReasonMessage: {
    i18nKey: 'withdrawalsOtherReasonMessage',
    defaults: 'Your withdrawal was rejected. Please check your email for details or contact support'
  },
  documentsOtherReasonMessage: {
    i18nKey: 'documentsOtherReasonMessage',
    defaults: 'Your document was rejected. Please check your email for details or contact support'
  },
  transactionDetails: {
    i18nKey: 'transactionDetails',
    defaults: 'Transaction Details'
  },
  verifyAccount: {
    i18nKey: 'verifyAccount',
    defaults: 'Verify Account'
  },
  rejectionReasonTitle:{
    i18nKey: 'rejectedReasonTitle',
    defaults: 'Rejection Reason'
  },
  pendingReasonTitle:{
    i18nKey: 'pendingReasonTitle',
    defaults: 'Pending Reason'
  },
  accountMigration: {
    i18nKey: 'accountMigration',
    defaults: 'Account Migration',
  },
  dearTrader: {
    i18nKey: 'dearTrader',
    defaults: 'Dear Trader,',
  },
  clientMigrationText1: {
    i18nKey: 'clientMigrationText1',
    defaults: 'You have attempted to log in to BDS Markets, regulated by the FSC Mauritius (License No. C116016172 - Domain: {{domain}}).',
  },
  clientMigrationText2: {
    i18nKey: 'clientMigrationText2',
    defaults: 'We have detected that your account is currently under BDSwiss Holding Ltd, regulated by CySec (License No. 199/13 - Domain: {{companyDomain}}.)',
  },
  clientMigrationText3: {
    i18nKey: 'clientMigrationText3',
    defaults: '<0>BDS Markets</0> is a regulated subsidiary of the <0>BDSwiss Group</0>. Clients requesting to trade with BDS Markets are required to open a new account or request to migrate their existing account. Please note that in order to migrate your account any open positions need to be closed.',
  },
  clientMigrationText31: {
    i18nKey: 'clientMigrationText31',
    defaults: 'After migration you may be provided with new MT4 Account details in which case any balances will be automatically transferred to your new trading account and your old account will be disabled from trading and will be only available for viewing your trading history.',
  },
  clientMigrationText4: {
    i18nKey: 'clientMigrationText4',
    defaults: 'Click <0>Confirm</0> to migrate your account and login to {{domain}}.',
  },
  migrateAccept: {
    i18nKey: 'migrateAccept',
    defaults: 'Confirm',
  },
  migrationReject: {
    i18nKey: 'migrationReject',
    defaults: 'No, don\'t migrate my account',
  },
  bdswissMigrationConfirmDisclaimer: {
    i18nKey: 'bdswissMigrationConfirmDisclaimer',
    defaults: '*By clicking “Confirm”, you agree that you have read, understood and accept the <0>Terms and Conditions</0> of BDS Markets.',
  },
  appropriatenessTestNotification: {
    i18nKey: 'appropriatenessTestNotification',
    defaults: 'Please complete your <0>Appropriateness Test</0>.'
  },
  appropriatenessTestLink: {
    i18nKey: 'appropriatenessTestLink',
    defaults: 'Complete approriateness test'
  },
  depositHeldNotification: {
    i18nKey: 'depositHeldNotification',
    defaults: 'You have <0> held deposits </0>, please verify your account.',
  },
  accountPleaseVerify: {
    i18nKey: 'accountPleaseVerify',
    defaults: 'Please <0> verify your account </0> otherwise your account may be suspended.'
  },
  depositsKYCVerification: {
    i18nKey: 'depositsKYCVerification',
    defaults: 'Please complete KYC verification process. Deposits are not allowed until KYC is approved. <0>Profile Settings</0>'
  },
  noDocuments: {
    i18nKey: 'noDocuments',
    defaults: 'You do not have any documents uploaded.'
  },
  readOnly:{
    i18nKey: 'readOnly',
    defaults: 'Read-Only'
  },
  getAstropay:{
    i18nKey: 'getAstropay',
    defaults: 'How to get your AstroPay Card?'
  },
  registerAstropay:{
    i18nKey: 'registerAstropay',
    defaults: 'Register for free at web.astropaycard.com or in the mobile App.'
  },
  purchaseAstropay:{
    i18nKey: 'purchaseAstropay',
    defaults: 'Purchase, Select the value and currency of your AstroPay Card.'
  },
  paymentAstropay:{
    i18nKey: 'paymentAstropay',
    defaults: 'Make the payment. Choose the local payment method and pay in local currency.'
  },
  useAstropay:{
    i18nKey: 'useAstropay',
    defaults: 'Ready to use. You will receive an email with the AstroPay Card information. Enjoy your AstroPay Card.'
  },
  accountDaysPleaseVerify: {
    i18nKey: 'accountDaysPleaseVerify',
    defaults: 'Your account is active. Please <0>verify your account</0> as soon as possible to avoid suspension.'
  },
  accepted:{
    i18nKey: 'accepted',
    defaults: 'Accepted'
  },
  notProcessed:{
    i18nKey: 'notProcessed',
    defaults: 'Not Processed'
  },
  passwordsNotSame:{
    i18nKey: 'passwordsNotSame',
    defaults: 'Passwords do not match!'
  },
  broker:{
    i18nKey: 'broker',
    defaults: 'Broker'
  },
  autoVerificationSent:{
    i18nKey: 'autoVerificationSent',
    defaults: 'Auto Verification Sent'
  },
  autoVerificationReceived:{
    i18nKey: 'autoVerificationReceived',
    defaults: 'Auto Verification Received'
  },
  autoVerificationRejected:{
    i18nKey: 'autoVerificationRejected',
    defaults: 'Auto Verification Rejected'
  },
  autoVerificationAccepted:{
    i18nKey: 'autoVerificationAccepted',
    defaults: 'Auto Verification Accepted'
  },
  autoVerificationError:{
    i18nKey: 'autoVerificationError',
    defaults: 'Auto Verification Failed'
  },
  expressAccountVerification:{
    i18nKey: 'expressAccountVerification',
    defaults: 'Express Account Verification'
  },
  pendingTranslation:{
    i18nKey: 'pendingTranslation',
    defaults: 'Pending Translation'
  },
  noEditLeveragePermissions:{
    i18nKey: 'noEditLeveragePermissions',
    defaults: 'You don\'t have permissions to edit leverage'
  },
  kycRejectedNotification: {
    i18nKey: 'kycRejectedNotification',
    defaults: 'Verification status has been temporarily rejected due to the following reason: <0>{{reason}}</0>.',
  },
  underMonitoringNotification: {
    i18nKey: 'underMonitoringNotification',
    defaults: 'Your deposit is <0>held</<0> and under manual review. Held deposits are reviewed with priority. <1>Contact Support</1> for futher questions. Reason: {{reason}}.',
  },
  depositCommisions: {
    i18nKey: 'depositCommisions',
    defaults: 'Deposit Commisions'
  },
  transferDepositCommisions: {
    i18nKey: 'transferDepositCommisions',
    defaults: 'Transfer Deposit Commisions',
  },
  transferb2b: {
    i18nKey: 'transferb2b',
    defaults: 'Transfer Deposit B2B',
  },
  reimbursementSettlement: {
    i18nKey: 'reimbursementSettlement',
    defaults: 'Reimbursement/Settlement',
  },
  demoFunds: {
    i18nKey: 'demoFunds',
    defaults: 'Demo Funds',
  },
  adjustmentCorrection: {
    i18nKey: 'adjustmentCorrection',
    defaults: 'Adjustment/Correction',
  },
  cfdModalMessage: {
    i18nKey: 'cfdModalMessage',
    defaults: 'You are about to acquire a product that is not simple and may be difficult to understand: Contract for Difference (CFD). The CNMV considers that due to the complexity and high risk, the product is not suitable for retail investors. The product you are about to acquire is a product with leverage. You should be aware that the losses acquired via trading this product may exceed the amount that is required to open the position.',
  },
  cfdModalAcknowledgement: {
    i18nKey: 'cfdModalAcknowledgement',
    defaults: 'I fully understand this product is complex and CNMV has determined that it is not convenient for me.',
  },
  leverageModalMessage: {
    i18nKey: 'leverageModalMessage',
    defaults: 'The financial product you are about to acquire (CFDs) is a product with leverage. You must be aware that losses may be greater than the amount disbursed initially for acquisition. The financial instrument is difficult to understand. Considering its complex nature and the risk associated, the CNMV considers that it is inappropriate for retail clients. The product that I am dealing in is complex and the CNMV considers it to be not suitable for me.',
  },
  leverageModalAcknowledgement: {
    i18nKey: 'leverageModalAcknowledgement',
    defaults: 'Please acknowledge of the understanding of risk involved in trading with margined products',
  },
  cfdModalClientSignature: {
    i18nKey: 'cfdModalClientSignature',
    defaults: 'Please type in your full name below to confirm the above',
  },
  signatureNotMatch: {
    i18nKey: 'signatureNotMatch',
    defaults: 'Signature does not match the client name.'
  },
  changeLeverageNoticeEu: {
    i18nKey: 'changeLeverageNoticeEu',
    defaults: 'The European Securities and Markets Authority introduced as of the 1st August 2018 leverage restrictions to the Sale of CFDs to retail investors',
  },
  leverageLimitations: {
    i18nKey: 'leverageLimitations',
    defaults: 'The leverage limitations are as follows:',
  },
  contactSupportEu: {
    i18nKey: 'contactSupportEu',
    defaults: 'To increase your leverage, send us a request to qualify as a <0>professional trader</0>. For more questions, please contact <1>support</1>.',
  },
  changeLeverageLimitation1: {
    i18nKey: 'changeLeverageLimitation1',
    defaults: '30:1 for major currency pairs',
  },
  changeLeverageLimitation2: {
    i18nKey: 'changeLeverageLimitation2',
    defaults: '20:1 for non-major currency pairs, gold and major indices',
  },
  changeLeverageLimitation3: {
    i18nKey: 'changeLeverageLimitation3',
    defaults: '10:1 for commodities other than gold and non-major equity indices',
  },
  changeLeverageLimitation4: {
    i18nKey: 'changeLeverageLimitation4',
    defaults: '5:1 for individual equities and other reference values',
  },
  changeLeverageLimitation5: {
    i18nKey: 'changeLeverageLimitation5',
    defaults: '2:1 for cryptocurrencies',
  },
  initialMargin:{
    i18nKey: 'initialMargin',
    defaults: 'Initial Margin',
  },
  entityRedirectionHeader: {
    i18nKey: 'entityRedirectionHeader',
    defaults: 'Redirection Notice',
  },
  entityRedirectionMessage: {
    i18nKey: 'entityRedirectionMessage',
    defaults: 'Our services are offered to your jurisdiction through our affiliate entity {{currentEntity}}, to which you were redirected',
  },
  deleteAccountSuccess: {
    i18nKey: 'deleteAccountSuccess',
    defaults: 'Your account has been deleted. You will be logged out.',
  },
  skipToStep:{
    i18nKey: 'skipToStep',
    defaults: 'Skip to step {{step}}'
  },
  skipButton:{
    i18nKey: 'skipButton',
    defaults: 'Skip'
  },
  signUp2min:{
    i18nKey: 'signUp2min',
    defaults: 'Get Started in less than 49 seconds',
  },
  signUpAffiliate:{
    i18nKey: 'signUpAffiliate',
    defaults: 'Register as an <0>Affiliate Partner</0>',
  },
  signUpMt5:{
    i18nKey: 'signUpMt5',
    defaults: 'Get Started with MT5',
  },
  emailExists:{
    i18nKey: 'emailExists',
    defaults: 'Email already exists',
  },
  affiliateEmailExists:{
    i18nKey: 'affiliateEmailExists',
    defaults: 'Affiliate email exists',
  },
  emailNotValidDomain:{
    i18nKey: 'emailNotValidDomain',
    defaults: 'Invalid email',
  },
  signUpCheckbox:{
    i18nKey: 'signUpCheckbox',
    defaults: 'I hereby verify that I am 18 years or older, I have read and accept your <0>General Terms & Conditions</0> of {{company}}, <1>Legal Documents </1>, <2>Personal Data Protection</2>',
  },
  signUpAffiliateCheckbox:{
    i18nKey: 'signUpAffiliateCheckbox',
    defaults: 'I am 18 years or older and I have read and accept your affiliate <0>General Terms & Conditions</0>, <1>Legal Documents </1>, <2>Personal Data Protection</2>, <3>Partner Agreement</3>, <4>Code of Conduct</4> & <5>Marketing Guidelines</5>',
  },
  isLessThan6CharsMoreThan32:{
    i18nKey: 'isLessThan6CharsMoreThan32',
    defaults: 'Password length has to be between 6 to 32 characters.',
  },
  isLessThan8CharsMoreThan16:{
    i18nKey: 'isLessThan8CharsMoreThan16',
    defaults: 'Password length has to be between 8 to 16 characters.',
  },
  noAnyLetter:{
    i18nKey: 'noAnyLetter',
    defaults: 'Password must contain at least one letter.',
  },
  noDigit:{
    i18nKey: 'noDigit',
    defaults: 'Password must contain at least one digit.',
  },
  skypeId:{
    i18nKey: 'skypeId',
    defaults: 'Skype',
  },
  website:{
    i18nKey: 'website',
    defaults: 'Website / Marketing Activity',
  },
  affiliateCountries:{
    i18nKey: 'affiliateCountries',
    defaults: 'What countries do you promote in',
  },
  expectedIn: {
    i18nKey: 'expectedIn',
    defaults: 'Expected in: {{processTime}}'
  },
  expiryDate: {
    i18nKey: 'expiryDate',
    defaults: 'Expiry Date'
  },
  invalidMonth: {
    i18nKey: 'invalidMonth',
    defaults: 'Month is invalid'
  },
  invalidYear: {
    i18nKey: 'invalidYear',
    defaults: 'Year is invalid'
  },
  pendingConfirmation: {
    i18nKey: 'pendingConfirmation',
    defaults: 'Pending confirmation'
  },
  documentsVerifiedFootNote: {
    i18nKey: 'documentsVerifiedFootNote',
    defaults: '*Please refer to our <0>Terms and Conditions</0> for our full documentation requirements'
  },
  eVerified:{
    i18nKey: 'eVerified',
    defaults: 'E-Verified'
  },
  countryRegulator: {
    i18nKey: 'countryRegulator',
    defaults: 'Your selected country regulator is:'
  },
  regulatorEntity: {
    i18nKey: 'regulatorEntity',
    defaults: 'Regulated by the {{regulator}}'
  },
  reviewCountryRegulator: {
    i18nKey: 'reviewCountryRegulator',
    defaults: 'Review country regulator <0>here</0>.'
  },
  regulator: {
    i18nKey: 'regulator',
    defaults: 'Regulator'
  },
  registeredUsers:{
    i18nKey: 'registeredUsers',
    defaults: 'registered users'
  },
  avgTrading:{
    i18nKey: 'avgTrading',
    defaults: 'avg. trading volume per month'
  },
  depositMethods:{
    i18nKey: 'depositMethods',
    defaults: 'deposit methods'
  },
  avgWithdrawal:{
    i18nKey: 'avgWithdrawal',
    defaults: 'avg. withdrawal time'
  },
  introducedInstant:{
    i18nKey: 'introducedInstant',
    defaults: '* Introduced instant credit card withdrawals'
  },
  supportTime:{
    i18nKey: 'supportTime',
    defaults: 'support response time'
  },
  companyFirms:{
    i18nKey: 'companyFirms',
    defaults: '{{company}} Group consists of the following Investment Firms'
  },
  leverageUp:{
    i18nKey: 'leverageUp',
    defaults: 'Leverage up to: 1:{{leverage}}'
  },
  leverageRetailClient30:{
    i18nKey: 'leverageRetailClient30',
    defaults: '<0>1:30 for retail clients</0>'
  },
  leverageRetailClient400:{
    i18nKey: 'leverageRetailClient400',
    defaults: '<0>1:400 for retail clients</0>'
  },
  leverageProfesionalClient400:{
    i18nKey: 'leverageProfesionalClient400',
    defaults: '<0>1:400 for professional clients</0>'
  },
  icfProtection:{
    i18nKey: 'icfProtection',
    defaults: 'ICF Protection'
  },
  negativeBalance:{
    i18nKey: 'negativeBalance',
    defaults: 'Negative balance protection'
  },
  insurance:{
    i18nKey: 'insurance',
    defaults: 'Professional indemnity insurance'
  },
  groupNumbers:{
    i18nKey: 'groupNumbers',
    defaults: 'Group in numbers'
  },
  regulationDialogTitle:{
    i18nKey: 'regulationDialogTitle',
    defaults: 'Local restrictions / Cross-border services'
  },
  regulationDialogTitleCysec:{
    i18nKey: 'regulationDialogTitleCysec',
    defaults: 'Important Notification'
  },
  regulationDialogText1:{
    i18nKey: 'regulationDialogText1',
    defaults: '{{trademark}}. (License No. {{license}} - Domain {{domain}}) is a financial services institution outside the European Union Area, which is subject to the supervision of the {{regulatorName}} ({{regulator}}).'
  },
  regulationDialogText2:{
    i18nKey: 'regulationDialogText2',
    defaults: 'This website may contain information in particular regarding financial services and products that could be regarded by a E.U. supervisory authority as an offer of financial services targeted in Europe.'
  },
  regulationDialogText3:{
    i18nKey: 'regulationDialogText3',
    defaults: 'By clicking on “continue” to be able to visit this website, you confirm that you have read and understood this notice and that you are visiting this website on your own initiative, without any encouragement whatsoever from {{trademark}}.'
  },
  regulationDialogText4:{
    i18nKey: 'regulationDialogText4',
    defaults: 'If you would like to visit the EU website please click cancel to be redirected.'
  },
  selectAll:{
    i18nKey: 'selectAll',
    defaults: 'Select All'
  },
  selectNone:{
    i18nKey: 'selectNone',
    defaults: 'Select None'
  },
  tradesmarterMessage: {
    i18nKey: 'tradesmarterMessage',
    defaults: 'Due to recent changes in the European Union regulatory environment in the relation to {{product}}, we have the obligation to inform you that your {{product}} account (only) will be deactivated by 2nd of July 2018',
  },
  tradesmarterSubMessage: {
    i18nKey: 'tradesmarterSubMessage',
    defaults: 'Please transfer your funds to a Forex account to continue trading with {{company}}.',
  },
  tradesmarterNote: {
    i18nKey: 'tradesmarterNote',
    defaults: 'Please note that if your account was already inactive due to inactivity, you will continue to incur inactivity fees until you transfer your funds to your Forex/CFD Account',
  },
  tradesmarterContactUs: {
    i18nKey: 'tradesmarterContactUs',
    defaults: 'Should you have any questions regarding the tranfer please do not hesitate to contact your {{company}} Account Manager or {{company}} support at <0>{{supportEmail}}</0>',
  },
  eVerificationFieldLabel: {
    i18nKey: 'eVerificationFieldLabel',
    defaults: 'Please type in the required details below, to be able to proceed',
  },
  eVerificationModal:{
    i18nKey: 'eVerificationModal',
    defaults: 'Please complete the information required below to get the best result through our auto-verification process. Note that we will not be able to automatically approve your account if you provide inaccurate or wrong profile details.',
  },
  phoneCode: {
    i18nKey: 'phoneCode',
    defaults: 'Phone Code'
  },
  billingAddress: {
    i18nKey: 'billingAddress',
    defaults: 'Billing Address'
  },
  billingAddressNote: {
    i18nKey: 'billingAddressNote',
    defaults: 'Your bank requires us to collect your address information to process the transaction.'
  },
  endingIn:{
    i18nKey: 'endingIn',
    defaults: 'ending in'
  },
  yourCards:{
    i18nKey: 'yourCards',
    defaults: 'Your Cards'
  },
  addNewCard:{
    i18nKey: 'addNewCard',
    defaults: 'Add new card'
  },
  smsVerification:{
    i18nKey: 'smsVerification',
    defaults: 'SMS Verification'
  },
  awaitingVerification:{
    i18nKey: 'awaitingVerification',
    defaults: 'Awaiting Verification'
  },
  smsCode:{
    i18nKey: 'smsCode',
    defaults: 'Please enter the 4-digit code you will receive shortly by SMS below'
  },
  enterCode:{
    i18nKey: 'enterCode',
    defaults: 'Enter 4-digit code'
  },
  resendSms:{
    i18nKey: 'resendSms',
    defaults: 'Resend SMS'
  },
  changePhoneNumber:{
    i18nKey: 'changePhoneNumber',
    defaults: 'Change Phone Number'
  },
  addPhoneNumber:{
    i18nKey: 'addPhoneNumber',
    defaults: 'Add Phone Number'
  },
  proceedSecure:{
    i18nKey: 'smsCodeproceedSecure',
    defaults: 'Proceed and Secure Account'
  },
  contactSupportProblems:{
    i18nKey: 'contactSupportProblems',
    defaults: 'Please contact support in case of problems'
  },
  codeSendTo:{
    i18nKey: 'codeSendTo',
    defaults: 'The verification code will be send to: <0>{{phone}}</0>'
  },
  phoneChangedCodeSent:{
    i18nKey: 'phoneChangedCodeSend',
    defaults: 'Your phone number has been changed. A verification code has been sent.'
  },
  phoneConfirmation:{
    i18nKey: 'phoneConfirmation',
    defaults: 'Phone Confirmation'
  },
  improveAccountProtection:{
    i18nKey: 'improveAccountProtection',
    defaults: 'Improve your account protection by linking your phone number'
  },
  goBack:{
    i18nKey: 'goBack',
    defaults: 'Go Back'
  },
  unableToCopyTrade: {
    i18nKey: 'unableToCopyTrade',
    defaults: 'Unable to copy trade',
  },
  unableCopyTradeMessage: {
    i18nKey: 'unableCopyTradeMessage',
    defaults: 'This strategy belongs to an affiliate with a {{accountType}} account. Only clients with a {{accountType}} account can copy this trading strategy',
  },
  unableCopyTradeAdvice: {
    i18nKey: 'unableCopyTradeAdvice',
    defaults: 'To sign up for a {{accountType}} account, go to ‘Add a New Account’ and select ‘{{accountType}} account’',
  },
  okUnderstoodLabel: {
    i18nKey: 'okUnderstoodLabel',
    defaults: 'Ok, understood'
  },
  unableToSend:{
    i18nKey: 'unableToSend',
    defaults: 'We were unable to send the verification code to the phone number provided.'
  },
  usedPhone:{
    i18nKey: 'usedPhone',
    defaults: 'Phone number provided already used by another client.'
  },
  wrongCode:{
    i18nKey: 'wrongCode',
    defaults: 'You have provided a wrong code. Please try again.'
  },
  becomeVip: {
    i18nKey: 'becomeVip',
    defaults: 'Become A {{company}} VIP'
  },
  openA: {
    i18nKey: 'openA',
    defaults: 'OPEN A'
  },
  evenLowerSpreads: {
    i18nKey: 'evenLowerSpreads',
    defaults: 'Even lower spreads'
  },
  deticatedSupport: {
    i18nKey: 'deticatedSupport',
    defaults: 'dedicated vip support'
  },
  deticatedSupportText: {
    i18nKey: 'deticatedSupportText',
    defaults: 'Personal Account Manager & Dedicated Multi-lingual support available 24/5'
  },
  lowerSpreadsBlack: {
    i18nKey: 'lowerSpreadsBlack',
    defaults: 'black account holders enjoy 40% lower spreads'
  },
  lowerSpreadsBlackText: {
    i18nKey: 'lowerSpreadsBlackText',
    defaults: 'Receive 1 on 1 Education or Trading Sessions and access VIP events'
  },
  learnMoreBlack: {
    i18nKey: 'learnMoreBlack',
    defaults: 'Learn more about Black Account'
  },
  uploadRules: {
    i18nKey: 'uploadRules',
    defaults: 'The file(s) uploaded should be in image format (.png, .jpg), and be less than 1MB size each.',
  },
  uploadBigFileError: {
    i18nKey: 'uploadBigFileError',
    defaults: 'Files exceed maximum size: {{files}}',
  },
  uploadWrongFormatError: {
    i18nKey: 'uploadWrongFormatError',
    defaults: 'Files are not in required format: {{files}}',
  },
  searchAddress: {
    i18nKey: 'searchAddress',
    defaults: 'Search your address'
  },
  loading: {
    i18nKey: 'loading',
    defaults: 'Loading...',
  },
  nationalIdIT:{
    i18nKey: 'nationalIdIT',
    defaults: 'The NID number is a Codice Fiscale – which is a 16 digit code, all numbers must be entered',
  },
  nationalIdES:{
    i18nKey: 'nationalIdES',
    defaults: 'The NID number is NIF/NIE Number – which is an 8-9 alphanumeric character sequence',
  },
  nationalIdZA:{
    i18nKey: 'nationalIdZA',
    defaults: 'The NID number is the South African ID number - a 13-digit number which is defined by the following format: YYMMDDSSSSCAZ',
  },
  nationalIdBR:{
    i18nKey: 'nationalIdBR',
    defaults: 'The NID number is the CPF number (stands for The Cadastro de Pessoas Físicas) is an 11-digits individual taxpayer identification number. Example of CPF: 231.002.999-00',
  },
  note:{
    i18nKey: 'note',
    defaults: 'Note:',
  },
  nationalIdNumber: {
    i18nKey: 'nationalIdNumber',
    defaults: 'National ID Number'
  },
  nationalIdWhereIs:{
    i18nKey: 'nationalIdWhereIs',
    defaults: 'Where is the NID number?'
  },
  noOptions:{
    i18nKey: 'noOptions',
    defaults: 'No available options'
  },
  tradingCentral:{
    i18nKey: 'tradingCentral',
    defaults: 'Trading Central'
  },
  tcAnalystViews:{
    i18nKey: 'tcAnalystViews',
    defaults: 'TC Analysis',
  },
  tcMarketBuzz:{
    i18nKey: 'tcMarketBuzz',
    defaults: 'TC Market Buzz',
  },
  tcLabsNewsfeeder:{
    i18nKey: 'tcLabsNewsfeeder',
    defaults: 'TC Labs Newsfeeder',
  },
  tcTechnicalInsights:{
    i18nKey: 'tcTechnicalInsights',
    defaults: 'Technical Insights',
  },
  autochartist:{
    i18nKey: 'autochartist',
    defaults: 'Autochartist'
  },
  autochartistDisabled:{
    i18nKey: 'autochartistDisabled',
    defaults: 'Autochartist is DISABLED'
  },
  whyAutochartist:{
    i18nKey: 'whyAutochartist',
    defaults: 'Why Autochartist?'
  },
  autochartistSubtitle:{
    i18nKey: 'autochartistSubtitle',
    defaults: 'Autochartist covers most CFD assets such as Forex, Indices, Commodities, Stocks and Cryptos. Some of the benefits of incorporating Autochartist to your day-to-day trading include:'
  },
  autochartistText1:{
    i18nKey: 'autochartistText1',
    defaults: 'Save valuable time by not having to continuously scan the markets for opportunities'
  },
  autochartistText2:{
    i18nKey: 'autochartistText2',
    defaults: 'Optimize your stop-loss and take-profit levels using volatility analysis'
  },
  autochartistText3:{
    i18nKey: 'autochartistText3',
    defaults: 'Access market data analysis across multiple time frames in real  time'
  },
  autochartistText4:{
    i18nKey: 'autochartistText4',
    defaults: 'No installation needed. Find it on your Dashboard'
  },
  requestAccess:{
    i18nKey: 'requestAccess',
    defaults: 'Request Access'
  },
  confirmAutochartist:{
    i18nKey: 'confirmAutochartist',
    defaults: 'Please confirm that you would like to be contacted by one of our account managers to receive more information on how you can access Autochartist Performance Stats'
  },
  confirmAutochartistDirectActionP1:{
    i18nKey: 'confirmAutochartistDirectActionP1',
    defaults: 'Autochartist Performance Stats is an exclusive feature that enables you to sort forming trends according to the highest probability of hitting the forecast price and discover which setups have been the most successful in the past 6 months!',
  },
  confirmAutochartistDirectActionP2:{
    i18nKey: 'confirmAutochartistDirectActionP2',
    defaults: 'Accessing this tool requires a one-time 500 additional deposit. Would you like to proceed?',
  },
  confirm:{
    i18nKey: 'confirm',
    defaults: 'Confirm'
  },
  closingPosition:{
    i18nKey: 'closingPosition',
    defaults: 'Closing Position'
  },
  closeOrderConfirmation:{
    i18nKey: 'closeOrderConfirmation',
    defaults: 'Are you sure you want to close this position?'
  },
  orderClosed:{
    i18nKey: 'orderClosed',
    defaults: 'Order has closed'
  },
  confirmStreetNumber:{
    i18nKey: 'confirmStreetNumber',
    defaults: 'Please provide building number if applicable'
  },
  requestAccessSuccess:{
    i18nKey: 'requestAccessSuccess',
    defaults: 'You have requested access to the Autochartist VIP'
  },
  requestAccessSuccessVps:{
    i18nKey: 'requestAccessSuccessVps',
    defaults: 'You have requested access to VPS service'
  },
  closePosition:{
    i18nKey: 'closePosition',
    defaults: 'Close Position'
  },
  transferFundsTitle:{
    i18nKey: 'transferFundsTitle',
    defaults: 'Internal Transfer'
  },
  transferFundsCard:{
    i18nKey: 'transferFundsCard',
    defaults: 'Transfer funds between your {{company}} accounts'
  },
  transferSendTitle:{
    i18nKey: 'transferSendTitle',
    defaults: 'External Transfer'
  },
  transferSendCard:{
    i18nKey: 'transferSend',
    defaults: 'Transfer funds to another {{company}} beneficiary account'
  },
  transferSpoaTitle:{
    i18nKey: 'transferSpoaTitle',
    defaults: 'SPOA Transfers'
  },
  transferSpoaCard:{
    i18nKey: 'transferSpoaCard',
    defaults: 'IB: Transfer funds from your account to another trading account'
  },
  transferIbTitle:{
    i18nKey: 'transferIbTitle',
    defaults: 'IB Transfers'
  },
  transferIbCard:{
    i18nKey: 'transferIbCard',
    defaults: 'IB: Transfer funds from your account to your sub IB\'s account'
  },
  affiliateClientId:{
    i18nKey: 'affiliateClientId',
    defaults: 'Client ID'
  },
  affiliateAccountId:{
    i18nKey: 'affiliateAccountId',
    defaults: 'Account ID'
  },
  findClient:{
    i18nKey: 'findClient',
    defaults: 'Find Client'
  },
  accountDetails:{
    i18nKey: 'accountDetails',
    defaults: 'Account Details:'
  },
  bdxBonus: {
    i18nKey: 'bdxBonus',
    defaults: 'Bonus',
  },
  bdxManual: {
    i18nKey: 'bdxManual',
    defaults: 'Deposit',
  },
  tradingCentralDescription:{
    i18nKey: 'tradingCentralDescription',
    defaults: 'An automated investment analytics and analyst research.'
  },
  autochartistDescription:{
    i18nKey: 'autochartistDescription',
    defaults: 'Get live analysis on key trends and emerging trading opportunities.'
  },
  forexCalculatorDescription:{
    i18nKey: 'forexCalculatorDescription',
    defaults: 'Calculate your positions` profit or loss, pip value and required margin.'
  },
  tradingAcademyDescription:{
    i18nKey: 'tradingAcademyDescription',
    defaults: 'Expand your trading knowledge with our wealth of e-courses and live webinars.'
  },
  unVerified: {
    i18nKey: 'unVerified',
    defaults: 'Unverified'
  },
  updateTermsTitle:{
    i18nKey: 'updateTermsTitle',
    defaults: 'Terms and Conditions Update'
  },
  decline: {
    i18nKey: 'decline',
    defaults: 'Decline'
  },
  clientCheckMsg: {
    i18nKey: 'clientCheckMsg',
    defaults: 'Please check account details above before clicking \'Continue\''
  },
  registrationError:{
    i18nKey: 'registrationError',
    defaults: 'Something went wrong. Please contact our support team for more information.'
  },
  connectionError:{
    i18nKey: 'connectionError',
    defaults: 'Connection Error. Please try again later.'
  },
  plan: {
    i18nKey: 'plan',
    defaults: 'Plan',
  },
  rawGrow: {
    i18nKey: 'rawGrow',
    defaults: 'Raw Grow',
  },
  rawPro: {
    i18nKey: 'rawPro',
    defaults: 'Raw Pro',
  },
  rawFree: {
    i18nKey: 'rawFree',
    defaults: 'Raw Free',
  },
  paymentMethod: {
    i18nKey: 'paymentMethod',
    defaults: 'Payment Method',
  },
  subscriptionActive: {
    i18nKey: 'subscriptionActive',
    defaults: 'Your plan will be automatically renewed on <0>{{renewalDate}}</0> and you\'ll be charged <1>{{renewalAmount}}</1>.',
  },
  changeOrCancel: {
    i18nKey: 'changeOrCancel',
    defaults: 'Change or Cancel',
  },
  yourPlan: {
    i18nKey: 'yourPlan',
    defaults: 'Your plan',
  },
  changePlan: {
    i18nKey: 'changePlan',
    defaults: 'Change plan',
  },
  changePlanTitle: {
    i18nKey: 'changePlanTitle',
    defaults: 'Change plan <0>{{plan}}</0>',
  },
  selectPlan: {
    i18nKey: 'selectPlan',
    defaults: 'Select plan',
  },
  free:{
    i18nKey: 'free',
    defaults: 'Free',
  },
  month:{
    i18nKey: 'month',
    defaults: 'Month',
  },
  raw_spread: {
    i18nKey: 'raw_spread',
    defaults: 'Raw Spread*',
  },
  rawSpreadExplanation: {
    i18nKey: 'rawSpreadExplanation',
    defaults: '*Direct Liquidity Provider Spreads with no markup',
  },
  no_Commission: {
    i18nKey: 'no_Commission',
    defaults: '0 Commission',
  },
  majors_only: {
    i18nKey: 'majors_only',
    defaults: 'Only Major FX pairs',
  },
  all_pairs: {
    i18nKey: 'all_pairs',
    defaults: 'All FX Pairs + Gold',
  },
  extra_insurance: {
    i18nKey: 'extra_insurance',
    defaults: 'Extra Insurance',
  },
  free_vps:{
    i18nKey: 'free_vps',
    defaults: 'Free VPS',
  },
  free_historic_tick_data:{
    i18nKey: 'free_historic_tick_data',
    defaults: 'Free Historic Tick Data',
  },
  volume:{
    i18nKey: 'volume',
    defaults: 'Trading Volume',
  },
  currentPlan:{
    i18nKey: 'currentPlan',
    defaults: 'Current Plan',
  },
  proceedButton: {
    i18nKey: 'proceedButton',
    defaults: 'Proceed',
  },
  choosePaymentMethod: {
    i18nKey: 'choosePaymentMethod',
    defaults: 'Choose Payment Method',
  },
  tradingAccountCurrency: {
    i18nKey: 'tradingAccountCurrency',
    defaults: 'Please note that this is the trading account currency',
  },
  paymentFor: {
    i18nKey: 'paymentFor',
    defaults: 'Payment for <0>{{plan}}</0>',
  },
  volumeConsumption: {
    i18nKey: 'volumeConsumption',
    defaults: 'Volume Consumption',
  },
  changePaymentMethod: {
    i18nKey: 'changePaymentMethod',
    defaults: 'Change Payment Method',
  },
  changePlanText: {
    i18nKey: 'changePlanText',
    defaults: 'Do you want to proceed with your current payment method?',
  },
  reactivate: {
    i18nKey: 'reactivate',
    defaults: 'Re-Activate',
  },
  activate: {
    i18nKey: 'activate',
    defaults: 'Activate',
  },
  subscriptionCancelled: {
    i18nKey: 'subscriptionCancelled',
    defaults: 'Your plan has been cancelled. Your trading conditions will be active until the end of your current billing cycle. You can choose to <1>re-activate</1> your subscription until <0>{{renewalDate}}</0>.',
  },
  subscriptionExpired: {
    i18nKey: 'subscriptionExpired',
    defaults: 'Your plan has been terminated. Please <1>choose your plan</1> to continue trading with zero commission. Otherwise you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.',
  },
  subscriptionPendingChange:{
    i18nKey: 'subscriptionPendingChange',
    defaults: 'Your plan will be changed to <0>{{pendingPlan}}</0> on <0>{{renewalDate}}</0> and you\'ll be charged <0>{{renewalAmount}}</0>. Until then you enjoy the advantages of your current plan.',
  },
  growRawConsumptionNote: {
    i18nKey: 'growRawConsumptionNote',
    defaults: 'If you exceed your monthly volume threshold, your plan will be upgraded automatically.',
  },
  subscriptionPaymentHistory:{
    i18nKey: 'subscriptionPaymentHistory',
    defaults: '<0>View Payment History</0>',
  },
  subscription: {
    i18nKey: 'subscription',
    defaults: 'Subscription',
  },
  somethingWrongError: {
    i18nKey: 'somethingWrongError',
    defaults: "Something went wrong, please try again later"
  },
  maxProVolume: {
    i18nKey: 'maxProVolume',
    defaults: "You have reached your trading volume limit. Until <0>{{renewalDate}}</0> you will be charged <0>{{overTheLimitCommission}} per $100.000</0> trading volume."
  },
  cancelledMaxVolume: {
    i18nKey: 'cancelledMaxVolume',
    defaults: "Your plan has been cancelled and you have reached your trading volume limit. From now on, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume."
  },
  cancelledMaxProVolume: {
    i18nKey: 'cancelledMaxProVolume',
    defaults: "Your plan has been cancelled and you have reached your trading volume limit. From now on, you will be charged <0>{{overTheLimitCommission}} per $100.000</0> trading volume."
  },
  checkPlans:{
    i18nKey: 'checkPlans',
    defaults: 'Check available plans'
  },
  subscriptionPendingFree:{
    i18nKey: 'subscriptionPendingFree',
    defaults: 'Your plan will be downgraded to <0>{{pendingPlan}}</0> on <0>{{renewalDate}}</0>. If you have open positions we will not be able to downgrade your plan. Please close all positions before <0>{{renewalDate}}</0> otherwise if you will continue trading, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  subscriptionFailedPayment:{
    i18nKey: 'subscriptionFailedPayment',
    defaults: 'Your payment for your current subscription plan has failed. Please <1>update your payment details</1> to continue trading with zero commission. Otherwise you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  forexMt4Desc:{
    i18nKey: 'forexMt4Desc',
    defaults: 'Open a Basic Account and trade 250+ CFDs on the MetaTrader 4 - the world’s leading platform.'
  },
  forexMt5Desc:{
    i18nKey: 'forexMt5Desc',
    defaults: 'Trade CFDs on top platforms with MT5’s advanced charting tools. Enjoy zero commission, 24/5 multilingual support, and daily market analysis. Spreads start at 1.5 pips.'
  },
  forexMt5mt5StdDesc:{
    i18nKey: 'forexMt5mt5StdDesc',
    defaults: 'Trade CFDs on top platforms with MT5’s advanced charting tools. Enjoy zero commission, 24/5 multilingual support, and daily market analysis. Spreads start at 1.5 pips.'
  },
  forexMt5mt5RawDesc:{
    i18nKey: 'forexMt5mt5RawDesc',
    defaults: 'Cut your trading costs with raw spreads from 0.0 pips and a $3 per side commission. Take advantage of MT5’s ultra-fast order execution, along with a Personal Account Manager, dedicated support, daily market analysis, and access to the Trading Academy.'
  },
  forexMt5mt5VipDesc:{
    i18nKey: 'forexMt5mt5VipDesc',
    defaults: 'Benefit from reduced spreads starting at 0.5 pips and zero commission with MT5’s ultra-fast order execution. Enjoy a Personal Account Manager, dedicated support, daily market analysis, and access to the Trading Academy.'
  },
  forexMt4rawDesc:{
    i18nKey: 'forexMt4rawDesc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt5invest_plusDesc:{
    i18nKey: 'forexMt5invest_plusDesc',
    defaults: 'Start trading more than 900 different Stock CFDs and ETFs from over 7 exchanges with ultra-low commissions and benefit from dividends paid on your long-term positions.'
  },
  forexRawAccountDesc:{
    i18nKey: 'forexRawAccountDesc',
    defaults: 'Open a Raw account and reduce your trading costs with our ultra-thin spreads.'
  },
  forexClassicAccountDesc:{
    i18nKey: 'forexClassicAccountDesc',
    defaults:  'Open a Live Account and trade more than 250 CFDs on world-leading platforms.'
  },
  forexClassicDemoAccountDesc:{
    i18nKey: 'forexClassicDemoAccountDesc',
    defaults:  'Open a Demo Account and test your trading skills with $10,000 virtual funds.'
  },
  requestHistoric:{
    i18nKey: 'requestHistoric',
    defaults: 'Request Historic Tick Data'
  },
  proFreeTicks:{
    i18nKey: 'proFreeTicks',
    defaults: 'I would like to request free tick data for my Raw Pro account from: {{startDate}} until {{endDate}}'
  },
  cardholderName:{
    i18nKey: 'cardholderName',
    defaults: 'Cardholder Name'
  },
  freeSubnote:{
    i18nKey: 'freeSubnote',
    defaults: '<1>You will not be charged for the Raw Free account.</1> We will pre-authorise your card in case your account reaches the trading volume limit and is automatically upgraded to the next tier.'
  },
  freeFallBack:{
    i18nKey: 'freeFallBack',
    defaults: 'We could not downgrade your plan because you had open positions during the downgrade process. Please close all of your positions to be able to switch to your selected plan. Otherwise if you will continue trading, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  dobField:{
    i18nKey: 'dobField',
    defaults: 'Fill your date of birth to proceed'
  },
  dobValidationMsg:{
    i18nKey: 'dobValidationMsg',
    defaults: 'Please filled in a valid date of birth date.'
  },
  vps: {
    i18nKey: 'vps',
    defaults: 'VPS'
  },
  vpsAccess: {
    i18nKey: 'vpsAccess',
    defaults: 'VPS Access'
  },
  vpsRequest: {
    i18nKey: 'vpsRequest',
    defaults: 'Your account is eligible for a VPS (Virtual Private Server) service. Please click below to request a VPS service and get your credentials.'
  },
  addAccountPassDesc: {
    i18nKey: 'addAccountPassDesc',
    defaults: 'Please enter your account login password.'
  },
  overTheLimitLabel:{
    i18nKey: 'overTheLimitLabel',
    defaults: 'Volume Limit Reached - Commissions Plan'
  },
  signUpSubTypes:{
    i18nKey: 'signUpSubTypes',
    defaults: 'Get Started with a {{accountSubType}} account'
  },
  generalError: {
    i18nKey: 'generalError',
    defaults: 'Something went wrong, please try again.',
  },
  openPrice: {
    i18nKey: 'openPrice',
    defaults: 'Open Price',
  },
  closePrice: {
    i18nKey: 'closePrice',
    defaults: 'Close Price',
  },
  depositReturnedFunds: {
    i18nKey: 'depositReturnedFunds',
    defaults: "Returned Funds",
  },
  completeProfileGDC: {
    i18nKey: 'completeProfileGDC',
    defaults: 'Please complete your personal details, to be eligible for Express Account Verification.',
  },
  regulationPopupMessageFsc: {
    i18nKey: 'regulationPopupMessageFsc',
    defaults: 'You are about to register to BDS Markets, that is regulated by the Financial Service Commission - FSC (License no: C116016172), Republic of Mauritius.',
  },
  regulationPopupMessageCysec: {
    i18nKey: 'regulationPopupMessageCysec',
    defaults: 'Based on your selection, you will register for an account authorized and regulated by the Cyprus Securities and Exchange Commision.<0 /><1 />Before you proceed, please confirm your decision.',
  },
  regulationPopupMessageVfsc: {
    i18nKey: 'regulationPopupMessageVfsc',
    defaults: 'You are about to register to BDS Ltd, that is regulated by the Financial Services Authority (the “FSA”, licence no. SD047), Republic of Seychelles.',
  },
  regulationPopupNotification: {
    i18nKey: 'regulationPopupNotification',
    defaults: 'As the Company can operate in the European Union under the reverse solicitation regime, please confirm that the decision to register was made on your own exclusive initiative and that no solicitation has been made by BDSwiss nor any other entity within the group or any BDSwiss partner.',
  },
  noThanks: {
    i18nKey: 'noThanks',
    defaults: 'No, Thanks',
  },
  details: {
    i18nKey: 'details',
    defaults: 'Details'
  },
  totalDeposits: {
    i18nKey: 'totalDeposits',
    defaults: 'Total Deposits'
  },
  amountLeft: {
    i18nKey: 'amountLeft',
    defaults: 'Available Amount for withdraw'
  },
  createWithdrawal: {
    i18nKey: 'createWithdrawal',
    defaults: 'Create Withdrawal'
  },
  refundOptionDisabled: {
    i18nKey: 'refundOptionDisabled',
    defaults: 'This option will be enabled once prior options have been fully refunded'
  },
  registerForEventLogin:{
    i18nKey: 'registerForEventLogin',
    defaults: 'Please LOGIN below to <0>confirm your registration</0> for our Seminar in {{eventCountry}} on {{eventDate}}'
  },
  registerForEventRegistration:{
    i18nKey: 'registerForEventRegistration',
    defaults: 'Please register to <0>confirm your attendance</0> for our Seminar in {{eventCountry}} on {{eventDate}}'
  },
  changeLeveragePopupText1: {
    i18nKey: 'changeLeveragePopupText1',
    defaults: 'The current leverage of this account is <0>1:{{leverage}}</0>.'
  },
  changeLeveragePopupText2: {
    i18nKey: 'changeLeveragePopupText2',
    defaults: 'Leverage is to be changed to <0>1:{{leverageChange}}</0>?'
  },
  documentsOrSupport: {
    i18nKey: 'documentsOrSupport',
    defaults: 'Please upload valid documents <0>here</0> or contact our <1>Support Team</1>.'
  },
  reCompleteAppTest: {
    i18nKey: 'reCompleteAppTest',
    defaults: 'Please re-complete <0>now</0>.'
  },
  emailsOrSupport: {
    i18nKey: 'emailsOrSupport',
    defaults: 'Please check for emails from us for further details or contact our <0>Support Team</0>.'
  },
  kycApTestPending: {
    i18nKey: 'kycApTestPending',
    defaults: 'Please proceed to (re)complete the Appropriateness questionnaire <0>now</0>, otherwise your account may be suspended.'
  },
  kycStatusTempRejected:{
    i18nKey: 'kycStatusTempRejected',
    defaults: 'Verification status of your account has been temporarily rejected.'
  },
  isUnderMonitoringWithReason: {
    i18nKey: 'isUnderMonitoringWithReason',
    defaults: 'Any future deposits will be pending.',
  },
  emailsOrDocuments: {
    i18nKey: 'emailOrDocuments',
    defaults: 'Please check your inbox for emails from us and upload required documents <0>here</0>.',
  },
  reason:{
    i18nKey: 'reason',
    defaults: 'Reason: <0>{{reason}}</0>.',
  },
  acceptedFormats: {
    i18nKey: 'acceptedFormats',
    defaults: '{{acceptedFormats}} and {{lastAccepted}} files are accepted.',
  },
  maxFileSizeText: {
    i18nKey: 'maxFileSizeText',
    defaults: 'Max size: {{maxSize}} MB',
  },
  poiAdditionalText: {
    i18nKey: 'poiAdditionalText',
    defaults: 'Passport, national ID card or Driving License.',
  },
  porAdditionalText: {
    i18nKey: 'porAdditionalText',
    defaults: 'Bank statement, Card statement, Utility bill (electricity, gas, water, internet or telephone), Tax letter, Residence certificate',
  },
  pofAdditionalText: {
    i18nKey: 'pofAdditionalText',
    defaults: 'Verify your credit/debit card, virtual card, e-wallet, bank wire details, crypto-wallet to ensure fast and secure processing of your payments.',
  },
  makeSureDocuments: {
    i18nKey: 'makeSureDocuments',
    defaults: 'Please make sure that your documents are:',
  },
  makeSureDocumentsTextPoi1: {
    i18nKey: 'makeSureDocumentsTextPoi1',
    defaults: 'Your photo or scan is clear, with no blurs, light reflection or shadows',
  },
  makeSureDocumentsTextPoi2: {
    i18nKey: 'makeSureDocumentsTextPoi2',
    defaults: 'All edges of the document must be visible',
  },
  makeSureDocumentsTextPoi3: {
    i18nKey: 'makeSureDocumentsTextPoi3',
    defaults: 'Both sides of the document must be uploaded',
  },
  viewExamples: {
    i18nKey: 'viewExamples',
    defaults: 'View examples of valid documents',
  },
  makeSureDocumentsTextPor1: {
    i18nKey: 'makeSureDocumentsTextPor1',
    defaults: 'Your documents are issued within the last 3 months',
  },
  makeSureDocumentsTextPor3: {
    i18nKey: 'makeSureDocumentsTextPor3',
    defaults: 'Includes:',
  },
  makeSureDocumentsTextPor3Sub1: {
    i18nKey: 'makeSureDocumentsTextPor3Sub1',
    defaults: 'Your name',
  },
  makeSureDocumentsTextPor3Sub2: {
    i18nKey: 'makeSureDocumentsTextPor3Sub2',
    defaults: 'Your current address',
  },
  makeSureDocumentsTextPor3Sub3: {
    i18nKey: 'makeSureDocumentsTextPor3Sub3',
    defaults: 'Date of issuance',
  },
  makeSureDocumentsTextPor3Sub4: {
    i18nKey: 'makeSureDocumentsTextPor3Sub4',
    defaults: 'The details of the issuer / issuing authority',
  },
  pofCardInstructions: {
    i18nKey: 'pofCardInstructions',
    defaults: 'Please make sure to cover your card number apart from the last 4 digits (also in the back). The CVV number must also be covered. The front and back side must be uploaded.',
  },
  pofVirtualCardInstructions: {
    i18nKey: 'pofVirtualCardInstructions',
    defaults: 'Please provide us with a statement of the “source” bank account that was used to create the virtual card, highlighting the transfer made to {{company}}',
  },
  depositVericationCardTitle:{
    i18nKey: 'depositVericationCardTitle',
    defaults: 'Deposit Verification',
  },
  depositVericationCard:{
    i18nKey: 'depositVericationCard',
    defaults: 'Verify your deposits',
  },
  viewOnly:{
    i18nKey: 'viewOnly',
    defaults: 'View Only'
  },
  transferToDetails: {
    i18nKey: 'transferToDetails',
    defaults: 'Transfered to: {{type}} - {{id}}'
  },
  transferFromDetails: {
    i18nKey: 'transferFromDetails',
    defaults: 'Transfered from: {{type}} - {{id}}'
  },
  currentPrice:{
    i18nKey: 'currentPrice',
    defaults: 'Current Price'
  },
  transferSpoaClientTitle:{
    i18nKey: 'transferSpoaTitle',
    defaults: 'SPOA Transfers'
  },
  transferSpoaClientCard:{
    i18nKey: 'transferSpoaClientCard',
    defaults: 'Transfer funds from your account to your IB`s account'
  },
  emailVerification:{
    i18nKey: 'emailVerification',
    defaults: 'Awaiting Email Verification'
  },
  emailVerificationThankYou:{
    i18nKey: 'emailVerificationThankYou',
    defaults: 'Thank you for registering.'
  },
  emailVerificationText1:{
    i18nKey: 'emailVerificationText1',
    defaults: 'An email has been sent to <0>{{clientEmail}}</0> with a link to verify your account.'
  },
  emailVerificationText2:{
    i18nKey: 'emailVerificationText2',
    defaults: 'If you have not received the email after a few minutes, please check your spam folder or contact support.'
  },
  emailAreadyConfirmed: {
    i18nKey: 'emailAreadyConfirmed',
    defaults: 'Email aready confirmed! Go to <0>Dashboard</0>',
  },
  appropTestFailed:{
    i18nKey: 'appropTestFailed',
    defaults: 'You do not seem to possess the appropriate experience and knowledge. If you wish to trade derivatives with the Company, you must first gain some experience or education relevant to trading financial Instruments offered by {{company}}. You can resubmit the appropriateness test in few days.'
  },
  call: {
    i18nKey: 'call',
    defaults: 'Call'
  },
  infoCallText:{
    i18nKey: 'infoCallText',
    defaults: 'You will receive a call from number: {{callerNumber}}'
  },
  secureId: {
    i18nKey: 'secureId',
    defaults: 'Secure ID'
  },
  emailValidation: {
    i18nKey: 'emailValidation',
    defaults: 'Please use a valid email'
  },
  walletAddressConfirmationValidation: {
    i18nKey: 'walletAddressConfirmationValidation',
    defaults: 'Wallet address do not match!'
  },
  usefulInfo: {
    i18nKey: 'usefulInfo',
    defaults: 'Useful information:'
  },
  isignthisDepositInfo: {
    i18nKey: 'isignthisDepositInfo',
    defaults: 'Please note that in case your total deposit amount is 2,000 EUR (or currency equivalent) and above; additional identity verification will be requested via email.'
  },
  isignthisDepositInfo2: {
    i18nKey: 'isignthisDepositInfo2',
    defaults: 'Kindly upload the documents through the link provided in the email.  You may also find the link in your account dashboard.'
  },
  isignthisDepositNote: {
    i18nKey: 'isignthisDepositNote',
    defaults: '*Your deposit will remain pending until the documents are received.'
  },
  taxInformation: {
    i18nKey: 'taxInformation',
    defaults: 'Tax Information'
  },
  taxNumber: {
    i18nKey: 'taxNumber',
    defaults: 'Identification Number ({{taxNumberName}})'
  },
  methodNotAvailable: {
    i18nKey: 'methodNotAvailable',
    defaults: 'If your card/account is no longer available for receiving refunds, please contact our Customer Support <0>here</0> or proceed to change the withdrawal option <1>here</1>.',
  },
  withdrawalOptionsSameMethod: {
    i18nKey: 'withdrawalOptionsSameMethod',
    defaults: 'As per the company’s withdrawal policy, you must withdraw your funds using the same method you used to deposit (order to follow: card deposit - e-wallet deposit - etc).',
  },
  withdrawalOptionsAnotherOption: {
    i18nKey: 'withdrawalOptionsAnotherOption',
    defaults: '<0>Another payment option will be made available</0> <1>only</1> after the initial funds have been fully used up or withdrawn.',
  },
  chooseRegulator: {
    i18nKey: 'chooseRegulator',
    defaults: 'Choose Regulator',
  },
  wealthManagement: {
    i18nKey: 'wealthManagement',
    defaults: 'Wealth Management',
  },
  proceedWith: {
    i18nKey: 'proceedWith',
    defaults: 'Proceed with {{regulator}}',
  },
  regNotAllowedPopupcysec:{
    i18nKey: 'regNotAllowedPopupcysec',
    defaults: 'Please kindly note that our CySEC licenced entity only accepts EU/EEA residents as clients<0/> <1/>As your country of residence is outside the EEA, your account will be registered under our {{choosedEntity}} licensed entity. Thank you for understanding'
  },
  regNotAllowedPopup:{
    i18nKey: 'regNotAllowedPopup',
    defaults: 'Please kindly note that our {{entity}} licenced entity does not accept clients from your country of resident<0/> <1/> Your account will be registered under our {{choosedEntity}} licensed entity. Thank you for understanding'
  },
  forbiddenCountryPopup:{
    i18nKey: 'forbiddenCountryPopup',
    defaults: 'You are not allowed to register from your country of residence. Thank you for understanding.'
  },
  importantNotification:{
    i18nKey: 'importantNotification',
    defaults: 'Important Notification'
  },
  dueDiligenceSubmitted: {
    i18nKey: 'dueDiligenceSubmitted',
    defaults: 'Due Diligence submitted'
  },
  dueDiligenceRejected: {
    i18nKey: 'dueDiligenceRejected',
    defaults: 'Due Diligence rejected'
  },
  generalTitle: {
    i18nKey: 'generalTitle',
    defaults: 'General Verification',
  },
  ibVerificationTitle: {
    i18nKey: 'ibVerificationTitle',
    defaults: 'IB Verification',
  },
  ibCorporateVerificationTitle: {
    i18nKey: 'ibVerificationTitle',
    defaults: 'IB Verification',
  },
  underReview: {
    i18nKey: 'underReview',
    defaults: 'Under Review'
  },
  dueDiligenceUnderReview: {
    i18nKey: 'dueDiligenceUnderReview',
    defaults: 'Due Diligence under review'
  },
  company: {
    i18nKey: 'company',
    defaults: 'Company',
  },
  individual: {
    i18nKey: 'individual',
    defaults: 'Individual',
  },
  currency: {
    i18nKey: 'currency',
    defaults: 'Currency',
  },
  signUpIB:{
    i18nKey: 'signUpIB',
    defaults: 'Register as an <0>Introducing Broker</0>'
  },
  signUpIBCheckbox:{
    i18nKey: 'signUpIBCheckbox',
    defaults: 'I hereby verify that I am 18 years or older, I have read and accept your <0>General Terms & Conditions</0> of {{company}}, <1>Legal Documents </1>, <2>Personal Data Protection</2>, <3>Code of Conduct</3>, <4>Marketing Guidelines</4>, <5>Partner  Agreement</5>, <6>Master IB Appendix</6> & <7>Appendix $5</7>',
  },
  accountDisconnectedLine1: {
    i18nKey: 'accountDisconnectedLine1',
    defaults: 'To activate your PAMM account, a deposit is necessary. Please make your deposit to proceed.',
  },
  accountDisconnectedLine2:{
    i18nKey: 'accountDisconnectedLine2',
    defaults: 'For any questions, don’t hesitate to contact us at <0>{{supportEmail}}</0>.',
  },
  accountDisconnectedTitle: {
    i18nKey: 'accountDisconnectedTitle',
    defaults: 'Deposit Needed',
  },
  pammManagerNotApprovedTitle: {
    i18nKey: 'pammManagerNotApprovedTitle',
    defaults: 'Approval Pending',
  },
  pammManagerNotApprovedLine1: {
    i18nKey: "pammManagerNotApprovedLine1",
    defaults: 'Your PAMM account is pending approval. We’ll update you as soon as it’s confirmed.',
  },
  pammManagerNotApprovedLine2: {
    i18nKey: 'pammManagerNotApprovedLine2',
    defaults: 'For any questions or concerns, please contact us at <0>{{email}}</0>.',
  },
  pleaseWait: {
    i18nKey: 'pleaseWait',
    defaults: 'Please wait',
  },
  rememberMe: {
    i18nKey: 'rememberMe',
    defaults: 'Remember Me',
  },
  forgetMe: {
    i18nKey: 'forgetMe',
    defaults: 'Forget Me',
  },
  payInstantlyWith: {
    i18nKey: 'payInstantlyWith',
    defaults: 'Pay Instantly with:',
  },
  unlinkMyAccount: {
    i18nKey: 'unlinkMyAccount',
    defaults: 'Unlink my Account',
  },
  BDErrorAccountClosed:{
    i18nKey: 'BDErrorAccountClosed',
    defaults: 'Your account was closed. Please contact <0>support</0>.'
  },
  maxLengthError:{
    i18nKey: 'maxLengthError',
    defaults: 'Maximum characters {{length}}.',
  },
  waitingTime: {
    i18nKey: 'waitingTime',
    defaults: 'Your document will be processed in approximately {{docProcessingTime}} {{working}} hours',
  },
  waitingTimeWorking: {
    i18nKey: 'waitingTimeWorking',
    defaults: 'working',
  },
  waitingTimeFootnote: {
    i18nKey: 'waitingTimeFootnote',
    defaults: 'please note that our backoffice team works Monday to Friday 24/5',
  },
  pleaseChooseAccount: {
    i18nKey: 'pleaseChooseAccount',
    defaults: 'Please choose one of the following accounts:',
  },
  pleaseUploadTheRequiredDocuments: {
    i18nKey: 'pleaseUploadTheRequiredDocuments',
    defaults: 'Please upload the required documents:',
  },
  almostDone:{
    i18nKey: 'almostDone',
    defaults: 'Almost Done!',
  },
  importantNotice:{
    i18nKey: 'importantNotice',
    defaults: 'Important Notice',
  },
  retakeTest:{
    i18nKey: 'retakeTest',
    defaults: 'Retake the test',
  },
  startTradingBtn: {
    i18nKey: 'startTradingBtn',
    defaults: 'Start Trading',
  },
  appropTestDesc: {
    i18nKey: 'appropTestDesc',
    defaults: 'The Appropriateness Test is mandatory for all {{company}} clients and was put in place to protect you as a client by ensuring that you have a good understanding of what trading forex and CFDs involves.'
  },
  helpSupport: {
    i18nKey: 'helpSupport',
    defaults: 'Need help? Please contact our <0>Support Team</0>.'
  },
  thankyouDepositAppropTest: {
    i18nKey: 'thankyouDepositAppropTest',
    defaults: 'Thank you for your deposit, your funds have been received and will appear on your account after you have completed your Appropriateness Test.'
  },
  minimumDepositFootnote: {
    i18nKey: 'minimumDepositFootnote',
    defaults: 'Please note that the minimum first deposit amount may differ from the {{amount}} limit (or trading account currency equivalent), depending on your country of residence and whether you have accessed our website through a third party or using a referral link.'
  },
  description:{
    i18nKey: 'description',
    defaults: 'Description',
  },
  readMore:{
    i18nKey: 'readMore',
    defaults: 'Read more'
  },
  hide: {
    i18nKey: 'hide',
    defaults: 'Hide'
  },
  leverageUpdateSuggestion: {
    i18nKey: 'leverageUpdateSuggestion',
    defaults: 'Your account has been set to a maximum <0>1:100</0> default Leverage. You can increase your leverage <1>here</1>. For more information, please contact our Support Team.'
  },
  refresh:{
    i18nKey: 'refresh',
    defaults: 'Refresh',
  },
  somethingWrongRefresh: {
    i18nKey: 'somethingWrongRefresh',
    defaults: "Something went wrong while fetching your accounts, please refresh the page."
  },
  customerSupport: {
    i18nKey: 'customerSupport',
    defaults: 'Should your documents not be processed in this time frame, contact our Customer Support or check for emails from us.'
  },
  depositResultHeldPartial: {
    i18nKey: 'depositResultHeldPartial',
    defaults: 'Due to insufficient funds your transaction was partially approved for the amount of <0>{{amount}}</0>. <1/>By clicking  <0>"Continue"</0> your deposit will be successful and your account will be credited.'
  },
  cancelTransaction: {
    i18nKey: 'cancelTransaction',
    defaults: 'Cancel Transaction'
  },
  credit: {
    i18nKey: 'credit',
    defaults: 'Credit'
  },
  affiliateDesc:{
    i18nKey: 'affiliateDesc',
    defaults: 'Open an Affiliate Account and benefit from our customised commission plans and daily withdrawal options.'
  },
  forexMt4ibDesc:{
    i18nKey: 'forexMt4ibDesc',
    defaults: 'Open an Introducing Broker account and start monetising your traffic with the help of our industry experts.'
  },
  kartwheelibDesc:{
    i18nKey: 'forexMt4ibDesc',
    defaults: 'Open an Introducing Broker account and start monetising your traffic with the help of our industry experts.'
  },
  moreDetails:{
    i18nKey: 'moreDetails',
    defaults: 'Enter your details'
  },
  addAccountDetailsDesc: {
    i18nKey: 'addAccountDetailsDesc',
    defaults: 'Please enter your skype ID, website and promoting countries.'
  },
  pleaseNote: {
    i18nKey: 'pleaseNote',
    defaults: 'Please note!'
  },
  partialDepositNote: {
    i18nKey: 'partialDepositNote',
    defaults: '{{type}} Account will be enabled automatically once a minimum account balance of <0 /> is reached.'
  },
  enableAccess: {
    i18nKey: 'enableAccess',
    defaults: 'Enable Access'
  },
  vipAutochartistStats: {
    i18nKey: 'vipAutochartistStats',
    defaults: 'Want to receive our VIP signals with Autochartist and Performance Stats?'
  },
  autochartistToggle: {
    i18nKey: 'autochartistToggle',
    defaults: 'I want to receive autochartist signals to my email.'
  },
  availableFundedAccount: {
    i18nKey: 'availableFundedAccount',
    defaults: 'Available for funded accounts.'
  },
  vipAccountTitle: {
    i18nKey: 'vipAccountTitle',
    defaults: 'VIP Account'
  },
  rawAccountTitle: {
    i18nKey: 'rawAccountTitle',
    defaults: 'Raw Account'
  },
  joinVipClubTitle2: {
    i18nKey: 'joinVipClubTitle2',
    defaults: 'Join our VIP Club & Enjoy Exclusive Benefits'
  },
  vipAccountPrivilaged: {
    i18nKey: 'vipAccountPrivilaged',
    defaults: '{{company}} offers two premium accounts VIP Account and Raw Account; both come with a set of benefits catered to high volume traders including complimentary trading alerts, live webinars, premium autochartist access, dedicated support and lower trading costs.'
  },
  liveAlertsTextVip: {
    i18nKey: 'liveAlertsTextVip',
    defaults: 'Receive Live Trading Alerts via Telegram with a proven success record directly from {{company}}’ seasoned traders'
  },
  zeroWaitingVip: {
    i18nKey: 'zeroWaitingVip',
    defaults: 'zero waiting time'
  },
  zeroWaitingTextVip: {
    i18nKey: 'zeroWaitingTextVip',
    defaults: 'Skip the waiting lists and get priority on all transactions from KYC to deposits and withdrawals'
  },
  lowerTradingCosts: {
    i18nKey: 'lowerTradingCosts',
    defaults: 'lower trading costs'
  },
  evenLowerSpreadsTextVip: {
    i18nKey: 'evenLowerSpreadsTextVip',
    defaults: 'Enjoy up to 40% lower spreads and commissions with our VIP account and ultra-thin spreads from 0.0 with our Raw Account!'
  },
  noFeesDepositsVip: {
    i18nKey: 'noFeesDepositsVip',
    defaults: '0 fees on cc deposits & withdrawals'
  },
  noFeesDepositsTextVip: {
    i18nKey: 'noFeesDepositsTextVip',
    defaults: 'Enjoy low-cost trading with no fees on credit card deposits or withdrawals over 20EUR'
  },
  fullAutochartistAccess: {
    i18nKey: 'fullAutochartistAccess',
    defaults: 'full autochartist access'
  },
  fullAutochartistAccessText: {
    i18nKey: 'fullAutochartistAccessText',
    defaults: 'Get notified on forming trends with confirmed support/resistance levels as well as performance stats on closed alerts'
  },
  spreadsFrom: {
    i18nKey: 'spreadsFrom',
    defaults: 'Spreads From'
  },
  from: {
    i18nKey: 'from',
    defaults: 'From'
  },
  openAccount: {
    i18nKey: 'openAccount',
    defaults: 'Open {{type}} account'
  },
  yourCapitalRisk: {
    i18nKey: 'yourCapitalRisk',
    defaults: 'Your capital is at risk'
  },
  activateVipRawAccount: {
    i18nKey: 'activateVipRawAccount',
    defaults: 'Activate your <0>{{type}}</0> account.'
  },
  waitingCovid19: {
    i18nKey: 'waitingCovid19',
    defaults: 'Extended waiting times due to COVID-19 safety measures.'
  },
  delays: {
    i18nKey: 'delays',
    defaults: 'Delays in Processing Times'
  },
  covidNotificationText: {
    i18nKey: 'covidNotificationText',
    defaults: 'At {{company}} we were quick to respond to the global COVID-19 crisis by following the local guidelines and restrictions and putting all the necessary measures in place to ensure the health and safety of our employees.'
  },
  covidNotificationText2: {
    i18nKey: 'covidNotificationText2',
    defaults: 'We continue to operate smoothly, our platforms are fully online and our trading services remain uninterrupted. However, due to the increasing workload and telecommuting challenges, we are currently experiencing minor delays in our Account Verification and Withdrawal processing times. We are onboarding new personnel to meet the increasing demand and hope to resolve this shortly.'
  },
  covidNotificationText3: {
    i18nKey: 'covidNotificationText3',
    defaults: 'We apologise for any inconvenience.'
  },
  forexMt4basicDesc:{
    i18nKey: 'forexMt4basicDesc',
    defaults: 'Trade 250+CFDs on leading platforms and access 24/5 multi-lingual support, daily briefs and live webinars. Spreads start at 1.5 pips and standard commissions apply. '
  },
  forexMt4blackDesc:{
    i18nKey: 'forexMt4blackDesc',
    defaults: 'Get more out of your account with reduced spreads from 1.1, dedicated support, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4swiss5Desc:{
    i18nKey: 'forexMt4swiss5Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($5 commission charged based on account base currency).'
  },
  forexMt4swiss8Desc:{
    i18nKey: 'forexMt4swiss8Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($8 commission charged based on account base currency).'
  },
  forexMt4swiss11Desc:{
    i18nKey: 'forexMt4swiss11Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($11 commission charged based on account base currency).'
  },
  compareAccountTypes:{
    i18nKey: 'compareAccountTypes',
    defaults: 'Compare account types'
  },
  minDepositAmount: {
    i18nKey: 'minDepositAmount',
    defaults: 'Minimum deposit amount'
  },
  averageSpread: {
    i18nKey: 'averageSpread',
    defaults: 'Average spread (EUR/USD)'
  },
  forexMetals: {
    i18nKey: 'forexMetals',
    defaults: 'Forex, Metals'
  },
  energies: {
    i18nKey: 'energies',
    defaults: 'Energies'
  },
  indices: {
    i18nKey: 'indices',
    defaults: 'Indices'
  },
  equities: {
    i18nKey: 'equities',
    defaults: 'Equities (Stocks)'
  },
  shares: {
    i18nKey: 'shares',
    defaults: 'Shares'
  },
  etfs: {
    i18nKey: 'etfs',
    defaults: 'ETFs'
  },
  benefits: {
    i18nKey: 'benefits',
    defaults: 'Benefits'
  },
  autochartistPerformanceStats: {
    i18nKey: 'autochartistPerformanceStats',
    defaults: 'Autochartist Performance Stats'
  },
  priorityServices: {
    i18nKey: 'priorityServices',
    defaults: 'Priority Services'
  },
  equivalentPerLot: {
    i18nKey: 'equivalentPerLot',
    defaults: '{{value}} (or equivalent) per lot'
  },
  ofVolume: {
    i18nKey: 'ofVolume',
    defaults: '{{value}} of the volume'
  },
  commissionValue: {
    i18nKey: 'commissionValue',
    defaults: '{{value}}'
  },
  minDepositAmountText: {
    i18nKey: 'minDepositAmountText',
    defaults: '{{value}} EUR/USD/GBP'
  },
  leverageText: {
    i18nKey: 'leverageText',
    defaults: '{{value}}'
  },
  averageSpreadText: {
    i18nKey: 'averageSpreadText',
    defaults: '{{value}} pips'
  },
  availableAccounts: {
    i18nKey: 'availableAccounts',
    defaults: '{{available}} of {{maxAllowed}} available',
  },
  maximumAllowedAccounts: {
    i18nKey: 'maximumAllowedAccounts',
    defaults: 'It appears that you have reached the maximum allowed amount of Trading Accounts. If you wish to register an additional account or manage existing accounts, please contact our customer support team.',
  },
  contactUs: {
    i18nKey: 'contactUs',
    defaults: 'Contact Us',
  },
  limitReached: {
    i18nKey: 'limitReached',
    defaults: 'Limit Reached!',
  },
  twentyfourFiveSupport: {
    i18nKey: 'twentyfourFiveSupport',
    defaults: '24/5 Support',
  },
  dividendsPaid: {
    i18nKey: 'dividendsPaid',
    defaults: 'Dividends paid on long term positions',
  },
  dailyAnalysis: {
    i18nKey: 'dailyAnalysis',
    defaults: 'Daily Analysis',
  },
  freeLiveWebinars: {
    i18nKey: 'freeLiveWebinars',
    defaults: 'Free Live Webinars',
  },
  basicAccessAutochartist: {
    i18nKey: 'basicAccessAutochartist',
    defaults: 'Basic Access to Autochartist',
  },
  limitedAccessTradingAlerts: {
    i18nKey: 'limitedAccessTradingAlerts',
    defaults: 'Limited Access to Trading Alerts',
  },
  personalAccountManager: {
    i18nKey: 'personalAccountManager',
    defaults: 'Personal Account Manager',
  },
  accessExclusiveWebinars: {
    i18nKey: 'accessExclusiveWebinars',
    defaults: 'Access to Exclusive Webinars',
  },
  vipTradingAlerts: {
    i18nKey: 'vipTradingAlerts',
    defaults: 'VIP Trading Alerts via Telegram',
  },
  rawSpreadsFromZero: {
    i18nKey: 'rawSpreadsFromZero',
    defaults: 'Raw Spreads From 0',
  },
  copyTrading: {
    i18nKey: 'copyTrading',
    defaults: 'Copy Trading'
  },
  copyTradingAccountSubtypeRequirement: {
    i18nKey: 'copyTradingAccountSubtypeRequirement',
    defaults: 'A {{accountSubtype}} account is required to copy this strategy',
  },
  availableStrategies: {
    i18nKey: 'availableStrategies',
    defaults: 'Available Strategies'
  },
  totalTrades: {
    i18nKey: 'totalTrades',
    defaults: 'Total Trades'
  },
  totalWinningTradesCount: {
    i18nKey: 'totalWinningTradesCount',
    defaults: 'Total Winning Trades'
  },
  totalLosingTradesCount: {
    i18nKey: 'totalLosingTradesCount',
    defaults: 'Total Losing Trades'
  },
  closedProfit: {
    i18nKey: 'closedProfit',
    defaults: 'Closed Profit'
  },
  roi: {
    i18nKey: 'roi',
    defaults: 'ROI'
  },
  tradingLength: {
    i18nKey: 'tradingLength',
    defaults: 'Trading Length'
  },
  startCopying: {
    i18nKey: 'startCopying',
    defaults: 'Start Copying'
  },
  stopCopying: {
    i18nKey: 'stopCopying',
    defaults: 'Stop Copying'
  },
  copyTradingSuggestion: {
    i18nKey: 'copyTradingSuggestion',
    defaults: 'Your are copying trading strategy from account {{ibAccount}}. <0>Go to Strategies.</0>'
  },
  requestCopyTradingTitle: {
    i18nKey: 'requestCopyTradingTitle',
    defaults: 'You are about to <0>start copying</0> trades',
  },
  requestCopyTradingText: {
    i18nKey: 'requestCopyTradingText',
    defaults: 'When copy-trading another trader, all of his positions will be automatically replicated by your trading account.',
  },
  requestCopyTradingBonusLossNotificationText: {
    i18nKey: 'requestCopyTradingBonusLossNotificationText',
    defaults: 'Starting the copy trading strategy will remove your existing account bonus.',
  },
  requestCopyTradingNoPerformanceFeeText: {
    i18nKey: 'requestCopyTradingNoPerformanceFeeText',
    defaults: 'You can always manage and adjust all open positions or choose to stop copy-trading altogether.',
  },
  requestCopyTradingText2: {
    i18nKey: 'requestCopyTradingText2',
    defaults: 'As the selected strategy is charging a <0>{{fee}}% performance fee</0>, You will not be able to manage and adjust all copied positions or trade on the account until you stop copying. Copying is done via the Equity Allocation model.',
  },
  cancelCopyTradingText: {
    i18nKey: 'cancelCopyTradingText',
    defaults: 'You are about to <0>cancel your</0> copying trading request.',
  },
  cancelRequest: {
    i18nKey: 'cancelRequest',
    defaults: 'Cancel Request',
  },
  requestStopCopyTradingTitle: {
    i18nKey: 'requestStopCopyTradingTitle',
    defaults: 'You are about to <0>stop copying</0> trades',
  },
  replace: {
    i18nKey: 'replace',
    defaults: 'Replace',
  },
  requestStopCopyTradingText: {
    i18nKey: 'requestStopCopyTradingText',
    defaults: 'Once you stop copying a strategy, your account will no longer replicate positions automatically. All existing positions will remain open until they reach their pre-determined Stop Loss or Take Profit Level, or they can be adjusted and closed manually by you.',
  },
  performaceSwitchLabel: {
    i18nKey: 'performaceSwitchLabel',
    defaults: 'View Copy Trading Performance',
  },
  performaceSwitchInfo: {
    i18nKey: 'performaceSwitchInfo',
    defaults: 'When enabled, you will be able to view and manage the performance of your copied positions.',
  },
  copying: {
    i18nKey: 'copying',
    defaults: 'Copying'
  },
  allocationMethodLabel: {
    i18nKey: 'allocationMethodLabel',
    defaults: 'Allocation Method',
  },
  percentageLabel: {
    i18nKey: 'percentageLabel',
    defaults: 'Percentage',
  },
  days:{
    i18nKey: 'days',
    defaults: 'Days',
  },
  depositPendingCopying:{
    i18nKey: 'depositPendingCopying',
    defaults: 'Start copying by depositing to your account.',
  },
  autochartistPerformanceStatsTitle: {
    i18nKey: 'autochartistPerformanceStatsTitle',
    defaults: 'Activate Autochartist Performance Stats',
  },
  bankAccount: {
    i18nKey: 'bankAccount',
    defaults: 'Bank Account',
  },
  clientHasSameLastnameAndDob: {
    i18nKey: 'clientHasSameLastnameAndDob',
    defaults: 'It seems that you already hold an account with {{entity}}. Please proceed with <0>logging</0> in to your Client Dashboard',
  },
  underagedClient: {
    i18nKey: 'underagedClient',
    defaults: 'Underaged Client',
  },
  explanationField: {
    i18nKey: 'explanationField',
    defaults: 'Please use your {{field}} as displayed in your identity document.',
  },
  yourRank: {
    i18nKey: 'yourRank',
    defaults: 'Your Rank'
  },
  minimumBalanceRequiredError: {
    i18nKey: 'minimumBalanceRequiredError',
    defaults: 'Minimum balance required:  <0>{{amount}}</0> <1> Deposit Now </1>'
  },
  competition: {
    i18nKey: 'competition',
    defaults: 'Competition'
  },
  stoppedParticipating: {
    i18nKey: 'stoppedParticipating',
    defaults: 'You have stopped participating in this competition'
  },
  stoppedParticipatingText: {
    i18nKey: 'stoppedParticipatingText',
    defaults: 'You have left this competition on the account <0>{{accountId}}</0> and all your progress has been reset. If you wish to participate in this competition again, you will have to use a different account, or wait for the next competition.'
  },
  selectDifferentAccount: {
    i18nKey: 'selectDifferentAccount',
    defaults: 'Select different account'
  },
  nicknameExists:{
    i18nKey: 'nicknameExists',
    defaults: 'nickname already exists',
  },
  showMore:{
    i18nKey: 'showMore',
    defaults: 'Show more',
  },
  seconds: {
    i18nKey: 'seconds',
    defaults: 'Seconds'
  },
  competitionEnded: {
    i18nKey: 'competitionEnded',
    defaults: 'Competition Ended'
  },
  competitionExitedMsg: {
    i18nKey: 'competitionExitedMsg',
    defaults: 'You have successfully exited competition'
  },
  signUpCompetition:{
    i18nKey: 'signUpCompetition',
    defaults: 'I have read and accept the <0>Trading Competition Terms & Conditions</0> of {{company}}',
  },
  enterCompetition:{
    i18nKey: 'enterCompetition',
    defaults: 'Enter Competition',
  },
  tradingCompetition: {
    i18nKey: 'tradingCompetition',
    defaults: 'Trading Competition'
  },
  tradingCompetitions: {
    i18nKey: 'tradingCompetitions',
    defaults: 'Trading Competitions'
  },
  leaderboard: {
    i18nKey: 'leaderboard',
    defaults: 'Leaderboard'
  },
  nickname: {
    i18nKey: 'nickname',
    defaults: 'Nickname'
  },
  selectCompetitionAccount: {
    i18nKey: 'selectCompetitionAccount',
    defaults: 'Select Competition Account'
  },
  entryFee: {
    i18nKey: 'entryFee',
    defaults: 'Entry Fee'
  },
  minBalance: {
    i18nKey: 'minBalance',
    defaults: 'Minimum Balance'
  },
  participants: {
    i18nKey: 'participants',
    defaults: 'Participants'
  },
  daysRemaining: {
    i18nKey: 'daysRemaining',
    defaults: 'Days Remaining'
  },
  hours: {
    i18nKey: 'hours',
    defaults: 'Hours'
  },
  rank: {
    i18nKey: 'rank',
    defaults: 'Rank'
  },
  turnover: {
    i18nKey: 'turnover',
    defaults: 'Turnover'
  },
  startCompetition: {
    i18nKey: 'startCompetition',
    defaults: 'Start Competition'
  },
  competitionRules: {
    i18nKey: 'competitionRules',
    defaults: 'Competition Rules'
  },
  quitCompetitionError: {
    i18nKey: 'quitCompetitionError',
    defaults: 'This account has quit competition'
  },
  differentCompetitionError: {
    i18nKey: 'differentCompetitionError',
    defaults: 'This account has is participating in a different competition'
  },
  exitCompetition: {
    i18nKey: 'exitCompetition',
    defaults: 'Exit Competition'
  },
  participatingAccount: {
    i18nKey: 'participatingAccount',
    defaults: 'Participating Account'
  },
  manage: {
    i18nKey: 'manage',
    defaults: 'Manage'
  },
  yourBalanceOnly: {
    i18nKey: 'yourBalanceOnly',
    defaults: 'Your Balance'
  },
  copyTradingError: {
    i18nKey: 'copyTradingError',
    defaults: 'This account is copying a strategy'
  },
  stopParticipating: {
    i18nKey: 'stopParticipating',
    defaults: 'You are about to stop participating in this competition'
  },
  stopParticipatingMsg: {
    i18nKey: 'stopParticipatingMsg',
    defaults: 'All your progress will be reset. If you wish to participate in this competition again, you will have to use a different account, or wait for the next competition.'
  },
  participatedCompetition:{
    i18nKey: 'participatedCompetition',
    defaults: 'Client participated again in this competition',
  },
  cannotParticipateCompetition:{
    i18nKey: 'cannotParticipateCompetition',
    defaults: 'This account cannot participate in a competition',
  },
  noClassicError: {
    i18nKey: 'noClassicError',
    defaults: 'Only Classic Accounts can participate in a Competition'
  },
  emptyLeaderboard:{
    i18nKey: 'emptyLeaderboard',
    defaults: 'This competition doesn`t have any participants yet, be the first to join!'
  },
  verifyYourAccount:{
    i18nKey: 'verifyYourAccount',
    defaults: 'Verify your account'
  },
  verifyText:{
    i18nKey: 'verifyText',
    defaults: 'To begin trading and participating in the competition, please verify your account.'
  },
  lastUpdatedAt:{
    i18nKey: 'lastUpdatedAt',
    defaults: 'Last updated at {{updatedAt}}'
  },
  registrationRejectedUsCitizen: {
    i18nKey: 'registrationRejectedUsCitizen',
    defaults: 'Your account registration was rejected as you have been identified as a US Reportable Person.<0>Logout</0>',
  },
  pepTitle: {
    i18nKey: 'pepTitle',
    defaults: 'Does any of the following statements apply to you?',
  },
  pepSubtitle: {
    i18nKey: 'pepSubtitle',
    defaults: 'I or any of my family members (consanguinity or civil form):',
  },
  tinUsError: {
    i18nKey: 'tinUsError',
    defaults: 'Please enter a valid U.S Tax Identification Number',
  },
  viewDetails: {
    i18nKey: 'viewDetails',
    defaults: 'View Details',
  },
  cancelCopyTradingText2: {
    i18nKey: 'cancelCopyTradingText2',
    defaults: 'Once you have cancelled the copy trading request, you will not be able to copy this strategy for 24 hours.',
  },
  cancelledRequest: {
    i18nKey: 'cancelledRequest',
    defaults: 'Cancelled Request',
  },
  creditingAccount: {
    i18nKey: 'creditingAccount',
    defaults: 'Crediting Account'
  },
  confirmValidPersonalDetails:{
    i18nKey: 'confirmValidPersonalDetails',
    defaults: 'Please ensure that your personal details and address match the ones on your identity document and utility bill respectively.'
  },
  houseNumberRequired:{
    i18nKey: 'houseNumberRequired',
    defaults: 'Please provide house number if applicable for enhanced account verification.'
  },
  noTransactionsCurrent: {
    i18nKey: 'noTransactionsCurrent',
    defaults: 'There are no transactions to display with the selected filters <1>{{totalTransactions}}</1>. <0>{{showMore}}</0>',
  },
  rowsInTable: {
    i18nKey: 'rowsInTable',
    defaults: 'Rows in table',
  },
  filtersApplied: {
    i18nKey: 'filtersApplied',
    defaults: 'filters applied',
  },
  entriesOf: {
    i18nKey: 'entriesOf',
    defaults: '{{entries}}{{showStar}} of {{entriesCount}}',
  },
  entriesOfInline: {
    i18nKey: 'entriesOfInline',
    defaults: '({{entries}}{{showStar}} of {{entriesCount}} Transactions)',
  },
  rollbackAddress: {
    i18nKey: 'rollbackAddress',
    defaults: 'Rollback Address',
  },
  rollbackAddressNote: {
    i18nKey: 'rollbackAddressNote',
    defaults: '<0>Rollback Address:</0> Please enter your private wallet address which will be used in case of a declined transaction. Do not enter an Exchange wallet address as any funds sent there could be lost.',
  },
  uploadCheckPrompt: {
    i18nKey: 'uploadCheckPrompt',
    defaults: 'Please upload your check'
  },
  uploadCheckPromptText: {
    i18nKey: 'uploadCheckPromptText',
    defaults: 'If you have performed transfers recently please upload a copy of your transfer document here or create a new one.'
  },
  yourBankDetails: {
    i18nKey: 'yourBankDetails',
    defaults: 'Your Bank Details'
  },
  yourBankDetailsText: {
    i18nKey: 'yourBankDetailsText',
    defaults: 'Please carefully complete your bank account details below in order to proceed with your bank wire transfer.'
  },
  yourBankAccountHolderName:{
    i18nKey: 'bankAccountHolderName',
    defaults: 'Your Bank Account Holder`s Name'
  },
  yourIbanAccountNumber: {
    i18nKey: 'ibanAccountNumber',
    defaults: 'Your IBAN / Account Number'
  },
  yourSwiftBic: {
    i18nKey: 'swiftBic',
    defaults: 'Your Bank SWIFT / BIC'
  },
  submittedWireDetails: {
    i18nKey: 'submittedWireDetails',
    defaults: 'You have successfully submitted your bank wire details. In order to proceed with your transfer, please <0>access your personal bank\'s payment facility directly (either via online banking or in person)</0> and use Tauro Markets banking details listed below.'
  },
  yourBankDetailsRequired: {
    i18nKey: 'yourBankDetailsRequired',
    defaults: 'This information is required in order to identify and successfully complete your bank wire transfer. Once you "Submit" your details, you will view the bank wire details of {{company}}.'
  },
  openPositionsUsCitizen: {
    i18nKey: 'openPositionsUsCitizen',
    defaults: '{{company}} does not provide services to US Reportable Persons. For more information or if you think you have been misclassified, please <0>contact support</0>.',
  },
  performanceFee: {
    i18nKey: 'performanceFee',
    defaults: 'Performance Fee'
  },
  managePositions: {
    i18nKey: 'managePositions',
    defaults: 'Manage Positions',
  },
  changeAccount: {
    i18nKey: 'changeAccount',
    defaults: 'Change Account'
  },
  strategyOpenPositions: {
    i18nKey: 'strategyOpenPositions',
    defaults: 'If you wish to start copying a performance fee strategy on this account, all open positions must be previously closed, otherwise please use a different account or create a new one. Once you start copying a performance fee strategy, manual trading will be disabled on the chosen account.'
  },
  copyingOpenPositions: {
    i18nKey: 'copyingOpenPositions',
    defaults: 'Performance Fee strategies are not available as you have open positions'
  },
  commissionType: {
    i18nKey: 'commissionType',
    defaults: 'Commission Type: {{fee}}% performance fee',
  },
  restrictedCopyStrategy: {
    i18nKey: 'restrictedCopyStrategy',
    defaults: 'You are restricted from copying this strategy for {{value}} {{label}}, as you have cancelled the copy request',
  },
  paypalDisclaimer1: {
    i18nKey: 'paypalDisclaimer1',
    defaults: 'Note: Please assure that you are using one of the below payment methods to deposit via your Paypal account:',
  },
  paypalDisclaimer2: {
    i18nKey: 'paypalDisclaimer2',
    defaults: "A) Using your wallet's balance or,",
  },
  paypalDisclaimer3: {
    i18nKey: 'paypalDisclaimer3',
    defaults: 'B) Via the bank account which is connected to your wallet.',
  },
  pleaseCompletePopup: {
    i18nKey: 'pleaseCompletePopup',
    defaults: 'The {{forceDocument}} Form is mandatory for your account verification. Please complete this information to be able to access your account.'
  },
  completeForm: {
    i18nKey: 'completeForm',
    defaults: 'Complete Form'
  },
  affiliateVerificationTitle: {
    i18nKey: 'affiliateVerificationTitle',
    defaults: 'Affiliate Verification',
  },
  affiliateCorporateVerificationTitle: {
    i18nKey: 'affiliateVerificationTitle',
    defaults: 'Affiliate Verification',
  },
  fsaFooter: {
    i18nKey: 'fsaFooter',
    defaults: 'BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the FSA, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles.',
  },
  acceptedAppendixesTitle:{
    i18nKey: 'acceptedAppendixesTitle',
    defaults: 'Accepted Appendixes',
  },
  maxFullNameLengthError: {
    i18nKey: 'maxFullNameLengthError',
    defaults: 'Maximum 32 characters allowed for full name (including spaces).',
  },
  minLengthError: {
    i18nKey: 'minLengthError',
    defaults: 'Minimum two characters are required.',
  },
  maxNameLengthError: {
    i18nKey: 'maxNameLengthError',
    defaults: 'Maximum of 32 characters is allowed.',
  },
  maxZipCodeLengthError: {
    i18nKey: 'maxZipCodeLengthError',
    defaults: 'Postal Code length has to be between 2 to 10 characters (including spaces).',
  },
  maxRegionLengthError: {
    i18nKey: 'maxRegionLengthError',
    defaults: 'Maximum of 32 characters is allowed (including spaces).',
  },
  maxStateLengthError: {
    i18nKey: 'maxStateLengthError',
    defaults: 'Maximum of 40 characters is allowed (including spaces).',
  },
  validationError: {
    i18nKey: 'validationError',
    defaults: 'Please use latin or arabic and non consecutive characters, and spaces only.',
  },
  phoneValidation: {
    i18nKey: 'phoneValidation',
    defaults: 'Please use a valid phone number',
  },
  streetNameRequired:{
    i18nKey: 'streetNameRequired',
    defaults: 'Please enter only the street name.',
  },
  changeAmount: {
    i18nKey: 'changeAmount',
    defaults: 'Change Amount'
  },
  freeMarginWarningTitle: {
    i18nKey: 'freeMarginWarningTitle',
    defaults: 'Requested amount is {{amount}}, free margin is {{freeMargin}}',
  },
  freeMarginWarningMessage1: {
    i18nKey: 'freeMarginWarningMessage1',
    defaults: 'Please note that you may run out of free margin and all your open positions will be stopped.',
  },
  freeMarginWarningMessage2: {
    i18nKey: 'freeMarginWarningMessage2',
    defaults: 'Also, if your free margin decreases below <0>{{amount}}</0>, your withdrawal request will be rejected or a lesser amount will be processed.',
  },
  notUploaded: {
    i18nKey: 'notUploaded',
    defaults: 'Not uploaded'
  },
  resubmit: {
    i18nKey: 'resubmit',
    defaults: 'Resubmit'
  },
  wantSkipWaitingList: {
    i18nKey: 'wantSkipWaitingList',
    defaults: 'Want to skip the waiting list?'
  },
  vipAccountsPriority: {
    i18nKey: 'vipAccountsPriority',
    defaults: 'VIP Accounts have a priority lane and are reviewed much faster, allong with many other benefits!'
  },
  pleaseMakeSure: {
    i18nKey: 'pleaseMakeSure',
    defaults: 'Please make sure:'
  },
  properlyUploadDocuments: {
    i18nKey: 'properlyUploadDocuments',
    defaults: 'How to properly upload documents'
  },
  submitDocument: {
    i18nKey: 'submitDocument',
    defaults: 'Submit Document'
  },
  submitDocuments: {
    i18nKey: 'submitDocuments',
    defaults: 'Submit Documents'
  },
  change: {
    i18nKey: 'change',
    defaults: 'Change'
  },
  formatFileError: {
    i18nKey: 'formatFileError',
    defaults: 'Wrong file format selected'
  },
  sizeFileError: {
    i18nKey: 'sizeFileError',
    defaults: 'File size too large'
  },
  uploadProperly: {
    i18nKey: 'uploadProperly',
    defaults: 'How to properly upload documents'
  },
  takingPictures: {
    i18nKey: 'takingPictures',
    defaults: 'When taking pictures or scan of your documents, make sure that your hand does not cover any part of the {{document}}, and that all the information is clearly visible.'
  },
  documentsExamples: {
    i18nKey: 'documentsExamples',
    defaults: 'Please see the examples below and make sure that your {{document}} photo or scan is similar. If your document is submitted correctly, it increases your chances of successful verification.'
  },
  shouldInclude: {
    i18nKey: 'shouldInclude',
    defaults: 'Documents should include:'
  },
  instructions: {
    i18nKey: 'instructions',
    defaults: 'Instructions:'
  },
  verifedPaymentMethods: {
    i18nKey: 'verifedPaymentMethods',
    defaults: 'Verified payment methods:'
  },
  threeCCsWarning: {
    i18nKey: 'threeCCsWarning',
    defaults: 'Please note that maximum 3 cards are allowed to be used for depositing funds. To ensure prompt processing of funds, kindly provide your card copies.',
  },
  tooManyRequests:{
    i18nKey: 'tooManyRequests',
    defaults: 'Your account was locked due to too many failed login attempts. Please try again in 5 minutes.',
  },
  prefixValidation: {
    i18nKey: 'prefixValidation',
    defaults: 'Please use a valid country code prefix',
  },
  ibanValidation: {
    i18nKey: 'ibanValidation',
    defaults: 'Please enter a valid iban / account number',
  },
  swiftCodeValidation: {
    i18nKey: 'swiftCodeValidation',
    defaults: 'Please enter a valid bank swift / bic',
  },
  ibanSwiftNotCoherent: {
    i18nKey: 'ibanSwiftNotCoherent',
    defaults: 'IBAN / Swift not coherent',
  },
  unsolicitedTitle: {
    i18nKey: 'unsolicitedTitle',
    defaults: 'Warning: Beware of Phishing Scams using {{company}}\' name.'
  },
  bewareUnsolicitedEmailsTitle: {
    i18nKey: 'bewareUnsolicitedEmailsTitle',
    defaults: 'Beware of Phishing Scams using {{company}}\' name'
  },
  unsolicitedText1: {
    i18nKey: 'unsolicitedText1',
    defaults: 'As our company continues to grow, we’ve seen an increase in the number of phishing emails by scammers pretending to be {{company}} employees. Identity theft is a crime we take very seriously, and we would like to ask you to help us keep your account and information secure by following these simple precautions:'
  },
  unsolicitedListItem1: {
    i18nKey: 'unsolicitedListItem1',
    defaults: 'Always confirm the sender’s identity before replying to any email requests and before opening attachments or clicking on links, even if they appear to come from a legitimate source',
  },
  unsolicitedListItem2: {
    i18nKey: 'unsolicitedListItem2',
    defaults: 'All our communications will be either from a company email address that ends in “@{{emailDomain}}” or via our in-app and website chat',
  },
  unsolicitedListItem3: {
    i18nKey: 'unsolicitedListItem3',
    defaults: 'Always be aware that no {{company}} employee will ever ask you for your personal passwords, or to withdraw money to any third party accounts, or install any software to your computer',
  },
  unsolicitedListItem4: {
    i18nKey: 'unsolicitedListItem4',
    defaults: 'Anyone who gets in touch with you on your personal social media pretending to be a {{company}} support agent or third party operator is a scammer and must be reported immediately',
  },
  unsolicitedListItem5: {
    i18nKey: 'unsolicitedListItem5 ',
    defaults: 'If you have any doubts whatsoever about an email or communication you’ve received, please contact us directly.'
  },
  showMoreItems: {
    i18nKey: 'showMoreItems',
    defaults: 'Show more items',
  },
  settingsPortfolioManagementTitle: {
    i18nKey: 'settingsPortfolioManagementTitle',
    defaults: 'Portfolio Management',
  },
  settingsPortfolioManagementCaption: {
    i18nKey: 'settingsPortfolioManagementCaption',
    defaults: 'For Portfolio Management accounts only',
  },
  suitabilityAssessment: {
    i18nKey: 'suitabilityAssessment',
    defaults: 'Suitability Assessment'
  },
  portfolioManagementDesc1: {
    i18nKey: 'portfolioManagementDesc1',
    defaults: 'The  suitability  assessment  is  carried  out  for  all  potential  clients  to  which  the  Company  may  provide portfolio management services.  The purpose of the suitability assessment is to enable the CIF to act in the best interest of its clients by obtaining the required information to assess whether a specific product is suitable for the client or not.  The questionnaire examines three criteria:  client’s  knowledge  and  experience  in  the  investment  sector  specially  relating  to  the  particular category of financial instrument or service, client’s financial situation  and  client’s  investment objectives.  The potential client needs to achieve a certain score in all three criteria in order to be considered as a suitable portfolio management client.'
  },
  suitabilityTestSubmitted: {
    i18nKey: 'suitabilityTestSubmitted',
    defaults: 'Suitability Test submitted'
  },
  suitabilityTestMessageLowMediumCysec: {
    i18nKey: 'suitabilityTestMessageLowMediumCysec',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and as derived from the Law you can invest in a <0>{{scoreResultStrategy}}</0>. Should you wish to invest with the Company in higher-risk strategies, even though the Company is warning you that these strategies may not be appropriate for you, you could still choose to proceed with your investment choice at your own risk.`,
  },
  suitabilityTestMessageHighCysec: {
    i18nKey: 'suitabilityTestMessageHighCysec',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and as derived from the Law you are able to invest in a <0>{{scoreResultStrategy}}</0> and in any other strategies available.`,
  },
  suitabilityTestMessageLowMediumFsc: {
    i18nKey: 'suitabilityTestMessageLowMediumFsc',
    defaults: `{{company}} (the "Company")  would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and you can invest in a <0>{{scoreResultStrategy}}</0>. Should you wish to invest with the Company in higher-risk strategies, even though the Company is warning you that these strategies may not be appropriate for you, you could still choose to proceed with your investment choice at your own risk.`,
  },
  suitabilityTestMessageHighFsc: {
    i18nKey: 'suitabilityTestMessageHighFsc',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and you are able to invest in a <0>{{scoreResultStrategy}}</0> and in any other strategies available.`,
  },
  low: {
    i18nKey: 'low',
    defaults: 'Low',
  },
  medium: {
    i18nKey: 'medium',
    defaults: 'Medium',
  },
  lowRiskStrategy: {
    i18nKey: 'lowRiskStrategy',
    defaults: 'low-risk strategy',
  },
  mediumRiskStrategy: {
    i18nKey: 'mediumRiskStrategy',
    defaults: 'medium-risk strategy',
  },
  highRiskStrategy: {
    i18nKey: 'highRiskStrategy',
    defaults: 'high-risk strategy',
  },
  suitabilityTestAlreadySubmitted: {
    i18nKey: 'suitabilityTestAlreadySubmitted',
    defaults: 'You have already submitted your suitability assessment.',
  },
  downloadPdf: {
    i18nKey: 'downloadPdf',
    defaults: 'Download PDF'
  },
  footerCySEC: {
    i18nKey: 'footerCySEC',
    defaults: 'BDSwiss Holding Ltd is licenced and regulated by the Cyprus Securities and Exchange Commission, Licence No 199/13 Address: Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus Registered Address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. Tel: +357 25 053 940 | Fax: +357 25 260 262 | Email: info@{{emailDomain}}'
  },
  footerFSA: {
    i18nKey: 'footerFSA',
    defaults: 'BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the “FSA”, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles',
  },
  footerFSC: {
    i18nKey: 'footerFSC',
    defaults: 'BDS Markets (Registration No:143350) Address: Amicorp, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene, Mauritius',
  },
  growingCommunity: {
    i18nKey: 'growingCommunity',
    defaults: 'A growing community',
  },
  accountNumber: {
    i18nKey: 'accountNumber',
    defaults: 'Account Number'
  },
  paymentProvider: {
    i18nKey: 'paymentProvider',
    defaults: 'Payment Provider'
  },
  voucher: {
    i18nKey: 'voucher',
    defaults: 'Voucher'
  },
  maximumAccounts: {
    i18nKey: 'maximumAccounts',
    defaults: 'Maximum Trading Account limit reached. If you wish to register an additional account or manage existing accounts, please contact our customer support team.',
  },
  accountSettings: {
    i18nKey: 'accountSettings',
    defaults: 'Account Settings'
  },
  copyTradingSettings: {
    i18nKey: 'copyTradingSettings',
    defaults: 'Copy Trading Settings'
  },
  provideCopyTrading: {
    i18nKey: 'provideCopyTrading',
    defaults: 'Provide Copy Trading'
  },
  changeFeePopupText1: {
    i18nKey: 'changeFeePopupText1',
    defaults: 'The current performance fee of this account is <0>{{fee}}%</0>.'
  },
  changeFeePopupText2: {
    i18nKey: 'changeFeePopupText2',
    defaults: 'Are you sure you want to change it to <0>{{feeChange}}%</0>?'
  },
  copyTradingWithdrawal: {
    i18nKey: 'copyTradingWithdrawal',
    defaults: 'Withdrawals are limited while your account is copying <0>{{ibAccount}}</0>. <1>Stop Copying</1>'
  },
  copyTradingWithdrawalP1: {
    i18nKey: 'copyTradingWithdrawalP1',
    defaults: 'As your account is copying a strategy which is charging a Performance Fee, withdrawals have been restricted.'
  },
  copyTradingWithdrawalP2: {
    i18nKey: 'copyTradingWithdrawalP2',
    defaults: 'In order to proceed with your withdrawal, you will need to stop copying for the specified account.'
  },
  copyTradingWithdrawalP3: {
    i18nKey: 'copyTradingWithdrawalP3',
    defaults: 'Once you have stopped copying:'
  },
  copyTradingWithdrawalP3T1: {
    i18nKey: 'copyTradingWithdrawalP3T1',
    defaults: 'All open positions are closed.'
  },
  copyTradingWithdrawalP3T2: {
    i18nKey: 'copyTradingWithdrawalP3T2',
    defaults: 'Any due performance fee’s are calculated and deducted.'
  },
  copyTradingWithdrawalP3T3: {
    i18nKey: 'copyTradingWithdrawalP3T3',
    defaults: 'Your account is returned to full access and withdrawals are enabled.'
  },
  accountName: {
    i18nKey: 'accountName',
    defaults: 'Account Name'
  },
  update: {
    i18nKey: 'update',
    defaults: 'Update'
  },
  providing: {
    i18nKey: 'providing',
    defaults: 'Providing'
  },
  stopProviding: {
    i18nKey: 'stopProviding',
    defaults: 'Stop Providing'
  },
  stopProvidingTitle: {
    i18nKey: 'stopProvidingTitle',
    defaults: 'You are about to <0> stop providing </0> Copy Trading'
  },
  stopProvidingText1: {
    i18nKey: 'stopProvidingText1',
    defaults: 'Once you choose to stop, your strategy will no longer be visible to your followers.'
  },
  stopProvidingText2: {
    i18nKey: 'stopProvidingText2',
    defaults: 'All of your existing followers will be notified, their copied positions will be closed, and their following on the given account will be discontinued.'
  },
  activeSince: {
    i18nKey: 'activeSince',
    defaults: 'Active since'
  },
  leverageNotice: {
    i18nKey: 'leverageNotice',
    defaults: 'Leverage Notice'
  },
  stopProvidingIsRestricted: {
    i18nKey: 'stopProvidingIsRestricted',
    defaults: 'Stop Providing is restricted'
  },
  activeFollowersText: {
    i18nKey: 'activeFollowersText',
    defaults: 'As you have active followers, your account is resrtricted from disabling providing. '
  },
  discontinueText: {
    i18nKey: 'discontinueText',
    defaults: 'If you wish to discontinue providing copy trading, all open and/or pending positions need to be closed.'
  },
  validationAccountNameError: {
    i18nKey: 'validationAccountNameError',
    defaults: 'You may only use latin characters, numbers, spaces or the following symbols: - . , _ / ! #.',
  },
  webinarRegistration: {
    i18nKey: 'webinarRegistration',
    defaults: 'Thank you for registering!'
  },
  webinarRegistrationText: {
    i18nKey: 'webinarRegistrationText',
    defaults: 'Please use this link to join the webinar: <0> {{joinUrl}} </0>'
  },
  webinarErrorText: {
    i18nKey: 'webinarErrorText',
    defaults: 'Webinar Registration Error'
  },
  footNote: {
    i18nKey: 'footNote',
    defaults: '*Please note that there are limited payment options available under this regulator.',
  },
  validateNumber: {
    i18nKey: 'validateNumber',
    defaults: 'Must be numeric',
  },
  validateBrazilianPhone: {
    i18nKey: 'validateBrazilianPhone',
    defaults: 'Must be numeric and start with 55',
  },
  referAndEarn: {
    i18nKey: 'referAndEarn',
    defaults: 'Refer & Earn',
  },
  howDoesWork: {
    i18nKey: 'howDoesWork',
    defaults: 'How does it work?',
  },
  startRefer: {
    i18nKey: 'startRefer',
    defaults: 'Start referring your friends and contacts to {{company}} and earn {{amountReferrer}} for each referral that meets the trading account requirements. Your referred friend also benefits from a {{amountReferral}} welcome reward.',
  },
  referAndEarnText1: {
    i18nKey: 'referAndEarnText1',
    defaults: 'Click “Copy Link” to copy your partner link',
  },
  referAndEarnText2: {
    i18nKey: 'referAndEarnText2',
    defaults: 'Share your link with your friends or contacts',
  },
  referAndEarnText3: {
    i18nKey: 'referAndEarnText3',
    defaults: 'Your friend must register through your link, deposit at least {{minDeposit}} and verify their account',
  },
  referAndEarnText4: {
    i18nKey: 'referAndEarnText4',
    defaults: 'Your referral must then place at least {{minTrades}} trades within a period of {{days}} days after registration',
  },
  referAndEarnText5: {
    i18nKey: 'referAndEarnText5',
    defaults: 'You will then be able to claim your {{amountReferrer}} reward and transfer it to your trading account',
  },
  yourPartnerLink: {
    i18nKey: 'yourPartnerLink',
    defaults: 'Your Partner Link',
  },
  copyLink: {
    i18nKey: 'copyLink',
    defaults: 'Copy Link',
  },
  yourStatus: {
    i18nKey: 'yourStatus',
    defaults: 'Your Status',
  },
  yourReward: {
    i18nKey: 'yourReward',
    defaults: 'Your Reward',
  },
  referralsReward: {
    i18nKey: 'referralsReward',
    defaults: 'Referrals Reward',
  },
  yourReferrals: {
    i18nKey: 'yourReferals',
    defaults: 'Your Referrals',
  },
  reward: {
    i18nKey: 'reward',
    defaults: 'Reward',
  },
  referrals: {
    i18nKey: 'referrals',
    defaults: 'Referrals',
  },
  activated: {
    i18nKey: 'activated',
    defaults: 'Activated',
  },
  registrationDate: {
    i18nKey: 'registrationDate',
    defaults: 'Registration Date',
  },
  claimReward: {
    i18nKey: 'claimReward',
    defaults: 'Claim Reward',
  },
  partnerMessage: {
    i18nKey: 'partnerMessage',
    defaults: 'Use your partner link to refer your friends and contacts to {{company}} for a chance to earn {{amount}} for each successful referral. Remember that your friends must register through your link in order to appear under “Your Referrals”.',
  },
  yourStatusMessage: {
    i18nKey: 'yourStatusMessage',
    defaults: 'As an Introducer, you get to claim {{amount}} every time you successfully refer someone to {{company}} using your Partner Link. To claim your reward your friend must register, verify their account, deposit at least {{minDeposit}} and place {{minTrades}} trades within {{days}} days.',
  },
  claimMessage: {
    i18nKey: 'claimMessage',
    defaults: 'You have {{amount}} of unclaimed Referral Rewards. Please select the account to which you would like to have your referral reward transferred to. If the selected account is in a different currency, then your reward will be automatically converted to your account’s default currency.',
  },
  claim: {
    i18nKey: 'claim',
    defaults: 'Claim',
  },
  copyShareReward: {
    i18nKey: 'copyShareReward',
    defaults: 'Please copy & share <0>your link</0> to start referring and receiving rewards.',
  },
  referralsLimit: {
    i18nKey: 'referralsLimit',
    defaults: 'Referrals limit reached!',
  },
  wishRefer: {
    i18nKey: 'wishRefer',
    defaults: 'If you wish to continue referring clients, please contact our partnership team.',
  },
  becomeAPartner: {
    i18nKey: 'becomeAPartner',
    defaults: 'Become a Partner',
  },
  referralLimitReached: {
    i18nKey: 'referralLimitReached',
    defaults: 'It appears you\'ve reached your maximum number of referrals.',
  },
  viewMyPerformance: {
    i18nKey: 'viewMyPerformance',
    defaults: 'View my performance',
  },
  trades: {
    i18nKey: 'trades',
    defaults: 'Trades',
  },
  congratulationsReferred: {
    i18nKey: 'congratulationsReferred',
    defaults: 'Congratulations! <0 />You have been referred.',
  },
  congratulationsSubtitle: {
    i18nKey: 'congratulationsSubtitle',
    defaults: 'In order to receive your {{amount}} welcome reward, please complete a few steps within {{days}} days of your registration.',
  },
  congratulationsText1: {
    i18nKey: 'congratulationsText1',
    defaults: 'Register and create your account.',
  },
  congratulationsText2: {
    i18nKey: 'congratulationsText2',
    defaults: 'Verify and Activate your client account.',
  },
  congratulationsText3: {
    i18nKey: 'congratulationsText3',
    defaults: 'Deposit and fund your trading account with a minimum of {{deposit}} Euro (or equivalent in other currencies).',
  },
  congratulationsText4: {
    i18nKey: 'congratulationsText4',
    defaults: 'Execute at least {{trades}} or more trades.',
  },
  register: {
    i18nKey: 'register',
    defaults: 'Register',
  },
  introducer: {
    i18nKey: 'introducer',
    defaults: 'Introducer',
  },
  clientDeclined: {
    i18nKey: 'clientDeclined',
    defaults: 'Client Declined',
  },
  activationOverdue: {
    i18nKey: 'activationOverdue',
    defaults: 'Activation Overdue',
  },
  unclaimedRewards: {
    i18nKey: 'unclaimedRewards',
    defaults: 'Unclaimed Rewards',
  },
  referralActivatedNotification:{
    i18nKey: 'referralActivatedNotification',
    defaults: 'Congratulations! You have received your {{amount}} referral reward! ',
  },
  referralAlreadyPartner:{
    i18nKey: 'referralAlreadyPartner',
    defaults: 'Congratulations! As a Partner you can now benefit from our customised commission plans.',
  },
  referralAlreadyPartnerTitle:{
    i18nKey: 'referralAlreadyPartnerTitle',
    defaults: 'You are a Partner!',
  },
  referralLinkUnavailableTitle:{
    i18nKey: 'referralLinkUnavailableTitle',
    defaults: 'Refferal link is unavailable',
  },
  referralLinkUnavailable:{
    i18nKey: 'referralLinkUnavailable',
    defaults: 'Your referral link will be available once you have verified your account.',
  },
  partnerLinkCopied:{
    i18nKey: 'partnerLinkCopied',
    defaults: 'Link copied to clipboard',
  },
  requestStopCopyTradingFeeTitle: {
    i18nKey: 'requestStopCopyTradingFeeTitle',
    defaults: 'Once you stop copying <0>{{strategy}}</0> which is charging a performance fee, your account will no longer open positions automatically.',
  },
  requestStopCopyTradingFeeText: {
    i18nKey: 'requestStopCopyTradingFeeText',
    defaults: 'All open and/or pending positions will be closed.',
  },
  requestStopCopyTradingFeeText2: {
    i18nKey: 'requestStopCopyTradingFeeText2',
    defaults: 'Any due performance fee will be calculated and deducted.',
  },
  requestStopCopyTradingFeeText3: {
    i18nKey: 'requestStopCopyTradingFeeText3',
    defaults: 'Copying of <0>{{strategy}}</0> will be disabled and your account will be returned to full access.',
  },
  requestStopCopyTradingFeeWeekend: {
    i18nKey: 'requestStopCopyTradingFeeWeekend',
    defaults: 'In the case that some markets are closed, positions will close automatically once the markets open.',
  },
  copyTradingWithdrawalPending:{
    i18nKey: 'copyTradingWithdrawalPending',
    defaults: 'Withdrawals are limited while performance fee’s for provider <0>{{ibAccount}}</0>, are calculated. Please wait until required calculations and deductions are done.',
  },
  performanceFeeDetails:{
    i18nKey: 'performanceFeeDetails',
    defaults: 'Performance Fee charge of strategy: {{providerName}}.',
  },
  documentProcessed: {
    i18nKey: 'documentProcessed',
    defaults: 'Your document will be processed within the next 24-48 hours',
  },
  bonus: {
    i18nKey: 'bonus',
    defaults: 'Bonus',
  },
  requiredLots: {
    i18nKey: 'requiredLots',
    defaults: 'Required Lots',
  },
  tradedLots: {
    i18nKey: 'tradedLots',
    defaults: 'Traded Lots',
  },
  bonusCopyTradingNotificationTitle: {
    i18nKey: 'bonusCopyTradingNotificationTitle',
    defaults: 'You cannot follow strategies on this account',
  },
  bonusCopyTradingNotification: {
    i18nKey: 'bonusCopyTradingNotification',
    defaults: 'As your account has an active bonus, you are not able to follow a strategy until your bonus is converted or removed. You can always use a different account to follow any of the available strategies.',
  },
  createNewAccount: {
    i18nKey: 'createNewAccount',
    defaults: 'Create New Account',
  },
  receiveBonus: {
    i18nKey: 'receiveBonus',
    defaults: 'Receive a First Time Bonus *',
  },
  receiveBonusNote: {
    i18nKey: 'receiveBonusNote',
    defaults: '* The available bonus amount is {{percentage}} of your first deposit, and could not exceed a total of {{maxAmount}}',
  },
  receiveAmountBonus: {
    i18nKey: 'receiveAmountBonus',
    defaults: 'You will receive a <0>{{amount}}</0> bonus with your deposit',
  },
  welcomeBonus: {
    i18nKey: 'welcomeBonus',
    defaults: 'Make your 1st deposit.',
  },
  requireKYCApprovalMessage: {
    i18nKey: 'requireKYCApprovalMessage',
    defaults: 'Welcome to your Trading Hub! To make your first deposit, please complete the KYC document submission process.',
  },
  requireKYCApprovalLink: {
    i18nKey: 'requireKYCApprovalLink',
    defaults: 'Verify',
  },
  welcomeBonusDays: {
    i18nKey: 'welcomeBonusDays',
    defaults: '{{days}} days left to receive a welcome bonus on your 1st deposit',
  },
  bonusCompetitionError: {
    i18nKey: 'bonusCompetitionError',
    defaults: 'Accounts with Bonuses cannot participate in competitions. <0 /> Please select a different participating account',
  },
  bonusTerms: {
    i18nKey: 'bonusTerms',
    defaults: '* By opting in to receive the first-time Bonus, I have read and agree to <0>Terms and Conditions</0>.',
  },
  showGBFooter:{
    i18nKey: 'showGBFooter',
    defaults: 'Deemed authorised and regulated by the Financial Conduct Authority. The nature and extent of consumer protections may differ from those for firms based in the UK. Details of the Financial Services Contracts Regime, which allows EEA-based firms to operate in the UK for a limited period to carry on activities which are necessary for the performance of pre-existing contracts, are available on the Financial Conduct Authority’s website.',
  },
  emailExistsClosed: {
    i18nKey: 'emailExistsClosed',
    defaults: 'Email already exists. Contact <0>support</0> to re-activate your account',
  },
  somethingWrongRefreshText:{
    i18nKey: 'somethingWrongRefreshText',
    defaults: 'If you have viewed this page after multiple attempts, we may be experiencing some technical issues. We will be working hard to try and resolve the issues as soon as possible. If you wish to continue trading you may download the mobile MetaTrader platforms (MT4 for IOS | MT4 for Android). Thank you for your understanding.',
  },
  showGBFooter2:{
    i18nKey: 'showGBFooter2',
    defaults: '<0>TP Firm CASS Disclosure:</0> Please refer to our Terms and Conditions and ICF Policy regarding the treatment of your funds in the event of Company’s failure (applicable for UK clients).',
  },
  dormantMessage: {
    i18nKey: 'dormantMessage',
    defaults: 'Your account requires updated details. Please proceed with the following actions: {{actions}}.',
  },
  missingep: {
    i18nKey: 'missingep',
    defaults: '<0>resubmit</0> Economic Profile questions',
  },
  riskWarningBds: {
    i18nKey: 'riskWarningBds',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. <3 />BDSTrading is registered trademark of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8>',
  },
  riskWarningBdsMau: {
    i18nKey: 'riskWarningBdsMau',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. <3 />BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (<2>FSC</2>) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the Company policies regarding the regulation <0>here</0>. <5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8> <6 /><7>bdstrading.com</7> is operated by BDS Swiss Markets Global Services Ltd which is the primary payment provider and website operator. BDS Swiss Markets Global Services Ltd is acting on behalf of its related company, BDS Markets.',
  },
  riskWarningBdsVa: {
    i18nKey: 'riskWarningBdsVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose.<3 />Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047).Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles.<5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8>',
  },
  bdsTradingRedirectionMessage: {
    i18nKey: 'bdsTradingRedirectionMessage',
    defaults: 'You will now be redirected to BDSTrading, a brand used by the Company for Swiss residents exclusively and is a registered trademark of {{company}}.',
  },
  messagePartnerAddAccount:{
    i18nKey: 'messagePartnerAddAccount',
    defaults: 'Please keep in mind that to get access to your Partner account you will need to upload some additional Verification documents.',
  },
  maybeLater: {
    i18nKey: 'maybeLater',
    defaults: 'Maybe Later'
  },
  additionalDocumentsNeeded: {
    i18nKey: 'additionalDocumentsNeeded',
    defaults: 'Additional Documents Needed'
  },
  pammForexDesc: {
    i18nKey: 'pammForexDesc',
    defaults: 'Automate your trading by investing in a specific strategy offered by a professional portfolio manager and benefit from proportional ROI at the end of the predetermined interval period.'
  },
  pAMMManagerAccountDesc: {
    i18nKey: 'pAMMManagerAccountDesc',
    defaults: 'Empower your trading network with a PAMM account – streamline management and maximise returns.',
  },
  pAMMAccountDesc: {
    i18nKey: 'pAMMAccountDesc',
    defaults: 'Your Affiliate Manager has added a new PAMM trading account. You may now follow their investment strategy by adding it to your portfolio.',
  },
  accountKycRequiredMsg: {
    i18nKey: 'accountKycRequiredMsg',
    defaults: 'The \'{{account}}\' is available only to verified clients. Please proceed with the verification of your account to be able to create a \'{{account}}\'.'
  },
  accountKycRequiredPendingMsg: {
    i18nKey: 'accountKycRequiredPendingMsg',
    defaults: 'The \'{{account}}\' is available only to verified clients. The verification process may take up to 48hours. Please check your email or dashboard for updates.'
  },
  capsLockON: {
    i18nKey: 'capsLockON',
    defaults: 'Caps Lock is On',
  },
  directa24Message: {
    i18nKey: 'directa24Message',
    defaults: 'If you want to deposit using Safari, you need to disable the following options in the Safari Settings:',
  },
  directa24MessageItem1: {
    i18nKey: 'directa24MessageItem1',
    defaults: 'Block All cookies',
  },
  directa24MessageItem2: {
    i18nKey: 'directa24MessageItem2',
    defaults: 'Prevent Cross-site cookies',
  },
  directa24MessageEnd: {
    i18nKey: 'directa24MessageEnd',
    defaults: 'You can also change the browser to Chrome, Firefox, or any other. Contact support if you need help',
  },
  loginVerification: {
    i18nKey: 'loginVerification',
    defaults: 'Login Verification',
  },
  loginVerificationText:{
    i18nKey: 'loginVerificationText',
    defaults: 'We have noticed that you are logged in from a different country than usual. To be able to proceed, we need to verify your account. We have sent you an email with a 5-digit code. Please enter it below. If you have not received the verification email, try checking your spam folder.'
  },
  loginVerificationText2:{
    i18nKey: 'loginVerificationText2',
    defaults: 'Please note that the code is valid for 5 minutes only.'
  },
  code:{
    i18nKey: 'code',
    defaults: 'Code'
  },
  expiredToken: {
    i18nKey: 'expiredToken',
    defaults: 'Token is Expired. You will be logged out.',
  },
  invalidToken: {
    i18nKey: 'invalidToken',
    defaults: 'Token is invalid',
  },
  enterCodeBelow:{
    i18nKey: 'enterCodeBelow',
    defaults: 'Enter code below:'
  },
  communicationLanguage: {
    i18nKey: 'communicationLanguage',
    defaults: 'Communication Language',
  },
  validationAddressError: {
    i18nKey: 'validationAddressError',
    defaults: 'Please use latin or arabic characters, numbers and spaces only.',
  },
  swapFreeIslamicAccount: {
    i18nKey: 'swapFreeIslamicAccount',
    defaults: 'Swap Free (Islamic Account)',
  },
  swapFreeIslamicAccountText: {
    i18nKey: 'swapFreeIslamicAccountText',
    defaults: 'Swap free trading in compliance with Sharia Law',
  },
  swapFreeIslamicAccountPopupText1: {
    i18nKey: 'swapFreeIslamicAccountPopupText1',
    defaults: 'Swap free Islamic Account allows swap free trading in compliance with the Sharia Law, which means you can trade in an Islamic account without overnight fees.',
  },
  swapFreeIslamicAccountPopupText2: {
    i18nKey: 'swapFreeIslamicAccountPopupText2',
    defaults: 'Once enabled, all new positions on your account will be Swap free.',
  },
  swapFreeIslamicAccountPopupText3: {
    i18nKey: 'swapFreeIslamicAccountPopupText3',
    defaults: 'Existing positions will not be affected.',
  },
  swapFreeIslamicAccountPopupNote: {
    i18nKey: 'swapFreeIslamicAccountPopupNote',
    defaults: 'Note that this option is available for Classic and VIP accounts only and <0>is not available for Raw accounts</0>.',
  },
  termsApply: {
    i18nKey: 'termsApply',
    defaults: '<0>Terms and Conditions</0> apply.',
  },
  swapFreeAvailable: {
    i18nKey: 'swapFreeAvailable',
    defaults: 'The swap-free accounts are available only to verified clients.',
  },
  swapFreeIslamicAccountPopupText4: {
    i18nKey: 'swapFreeIslamicAccountPopupText4',
    defaults: 'Please proceed with the verification of your account before enabling the swap-free option.',
  },
  enabled: {
    i18nKey: 'enabled',
    defaults: 'Enabled',
  },
  enable: {
    i18nKey: 'enable',
    defaults: 'Enable',
  },
  disabled: {
    i18nKey: 'disabled',
    defaults: 'Disabled',
  },
  accountsFailedSwapFree: {
    i18nKey: 'accountsFailedSwapFree',
    defaults: 'Some accounts failed to enable Swap Free: ',
  },
  swapFreeKycRequiredPendingMsg: {
    i18nKey: 'swapFreeKycRequiredPendingMsg',
    defaults: 'The verification process may take up to 48hours. Please check your email or dashboard for updates.'
  },
  scanQRCode: {
    i18nKey: 'scanQRCode',
    defaults: 'Scan this QR Code to pay instantly.'
  },
  proceedWithBrowser: {
    i18nKey: 'proceedWithBrowser',
    defaults: 'Proceed with <0>web browser</0>.'
  },
  providerStats:{
    i18nKey: 'providerStats',
    defaults: 'Provider Stats',
  },
  totalFollowersCount:{
    i18nKey: 'totalFollowersCount',
    defaults: 'Total Copiers',
  },
  monthlyFollowersCount:{
    i18nKey: 'monthlyFollowersCount',
    defaults: 'Copiers In Month',
  },
  totalCopiedPnl:{
    i18nKey: 'totalCopiedPnl',
    defaults: 'Total Copied PnL',
  },
  monthlyCopiedPnl:{
    i18nKey: 'monthlyCopiedPnl',
    defaults: 'Copied PnL In Month',
  },
  allTimePerformance:{
    i18nKey: 'allTimePerformance',
    defaults: 'All Time Performance',
  },
  totalActiveFollowersCount:{
    i18nKey: 'totalActiveFollowersCount',
    defaults: 'Total Active Copiers',
  },
  profitPerPeriod:{
    i18nKey: 'profitPerPeriod',
    defaults: 'Copied P/L',
  },
  downloadFollowerPerformance:{
    i18nKey: 'downloadFollowerPerformance',
    defaults: 'Download Follower Performance',
  },
  accountId:{
    i18nKey: 'accountId',
    defaults: 'Account Id',
  },
  performanceFeePaid:{
    i18nKey: 'performanceFeePaid',
    defaults: 'Paid Performance Fee',
  },
  noFollowersDataAvailable:{
    i18nKey: 'noFollowersDataAvailable',
    defaults: 'No followers data available for the current month.',
  },
  startCopyingDate:{
    i18nKey: 'startCopyingDate',
    defaults: 'Starting Date',
  },
  stopCopyingDate:{
    i18nKey: 'stopCopyingDate',
    defaults: 'End Date',
  },
  monthlyWinningTradesCount: {
    i18nKey: 'monthlyWinningTradesCount',
    defaults: 'Winning Trades In Month'
  },
  monthlyLosingTradesCount: {
    i18nKey: 'monthlyLosingTradesCount',
    defaults: 'Losing Trades In Month'
  },
  totalTradesCount: {
    i18nKey: 'totalTradesCount',
    defaults: 'Total Trades Count',
  },
  monthlyTradesCount: {
    i18nKey: 'monthlyTradesCount',
    defaults: 'Trades Count In Month',
  },
  providerReportDateNotification:{
    i18nKey: 'providerReportDateNotification',
    defaults: 'Provider Stats report shows statistics based on copied trades performed after {{copyTradingAutomationStartDate}}.',
  },
  riskWarningBDSwissGroup:{
    i18nKey: 'riskWarningBDSwissGroup',
    defaults: 'This website is for general information purposes only and is operated by BDSwiss AG. BDSwiss AG is the BDSwiss Group’s Holding Company domiciled in Grienbachstrasse 11, 6300, Zug, Switzerland. BDSwiss AG does NOT offer any financial products or services. <4/>Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the “CySEC”, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation.<3/>BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (FSC) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the company policies regarding the regulation.<5/>BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the “FSA”, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation.<6/>BDSwiss GmbH (Registration No. HRB 160749B) is the registered Tied Agent of BDSwiss Holding Ltd in Germany. Registered address at c/o Mindspace Germany GmbH, Friedrichstraße 68, 10117, Berlin, Germany.'
  },
  forbiddenCountryPopupSpecific:{
    i18nKey: 'forbiddenCountryPopupSpecific',
    defaults: 'Registrations from {{country}} are currently not allowed. We are actively working to resolve this problem quickly, you may try again in a later stage. Thank you for your understanding.',
  },
  temporaryCeasingPopupText:{
    i18nKey: 'temporaryCeasingPopupText',
    defaults: 'Currently, the Company is updating its regulatory requirements, therefore, is temporarily ceasing any operations in {{countries}}. We will inform you accordingly upon restarting our operations.',
  },
  walletAddress: {
    i18nKey: 'walletAddress',
    defaults: 'Wallet Address',
  },
  max: {
    i18nKey: 'max',
    defaults: 'Max',
  },
  cryptoWalletDepositInstructionsTitle: {
    i18nKey: 'cryptoWalletDepositInstructionsTitle',
    defaults: 'How to deposit your {{company}} {{productName}} account?',
  },
  cryptoWalletDepositInstructions: {
    i18nKey: 'cryptoWalletDepositInstructions',
    defaults: 'Please proceed with a transfer from your <0>{{accountCurrency}}</0> Wallet Address to the below Wallet Address:',
  },
  walletCopied:{
    i18nKey: 'walletAddressCopied',
    defaults: 'Wallet Address copied to clipboard',
  },
  cryptoWalletVerificationRequired: {
    i18nKey: 'cryptoWalletVerificationRequired',
    defaults: 'Please proceed with the verification of your account to be able to check your wallet details.',
  },
  productVerificationRequired: {
    i18nKey: 'productVerificationRequired',
    defaults: '{{productName}} is available only to verified clients. ',
  },
  productVerificationPending: {
    i18nKey: 'productVerificationPending',
    defaults: 'The verification process may take up to 48hours. Please check your email or dashboard for updates.',
  },
  corporateNote: {
    i18nKey: 'corporateNote',
    defaults: 'Please note that by choosing to onboard as a “Company”, you will have to pass the mandatory <0>corporate verification</0> process. If you don’t have a registered company, please choose the “Individual” option.',
  },
  regulationPopupMessage3: {
    i18nKey: 'regulationPopupMessage3',
    defaults: 'By clicking "Accept" you will continue with your registration under the entity regulated by {{regulator}} {{regulatorCountry}}.',
  },
  chooseEntity: {
    i18nKey: 'chooseEntity',
    defaults: 'Choose Entity',
  },
  vpsSetupWait: {
    i18nKey: 'vpsSetupWait',
    defaults: 'VPS setup is in progress. Please wait, this may take a while. If you don`t get your VPS details in next 24 hours from your request, please contact our support team.'
  },
  vpsUnavailable: {
    i18nKey: 'vpsUnavailable',
    defaults: 'VPS Subscription is unavailable for Raw Pro accounts.'
  },
  one_core: {
    i18nKey: 'one_core',
    defaults: '1 vCPU',
  },
  two_core: {
    i18nKey: 'two_core',
    defaults: '2 vCPU',
  },
  four_core: {
    i18nKey: 'four_core',
    defaults: '4 vCPU',
  },
  disk_space_40: {
    i18nKey: 'disk_space_40',
    defaults: 'Storage 40GB HDD',
  },
  disk_space_50: {
    i18nKey: 'disk_space_50',
    defaults: 'Storage 50GB HDD',
  },
  disk_space_100: {
    i18nKey: 'disk_space_100',
    defaults: 'Storage 100GB HDD',
  },
  memory_1gb: {
    i18nKey: 'memory_1gb',
    defaults: 'RAM 1GB',
  },
  memory_2gb: {
    i18nKey: 'memory_2gb',
    defaults: 'RAM 2GB',
  },
  memory_4gb: {
    i18nKey: 'memory_4gb',
    defaults: 'RAM 4GB',
  },
  os_windows: {
    i18nKey: 'os_windows',
    defaults: 'Win Server 2012 R2',
  },
  submitVpsSubscriptionTitle: {
    i18nKey: 'submitVpsSubscriptionTitle',
    defaults: 'Do you want to create a VPS subscription?',
  },
  ipAddress: {
    i18nKey: 'ipAddress',
    defaults: 'IP Address',
  },
  vpsBillingCycle: {
    i18nKey: 'vpsBillingCycle',
    defaults: 'Billing Cycle',
  },
  vpsLastBilled: {
    i18nKey: 'vpsLastBilled',
    defaults: 'Last Billed',
  },
  vpsNextDue: {
    i18nKey: 'vpsNnextDue',
    defaults: 'Next Due',
  },
  vpsAmountDue: {
    i18nKey: 'vpsAmountDue',
    defaults: 'Amount Due',
  },
  vpsActions: {
    i18nKey: 'vpsActions',
    defaults: 'Subscription Actions',
  },
  vpsServerDetails: {
    i18nKey: 'vpsServerDetails',
    defaults: 'Server',
  },
  vpsServerActions: {
    i18nKey: 'vpsServerActions',
    defaults: 'Actions',
  },
  vpsServerStart: {
    i18nKey: 'vpsServerStart',
    defaults: 'Start',
  },
  vpsServerStop: {
    i18nKey: 'vpsServerStop',
    defaults: 'Stop',
  },
  vpsServerReboot: {
    i18nKey: 'vpsServerReboot',
    defaults: 'Reboot',
  },
  vpsOS: {
    i18nKey: 'vpsOS',
    defaults: 'Operating System',
  },
  vpsRAM: {
    i18nKey: 'vpsRAM',
    defaults: 'RAM',
  },
  vpsBandwidth: {
    i18nKey: 'vpsBandwidth',
    defaults: 'Network Bandwidth',
  },
  vpsPassword: {
    i18nKey: 'vpsPassword',
    defaults: 'Root Password',
  },
  vpsServerRunning: {
    i18nKey: 'vpsServerRunning',
    defaults: 'Running',
  },
  vpsServerStopped: {
    i18nKey: 'vpsServerStopped',
    defaults: 'Stopped',
  },
  vpsActionConfirmation: {
    i18nKey: 'vpsActionConfirmation',
    defaults: 'Please confirm <0>{{action}}</0> server action.',
  },
  vpsActionSuccess: {
    i18nKey: 'vpsActionSuccess',
    defaults: 'Successfully completed {{action}} server action.',
  },
  vpsActionFailure: {
    i18nKey: 'vpsActionFailure',
    defaults: '{{action}} server action failed: {{error}}.',
  },
  vpsCancelSubscriptionTitle: {
    i18nKey: 'vpsCancelSubscriptionTitle',
    defaults: 'Do you want to cancel your {{plan}} subscription?',
  },
  vpsCancelSubscriptionMessage: {
    i18nKey: 'vpsCancelSubscriptionMessage',
    defaults: 'Please have in mind that if you proceed with cancellation, your <0>current VPS setup will be lost by the end of your billing cycle</0>. There is no option to reactivate your subscription, you will be able to subscribe to a new plan after the end of current billing cycle.',
  },
  vpsChangePlanMessage: {
    i18nKey: 'vpsChangePlanMessage',
    defaults: 'Do you want to change your subscription plan?',
  },
  vpsSubscriptionPendingChangeFree:{
    i18nKey: 'vpsSubscriptionPendingChangeFree',
    defaults: 'Your plan will be changed to <0>{{pendingPlan}}</0> on <0>{{renewalDate}}</0>. Until then you enjoy the advantages of your current plan.',
  },
  vpsSubscriptionActiveFree:{
    i18nKey: 'vpsSubscriptionActiveFree',
    defaults: 'Enjoy your free <0>{{plan}}</0> subscription.',
  },
  vpsLatencyChart: {
    i18nKey: 'vpsLatencyChart',
    defaults: 'Latency to MetaTrader'
  },
  vpsLatencyChartXAxis: {
    i18nKey: 'vpsLatencyChartXAxis',
    defaults: 'Measurement time'
  },
  vpsLatencyChartYAxis: {
    i18nKey: 'vpsLatencyChartYAxis',
    defaults: 'Latency'
  },
  vpsLatencyTooltip: {
    i18nKey: 'vpsLatencyTooltip',
    defaults: 'Latency in milliseconds'
  },
  vpsCancelSubscription: {
    i18nKey: 'vpsCancelSubscription',
    defaults: 'Cancel Subscription',
  },
  vpsSubscriptionDescription:{
    i18nKey: 'vpsSubscriptionDescription',
    defaults: 'Choose your plan that best fits your requirements and ensure you are getting the maximum benefit from trading with us.',
  },
  vpsTerminateSubscriptionTitle:{
    i18nKey: 'vpsTerminateSubscriptionTitle',
    defaults: 'Are you sure you want to terminate your subscription immediately?',
  },
  vpsTerminateSubscriptionMessage:{
    i18nKey: 'vpsTerminateSubscriptionMessage',
    defaults: 'Terminating your subscription you will not be able to re-activate, although , if you want later you can create a new subscription.',
  },
  terminate:{
    i18nKey: 'terminate',
    defaults: 'Terminate',
  },
  vpsReactivateSubscriptionMessage:{
    i18nKey: 'vpsReactivateSubscriptionMessage',
    defaults: 'Are you sure you want to reactivate your subscription?',
  },
  vpsSubscriptionCancelled: {
    i18nKey: 'vpsSubscriptionCancelled',
    defaults: 'Your plan has been cancelled. Your current setup will be active until <0>{{renewalDate}}</0>.',
  },
  vpsSubscriptionFailedPayment:{
    i18nKey: 'vpsSubscriptionFailedPayment',
    defaults: 'Your payment for your current subscription plan has failed. Please <1>update your payment details</1> to continue using your VPS service. If you failed to update your details until <0>{{renewalDate}}</0>, your subscription will be terminated and your VPS setup will be lost.',
  },
  vpsResetPassNotice:{
    i18nKey: 'vpsResetPassNotice',
    defaults: 'Please have in mind that you will need to  <0>reboot your VPS service</0> to be able to use your new password.',
  },
  vpsTerminateFailedPaymentMessage:{
    i18nKey: 'vpsTerminateFailedPaymentMessage',
    defaults: 'Your payment for your current subscription plan has failed. Please <0>update your payment details</0> to continue using your VPS service. If you are going to terminate your subscription, <0>your VPS setup will be lost</0>.',
  },
  newVpsDisabled:{
    i18nKey: 'newVpsDisabled',
    defaults: 'Currently new VPS subscription is not available. Please try again later.',
  },
  vpsVerificationRequired:{
    i18nKey: 'vpsVerificationRequired',
    defaults: 'VPS Service is available only to verified clients. Please proceed with the verification of your account to be able to apply for a VPS subscription.',
  },
  vpsVerificationPending:{
    i18nKey: 'vpsVerificationPending',
    defaults: 'VPS Service is available only to verified clients. The verification process may take up to 48hours. Please check your email or dashboard for updates.',
  },
  howToAccessVps:{
    i18nKey: 'howToAccessVps',
    defaults: 'How to access your VPS?',
  },
  howToAccessVpsStep1:{
    i18nKey: 'howToAccessVpsStep1',
    defaults: 'After completing payment and activating your VPS you will receive an email. You need to take note of the following:',
  },
  howToAccessVpsStep1Note1:{
    i18nKey: 'howToAccessVpsStep1Note1',
    defaults: 'Main IP - (Your Server/VPS IP address)',
  },
  howToAccessVpsStep1Note2:{
    i18nKey: 'howToAccessVpsStep1Note2',
    defaults: 'Username - Administrator',
  },
  howToAccessVpsStep1Note3:{
    i18nKey: 'howToAccessVpsStep1Note3',
    defaults: 'Password - check your dashboard',
  },
  howToAccessVpsStep2:{
    i18nKey: 'howToAccessVpsStep2',
    defaults: 'Open Remote Desktop Connection on your PC by clicking on Start',
  },
  howToAccessVpsStep2Note1:{
    i18nKey: 'howToAccessVpsStep2Note1',
    defaults: 'Instructions are based on Windows OS, you may use any remote desktop application available for your operating system.',
  },
  howToAccessVpsStep3:{
    i18nKey: 'howToAccessVpsStep3',
    defaults: 'Copy and Paste your Server IP into the Computer field and click Connect',
  },
  howToAccessVpsStep4:{
    i18nKey: 'howToAccessVpsStep4',
    defaults: 'Type User Name Administrator and your VPS password',
  },
  pendingRulesCompetitions: {
    i18nKey: 'pendingRulesCompetitions',
    defaults: 'Participant did not meet all the conditions and therefore has not been ranked yet',
  },
  remainingTrades: {
    i18nKey: 'remainingTrades',
    defaults: '{{trades}} left',
  },
  howToParticipateCompetition:{
    i18nKey: 'howToParticipateCompetition',
    defaults: 'How to participate in Competition?',
  },
  officialOnlineTradingPartner:{
    i18nKey: 'officialOnlineTradingPartner',
    defaults: 'Official Online Trading Partner',
  },
  openTradesAffected:{
    i18nKey: 'openTradesAffected',
    defaults: 'Note that all the open trades will be affected by this change!',
  },
  referredByPartner:{
    i18nKey: 'referredByPartner',
    defaults: 'Referred by a partner',
  },
  partnerReferenceNumber:{
    i18nKey: 'partnerReferenceNumber',
    defaults: 'Partner reference number',
  },
  bonusAcceptedTerms:{
    i18nKey: 'bonusAcceptedTerms',
    defaults: 'I have read and accepted the <0>First Deposit Bonus Terms & Conditions</0> and wish to receive a Bonus.',
  },
  spoaTitle:{
    i18nKey: 'spoaTitle',
    defaults: 'Special Power of Attorney Authorisation',
  },
  spoaNote:{
    i18nKey: 'spoaNote',
    defaults: 'By clicking the Grant Authorisation button, the Introducing Broker with the below-submitted ID will be able to perform a deposit on your behalf to your {{company}} account.',
  },
  spoaAuthorizationText2: {
    i18nKey: 'spoaAuthorizationText2',
    defaults: 'You are participating in our Bonus Campaign.'
  },
  eBookDialogTitle: {
    i18nKey: 'eBookDialogTitle',
    defaults: 'Download Free Forex eBooks',
  },
  eBookDialogDescription: {
    i18nKey: 'eBookDialogDescription',
    defaults: 'Whether you are just learning the basics, or are an experienced trader looking to refine your strategy, {{company}} ebook library can help you take your trading skills to the next level. Our ebooks cover a range of insightful topics from basic fundamental principles to advanced trading strategies.',
  },
  technicalIndicatorTitle: {
    i18nKey: 'technicalIndicatorTitle',
    defaults: 'Technical Indicators Defined and Explained',
  },
  technicalIndicatorSubtitle: {
    i18nKey: 'technicalIndicatorSubtitle',
    defaults: 'A guide to understanding and applying the most popular technical indicators.',
  },
  technicalIndicatorListItem1: {
    i18nKey: 'technicalIndicatorListItem1',
    defaults: 'RSI - Relative Strength Index',
  },
  technicalIndicatorListItem2: {
    i18nKey: 'technicalIndicatorListItem2',
    defaults: 'Average Directional Index',
  },
  technicalIndicatorListItem3: {
    i18nKey: 'technicalIndicatorListItem3',
    defaults: 'Parabolic SAR',
  },
  technicalIndicatorListItem4: {
    i18nKey: 'technicalIndicatorListItem4',
    defaults: 'Moving Average Convergence and Divergence (MACD)',
  },
  technicalIndicatorListItem5: {
    i18nKey: 'technicalIndicatorListItem5',
    defaults: 'Bollinger Bands <0/>',
  },
  technicalIndicatorListItem6: {
    i18nKey: 'technicalIndicatorListItem6',
    defaults: 'Linearly Weighted Moving Average',
  },
  technicalIndicatorListItem7: {
    i18nKey: 'technicalIndicatorListItem7',
    defaults: 'Exponential Moving Average',
  },
  technicalIndicatorListItem8: {
    i18nKey: 'technicalIndicatorListItem8',
    defaults: 'Simple Moving Average',
  },
  technicalIndicatorListItem9: {
    i18nKey: 'technicalIndicatorListItem9',
    defaults: 'Stochastic Oscillator',
  },
  ebook: {
    i18nKey: 'ebook',
    defaults: 'Forex Electronic Book',
  },
  ebookDescription: {
    i18nKey: 'ebookDescription',
    defaults: 'Download a guide to understanding and applying the most popular technical indicators.',
  },
  downloadEbook: {
    i18nKey: 'downloadEbook',
    defaults: 'Download full ebook',
  },
  languageDisclaimer:{
    i18nKey: 'languageDisclaimer',
    defaults: 'Language selection will define the <0>communication</0> language with {{company}} team. All notifications, SMS and emails will be sent in your selected language.',
  },
  bech32WalletAddress: {
    i18nKey: 'bech32WalletAddress',
    defaults: 'Please be informed that the wallet address you are about to copy is a Bech32 address, before proceeding with the payment you should check that your wallet provider supports payments to Bech32 addresses.'
  },
  extraFeeForCryptoWarning: {
    i18nKey: 'extraFeeForCryptoWarning',
    defaults: 'Crypto deposits may incur extra fees from the provider. We do not charge depositing fees on our end.'
  },
  bitnukWithdrawalFee: {
    i18nKey: 'bitnukWithdrawalFee',
    defaults: 'A withdrawal fee of {{withdrawalFee}} will be applied.',
  },
  submittedWireDetailsWarning: {
    i18nKey: 'submittedWireDetailsWarning',
    defaults: 'Please note that you need to add your client reference number to the transfer, otherwise the payment will be anonymous to us and we won\'t be able to add the funds to your account.'
  },
  important: {
    i18nKey: 'important',
    defaults: 'Important',
  },
  registrationServerError: {
    i18nKey: 'registrationServerError',
    defaults: 'Registration is currently unavailable as we are experiencing some technical issues. We are working hard to try and resolve the issues as soon as possible. Please try again in a while. Thank you for your understanding.',
  },
  supportNotification: {
    i18nKey: 'supportNotification',
    defaults: 'Make sure you are speaking to us by asking for your Security Code at the start of your call!',
  },
  supportAlertTitle: {
    i18nKey: 'supportAlertTitle',
    defaults: 'Use the {{company}} Security Code System to protect yourself from telephone scammers!'
  },
  supportAlertText1: {
    i18nKey: 'supportAlertText1',
    defaults: 'To protect you from telephone scammers, we have a Security Code System in place. Always ensure that you are speaking to us by requesting your Security Code at the start of all your calls with {{company}}.'
  },
  supportAlertText2: {
    i18nKey: 'supportAlertText2',
    defaults: '<0>How it works</0>'
  },
  supportAlertText3: {
    i18nKey: 'supportAlertText3',
    defaults: 'Upon your request, the agent handling your call will send you a unique Security Code via email, and once you confirm receipt, will then spell out the code to you.'
  },
  supportAlertText4: {
    i18nKey: 'supportAlertText4',
    defaults: 'The code you hear from the agent should match the code in the email. This way you will know that you are speaking to us, and not to a scammer pretending to be us.'
  },
  supportAlertText5: {
    i18nKey: 'supportAlertText5',
    defaults: 'We highly recommend and strongly advise that you always request your Security Code when calling {{company}}.'
  },
  licenseAcknowledgementCountries: {
    i18nKey: 'licenseAcknowledgementCountries',
    defaults: 'I acknowledge and understand that {{company}} is not regulated by any of the {{authority}} authorities and operates from its Licence N°{{license}} issued by the {{regulation}} ({{regulationLabel}}) {{regulationCountry}}'
  },
  tradeCompanion:{
    i18nKey: 'tradeCompanion',
    defaults: 'Trade Companion'
  },
  tradeCompanionDescription:{
    i18nKey: 'tradeCompanionDescription',
    defaults: 'Take full advantage of professional in-depth analysis of your trades powered by Tableau'
  },
  tradeCompanionDisclaimer:{
    i18nKey: 'tradeCompanionDisclaimer',
    defaults: 'On this page you can access trading statistics per all of your accounts to get insights for your future deals.'
  },
  tradeCompanionError:{
    i18nKey: 'tradeCompanionError',
    defaults: 'Something went wrong fetching your trading records. Please try again later.'
  },
  tradeCompanionFooter:{
    i18nKey: 'tradeCompanionFooter',
    defaults: 'Any and all information on the Trade Companion tool is provided for informative purpose only. All content mentioned does not constitute professional advice and the past performance of any trading system or methodology is not necessarily indicative of future results. No guarantee is made that you will be able to replicate the same results.'
  },
  forbiddenCountryPopupSpecificStrict:{
    i18nKey: 'forbiddenCountryPopupSpecificStrict',
    defaults: 'Registrations from {{country}} are not allowed, as we do not provide financial services in your country of residence.',
  },
  consecutiveSpaces: {
    i18nKey: 'consecutiveSpaces',
    defaults: 'Consecutive spaces between words are not allowed.',
  },
  profileAndSettings: {
    i18nKey: 'profileAndSettings',
    defaults: 'Profile and Settings',
  },
  registrationRedirect: {
    i18nKey: 'registrationRedirect',
    defaults: 'Based on your selection of country you will now be redirected to {{company}}, to register for an account authorized and regulated by the {{regulator}}.',
  },
  importantInformation: {
    i18nKey: 'importantInformation',
    defaults: 'Important Information',
  },
  payretailersLocalBanksNotice: {
    i18nKey: 'payretailersLocalBanksNotice',
    defaults: 'When using this payment method, withdrawals from your trading account will be processed via local bank transfer.',
  },
  emailExistsLogin:{
    i18nKey: 'emailExistsLogin',
    defaults: 'Email already exist, please try to <0>login</0> instead.',
  },
  successfulMigrationTitle: {
    i18nKey: 'successfulMigrationTitle',
    defaults: 'Your account was successfully migrated under {{trademark}}',
  },
  successfulMigrationText1: {
    i18nKey: 'successfulMigrationText1',
    defaults: 'You now enjoy greater investor protections including participation in the Investor Compensation Fund. All your personal and trading account information along with all your account assets, transaction history, liabilities, and balances were also transferred to {{trademark}}. This means that you will be able to pick up exactly where you left off when it comes to your trading.',
  },
  successfulMigrationText2: {
    i18nKey: 'successfulMigrationText2',
    defaults: 'Our {{regulator}} regulator does enforce a lower leverage ratio (of up to {{leverage}}) as a means to protect retail traders from unwarranted losses. However, you may choose to apply for a Professional Client categorisation if you believe you qualify for higher leverage. <0>Learn More</0>',
  },
  successfulMigrationText3: {
    i18nKey: 'successfulMigrationText3',
    defaults: 'Our {{regulator}} regulator does enforce a lower leverage ratio (of up to {{leverage}}) as a means to protect retail traders from unwarranted losses. However, you may choose to apply for a Professional Client categorisation by sending a direct request to <2>{{supportEmail}}</2>.',
  },
  successfulMigrationText4: {
    i18nKey: 'successfulMigrationText4',
    defaults: 'Kindly note that your account server and MT4/5 Account ID were updated, and you can find your new credentials directly on your dashboard. If you have any trouble accessing your account or platforms, please contact us directly via <1>Live Chat</1>.',
  },
  invalidCompany:{
    i18nKey: 'invalidCompany',
    defaults: '{{entity}} does not provide services to {{country}}',
  },
  acceptedDocuments: {
    i18nKey: 'acceptedDocuments',
    defaults: 'Accepted Documents',
  },
  acceptedDocumentsTextPor1: {
    i18nKey: 'acceptedDocumentsTextPor1',
    defaults: 'Electricity, water, gas or internet bill',
  },
  acceptedDocumentsTextPor2: {
    i18nKey: 'acceptedDocumentsTextPor2',
    defaults: 'Bank statement / credit card statement / bank reference letter',
  },
  acceptedDocumentsTextPor3: {
    i18nKey: 'acceptedDocumentsTextPor3',
    defaults: 'Tax bill / Council tax bill (for the current year)',
  },
  acceptedDocumentsTextPor4: {
    i18nKey: 'acceptedDocumentsTextPor4',
    defaults: 'Social insurance statement',
  },
  acceptedDocumentsTextPor5: {
    i18nKey: 'acceptedDocumentsTextPor5',
    defaults: 'Government-issued Certificate of Residence or notarised Affidavit of Residence',
  },
  licenseAcknowledgementCountriesText2: {
    i18nKey: 'licenseAcknowledgementCountriesText2',
    defaults: 'I acknowledge and understand that {{company}} is not regulated by any {{authority}} financial authorities and operates via its license N°{{license}}, issued by the {{regulation}} ({{regulationLabel}}) {{regulationCountry}}. By registering with {{company}}, I understand that I do not have the same level of investor protections offered in my country of residence'
  },
  faqs: {
    i18nKey: 'faqs',
    defaults: 'FAQs',
  },
  frequentlyAskedQuestions: {
    i18nKey: 'frequentlyAskedQuestions',
    defaults: 'Frequently asked questions',
  },
  seeAllFaqs: {
    i18nKey: 'seeAllFaqs',
    defaults: 'see all FAQs',
  },
  wireTransferNotificationBankNotAvailable: {
    i18nKey: 'wireTransferNotificationBankNotAvailable',
    defaults: 'Please note that as of {{date}}, <0>wire transfers via {{bankName}} are no longer available</0>. All deposits sent via this payment method will be automatically rejected by the bank. Kindly proceed with deleting the {{bankName}} transfer details from your saved online banking methods. Future wire transfers will be processed via alternative banking providers.',
  },
  migrateEpTestMessage:{
    i18nKey: 'migrateEpTestMessage',
    defaults: 'Due to your account\'s migration to {{company}}, some additional information is needed to complete your Economic Profile. Please take a moment and fill in the missing information on the form.',
  },
  readAcceptTerms:{
    i18nKey: 'readAcceptTerms',
    defaults: 'I have read and accept the <0>General Terms & Conditions</0>, <1>Legal Documents </1>, <2>Personal Data Protection</2> of {{company}}',
  },
  migratePartnersTitle:{
    i18nKey: 'migratePartnersTitle',
    defaults: '{{entity}} Account Requirements',
  },
  migratePartners:{
    i18nKey: 'migratePartners',
    defaults: 'Following relevant regulatory requirements, all {{company}} Partners working with {{basedClients}} clients are now required to maintain a separate account under our {{entity}} regulated entity.',
  },
  euBased:{
    i18nKey: 'euBased',
    defaults: 'EU-based',
  },
  migratePartnersText2:{
    i18nKey: 'migratePartnersText2',
    defaults: 'You are accordingly requested to provide a new email address so that we can create a separate partner account for you under {{entity}} regulated entity. Please also read and accept the relevant Terms and Conditions before you “Submit” a new email address.',
  },
  submittedWireDetailsWarningAdditional: {
    i18nKey: 'submittedWireDetailsWarningAdditional',
    defaults: 'Please note that you need to add your client reference number to the transfer, otherwise the payment will be anonymous to us and we won’t be able to add the funds to your account. Please make sure that any additional information must be provided in English Language.'
  },
  submittedWireDetailsWarningAdditionalReference: {
    i18nKey: 'submittedWireDetailsWarningAdditionalReference',
    defaults: 'Please note that you need to copy the content of the Reference field and paste it to your bank transfer, otherwise your payment might be lost. Please make sure that any additional information must be provided in English.'
  },
  walletSnackbar:{
    i18nKey: 'walletSnackbar',
    defaults: 'Use your {{wallet}} to deposit, store and withdraw Bitcoin or fund your trading accounts!',
  },
  unlockWallet:{
    i18nKey: 'unlockWallet',
    defaults: 'Unlock your {{wallet}}!',
  },
  bitwalletBenefits:{
    i18nKey: 'bitwalletBenefits',
    defaults: 'To use all the benefits of the {{company}} Bitcoin wallet you first need to complete your account verification. You will then be able to:',
  },
  bitwalletBenefit1:{
    i18nKey: 'bitwalletBenefit1',
    defaults: 'Deposit Bitcoin to your {{company}} {{wallet}} from any external wallet',
  },
  bitwalletBenefit2:{
    i18nKey: 'bitwalletBenefit2',
    defaults: 'Fund your trading accounts with Bitcoin',
  },
  bitwalletBenefit3:{
    i18nKey: 'bitwalletBenefit3',
    defaults: 'Transfer funds from your accounts to {{wallet}}',
  },
  bitwalletBenefit4:{
    i18nKey: 'bitwalletBenefit4',
    defaults: 'Withdraw Bitcoin from {{wallet}} to any external wallet',
  },
  completeVerification:{
    i18nKey: 'completeVerification',
    defaults: 'Complete verification',
  },
  bankTransferWithdrawalWarningTitle:{
    i18nKey: 'bankTransferWithdrawalWarningTitle',
    defaults: 'Bank wire withdrawals are experiencing delays!',
  },
  bankTransferWithdrawalWarningText:{
    i18nKey: 'bankTransferWithdrawalWarningText',
    defaults: 'Please be informed that our banking providers seem to be currently experiencing some delays in their processing of bank wire withdrawals as they are required to run additional AML/KYC checks on individual clients. We apologise for the inconvenience. If you have any other alternative banking or payment option as a preselected payment method, then you may choose to submit your withdrawal request via a different option.',
  },
  usdtOption: {
    i18nKey: 'usdtOption',
    defaults: 'USDT Option',
  },
  bankTransfer: {
    i18nKey: 'bankTransfer',
    defaults: 'Bank transfer'
  },
  bankTransferSignText: {
    i18nKey: 'bankTransferSignText',
    defaults: 'Before you send us a transfer you need to digitally sign the Payment acknowledgment - it will take several minutes.'
  },
  bankTransferJMFinancialText: {
    i18nKey: 'bankTransferJMFinancialText',
    defaults: 'Prior to sending us a wire transfer, we kindly request that you digitally sign the Payment Acknowledgment Form. This will only take several minutes.'
  },
  bankTransferJMFinancialAcknowledgmentText: {
    i18nKey: 'bankTransferJMFinancialAcknowledgmentText',
    defaults: 'You have successfully signed the Payment Acknowledgment Form! You can now proceed with the wire transfer.'
  },
  signNow: {
    i18nKey: 'signNow',
    defaults: 'Sign now'
  },
  documentExecutionError: {
    i18nKey: 'documentExecutionError',
    defaults: 'Failed to sign document, please try again!'
  },
  noAddress: {
    i18nKey: 'noAddress',
    defaults: 'No address was provided. Please add your address to your profile first.',
  },
  joinVipClubTitle: {
    i18nKey: 'joinVipClubTitle',
    defaults: 'Join our VIP Club'
  },
  vipAccountPrivilagedText2: {
    i18nKey: 'vipAccountPrivilagedText2',
    defaults: 'Designed for individuals looking to trade larger volumes at even more competitive conditions, {{company}} premium VIP and Raw accounts come with a set of exclusive benefits including live trading alerts, lower spreads and commissions, dedicated support and premium trading tools.'
  },
  upgradeAccountAccess: {
    i18nKey: 'upgradeAccountAccess',
    defaults: 'Upgrade your account to access exclusive tools and benefits'
  },
  openAccountType: {
    i18nKey: 'openAccountType',
    defaults: 'Open {{type}}'
  },
  takeAdvantage: {
    i18nKey: 'takeAdvantage',
    defaults: 'Take advantage of our most exclusive trading conditions'
  },
  vipAccountAdvantage: {
    i18nKey: 'vipAccountAdvantage',
    defaults: 'Lower Costs or Raw Spreads'
  },
  vipAccountAdvantageText: {
    i18nKey: 'vipAccountAdvantageText',
    defaults: 'Lower your trading costs by accessing $0 commissions on indices and/or raw spreads on all majors from 0.0 pips.'
  },
  vipAccountAdvantage2: {
    i18nKey: 'vipAccountAdvantage2',
    defaults: 'Real-Time VIP Trading Alerts'
  },
  vipAccountAdvantageText2: {
    i18nKey: 'vipAccountAdvantageText2',
    defaults: 'Live Trading Alerts via Telegram with actionable insights and key market updates from industry experts.'
  },
  vipAccountAdvantage3: {
    i18nKey: 'vipAccountAdvantage3',
    defaults: 'Trade Companion Personal Analytics'
  },
  vipAccountAdvantageText3: {
    i18nKey: 'vipAccountAdvantageText3',
    defaults: 'Monitor, analyse and improve your trading performance with key personal analytics available directly on your Dashboard.'
  },
  vipAccountAdvantage4: {
    i18nKey: 'vipAccountAdvantage4',
    defaults: 'Premium Autochartist Tools and Plugins'
  },
  vipAccountAdvantageText4: {
    i18nKey: 'vipAccountAdvantageText4',
    defaults: 'Receive the highest performing setups on forming trends with confirmed support/resistance levels.'
  },
  vipAccountAdvantage5: {
    i18nKey: 'vipAccountAdvantage5',
    defaults: 'Priority of Transactions'
  },
  vipAccountAdvantageText5: {
    i18nKey: 'vipAccountAdvantageText5',
    defaults: 'Get immediate priority on all processing requests including KYC account verifications, deposits and withdrawals.'
  },
  vipAccountAdvantage6: {
    i18nKey: 'vipAccountAdvantage6',
    defaults: 'WebTrader Trends Analysis Tool'
  },
  vipAccountAdvantageText6: {
    i18nKey: 'vipAccountAdvantageText6',
    defaults: 'Open a VIP account to access over 500 upcoming price trends with possible breakout levels plotted live on your WebTrader chart.'
  },
  vipAccountAdvantage7: {
    i18nKey: 'vipAccountAdvantage7',
    defaults: 'Exclusive Webinars & Events '
  },
  vipAccountAdvantageText7: {
    i18nKey: 'vipAccountAdvantageText7',
    defaults: 'Attend premium webinars hosted by our leading analysts and receive exclusive invitations to VIP seminars and events.'
  },
  vipAccountAdvantage8: {
    i18nKey: 'vipAccountAdvantage8',
    defaults: 'Dedicated Account Manager'
  },
  vipAccountAdvantageText8: {
    i18nKey: 'vipAccountAdvantageText8',
    defaults: 'You will be assigned to your personal BDSwiss Account Manager who will provide you with expert support every step of the way.'
  },
  strategySecuritySettings: {
    i18nKey: 'strategySecuritySettings',
    defaults: 'Strategy Security Settings',
  },
  passwordProtected: {
    i18nKey: 'passwordProtected',
    defaults: 'Password Protected',
  },
  passwordProtection: {
    i18nKey: 'passwordProtection',
    defaults: 'Password Protection',
  },
  passwordProtectionText: {
    i18nKey: 'passwordProtectionText',
    defaults: 'Once the strategy is password protected, it will remain visible to all followers. A \'Password protected\' indication will be shown on the chosen strategy, with an explanation of how to unlock and enter the set password.',
  },
  passwordProtectedStrategy: {
    i18nKey: 'passwordProtectedStrategy',
    defaults: 'The Strategy you would like to subscribe to is Password Protected, please enter the Password and start copying trades.',
  },
  enterPasswordProtectStrategy: {
    i18nKey: 'enterPasswordProtectStrategy',
    defaults: 'Please enter a password to be able to proceed',
  },
  topUpDemo: {
    i18nKey: 'topUpDemo',
    defaults: 'Top Up Demo Account Balance',
  },
  amountWillBeAdded: {
    i18nKey: 'amountWillBeAdded',
    defaults: 'Amount will be added to the account balance',
  },
  enterAmount: {
    i18nKey: 'enterAmount',
    defaults: 'Enter Amount',
  },
  topUpBalanceSuccess: {
    i18nKey: 'topUpBalanceSuccess',
    defaults: 'Your demo balance has been topped up with {{amount}}! Go to <0>Dashboard</0>'
  },
  topUpDemoMax: {
    i18nKey: 'topUpDemoMax',
    defaults: 'Demo account balance is maxed.'
  },
  topUpBalanceError: {
    i18nKey: 'topUpBalanceError',
    defaults: 'Demo account has a maximum balance of {{amount}}'
  },
  topUpNumbersError: {
    i18nKey: 'topUpNumbersError',
    defaults: 'Please enter whole numbers without any symbols'
  },
  totalAccountBalanceUpdated: {
    i18nKey: 'totalAccountBalanceUpdated',
    defaults: 'Total account balance will be updated to this amount',
  },
  forexMt4blackDemoDesc:{
    i18nKey: 'forexMt4blackDemoDesc',
    defaults:  'Practice trading with reduced spreads, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4rawDemoDesc:{
    i18nKey: 'forexMt4rawDemoDesc',
    defaults:  'Practice trading with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  selectTradingAccount: {
    i18nKey: 'selectTradingAccount',
    defaults: 'Select Trading Account to Open',
  },
  noteAccessMt5: {
    i18nKey: 'noteAccessMt5',
    defaults: 'Please note that in order to access MT5 accounts you will need to click on the respective account directly under the accounts section of the dashboard.',
  },
  mt5TTradeNoteTitle: {
    i18nKey: 'mt5TTradeNoteTitle',
    defaults: 'Download MT5 or Open a New Trading Account',
  },
  mt5TTradeNote: {
    i18nKey: 'mt5TTradeNote',
    defaults: 'For easy access to your trading accounts through our webtrader please <0>open an MT4 trading account</0>, otherwise follow the steps below to access your MT5 account.',
  },
  fundYourAccountText: {
    i18nKey: 'fundYourAccountText',
    defaults: '<1>Fund your account<1>',
  },
  downloadServerDesktopApplication: {
    i18nKey: 'downloadServerDesktopApplication',
    defaults: '<0>Download</0> the {{server}} Desktop Application',
  },
  openServerApplication: {
    i18nKey: 'openServerApplication',
    defaults: 'Open the {{server}} Application',
  },
  addAccountLoginAccount: {
    i18nKey: 'addAccountLoginAccount',
    defaults: 'Add your account by clicking to «Login to a trade account»',
  },
  enterAccountDetails: {
    i18nKey: 'enterAccountDetails',
    defaults: 'Enter your account details:',
  },
  passwordMT5Note: {
    i18nKey: 'passwordMT5Note',
    defaults: '*Your Password*',
  },
  loginMT5Note: {
    i18nKey: 'loginMT5Note',
    defaults: '{{server}} account ID (found under <0>dashboard accounts</0>)',
  },
  live: {
    i18nKey: 'live',
    defaults: 'Live',
  },
  moreInfo: {
    i18nKey: 'moreInfo',
    defaults: 'More Info',
  },
  download: {
    i18nKey: 'download',
    defaults: 'Download',
  },
  registrationDisabledError: {
    i18nKey: 'registrationDisabledError',
    defaults: 'We regret to inform you that we temporarily cannot accept new clients until further notice. Thank you for your understanding.',
  },
  pleaseNoteColon:{
    i18nKey: 'pleaseNoteColon',
    defaults: 'Please Note:'
  },
  noWithdrawalPop:{
    i18nKey: 'noWithdrawalPop',
    defaults: 'You will not be able to withdraw funds without uploading valid Proof(s) of Payment in this section due to Anti Money Laundering (AML) regulation.'
  },
  documentsSubjectReview:{
    i18nKey: 'documentsSubjectReview',
    defaults: '* The documents are subject to review and approval.'
  },
  willDoItLater:{
    i18nKey: 'willDoItLater',
    defaults: 'I’ll do it later'
  },
  uploadTheDoc:{
    i18nKey: 'uploadTheDoc',
    defaults: 'Upload the {{docCategory}}'
  },
  pof:{
    i18nKey: 'pof',
    defaults: 'POF'
  },
  tradingPsychologyTitle: {
    i18nKey: 'tradingPsychologyTitle',
    defaults: 'Psychology of Trading',
  },
  tradingPsychologySubtitle: {
    i18nKey: 'tradingPsychologySubtitle',
    defaults: 'A guide to understanding the psychology that could impact trading experience. <0 />Learn how to:',
  },
  tradingPsychologyListItem1: {
    i18nKey: 'tradingPsychologyListItem1',
    defaults: 'Establish the proper trading mindset',
  },
  tradingPsychologyListItem2: {
    i18nKey: 'tradingPsychologyListItem2',
    defaults: 'Develop and follow appropriate trading strategies',
  },
  tradingPsychologyListItem3: {
    i18nKey: 'tradingPsychologyListItem3',
    defaults: 'Increase your risk-taking capacity',
  },
  tradingPsychologyListItem4: {
    i18nKey: 'tradingPsychologyListItem4',
    defaults: 'Safeguard your trades and manage your risk',
  },
  tradingPsychologyListItem5: {
    i18nKey: 'tradingPsychologyListItem5',
    defaults: 'Control fear, greed and other negative emotions',
  },
  tradingPsychologyListItem6: {
    i18nKey: 'tradingPsychologyListItem6',
    defaults: 'Avoid trading emotionally',
  },
  tradingPsychologyListItem7: {
    i18nKey: 'tradingPsychologyListItem7',
    defaults: 'Understand trading biases and how to overcome them',
  },
  tradingPsychologyListItem8: {
    i18nKey: 'tradingPsychologyListItem8',
    defaults: 'Take care of your health during trading',
  },
  ebookNew: {
    i18nKey: 'ebookNew',
    defaults: 'Forex eBooks',
  },
  ebookDescriptionNew: {
    i18nKey: 'ebookDescriptionNew',
    defaults: 'Our ebooks cover a range of insightful topics from basic fundamental principles to advanced trading strategies.',
  },
  officialSponsorOf:{
    i18nKey: 'officialSponsorOf',
    defaults: 'Official Sponsor Of',
  },
  centDesc: {
    i18nKey: 'centDesc',
    defaults: 'Start trading in cents from as low as $10 and reduce your exposure by 100 times. Buy or Sell over 70 forex and commodity pairs with up to 1:2000 leverage, access a range of free tools, and enjoy 24/5 multi-lingual support.'
  },
  receiveInCUD: {
    i18nKey: 'receiveInCUD',
    defaults: 'You will receive {{amount}} USD cents (CUD) on your balance'
  },
  withdrawInCUD: {
    i18nKey: 'withdrawInCUD',
    defaults: 'You will withdraw {{amount}} USD cents (CUD) from your balance'
  },
  minDepositAmountUsd: {
    i18nKey: 'minDepositAmountUsd',
    defaults: 'Minimum deposit amount'
  },
  minDepositAmountUsdText: {
    i18nKey: 'minDepositAmountUsdText',
    defaults: '{{value}} USD'
  },
  averageMetalsEnergiesSpread: {
    i18nKey: 'averageMetalsEnergiesSpread',
    defaults: 'Average metals/energies spread',
  },
  averageMetalsEnergiesSpreadText: {
    i18nKey: 'averageMetalsEnergiesSpreadText',
    defaults: '{{value}} pips',
  },
  tradingCentralTools: {
    i18nKey: 'tradingCentralTools',
    defaults: 'Trading Central Tools',
  },
  upTo500Leverage: {
    i18nKey: 'upTo500Leverage',
    defaults: 'Up to 1:500 Leverage',
  },
  equivalentPerLotZero: {
    i18nKey: 'equivalentPerLotZero',
    defaults: '{{value}}'
  },
  forexMt4premiumDesc: {
    i18nKey: 'forexMt4premiumDesc',
    defaults: 'Trade gold, oil, and forex pairs with tighter spreads and higher leverage of up to 1:1000 on all fx pairs, up to 1:200 on gold and up to 1:100 on oil. Access free analysis tools including Autochartist and Trading Central, live webinars, expert support, and a wealth of educational resources.',
  },
  equityWarning: {
    i18nKey: 'equityWarning',
    defaults: 'The selected deposit amount will increase your account equity to over the {{amount}} threshold, which will in turn automatically reduce your account’s maximum leverage to 1:500.',
  },
  selectAmountEquityWarning: {
    i18nKey: 'selectAmountEquityWarning',
    defaults: 'Please note that if your equity exceeds {{amount}} as a result of this deposit, your leverage will be reduced to 1:500.',
  },
  upToLeverage: {
    i18nKey: 'upToLeverage',
    defaults: 'Up to 1:1000 Leverage*',
  },
  leverageNote: {
    i18nKey: 'leverageNote',
    defaults: '*Please note that if your account equity exceeds the $25,000 threshold, then your account’s maximum available leverage will be automatically reduced to 1:500.',
  },
  footerBonus: {
    i18nKey:'footerBonus',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure that you are aware of all our <2>legal documents</2>, <3>T&Cs</3>, <5>Bonus T&Cs</5> and <4>Privacy Policy</4>.<6> </6>'
  },
  toIBPortal: {
    i18nKey:'toIBPortal',
    defaults: 'IB Portal'
  },
  solicitationAcceptanceTitle: {
    i18nKey: 'solicitationAcceptanceTitle',
    defaults: 'Solicitation Acceptance',
  },
  MVEHoldingsLtd: {
    i18nKey: 'MVEHoldingsLtd',
    defaults: 'MVE Holdings Ltd ',
  },
  solicitationAcceptancePart1: {
    i18nKey: 'solicitationAcceptancePart1',
    defaults: 'The information provided on this website does not constitute an offer or solicitation to buy or sell any trading service, security, investment fund, investment advice or other product or service to anyone in any jurisdiction in which an offer or solicitation is not authorized or cannot legally be made or to any person to whom it is unlawful to make an offer or solicitation. If you choose to access this website, you do so at your own exclusive initiative and risk and are responsible for compliance with applicable laws, rules and regulations that may apply to you. In addition, there is no provision of an investor compensation scheme as may apply to your home jurisdiction.',
  },
  solicitationAcceptancePart2: {
    i18nKey: 'solicitationAcceptancePart2',
    defaults: 'By clicking the submit button below, I hereby agree and acknowledge that I have approached the Company at my own exclusive initiative and that no solicitation or recommendation has been made by the Company or any other entity within the group and that I understand the risks associated with trading of derivative instruments.',
  },
  accessNow: {
    i18nKey: 'accessNow',
    defaults: 'Access Now',
  },
  underLoginText: {
    i18nKey: 'underLoginText',
    defaults: 'Please enter your details below'
  },
  MVEHoldingsLtdFooter: {
    i18nKey: 'MVEHoldingsLtdFooter',
    defaults: `MVE Holdings Ltd is authorized by the Republic of Marshall Islands with registration no. 111641.
    Address: Ajeltake Road, Majuro, MH96960, Marshall Islands. Registered Email: info@valutamarkets.com`
  },
  lydianSuccessWithdrawal: {
    i18nKey: 'lydianSuccessWithdrawal',
    defaults: 'Your withdrawal has been successfully submitted. Please check your lydian wallet for your funds.'
  },
  completeResidentialAddress: {
    i18nKey: 'completeResidentialAddress',
    defaults: 'To proceed with a payment, please complete your residential address in the personal details section of your account',
  },
  fillAddressInfo: {
    i18nKey: 'fillAddressInfo',
    defaults: 'Fill in address information',
  },
  competitionPosition: {
    i18nKey: 'competitionPosition',
    defaults: 'Competition Position'
  },
  prize: {
    i18nKey: 'prize',
    defaults: 'Prize'
  },
  acceptNonEURegulation: {
    i18nKey: 'acceptNonEURegulation',
    defaults: 'Please note that you are entering the site of Valuta Markets, trademark of MVE Holdings Limited, authorized by Marshall Island with registration number 11164 and regulated by MISA with license number T2022103.\nBy clicking Continue, you acknowledge the information below. We are not regulated by any jurisdiction in the European Union (EU). Therefore you will fall outside of the EU\'s regulatory regime - MiFID II - and you will therefore lose all protections afforded under EU regulation and law.',
  },
  leaveWebsite: {
    i18nKey: 'leaveWebsite',
    defaults: 'Leave the website',
  },
  clickForFurtherInfo: {
    i18nKey: 'clickForFurtherInfo',
    defaults: 'Click here for further information',
  },
  tradingComingSoon: {
    i18nKey: 'tradingComingSoon',
    defaults: 'New Trading Platform coming soon!'
  },
  pausedTrading: {
    i18nKey: 'pausedTrading',
    defaults: 'Trading and Copy trading are temporarily paused for an exciting improvement!'
  },
  mauMT4TermsTitle: {
    i18nKey: 'mauMT4TermsTitle',
    defaults: 'Review Our Terms Before Proceeding',
  },
  mauMT4TermsBody1:{
    i18nKey: 'mauMT4TermsBody1',
    defaults: 'By clicking the button below, you confirm that you have read, understood, and agreed to the following: <0>General Terms & Conditions</0>, <1>Legal Documents </1>, <2>Personal Data Protection</2>',
  },
  mauMT4TermsBody2:{
    i18nKey: 'mauMT4TermsBody2',
    defaults: 'We are committed to protecting your personal data and ensuring a secure, seamless experience on our platform. For more details on how we manage your information,please visit our <0>Privacy Policy</0>.',
  },
  mauMT4TermsCancel: {
    i18nKey: 'mauMT4TermsCancel',
    defaults: 'CANCEL',
  },
  mauMT4TermsAccept: {
    i18nKey: 'mauMT4TermsAccept',
    defaults: 'Accept and create account',
  },
  bonusTermsTitle: {
    i18nKey: 'bonusTermsTitle',
    defaults: 'Please review our Bonus Terms and Conditions',
  },
  bonusTermsBody: {
    i18nKey: 'bonusTermsBody',
    defaults: 'Dear client,\nPlease make sure you have read and accepted our bonus terms and conditions.\nAs a small reminder, we would like to notify you:\n',
  },
  bonusTermsBodyReminder1: {
    i18nKey: 'bonusTermsBodyReminder1',
    defaults: 'Once the bonus is applied to your account, you will not be able to withdraw or transfer funds from the account with a bonus while it has open positions.'
  },
  bonusTermsBodyReminder2: {
    i18nKey: 'bonusTermsBodyReminder2',
    defaults: 'In order to withdraw or transfer funds, all positions on the account must be closed.'
  },
  bonusTermsBodyReminder3: {
    i18nKey: 'bonusTermsBodyReminder3',
    defaults: 'The bonus is used as an equity boosting tool. Once a withdrawal is made, the bonus will be removed from the account.'
  },
  continueWithoutBonus: {
    i18nKey: 'continueWithoutBonus',
    defaults: 'Continue without Bonus',
  },
  acceptAndContinueWithBonus: {
    i18nKey: 'continueWithBonus',
    defaults: 'Accept and Continue',
  },
  depositToEnableFeature: {
    i18nKey: 'depositToEnableFeature',
    defaults: 'Deposit now to enable this feature!',
  },
  contactToEnableTradingAcademy: {
    i18nKey: 'contactToEnableTradingAcademy',
    defaults: 'Contact our <0>Support Team</0> to enable this feature!',
  },
  italianUserNotification: {
    i18nKey: 'italianUserNotification',
    defaults: 'We are working on new and exciting products for our clients! In the meantime, you can access all functions in your account below. Thanks for your continued support.'
  },
  notifyWithdrawOpenPositions: {
    i18nKey: 'notifyWithdrawOpenPositions',
    defaults: 'In order to withdraw or transfer from an account with a bonus, please close all open positions',
  },
  tradingRestricted: {
    i18nKey: 'tradingRestricted',
    defaults: 'Your trading is restricted',
  },
  depositNeeded: {
    i18nKey: 'depositNeeded',
    defaults: 'Deposit Needed',
  },
  disconnected: {
    i18nKey: 'disconnected',
    defaults: 'Disconnected',
  },
  pammInvestorReconnectTitle: {
    i18nKey: 'pammInvestorReconnectTitle',
    defaults: "You've disconnected from PAMM account",
  },
  reconnect: {
    i18nKey:'reconnect',
    defaults: 'Reconnect',
  },
  pammInvestorReconnectExplanation: {
    i18nKey: 'pammInvestorReconnectExplanation',
    defaults: 'You have been disconnected from this PAMM account. To rejoin, please select "Reconnect". If you wish to withdraw your funds, please select "Withdraw"',
  },
  pammInvestorMinimumDepositRequiredTitle: {
    i18nKey: 'pammInvestorMinimumDepositRequiredTitle',
    defaults: 'Minimum Investment Required',
  },
  pammInvestorMinimumDepositRequiredLine1: {
    i18nKey: 'pammInvestorMinimumDepositRequiredLine1',
    defaults: 'To connect your PAMM investor account with your money manager, a minimum investment of {{amount}} is required. Your current balance is {{balance}}. Please make a deposit to meet the required amount and proceed.',
  },
  pammInvestorMinimumDepositRequiredLine2: {
    i18nKey: 'pammInvestorMinimumDepositRequiredLine2',
    defaults: 'For any assistance, feel free to contact us at <0>{{supportEmail}}</0>',
  },
  ibAccountPendingTitle: {
    i18nKey: 'ibAccountPendingTitle',
    defaults: 'Approval Pending'
  },
  ibAccountPendingLine1: {
    i18nKey: 'ibAccountPendingLine1',
    defaults: "Your IB request is pending approval. We'll update you as soon as it's confirmed.",
  },
  ibAccountPendingLine2: {
    i18nKey: 'ibAccountPendingLine2',
    defaults: "For any questions or concerns, please contact us at <0>{{supportEmail}}</0>",
  },
  registerServerError: {
    i18nKey: 'registerServerError',
    defaults: "We encountered an unexpected issue while loading this form. Please try again later.",
  },
  promoCode: {
    i18nKey: 'promoCode',
    defaults: 'Promo Code'
  },
  wrongPromoCode: {
    i18nKey: 'wrongPromoCode',
    defaults: 'Invalid Promo Code'
  },
  welcomeMessagePopupTitle: {
    i18nKey: 'welcomeMessagePopupTitle',
    defaults: 'Congratulations! Your Trading Account is Ready.'
  },
  welcomeMessagePopupLine1: {
    i18nKey: 'welcomeMessagePopupLine1',
    defaults: 'You’re all set to start trading! Here are your account details:',
  },
  mt4Login: {
    i18nKey:'mt4Login',
    defaults: 'MT4 Login'
  },
  mt4Password: {
    i18nKey:'mt4Password',
    defaults: 'MT4 Password'
  },
  mt4Server: {
    i18nKey:'mt4Server',
    defaults: 'MT4 Server'
  },
  mt5Login: {
    i18nKey:'mt5Login',
    defaults: 'MT5 Login'
  },
  mt5Password: {
    i18nKey:'mt5Password',
    defaults: 'MT5 Password'
  },
  mt5Server: {
    i18nKey:'mt5Server',
    defaults: 'MT5 Server'
  },
  passwordPlaceholderTauro: {
    i18nKey: 'passwordPlaceholderTauro',
    defaults: 'The password you have set for your Tauro profile'
  },
  welcomeMessagePopupLine2: {
    i18nKey: 'welcomeMessagePopupLine2',
    defaults: 'If you have any questions, you may contact our support team anytime.',
  },
  iHaveNoted: {
    i18nKey: 'iHaveNoted',
    defaults: 'I have noted my credentials and will not share them with anyone.'
  },
  takeMeToMyTradingAccount: {
    i18nKey: 'takeMeToMyTradingAccount',
    defaults: 'Take me to my trading account',
  },
  pammInvestorDashboard: {
    i18nKey: 'pammInvestorDashboard',
    defaults: 'PAMM Investor Dashboard'
  },
  availableOffers: {
    i18nKey: 'availableOffers',
    defaults: 'Available Offers'
  },
  singleLevelPerformanceFee: {
    i18nKey: 'singleLevelPerformanceFee',
    defaults: 'Performance Fee: {{fee}}%'
  },
  investors: {
    i18nKey: 'investors',
    defaults: 'Total Investors: {{activeInvestmentCount}}'
  },
  multipleLevelPerformanceFees: {
    i18nKey: 'multipleLevelPerformanceFees',
    defaults: 'Multi-Level Fee Applied'
  },
  tradingInterval: {
    i18nKey: 'tradingInterval',
    defaults: 'Trading Interval'
  },
  weeks: {
    i18nKey: 'weeks',
    defaults: 'Weeks'
  },
  months: {
    i18nKey:'months',
    defaults: 'Months'
  },
  calendarMonths: {
    i18nKey: 'calendarMonths',
    defaults: 'Months'
  },
  day: {
    i18nKey: 'day',
    defaults: 'Day'
  },
  week: {
    i18nKey: 'week',
    defaults: 'Week'
  },
  calendarMonth: {
    i18nKey: 'calendarMonth',
    defaults: 'Month'
  },
  minDeposit: {
    i18nKey: 'minDeposit',
    defaults: 'Min. Deposit'
  },
  minWithdrawal: {
    i18nKey: 'minWithdrawal',
    defaults: 'Min. Withdrawal'
  },
  minInitialInvestment: {
    i18nKey:'minInitialInvestment',
    defaults: 'Min. Initial Investment'
  },
  joinLink: {
    i18nKey: 'joinLink',
    defaults: 'Join Link'
  },
  offerDetails: {
    i18nKey: 'offerDetails',
    defaults: 'Offer Details'
  },
  linkReuseInfo: {
    i18nKey: 'linkReuseInfo',
    defaults: 'Leaving this offer now means you’ll need the original link to access it again',
  },
  feeStructureTitle: {
    i18nKey: 'feeStructureTitle',
    defaults: 'Fee Structure'
  },
  feeStructureTooltip: {
    i18nKey: 'feeStructureTooltip',
    defaults: 'A performance fee is a percentage of profits paid to the money manager at the end of each trading period or upon withdrawal.'
  },
  performanceFees: {
    i18nKey: 'performanceFees',
    defaults: 'Performance Fees'
  },
  enterPasswordDescription: {
    i18nKey: 'enterPasswordDescription',
    defaults: 'Please enter your account login password'
  },
  invest: {
    i18nKey: 'invest',
    defaults: 'Invest'
  },
  noOffers: {
    i18nKey: 'noOffers',
    defaults: "You haven't set up any offers. Set them up in your PAMM portal, and they'll be ready on this page to share with investors through a special link."
  },
  feeStructureHeader: {
    i18nKey: 'feeStructureHeader',
    defaults: 'Fee Structure'
  },
  feeStructureHeaderTooltip: {
    i18nKey: 'feeStructureHeaderTooltip',
    defaults: 'A performance fee is a percentage of profits paid to the money manager. It’s paid at the end of each trading period or if the investor withdraws. If there are losses, no fee is charged until profits cover these losses. Fees may vary based on each investor’s total funds.'
  },
  alreadyAcceptedHeader: {
    i18nKey: 'alreadyAcceptedHeader',
    defaults: 'You Are Already Connected to This Offer'
  },
  alreadyAcceptedContent: {
    i18nKey: 'alreadyAcceptedContent',
    defaults: 'PAMM trading allows your Money Manager to handle the trading for you, with profits or losses distributed based on your share of the pool. Your investment is already in good hands with your Money Manager. To stay updated or review performance, head to your PAMM Portal, where you’ll find everything you need to track your progress.',
  },
  toPammPortal: {
    i18nKey: 'toPammPortal',
    defaults: 'Go to PAMM portal'
  },
  differentPartnerWarningHeader: {
    i18nKey: 'differentPartnerWarningHeader',
    defaults: 'You Have a Different Partner – Here’s How to Proceed',
  },
  differentPartnerWarningLine1: {
    i18nKey: 'differentPartnerWarningLine1',
    defaults: 'You have chosen a different partner than the one you initially signed up with. If you want to continue using PAMM trading, you must first sign up for your new partner and link your accounts. Once you have linked your accounts, you can resume your PAMM trading experience.'
  },
  differentPartnerWarningLine2: {
    i18nKey: 'differentPartnerWarningLine2',
    defaults: 'To join the new offer with this partner, please log out and create a new profile with Tauro Markets using the same invitation',
  },
  signOutAndCreateNewProfile: {
    i18nKey: 'signOutAndCreateNewProfile',
    defaults: 'Sign Out and Create a New Profile'
  },
  differentPartnerWarningNotes: {
    i18nKey: 'differentPartnerWarningNotes',
    defaults: 'Note: Creating a new profile won’t affect your existing account.',
  },
  pammFeesAccount: {
    i18nKey: 'pammFeesAccount',
    defaults: 'PAMM Fees Account'
  },
  pammTradesAccount: {
    i18nKey: 'pammTradesAccount',
    defaults: 'PAMM Trades Account'
  },
  pammInvestorPendingTitle: {
    i18nKey: 'pammInvestorPendingTitle',
    defaults: 'Deposit Approval Pending',
  },
  pammInvestorPendingLine1: {
    i18nKey: 'pammInvestorPendingLine1',
    defaults: 'The Money Manager needs to approve your deposit request before you can join the offer.',
  },
  pammInvestorPendingLine2: {
    i18nKey: 'pammInvestorPendingLine2',
    defaults: "For any questions, don't hesitate to contact us at <0>{{supportEmail}}</0>",
  },
  moneyManagerID: {
    i18nKey:'moneyManagerID',
    defaults: 'Money Manager ID'
  },
  fees: {
    i18nKey: 'fees',
    defaults: 'Fees'
  },
  trade: {
    i18nKey: 'trade',
    defaults: 'Trade'
  },
  enableMFA: {
    i18nKey: 'enableMFA',
    defaults: 'Enable Multi-Factor Authentication (MFA)'
  },
  setupYourAuthenticatorApp: {
    i18nKey:'setupYourAuthenticatorApp',
    defaults: 'Setup Your Authenticator App',
  },
  downloadAuthenticatorApp: {
    i18nKey: 'downloadAuthenticatorApp',
    defaults: 'Download an authenticator app'
  },
  getStartedMFA: {
    i18nKey: 'getStartedMFA',
    defaults: 'Get started by downloading an app like <0>Google Authenticator</0>, <1>Microsoft Authenticator</1>, etc.'
  },
  linkYourAuthenticatorApp: {
    i18nKey: 'linkYourAuthenticatorApp',
    defaults: 'Link your authenticator app'
  },
  linkYourAuthenticatorAppText: {
    i18nKey: 'linkYourAuthenticatorAppText',
    defaults: "Next, use your authenticator app to scan the QR code below. If you can't scan the code, you can also enter the following setup key: <0>{{code}}</0>"
  },
  enterConfirmationCode: {
    i18nKey: 'enterConfirmationCode',
    defaults: 'Enter the confirmation code'
  },
  useAuthenticatorApp: {
    i18nKey: 'useAuthenticatorApp',
    defaults: 'Finally, use your authenticator app to generate a 6-digit confirmation code and enter it below.',
  },
  verify: {
    i18nKey:'verify',
    defaults: 'Verify'
  },
  invalidTotpCode: {
    i18nKey: 'invalidTotpCode',
    defaults: 'Invalid TOTP code'
  },
  cancelTotp: {
    i18nKey: 'cancelTotp',
    defaults: 'Changed your mind? Choose a different authentication method'
  },
  verifyWithYourAuthenticatorApp: {
    i18nKey:'verifyWithYourAuthenticatorApp',
    defaults: 'Verify with Your Authenticator App'
  },
  useTheCode: {
    i18nKey: 'useTheCode',
    defaults: 'Use the code from your authenticator app to confirm your identity and log in.'
  },
  enableMFATooltip: {
    i18nKey: 'enableMFATooltip',
    defaults: 'Two-factor authentication protects your account by adding an extra security step when you sign in.',
  },
  authenticatorApp: {
    i18nKey: 'authenticatorApp',
    defaults: 'Authenticator App'
  },
  useAnyAuthenticatorApp: {
    i18nKey: 'useAnyAuthenticatorApp',
    defaults: 'Use any Authenticator app to protect your account and transactions.',
  },
  totpAlreadyEnabled: {
    i18nKey: 'totpAlreadyEnabled',
    defaults: 'Two-factor authentication is already enabled for this account.'
  },
  totpSetupSuccessTitle: {
    i18nKey: 'totpSetupSuccessTitle',
    defaults: 'Multi-Factor Authentication Enabled Successfully'
  },
  totpSetupSuccessContent: {
    i18nKey: 'totpSetupSuccessContent',
    defaults: "You've successfully set up the authenticator app for added account security"
  },
  disableAuthenticatorApp: {
    i18nKey: 'disableAuthenticatorApp',
    defaults: 'Disable Authenticator App'
  },
  disableAuthenticatorAppWarning: {
    i18nKey: 'disableAuthenticatorAppWarning',
    defaults: 'Removing the authenticator app will make your account less secure. If this is your only method for multi-factor authentication, disabling it will completely turn off MFA for your account. Proceed with caution.',
  },
  disable: {
    i18nKey: 'disable',
    defaults: 'Disable'
  },
  enterYourAccountPassword: {
    i18nKey: 'enterYourAccountPassword',
    defaults: 'Enter your account password to confirm'
  },
  enterTheCode: {
    i18nKey: 'enterTheCode',
    defaults: 'Enter the code from your authenticator app'
  },
  forYourSecurity: {
    i18nKey: 'forYourSecurity',
    defaults: 'For your security, use the code generated by your authenticator app to proceed'
  },
  removeAuthenticator: {
    i18nKey:'removeAuthenticator',
    defaults: 'Remove Authenticator'
  },
  nevermindKeepIt: {
    i18nKey: 'nevermindKeepIt',
    defaults: "Nevermind, I'll keep it"
  },
  totpRemovalSetupSuccessTitle: {
    i18nKey: 'totpRemovalSetupSuccessTitle',
    defaults: 'Authenticator App Disabled Successfully'
  },
  totpRemovalSetupSuccessContent: {
    i18nKey: 'totpRemovalSetupSuccessContent',
    defaults: "If you've set up other verification methods, you can continue using them to secure your account. For added security, you can re-enable it again or explore additional options in your account settings.",
  },
  invalidPassword: {
    i18nKey: 'invalidPassword',
    defaults: 'Invalid password'
  },
  staySigned: {
    i18nKey:'staySigned',
    defaults: 'Stay signed in on this device for 30 days (trusted devices only)'
  },
  mt5Account: {
    i18nKey:'mt5Account',
    defaults: 'MT5 Account'
  },
  mt4Account: {
    i18nKey:'mt4Account',
    defaults: 'MT4 Account'
  },
  noLowercaseLetters: {
    i18nKey: 'noLowercaseLetters',
    defaults: 'Password should include at least one lowercase letter.'
  },
  noUppercaseLetters: {
    i18nKey: 'noUppercaseLetters',
    defaults: 'Password should include at least one uppercase letter.'
  },
  noSpecialCharacters: {
    i18nKey: 'noSpecialCharacters',
    defaults: 'Password should include at least one special character.'
  },
  viewOffers: {
    i18nKey: 'viewOffers',
    defaults: 'View offers'
  },
  view: {
    i18nKey: 'view',
    defaults: 'View'
  }
}
